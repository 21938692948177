import React, { useCallback, useReducer } from 'react';

/* Data Things */
import {
  IHostAccommodationListItemDTO,
  IUpdateHostIndividualDTO,
  ICreateHostIndividualDTO,
  IUpdateHostCompanyDTO,
  ICreateHostCompanyDTO,
  IHostIndividualDTO,
  IHostCompanyDTO,
  IHostListDTO,
  IHostDTO
} from '../../../typings/DTOs';
import { CompanyHost, HostFilter, IndividualHost } from './helper';
import { HostsState, initialState, hostsReducer } from '../../../reducers/Partners/Hosts';
import { ApiErrorResult, api } from '../../../utils/api';
import { useIsMounted } from '../../../hooks';
import { Order } from '../../../typings/common';

/* Presentation Things */
import { Snackbar } from '../../../components/Snackbar';

interface IHostsContext extends HostsState {
  onEditIndividualHost: (host: IUpdateHostIndividualDTO, id: number) => Promise<string | null>;
  onAddIndividualHost: (host: ICreateHostIndividualDTO) => Promise<string | null>;
  onEditCompanyHost: (host: IUpdateHostCompanyDTO, id: number) => Promise<string | null>;
  onAddCompanyHost: (host: ICreateHostCompanyDTO) => Promise<string | null>;
  onGetHosts: (text?: string, signal?: AbortSignal, page?: number, filter?: Object) => Promise<string | null>;
  onGetHost: (id: number) => Promise<string | null>;
  onFilter: (filter: HostFilter) => void;
}

const HostsContext = React.createContext<IHostsContext>({
  ...initialState,
  onEditIndividualHost: () => Promise.reject(),
  onAddIndividualHost: () => Promise.reject(),
  onEditCompanyHost: () => Promise.reject(),
  onAddCompanyHost: () => Promise.reject(),
  onGetHosts: () => Promise.reject(),
  onGetHost: () => Promise.reject(),
  onFilter: () => null
});

function HostsProvider(props: React.PropsWithChildren<{}>) {
  /* States */
  const [state, dispatch] = useReducer(hostsReducer, initialState);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetHosts = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof (CompanyHost | IndividualHost) = 'name', order: Order = 'ASC'): Promise<string | null> => {
      try {
        const hosts = await api<IHostListDTO>('host', { search, filter, sort, order, limit: 15, offset: (page - 1) * 15 }, { signal });

        if (!hosts.message && _isMounted.current) {
          dispatch({ type: 'success_get_hosts', payload: { hosts, page, sort, order } });

          return Promise.resolve(null);
        } else throw new Error(hosts.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_get_hosts', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onGetHost = useCallback(
    async (id: number): Promise<string | null> => {
      try {
        const [host, accommodations] = await Promise.all([api<IHostDTO>(`host/${id}`), api<IHostAccommodationListItemDTO[]>(`host/${id}/accommodations`)]);
        if (!host.message && !accommodations.message && _isMounted.current) {
          dispatch({ type: 'success_get_host', payload: { host, accommodations } });

          return Promise.resolve(null);
        } else throw new Error(host.message || accommodations.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onEditCompanyHost = useCallback(
    async (body: IUpdateHostCompanyDTO, id: number): Promise<string | null> => {
      try {
        const host = await api<IHostCompanyDTO>(`host/company/${id}`, undefined, { method: 'PUT', body });

        if (!host.message && _isMounted.current) {
          dispatch({ type: 'success_put_company', payload: { host } });

          return Promise.resolve(null);
        } else throw new Error(host.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_put_company', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAddCompanyHost = useCallback(
    async (body: ICreateHostCompanyDTO): Promise<string | null> => {
      try {
        const company = await api<IHostCompanyDTO>('host/company', undefined, { method: 'POST', body });

        if (!company.message && _isMounted.current) {
          dispatch({ type: 'success_post_company', payload: { company } });

          return Promise.resolve(null);
        } else throw new Error(company.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_post_company', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onEditIndividualHost = useCallback(
    async (body: IUpdateHostIndividualDTO, id: number): Promise<string | null> => {
      try {
        const host = await api<IHostIndividualDTO>(`host/individual/${id}`, undefined, { method: 'PUT', body });

        if (!host.message && _isMounted.current) {
          dispatch({ type: 'success_put_individual', payload: { host } });

          return Promise.resolve(null);
        } else throw new Error(host.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_put_individual', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAddIndividualHost = useCallback(
    async (body: ICreateHostIndividualDTO): Promise<string | null> => {
      try {
        const individual = await api<IHostIndividualDTO>('host/individual', undefined, { method: 'POST', body });

        if (!individual.message && _isMounted.current) {
          dispatch({ type: 'success_post_individual', payload: { individual } });

          return Promise.resolve(null);
        } else throw new Error(individual.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_post_individual', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAutoCloseResponse = useCallback(() => dispatch({ type: 'set_partial_state', payload: { response: null } }), []);

  const onFilter = useCallback((filter: HostFilter) => dispatch({ type: 'set_filter', payload: { filter } }), []);

  return (
    <HostsContext.Provider value={{ ...state, onEditIndividualHost, onEditCompanyHost, onAddIndividualHost, onAddCompanyHost, onGetHosts, onGetHost, onFilter }}>
      <Snackbar opened={!!state.response} label={state.response!?.message} type={state.response!?.type} onAutoClose={onAutoCloseResponse} />
      {props.children}
    </HostsContext.Provider>
  );
}

export { HostsProvider, HostsContext };
