import { IClientDTO, IClientListItemDTO } from "../../../typings/DTOs";

export class Client {
    public id: number;
    public name: string;
    public taxNumber: string;
    public isActive: boolean;
    public description: string;

    constructor(p: IClientListItemDTO | IClientDTO) {
        this.id = p.id;
        this.name = p.name || "";
        this.taxNumber = p.taxNumber || "";
        this.isActive = p.isActive;
        this.description = (p as IClientDTO)?.description || ""
    }
}

export type ClientFilter = { status: 'active' | 'inactive' | null }