/* Navigation things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { AccommodationsProvider } from '../../contexts/Accommodations';

/* Screens */
import CreateOrEdit from './CreateOrEdit';
import Details from './Details';
import List from './List';

const Accommodations = (_: RouteComponentProps) => {
  return (
    <AccommodationsProvider>
      <Router basepath="szallasok" primary={false}>
        <Details path="/:id/:year/:month" />
        <Details path="/:id" />
        <CreateOrEdit path="szerkesztes/:id" />
        <CreateOrEdit path="hozzaadas" />
        <List path="/:year/:month" />
        <List default />
      </Router>
    </AccommodationsProvider>
  );
};

export default Accommodations;
