import { Fragment, useState } from 'react';
/* Data things */
import { ID_NAMES } from '../../constants';

/* Presentational Things */
import { usePopper } from 'react-popper';
import { Popover, Transition } from '@headlessui/react';
import { ProbondIdCard, TaxIdCard, IdCard, MoreCard, PassportCard } from '../Icons';

type Props = {
  mobile?: boolean;
  probondId?: string;
  taxIdentificationNumber?: string;
  passportNumber?: string;
  idCardNumber?: string;
  searchValue?: string;
};

export function Identification(props: Props) {
  const { probondId, taxIdentificationNumber, passportNumber, idCardNumber, mobile } = props;
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 0] } },
      { name: 'preventOverflow', enabled: false }
    ]
  });
  let quantity = 0;
  probondId && quantity++;
  taxIdentificationNumber && quantity++;
  passportNumber && quantity++;
  idCardNumber && quantity++;

  if (quantity !== 0) {
    const moreThanOne = quantity > 1;

    if (!moreThanOne) {
      return (
        <div className={`h-5 items-center${mobile ? ' text-sm text-gray-500' : ''}`}>
          {passportNumber ? (
            <PassportCard className="w-6 h-6 mr-1 inline align-middle text-amber-800" />
          ) : idCardNumber ? (
            <IdCard className="w-6 h-6 mr-1 inline align-middle text-gray-500" />
          ) : taxIdentificationNumber ? (
            <TaxIdCard className="w-6 h-6 mr-1 inline align-middle text-green-600" />
          ) : (
            <ProbondIdCard className="w-6 h-6 mr-1 inline align-middle text-[#0D6EFD]" />
          )}
          <span className="truncate w-full align-middle" title={passportNumber || idCardNumber || taxIdentificationNumber || probondId}>
            {passportNumber || idCardNumber || taxIdentificationNumber || probondId}
          </span>
        </div>
      );
    } else {
      return (
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button ref={setReferenceElement} className={`overflow-visible cursor-pointer h-5${mobile ? ' text-sm text-gray-500 leading-none' : ''}`}>
                <MoreCard
                  title={passportNumber ? ID_NAMES.passportNumber : idCardNumber ? ID_NAMES.idCardNumber : taxIdentificationNumber ? ID_NAMES.taxIdentificationNumber : ID_NAMES.probondId}
                  className={`w-6 h-6 mr-1 inline align-middle ${passportNumber ? 'text-amber-800' : idCardNumber ? '' : taxIdentificationNumber ? 'text-green-600' : probondId && 'text-[#0D6EFD]'}`}
                />
                <span className="truncate w-full align-middle" title={passportNumber || idCardNumber || taxIdentificationNumber || probondId}>
                  {passportNumber || idCardNumber || taxIdentificationNumber || probondId}
                </span>
              </Popover.Button>
              <Transition as={Fragment} show={open} enterFrom="translate-y-4 opacity-0" enterTo="translate-y-0 opacity-100" leaveFrom="translate-y-0 opacity-75" leaveTo="translate-y-4 opacity-0">
                <Popover.Panel
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className={`absolute z-30 p-2 -ml-2 -mt-4 ring-offset-0 ring-1 ring-gray-300 bg-white rounded-md shadow${mobile ? ' text-sm text-gray-500 pointer-events-none' : 'pointer-events-auto'}`}>
                  <div className="flex flex-col gap-2">
                    {passportNumber && <Identification passportNumber={passportNumber} />}
                    {idCardNumber && <Identification idCardNumber={idCardNumber} />}
                    {taxIdentificationNumber && <Identification taxIdentificationNumber={taxIdentificationNumber} />}
                    {probondId && <Identification probondId={probondId} />}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      );
    }
  } else return <></>;
}
