import React, { useContext, useEffect, useRef, useState } from 'react';

/* Presentation Things */
import { Dialog, DialogHandle } from '../../../components/Dialog';
import { TableChips } from '../../../components/Table';
import { Fieldset } from '../../../components/Fieldset';
import { Button } from '../../../components/Buttons';
import { Radio } from '../../../components/Inputs';
import { Chips } from '../../../components/Chips';

/* Data Things */
import { ProjectsContext } from '../../../contexts/Projects';
import { ProjectsFilter } from '../../../contexts/Projects/helper';

type FilterBodyProps = {
  onChangeStatus(ev: React.ChangeEvent<HTMLInputElement>): void;
  model: ProjectsFilter;
};

export const Filter = (open: boolean, onClose: () => void, onSubmit: (filter: Object) => Promise<void>) => {
  /* Contexts */
  const {
    projects: { filter },
    onFilter
  } = useContext(ProjectsContext);

  /* States */
  const [tempModel, setTempModel] = useState(filter);

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const handleSubmit = () => {
    onSubmit(tempModel);
    onFilter(tempModel);
    onClose();
  };

  const handleClose = () => {
    setTempModel(filter);
    onClose();
  };

  const onDeselectStatus = () => {
    onSubmit({ ...tempModel, status: null });
    onFilter({ ...tempModel, status: null });
  };

  const onChangeStatus = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTempModel((p) => ({ ...p, status: ev.target.value as 'active' | 'inactive' }));
  };
  useEffect(() => setTempModel(filter), [filter]);

  return (
    <>
      <Dialog
        open={open}
        ref={_dialog}
        onClose={handleClose}
        contents={[
          {
            title: 'Szűrő',
            body: <FilterBody model={tempModel} onChangeStatus={onChangeStatus} />,
            footer: <Button title="Kész" className="grow" onClick={handleSubmit} />
          }
        ]}
      />
      <TableChips hasActiveFilter={Boolean(filter.status)}>{filter.status && <Chips key="status" label={filter.status === 'active' ? 'Aktív' : 'Inaktív'} onDelete={onDeselectStatus} />}</TableChips>
    </>
  );
};

const FilterBody = ({ model, onChangeStatus }: FilterBodyProps) => {
  return (
    <div className="p-5 flex flex-col gap-y-8 text-sm pb-8">
      <Fieldset label="Státusz">
        <Radio title="Aktív" htmlFor="active" value="active" theme="form" onChange={onChangeStatus} checked={model.status === 'active'} />
        <Radio title="Inaktív" htmlFor="inactive" value="inactive" theme="form" onChange={onChangeStatus} checked={model.status === 'inactive'} />
      </Fieldset>
    </div>
  );
};
