import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

/* Presentation Things */
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { IconButton } from '../Buttons';
import { XIcon } from '@heroicons/react/solid';

type DialogContent = {
  title?: string;
  body?: JSX.Element;
  footer?: JSX.Element;
};

interface Props {
  open: boolean;
  contents: DialogContent[];
  onClose(): void;
  className?: string;
}

export type DialogHandle = {
  previousScreen: () => void;
  nextScreen: () => void;
};

const appRoot = document.getElementById('notification') as Element;

// Animation props
const overlay = {
  hide: {
    opacity: 0,
    transition: {
      easings: [0.42, 0, 0.58, 1],
      duration: 0.3,
      delay: 0.1
    }
  },
  show: {
    opacity: 1,
    transition: {
      easings: [0.42, 0, 0.58, 1],
      duration: 0.1
    }
  }
};

const dialog = {
  hide: {
    opacity: 0,
    y: '10%',
    transition: {
      easings: ['easeIn', 'easeOut'],
      duration: 0.1
    }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      easings: ['easeIn', 'easeOut'],
      duration: 0.1,
      delay: 0.1
    }
  }
};

const initial = { opacity: 0, y: '10%' };

export const Dialog = React.forwardRef<DialogHandle, Props>(({ open, contents, onClose, className }, ref) => {
  /* States */
  const [activeScreen, setActiveScreen] = useState(0);

  /* Refs */
  const dialogDiv = useRef<HTMLDivElement>(null);
  const outerDiv = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    previousScreen: onPrevScreen,
    nextScreen: onNextScreen
  }));

  const onNextScreen = () => setActiveScreen((prev) => (prev + 1 > contents.length - 1 ? prev : prev + 1));

  const onPrevScreen = () => setActiveScreen((prev) => Math.max(0, prev - 1));

  const handleClose = () => {
    onClose();
    setActiveScreen(0);
  };

  const onOuterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (outerDiv.current) {
      outerDiv.current.contains(dialogDiv.current);
      handleClose();
    }
  };

  useEffect(() => {
    function onEscapePress(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        handleClose();
      }
    }

    if (open) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('keydown', onEscapePress);
    } else {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', onEscapePress);
    }

    return () => window.removeEventListener('keydown', onEscapePress);
    // eslint-disable-next-line
  }, [open]);

  return ReactDOM.createPortal(
    <AnimatePresence presenceAffectsLayout>
      {open ? (
        <motion.div layout className="fixed flex items-center justify-center z-50 inset-0 cursor-default" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <motion.div variants={overlay} initial={{ opacity: 0 }} animate="show" exit="hide" className="flex items-center justify-center min-h-screen sm:pt-4 sm:px-4 sm:pb-20 text-center sm:p-0">
            <div ref={outerDiv} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onOuterClick} />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
          </motion.div>

          <motion.div
            layout
            variants={dialog}
            initial={initial}
            animate="show"
            exit="hide"
            ref={dialogDiv}
            className={`fixed bottom-0 sm:relative flex sm:align-middle bg-white sm:rounded-lg shadow-xl transform sm:max-w-lg sm:min-w-[500px] sm:min-h-[30vh] max-h-full sm:max-h-[95vh] w-full overflow-x-hidden transition-all duration-300 ${className}`}>
            {contents.map((content, index) => (
              <div key={index} style={{ transform: `translateX(-${activeScreen}00%)` }} className="flex flex-col flex-1 min-w-full shrink-0 transition-all duration-300">
                <div className="relative flex items-center justify-center px-4 pt-5 w-full">
                  {index > 0 && <IconButton className="absolute top-0 bottom-0 my-auto left-0 pl-4 pt-5" icon={<ChevronLeftIcon className="icon-sm right-2" />} onClick={onPrevScreen} />}

                  {content.title && (
                    <h3 className="text-lg text-center font-medium text-gray-900 basis-[fit-content] grow" id="modal-title">
                      {content.title}
                    </h3>
                  )}

                  <IconButton className="absolute top-0 bottom-0 my-auto right-0 pr-4 pt-5" icon={<XIcon className="icon-sm" />} onClick={handleClose} />
                </div>

                <div className="overflow-y-auto flex-1">
                  <AnimatePresence presenceAffectsLayout>{activeScreen === index && content.body}</AnimatePresence>
                </div>

                <div className="p-5 flex gap-x-4 justify-between basis-[fit-content] grow-0">{content.footer}</div>
              </div>
            ))}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>,
    appRoot
  );
});
