import { useEffect, useRef } from 'react';

export function useIsMounted(): { current: boolean } {
  const _isMounted = useRef(false);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);
  return _isMounted;
}
