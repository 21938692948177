import React, { useCallback, useReducer } from 'react';

/* Data Things */
import { ICreateProjectDTO, IProjectDTO, IProjectListDTO, IUpdateProjectDTO } from '../../typings/DTOs';
import { ProjectsState, projectsReducer, initialState } from '../../reducers/Projects';
import { Project, ProjectsFilter } from './helper';
import { ApiErrorResult, api } from '../../utils/api';
import { useIsMounted } from '../../hooks';
import { Order } from '../../typings/common';

/* Presentation Things */
import { Snackbar } from '../../components/Snackbar';

interface IProjectsContext extends ProjectsState {
  onGetProjects: (text?: string, signal?: AbortSignal, page?: number, filter?: Object) => Promise<string | null>;
  onGetProject: (id: number) => Promise<string | null>;
  onEditProject: (body: IUpdateProjectDTO, id: number) => Promise<string | null>;
  onAddProject: (body: ICreateProjectDTO) => Promise<string | null>;
  onFilter: (filter: ProjectsFilter) => void;
}

const ProjectsContext = React.createContext<IProjectsContext>({
  ...initialState,
  onGetProjects: () => Promise.reject(),
  onGetProject: () => Promise.reject(),
  onEditProject: () => Promise.reject(),
  onAddProject: () => Promise.reject(),
  onFilter: () => null
});

function ProjectsProvider(props: React.PropsWithChildren<{}>) {
  /* States */
  const [state, dispatch] = useReducer(projectsReducer, initialState);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetProjects = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof Project = 'workNumber', order: Order = 'ASC'): Promise<string | null> => {
      try {
        const projects = await api<IProjectListDTO>('project', { search, filter, sort, order, limit: 50, offset: (page - 1) * 50 }, { signal });

        if (!projects.message && _isMounted.current) {
          dispatch({ type: 'success_get_projects', payload: { projects, page, sort, order } });

          return Promise.resolve(null);
        } else throw new Error(projects.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_get_projects', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onGetProject = useCallback(
    async (id: number): Promise<string | null> => {
      try {
        const project = await api<IProjectDTO>(`project/${id}`);

        if (!project.message && _isMounted.current) {
          dispatch({ type: 'success_get_project', payload: { project } });

          return Promise.resolve(null);
        } else throw new Error(project.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onEditProject = useCallback(
    async (body: IUpdateProjectDTO, id: number): Promise<string | null> => {
      try {
        const project = await api<IProjectDTO>(`project/${id}`, undefined, { method: 'PUT', body });

        if (!project.message && _isMounted.current) {
          dispatch({ type: 'success_put_project', payload: { project } });

          return Promise.resolve(null);
        } else throw new Error(project.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_put_project', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAddProject = useCallback(
    async (body: ICreateProjectDTO): Promise<string | null> => {
      try {
        const project = await api<IProjectDTO>('project', undefined, { method: 'POST', body });

        if (!project.message && _isMounted.current) {
          dispatch({ type: 'success_post_project', payload: { project } });

          return Promise.resolve(null);
        } else throw new Error(project.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_post_project', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAutoCloseResponse = useCallback(() => dispatch({ type: 'set_partial_state', payload: { response: null } }), []);

  const onFilter = useCallback((filter: ProjectsFilter) => dispatch({ type: 'set_filter', payload: { filter } }), []);

  return (
    <ProjectsContext.Provider value={{ ...state, onAddProject, onEditProject, onGetProject, onGetProjects, onFilter }}>
      <Snackbar opened={!!state.response} label={state.response!?.message} type={state.response!?.type} onAutoClose={onAutoCloseResponse} />
      {props.children}
    </ProjectsContext.Provider>
  );
}

export { ProjectsProvider, ProjectsContext };
