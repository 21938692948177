import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

/* Data Things */
import { getInitialState, transformModelToIUpdateBookingDTO } from './helper';
import { AccommodationEmployeeListItem } from '../../Hotels/Details/Overview/helper';
import { SelectableAccommodation } from '../../../contexts/Config/helper';
import { ApiErrorResult, api } from '../../../utils/api';
import { onChangeInput } from '../../../constants/functions';
import { ConfigContext } from '../../../contexts/Config';
import { useIsMounted } from '../../../hooks';
import { IBookingDTO } from '../../../typings/DTOs';
import { Option } from '../../../components/Select/helper';
import { Model } from '../helper';

/* Presentation Things */
import { Dialog, DialogHandle } from '../../../components/Dialog';
import { SelectDialogBody } from '../../../components/Select';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Button } from '../../../components/Buttons';
import { Input } from '../../../components/Inputs';

type Props = {
  accommodationId: number;
  employee: Partial<AccommodationEmployeeListItem> | null;
  open: boolean;
  onClose(): void;
  onSave(booking: IBookingDTO): void;
};

type FirstStepProps = {
  model: Model;
  onChangeSecondStep(e: React.SetStateAction<'jobNumbers' | 'hotels' | 'secondaryProject' | undefined>): void;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

const EditMoveIn = ({ open, onClose, onSave, employee, accommodationId }: Props) => {
  /* Contexts */
  const { accommodations, workNumbers } = useContext(ConfigContext);

  /* States */
  const [secondStep, setSecondStep] = useState<'jobNumbers' | 'hotels' | 'secondaryProject'>();
  const [model, setModel] = useState(getInitialState(accommodations, workNumbers, employee, accommodationId));

  /* Variables */
  const secondaryWorkNumbers = useMemo(() => {
    return [{ text: '-', value: 0 }, ...workNumbers.filter((x) => x.value !== model.project?.value)];
  }, [workNumbers, model.project]);

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const onSubmit = async (): Promise<string | null> => {
    try {
      if (employee && model.accommodation && model.project && model.movingIn.isValid) {
        const resp = await api<IBookingDTO>(`booking/movein/${employee.id}`, undefined, { method: 'PUT', body: transformModelToIUpdateBookingDTO(model) });

        if (!resp.message) {
          onSave(resp);
          return Promise.resolve(null);
        } else throw new Error(resp.message);
      } else throw new Error('Munkaszám, Szálláshely és Beköltözés dátuma mezők kitöltése kötelező!');
    } catch (error) {
      const { message } = error as ApiErrorResult;
      return Promise.reject(String(message || error));
    }
  };

  const handleClose = () => {
    setSecondStep(undefined);
    onClose();
  };

  const handlePrev = () => {
    setSecondStep(undefined);
    _dialog.current?.previousScreen();
  };

  const onChangeSecondaryProject = (o: Option) => setModel((prev) => ({ ...prev, secondaryProject: o }));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setModel);

  const onChangeJobNumber = (o: Option) => setModel((prev) => ({ ...prev, project: o }));

  const onChangeHotel = (o: Option) => setModel((prev) => ({ ...prev, accommodation: o as SelectableAccommodation }));

  useEffect(() => {
    if (secondStep && _dialog.current) {
      _dialog.current.nextScreen();
    }
  }, [secondStep, _dialog]);

  useEffect(() => {
    setModel(getInitialState(accommodations, workNumbers, employee, accommodationId));
  }, [employee, accommodationId, accommodations, workNumbers]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      ref={_dialog}
      className="h-[95vh]"
      contents={[
        {
          title: 'Beköltözés szerkesztése',
          body: <FirstStep model={model} onChange={handleOnChange} onChangeSecondStep={setSecondStep} />,
          footer: <FirstFooter onClose={handleClose} onSubmit={onSubmit} />
        },
        {
          title: secondStep === 'jobNumbers' ? 'Munkaszám kiválasztása' : secondStep === 'secondaryProject' ? 'Másodlagos munkaszám' : 'Szálláshely kiválasztása',
          body:
            secondStep === 'jobNumbers' ? (
              <SelectDialogBody options={workNumbers} dialog="single" onSelect={onChangeJobNumber} selecteds={model.project ? { [model.project.value]: model.project } : {}} />
            ) : secondStep === 'secondaryProject' ? (
              <SelectDialogBody options={secondaryWorkNumbers} dialog="single" onSelect={onChangeSecondaryProject} selecteds={model.secondaryProject ? { [model.secondaryProject.value]: model.secondaryProject } : {}} />
            ) : (
              <SelectDialogBody options={accommodations} dialog="single" onSelect={onChangeHotel} selecteds={model.accommodation ? { [model.accommodation.value]: model.accommodation } : {}} />
            ),
          footer: <Button title="Ok" className="grow" onClick={handlePrev} />
        }
      ]}
    />
  );
};

const FirstStep = ({ model, onChange, onChangeSecondStep }: FirstStepProps) => {
  const _firstDiv = useRef<HTMLDivElement>(null);

  const setSecondStepToSecondaryJobnumbers = () => onChangeSecondStep('secondaryProject');

  const setSecondStepToJobnumbers = () => onChangeSecondStep('jobNumbers');

  const setSecondStepToHotels = () => onChangeSecondStep('hotels');

  useLayoutEffect(() => _firstDiv.current?.focus(), [_firstDiv]);

  return (
    <form className="space-y-8 m-5">
      <div className="flex items-center space-x-4">
        <div tabIndex={0} className="dialog-select flex-1" onClick={setSecondStepToJobnumbers} ref={_firstDiv}>
          <span className="dialog-select__label">Munkaszám</span>

          <div className="dialog-select__input">
            {model.project?.text}
            <ChevronRightIcon className="icon-xs" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <div tabIndex={0} className="dialog-select flex-1" onClick={setSecondStepToSecondaryJobnumbers}>
          <span className="dialog-select__label">Másodlagos munkaszám</span>

          <div className="dialog-select__input">
            {model.secondaryProject?.text}
            <ChevronRightIcon className="icon-xs" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <div tabIndex={0} className="dialog-select flex-1" onClick={setSecondStepToHotels}>
          <span className="dialog-select__label">Szálláshely</span>

          <div className="dialog-select__input">
            <span>{model.accommodation?.description ?? ''}</span>
            <ChevronRightIcon className="icon-xs" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <Input wrapperClassName="flex-1" type="date" label="Beköltözés dátuma" name="movingIn" value={model.movingIn} onChange={onChange} required />
      </div>

      <div className="flex items-center space-x-4">
        <Input type="date" label="Kiköltözés dátuma" name="movingOut" min={model.movingIn} value={model.movingOut} onChange={onChange} wrapperClassName="flex-1" />
      </div>

      <div className="flex items-center space-x-4">
        <Input wrapperClassName="flex-1" type="number" label="Munkavállaló havi hozzájárulása" unit="Ft" name="contribution" value={model.contribution} onChange={onChange} />
      </div>
    </form>
  );
};

const FirstFooter = ({ onClose, onSubmit }: { onClose(): void; onSubmit(): Promise<string | null> }) => {
  /* Context */
  const { onFeedback } = useContext(ConfigContext);

  /* States */
  const [isLoading, setIsLoading] = useState(false);

  /* Hooks */
  const _isMounted = useIsMounted();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const resp = await onSubmit();
      if (resp === null && _isMounted.current) {
        onFeedback({ type: 'success', message: 'Sikeres beköltözés szerkesztés' });
        onClose();
      }
    } catch (error) {
      _isMounted.current && onFeedback({ type: 'error', message: error as string });
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  return (
    <>
      <Button title="Mégsem" theme="secondary" className="grow" onClick={onClose} disabled={isLoading} />
      <Button title="Mentés" className="relative grow" onClick={handleSubmit} loading={isLoading} disabled={isLoading} />
    </>
  );
};

export default EditMoveIn;
