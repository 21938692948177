import React from 'react';

import { useAuthorization } from '../../hooks';
import { AppRole } from '../../typings/enum';

const HideableDiv = ({
  hiddenFor,
  children,
  ...props
}: React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    hiddenFor: keyof typeof AppRole | (keyof typeof AppRole)[];
  }
>) => {
  /* Hooks */
  const { role } = useAuthorization();

  if (Array.isArray(hiddenFor) ? hiddenFor.includes(role) : role === hiddenFor) return null;

  return <div {...props}>{children}</div>;
};

const HideableFieldset = ({
  hiddenFor,
  children,
  ...props
}: React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> & {
    hiddenFor: keyof typeof AppRole | (keyof typeof AppRole)[];
  }
>) => {
  /* Hooks */
  const { role } = useAuthorization();

  if (Array.isArray(hiddenFor) ? hiddenFor.includes(role) : role === hiddenFor) return null;

  return <fieldset {...props}>{children}</fieldset>;
};

export const Hideable = ({ children }: React.PropsWithChildren<{}>) => children;

Hideable.Fieldset = HideableFieldset;
Hideable.Div = HideableDiv;
