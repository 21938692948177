import { IAccommodationContactDTO, IAccommodationContractDTO, IAccommodationDTO, ICreateAccommodationDTO, IUpdateAccommodationDTO } from '../../../typings/DTOs';
import { SelectableHost, SelectableProject, SelectableBRCManager } from '../../../contexts/Config/helper';
import { ChargeType } from '../../../typings/enum';
import { DateTime } from 'luxon';
import { File } from '../../../typings/common';

export const TODAY = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

export const DEFAULT_CONTRACT = (tempId: number, startDate?: DateTime): ContractModel => ({
  tempId,
  id: null,
  endDate: null,
  spaces: null,
  startDate: startDate || TODAY,
  noticeDays: null,
  cost: {
    chargeType: ChargeType.Daily,
    cost: null,
    dailyMinimum: null
  },
  specialCosts: [],
  attachments: [],
  tempAttachments: []
});

export type Model = {
  address: string;
  managers: SelectableBRCManager[];
  host: SelectableHost | null;
};

export type SpecialCostModel = {
  id: number | null;
  tempId: number;
  workNumber: SelectableProject;
  cost: number | null;
};

export type ContractModel = {
  id: number | null;
  tempId: number;
  endDate: DateTime | null;
  spaces: number | null;
  startDate: DateTime;
  noticeDays: number | null;
  cost: {
    chargeType: ChargeType;
    cost: number | null;
    dailyMinimum: number | null;
  };
  specialCosts: SpecialCostModel[];
  attachments: File[];
  tempAttachments: File[];
};

export type ContactModel = {
  emailAddress: string | null;
  name: string | null;
  phoneNumber: string | null;
  role: string | null;
};

export const transformIAccommodationContractDTOsToContractModels = (contracts: IAccommodationContractDTO[], workNumbers: SelectableProject[]): ContractModel[] => {
  return contracts.map((p) => ({
    id: p.id,
    tempId: p.id,
    endDate: p.endDate ? DateTime.fromISO(p.endDate) : null,
    noticeDays: p.noticeDays,
    spaces: p.spaces,
    cost: p.cost,
    startDate: p.startDate ? DateTime.fromISO(p.startDate) : DateTime.now(),
    specialCosts: (p.specialCosts || []).reduce((acc, curr) => {
      const workNumber = workNumbers.find((x) => x.value === curr.projectId);

      if (workNumber) {
        return [...acc, { id: curr.id, tempId: curr.projectId, cost: curr.cost, workNumber }];
      }

      return acc;
    }, [] as SpecialCostModel[]),
    attachments: p.attachments ? p.attachments.map((x) => new File(x.id, x.name, x.url)) : [],
    tempAttachments: []
  }));
};

export const transformIAccommodationDTOToModel = (model: IAccommodationDTO, hosts: SelectableHost[], managers: SelectableBRCManager[]): Model => {
  return {
    address: model.address || '',
    host: hosts.find((x) => x.value === model.host.id) || null,
    managers: (model.managers || []).reduce((acc, curr) => {
      const manager: SelectableBRCManager | undefined = managers.find((x) => x.value === curr.id);

      if (manager) {
        return [...acc, manager];
      }

      return acc;
    }, [] as SelectableBRCManager[])
  };
};

export const transformModelToICreateAccommodationDTO = (model: Model, contact: IAccommodationContactDTO, contracts: ContractModel[]): ICreateAccommodationDTO => {
  const managerIds = model.managers.map((x) => x.value);
  return {
    address: model.address,
    managerIds: managerIds.length > 0 ? managerIds : null,
    hostId: model.host!.value,
    contact: {
      emailAddress: contact.emailAddress || null,
      name: contact.name || null,
      phoneNumber: contact.phoneNumber || null,
      role: contact.role || null
    },
    contracts: contracts.map((x) => {
      const specialCosts = x.specialCosts.map((y) => ({
        id: y.id,
        projectId: y.workNumber.value,
        cost: y.cost!
      }));
      const attachments = x.attachments.map((y) => y.id);
      const tempAttachments = x.tempAttachments.map((y) => y.id);

      return {
        id: x.id,
        startDate: x.startDate.toISODate(),
        endDate: x.endDate ? x.endDate.toISODate() : null,
        noticeDays: x.noticeDays,
        spaces: x.spaces!,
        cost: {
          chargeType: x.cost.chargeType,
          cost: x.cost.cost!,
          dailyMinimum: x.cost.chargeType === ChargeType.Daily ? x.cost.dailyMinimum : null
        },
        specialCosts: specialCosts.length > 0 ? specialCosts : null,
        attachments: attachments.length > 0 ? attachments : null,
        tempAttachments: tempAttachments.length > 0 ? tempAttachments : null
      };
    })
  };
};

export const transformModelToIUpdateAccommodationDTO = (model: Model, contact: IAccommodationContactDTO, contracts: ContractModel[]): IUpdateAccommodationDTO => {
  const managerIds = model.managers.map((x) => x.value);
  return {
    address: model.address,
    managerIds: managerIds.length > 0 ? managerIds : null,
    hostId: model.host!.value,
    contact: {
      emailAddress: contact.emailAddress || null,
      name: contact.name || null,
      phoneNumber: contact.phoneNumber || null,
      role: contact.role || null
    },
    contracts: contracts.map((x) => {
      const specialCosts = x.specialCosts.map((y) => ({
        id: y.id,
        projectId: y.workNumber.value,
        cost: y.cost!
      }));
      const attachments = x.attachments.map((y) => y.id);
      const tempAttachments = x.tempAttachments.map((y) => y.id);

      return {
        id: x.id,
        startDate: x.startDate.toISODate(),
        endDate: x.endDate ? x.endDate.toISODate() : null,
        noticeDays: x.noticeDays,
        spaces: x.spaces!,
        cost: {
          chargeType: x.cost.chargeType,
          cost: x.cost.cost!,
          dailyMinimum: x.cost.chargeType === ChargeType.Daily ? x.cost.dailyMinimum : null
        },
        specialCosts: specialCosts.length > 0 ? specialCosts : null,
        attachments: attachments.length > 0 ? attachments : null,
        tempAttachments: tempAttachments.length > 0 ? tempAttachments : null
      };
    })
  };
};
