import { IExpiredProjectListItemDTO, IProjectContracrSpecialCostDTO, IProjectContractDTO, IProjectDTO, IProjectEmployeeListItemDTO, IProjectListItemDTO } from '../../typings/DTOs';
import { AccommodationPreview, File, HostPreview, ProjectPreview } from '../../typings/common';
import { SelectableClient, SelectableProHumanManager } from '../Config/helper';
import { ChargeType } from '../../typings/enum';
import { DateTime } from 'luxon';
import { TODAY } from '../../constants';

export class ProjectEmployeeListItem {
  public id: number;
  public name: string;
  public idCardNumber: string;
  public probondId: string;
  public taxIdentificationNumber: string;
  public passportNumber: string;
  public isRelative: boolean;
  public relatives: [] | never[];
  public moveOutDate: DateTime | null;
  public moveInDate: DateTime;
  public project: ProjectPreview | null;
  public secondaryProject: ProjectPreview | null;
  public accommodation: AccommodationPreview | null;
  public host: HostPreview | null;

  constructor(p: IProjectEmployeeListItemDTO) {
    this.id = p.id;
    this.name = p.name || '';
    this.idCardNumber = p.idCardNumber || '';
    this.probondId = p.probondId || '';
    this.taxIdentificationNumber = p.taxIdentificationNumber || '';
    this.passportNumber = p.passportNumber || '';
    this.isRelative = false;
    this.relatives = p.relatives || [];
    this.moveOutDate = p.moveOutDate ? DateTime.fromISO(p.moveOutDate) : null;
    this.moveInDate = p.moveInDate ? DateTime.fromISO(p.moveInDate) : DateTime.now();
    this.accommodation = p.accommodation ? new AccommodationPreview(p.accommodation.id, p.accommodation.address) : null;
    this.host = p.host ? new HostPreview(p.host.id, p.host.name) : null;
    this.secondaryProject = p.secondaryProject ? new ProjectPreview(p.secondaryProject.id, p.secondaryProject.workNumber) : null;
    this.project = p.project ? new ProjectPreview(p.project.id, p.project.workNumber) : null;
  }
}

export class ProjectContractSpecialCost {
  public id: number;
  public cost: {
    cost: number;
    dailyMinimum: number | null;
    chargeType: ChargeType;
  };
  public host: {
    id: number;
    name: string;
  };

  public accommodation: {
    id: number;
    address: string;
  };

  constructor(p: IProjectContracrSpecialCostDTO) {
    this.id = p.id;
    this.cost = {
      cost: p.cost.cost,
      dailyMinimum: p.cost.dailyMinimum,
      chargeType: p.cost.chargeType
    };
    this.host = {
      id: p.host.id,
      name: p.host.name || ''
    };
    this.accommodation = {
      id: p.accommodation.id,
      address: p.accommodation.address || ''
    };
  }
}

export class ProjectContract {
  public id: number;
  public startDate: DateTime;
  public endDate: DateTime | null;
  public noticeDays: number | null;
  public specialCosts: Array<ProjectContractSpecialCost>;
  public attachments: Array<File>;

  public get isActive(): boolean {
    return this.startDate <= TODAY && (!this?.endDate || this.endDate >= TODAY);
  }

  constructor(p: IProjectContractDTO) {
    this.id = p.id;
    this.startDate = p.startDate ? DateTime.fromISO(p.startDate) : DateTime.now();
    this.endDate = p.endDate ? DateTime.fromISO(p.endDate) : null;
    this.noticeDays = p.noticeDays;
    this.specialCosts = (p.specialCosts || []).map((x) => new ProjectContractSpecialCost(x));
    this.attachments = (p.attachments || []).map((x) => new File(x.id, x.name, x.url));
  }
}

export class Project {
  public id: number;
  public workNumber: string;
  public clientWorkNumber: string;
  public isActive: boolean;
  public client: SelectableClient;
  public proHumanManagers: SelectableProHumanManager[] = [];
  public contracts: Array<ProjectContract> | null = null;

  constructor(p: IProjectDTO | IProjectListItemDTO) {
    this.id = p.id;
    this.workNumber = p.workNumber || '';
    this.clientWorkNumber = p.clientWorkNumber || '';
    this.isActive = p.isActive;
    this.client = {
      value: p.client.id,
      text: p.client.name || '',
      description: ''
    };

    if (isIProjectDTO(p)) {
      this.proHumanManagers = (p.proHumanManagers || []).map((x) => new SelectableProHumanManager(x));
      this.contracts = p.contracts
        .map((x) => new ProjectContract(x))
        .sort((a, b) => {
          if (a.isActive) {
            return -1;
          } else if (b.isActive) {
            return 1;
          }
          return b.startDate.valueOf() - a.startDate.valueOf();
        });
      this.isActive = this.contracts.some((x) => x.isActive);
    }
  }
}

const isIProjectDTO = (p: IProjectDTO | IProjectListItemDTO): p is IProjectDTO => {
  return (p as IProjectDTO).proHumanManagers !== undefined;
};

export type ProjectsFilter = { status: 'active' | 'inactive' | null };

export class ExpiredProjectListItem {
  public employee: {
    id: number;
    name: string;
    idNumbers: string;
  };
  public accommodation: AccommodationPreview;
  public project: ProjectPreview;
  public moveInDate: DateTime;
  public moveOutDate: DateTime | null;
  public lastContractEndDate: DateTime;

  constructor(p: IExpiredProjectListItemDTO) {
    this.employee = {
      id: p.employee.id,
      name: p.employee.name || '',
      idNumbers:
        (p.employee.idCardNumber ? p.employee.idCardNumber + ' | ' : '') +
          (p.employee.taxIdentificationNumber ? p.employee.taxIdentificationNumber + ' | ' : '') +
          (p.employee.passportNumber ? p.employee.passportNumber + ' | ' : '') +
          (p.employee.probondId ? p.employee.probondId : '') || ''
    };
    if (this.employee.idNumbers && this.employee.idNumbers.slice(-1) === ' ') {
      this.employee.idNumbers = this.employee.idNumbers.slice(0, -3);
    }
    this.accommodation = new AccommodationPreview(p.accommodation.id, p.accommodation.address);
    this.project = new ProjectPreview(p.project.id, p.project.workNumber);
    this.moveInDate = p.moveInDate ? DateTime.fromISO(p.moveInDate) : DateTime.now();
    this.moveOutDate = p.moveOutDate ? DateTime.fromISO(p.moveOutDate) : null;
    this.lastContractEndDate = p.lastContractEndDate ? DateTime.fromISO(p.lastContractEndDate) : DateTime.now();
  }
}
