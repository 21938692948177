import { APIResponseFeedback, FilterAndSorteablePaginatedList, Order } from "../../../typings/common";
import { IClientDTO, IClientListDTO } from "../../../typings/DTOs";
import { Client, ClientFilter } from "../../../contexts/Partners/Clients/helper";

export const initialState: ClientsState = {
    clients: {
        items: [],
        totalLength: 0,
        offset: 0,
        page: 1,
        isLoading: true,
        error: '',
        filter: {
            status: null
        },
        order: 'ASC',
        sort: 'name'
    },
    response: null,
};

export interface ClientsState {
    clients: FilterAndSorteablePaginatedList<ClientFilter, Client>
    response: APIResponseFeedback | null;
}

type ClientsAction =
    | { type: 'success_get_clients'; payload: { clients: IClientListDTO, page: number, sort: keyof Client, order: Order } }
    | { type: 'error_get_clients'; payload: { error: string } }
    | { type: 'success_get_client'; payload: { client: IClientDTO } }
    | { type: 'error_get_client'; payload: { error: string } }
    | { type: 'success_post_client'; payload: { client: IClientDTO } }
    | { type: 'error_post_client'; payload: { error: string } }
    | { type: 'success_put_client'; payload: { client: IClientDTO } }
    | { type: 'error_put_client'; payload: { error: string } }
    | { type: 'set_filter'; payload: { filter: ClientFilter } }
    | { type: 'set_partial_state'; payload: Partial<ClientsState> }

export function clientsReducer(state: ClientsState = initialState, action: ClientsAction): ClientsState {
    switch (action.type) {
        case 'success_get_clients': {
            return {
                ...state,
                clients: {
                    ...state.clients,
                    items: action.payload.clients.items.reduce((acc, curr) => ([...acc, new Client(curr)]), [] as Client[]),
                    totalLength: action.payload.clients.totalLength,
                    offset: action.payload.clients.offset,
                    order: action.payload.order,
                    page: action.payload.page,
                    sort: action.payload.sort,
                    isLoading: false,
                    error: ''
                },
            };
        }

        case 'error_get_clients': {
            return {
                ...state,
                ...state,
                clients: {
                    ...state.clients,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }

        case "success_get_client": {
            let isExist = false;

            state.clients.items.forEach((client, i) => {
                if (client.id === action.payload.client.id) {
                    isExist = true;
                    state.clients.items[i] = new Client(action.payload.client);
                }
            })

            return {
                ...state,
                clients: {
                    ...state.clients,
                    items: isExist ? [...state.clients.items] : [new Client(action.payload.client), ...state.clients.items]
                }
            }
        }

        case "error_get_client": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_post_client": {
            return {
                ...state,
                clients: {
                    ...state.clients,
                    items: [new Client(action.payload.client), ...state.clients.items],
                    totalLength: state.clients.totalLength + 1
                },
                response: { type: 'success', message: "Sikeres ügyfél létrehozás" }
            }
        }

        case "error_post_client": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_put_client": {
            return {
                ...state,
                clients: {
                    ...state.clients,
                    items: state.clients.items.map(x => x.id === action.payload.client.id ? new Client(action.payload.client) : x)
                },
                response: { type: 'success', message: "Sikeres ügyfél szerkesztés" }
            }
        }

        case "error_put_client": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case 'set_filter': {
            return {
                ...state,
                clients: {
                    ...state.clients,
                    filter: action.payload.filter
                }
            }
        }

        case "set_partial_state": {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state;
    }
}
