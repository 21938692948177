import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../../components/Buttons';
import { CircularProgress } from '../../../../components/CircularProgress';
import { Accordion } from '../../../../components/Accordion';
import { PlusIcon } from '@heroicons/react/outline';
import { Table } from '../../../../components/Table';
import { Badge } from '../../../../components/Badge';

// Data things
import { toCurrencyString, toDateString, toPrettifiedNumber } from '../../../../constants/functions';
import { IHostAccommodationListItemDTO } from '../../../../typings/DTOs';
import { ApiErrorResult } from '../../../../utils/api';
import { XLARGE_SCREEN } from '../../../../constants';
import { HostsContext } from '../../../../contexts/Partners/Hosts';
import { useIsMounted } from '../../../../hooks';
import { CompanyHost } from '../../../../contexts/Partners/Hosts/helper';
// Variables
const header = ['Szálláshely címe', 'Foglalt', 'Szabad', 'Aktív?', 'ROI', 'Kiadás', 'Bevétel', 'buttons'];

export default function Details(props: RouteComponentProps<{ id: number }>) {
  /* Context */
  const { onGetHost, hosts } = useContext(HostsContext);

  /* Variables */
  const selectedHost = useMemo(() => hosts.items.find((x) => x.id === +props.id!), [hosts.items, props.id]);
  const hasData = Array.isArray(selectedHost?.accommodations);

  /* States */
  const [isLoading, setIsLoading] = useState(!hasData);
  const [error, setError] = useState('');

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetInitial = useCallback(async () => {
    try {
      setIsLoading(!hasData);
      setError('');

      const resp = await onGetHost(Number(props.id));
      if (typeof resp === 'string') throw new Error(resp);
    } catch (error) {
      const { message } = error as ApiErrorResult;
      _isMounted.current && !hasData && setError(String(message || error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [props.id, onGetHost, _isMounted]);

  useEffect(() => void onGetInitial(), [onGetInitial]);

  return (
    <div className="page-container max-w-7xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={onGetInitial} />
        </div>
      ) : selectedHost ? (
        <>
          <div className="pt-4 sm:pt-8 bg-white">
            <h1 className="text-3xl font-extrabold text-gray-900">{selectedHost?.name || ''}</h1>

            <div className="flex items-center space-x-4 mt-2">{selectedHost && <Badge label={selectedHost.isActive ? 'Aktív' : 'Inaktív'} className={selectedHost.isActive ? 'badge--green' : 'badge--gray'} />}</div>
          </div>

          <h3 className="section-title mt-12">Alap adatok</h3>

          {selectedHost instanceof CompanyHost ? (
            <div className="data-line">
              <div className="data-line__item">
                <div className="section-label">Adószám</div>

                <div className="data-line__item-data">{selectedHost.taxNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Bankszámlaszám</div>

                <div className="data-line__item-data">{selectedHost.bankAccountNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Megjegyzés</div>

                <div className="data-line__item-data text-right">{selectedHost.description}</div>
              </div>

              <div className="data-line__item" />
            </div>
          ) : (
            <div className="data-line">
              <div className="data-line__item">
                <div className="section-label">Születési név</div>

                <div className="data-line__item-data">{selectedHost.birthName}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Születési idő</div>

                <div className="data-line__item-data">{toDateString(selectedHost.birthdate)}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Adóazonosító jele</div>

                <div className="data-line__item-data">{selectedHost.taxIdentificationNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">TAJ szám</div>

                <div className="data-line__item-data">{selectedHost.socialSecurityNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Szig. szám</div>

                <div className="data-line__item-data">{selectedHost.idCardNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Bankszámlaszám</div>

                <div className="data-line__item-data">{selectedHost.bankAccountNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Megjegyzés</div>

                <div className="data-line__item-data text-right">{selectedHost.description}</div>
              </div>

              <div className="data-line__item" />
            </div>
          )}

          <h3 className="section-title mt-12">Elérhetőség</h3>

          {selectedHost instanceof CompanyHost ? (
            <div className="data-line">
              <div className="data-line__item">
                <div className="section-label">Kapcsolattartó</div>

                <div className="data-line__item-data">{selectedHost.contact.name}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Telefonszám</div>

                <div className="data-line__item-data">{selectedHost.contact.phoneNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Email cím</div>

                <div className="data-line__item-data">{selectedHost.contact.emailAddress}</div>
              </div>

              <div className="data-line__item" />
            </div>
          ) : (
            <div className="data-line">
              <div className="data-line__item">
                <div className="section-label">Állandó lakcím</div>

                <div className="data-line__item-data">{selectedHost.contact.homeAddress}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Tartózkodási cím</div>

                <div className="data-line__item-data">{selectedHost.contact.residenceAddress}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Telefonszám</div>

                <div className="data-line__item-data">{selectedHost.contact.phoneNumber}</div>
              </div>

              <div className="data-line__item">
                <div className="section-label">Email cím</div>

                <div className="data-line__item-data">{selectedHost.contact.emailAddress}</div>
              </div>
            </div>
          )}

          <div className="table-header flex-row items-center mb-6">
            <h3 className="table-header__subtitle">Szállások</h3>

            <Button title="Új szállás" to={`/szallasok/hozzaadas?partner=${selectedHost.id}`} hiddenFor="Reader" className="hidden twsm:block" />

            <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} to={`/szallasok/hozzaadas?partner=${selectedHost.id}`} hiddenFor="Reader" theme="primary" className="twsm:hidden" />
          </div>

          <Table
            className="partners-host-details-table"
            data={selectedHost?.accommodations || []}
            error={selectedHost?.accommodations?.length === 0 ? 'Nem rendelkezik szállással a partner' : ''}
            header={header}
            onRowRender={TableRow}
          />
        </>
      ) : null}
    </div>
  );
}

const Row = (accommodation: IHostAccommodationListItemDTO, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={accommodation.id}>
    <div>
      <span title={accommodation.address !== null ? accommodation.address : ''}>{accommodation.address}</span>

      <Badge label={accommodation.isActive === true ? 'Aktív' : 'Inaktív'} className={`inline-flex sml:hidden ${accommodation.isActive === true ? 'badge--green' : 'badge--gray'}`} />
    </div>

    <div>{accommodation.booked ?? '-'}</div>

    <div>{accommodation.available}</div>

    <div>
      <Badge label={accommodation.isActive ? 'Aktív' : 'Inaktív'} className={accommodation.isActive ? 'badge--green' : 'badge--gray'} />
    </div>

    <div>{accommodation.roi !== null ? <span className={accommodation.roi >= 0 ? 'text-green-800' : 'text-red-800'}>{toPrettifiedNumber(accommodation.roi) + '%'}</span> : '-'}</div>

    <div>
      <span className="text-red-800">{accommodation.expenses !== null ? toCurrencyString(accommodation.expenses) : '-'}</span>
    </div>

    <div>
      <span className="text-green-800">{accommodation.incomes !== null ? toCurrencyString(accommodation.incomes) : '-'}</span>
    </div>

    <div className="action-2">
      <IconButton icon={<EyeIcon className="icon-sm" />} to={`/szallasok/${accommodation.id}`} />

      <IconButton icon={<PencilIcon className="icon-sm" />} to={`/szallasok/szerkesztes/${accommodation.id}`} hiddenFor="Reader" />
    </div>
  </div>
);

const MobileRow = (accommodation: IHostAccommodationListItemDTO) => (
  <Accordion
    key={accommodation.id}
    className="card"
    isCard
    isBorderBottom
    summaryClassName="h-auto"
    label={
      <div className="card-header flex-row justify-between">
        <span className="card-header__primary-text flex items-center w-full">{accommodation.address}</span>

        <div className="flex justify-end items-center gap-8">
          <div className="data hidden twmd:flex mt-0 border-t-0">
            <div className="data-item mt-0 gap-4 min-w-[160px]">
              <span className="data-item__title">ROI</span>

              {accommodation.roi !== null ? <span className={accommodation.roi >= 0 ? 'text-green-800' : 'text-red-800'}>{toPrettifiedNumber(accommodation.roi) + '%'}</span> : '-'}
            </div>
          </div>
          <Badge label={accommodation.isActive === true ? 'Aktív' : 'Inaktív'} className={`${accommodation.isActive === true ? 'badge--green' : 'badge--gray'} w-fit`} />
        </div>
      </div>
    }>
    <div className="data">
      <div className="data-item">
        <span className="data-item__title">Kiadás</span>

        <span className="data-item__data">{accommodation.expenses !== null ? toCurrencyString(accommodation.expenses) : '-'}</span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Bevétel</span>

        <span className="data-item__data">{accommodation.incomes !== null ? toCurrencyString(accommodation.incomes) : '-'}</span>
      </div>

      <div className="data-item twmd:hidden">
        <span className="data-item__title">ROI</span>

        {accommodation.roi !== null ? <span className={accommodation.roi >= 0 ? 'text-green-800' : 'text-red-800'}>{toPrettifiedNumber(accommodation.roi) + '%'}</span> : '-'}
      </div>

      <div className="data-item data-item--action">
        <span className="data-item__data ml-auto gap-x-4">
          <IconButton icon={<EyeIcon className="icon-sm" />} to={`/szallasok/${accommodation.id}`} />

          <IconButton icon={<PencilIcon className="icon-sm" />} to={`/szallasok/szerkesztes/${accommodation.id}`} hiddenFor="Reader" />
        </span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: XLARGE_SCREEN };
