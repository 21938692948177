import { useEffect, useRef, useState } from 'react';

/* Data Things */
import { DATE_INPUT_FORMAT } from '../../../constants';
import { DateTime } from 'luxon';

/* Presentation Things */
import { Dialog, DialogHandle } from '../../../components/Dialog';
import { Button } from '../../../components/Buttons';
import { Input } from '../../../components/Inputs';

type DialogProps = {
  date: DateTime;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

type MoveOutFormProps = { onSubmit(date: DateTime): void; onClose(): void; isOpen: boolean };

export const MoveOutForm = ({ onSubmit, onClose, isOpen }: MoveOutFormProps) => {
  /* States */
  const [date, setDate] = useState(DateTime.now());

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const handleSubmit = () => {
    onSubmit(date);
    onClose();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = DateTime.fromFormat(e.target.value, DATE_INPUT_FORMAT);
    setDate(parsed.isValid ? parsed : date);
  };

  useEffect(() => {
    if (_dialog.current) {
      _dialog.current.nextScreen();
    }
  }, [_dialog]);

  return (
    <Dialog
      className="min-h-min h-[220px] min-w-min w-[350px]"
      open={isOpen}
      onClose={onClose}
      ref={_dialog}
      contents={[
        {
          title: 'Kiköltöztetés',
          body: <DialogBodyContent date={date} onChange={onChange} />,
          footer: <DialogFooter onClose={onClose} onSubmit={handleSubmit} />
        }
      ]}
    />
  );
};

const DialogBodyContent = ({ date, onChange }: DialogProps) => {
  return (
    <div className="space-y-8 divide-y divide-gray-200 m-5">
      <Input type="date" label="Kiköltözés dátuma" value={date} onChange={onChange} required />
    </div>
  );
};

const DialogFooter = ({ onClose, onSubmit }: { onClose(): void; onSubmit(): void }) => {
  return (
    <>
      <Button title="Mégsem" theme="secondary" className="grow" onClick={onClose} />
      <Button title="Kész" className="grow" onClick={onSubmit} />
    </>
  );
};
