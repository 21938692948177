import { ChevronDownIcon } from '@heroicons/react/solid';
import React from 'react';

type Props = React.DetailedHTMLProps<React.DetailsHTMLAttributes<HTMLDetailsElement>, HTMLDetailsElement> & {
  label: string | JSX.Element;
  isBorderBottom?: boolean;
  summaryClassName?: string;
  isCard?: boolean;
};

export function Accordion({ label, isBorderBottom, summaryClassName, isCard, className, ...props }: React.PropsWithChildren<Props>) {
  return (
    <details className={`pt-4 relative ${isBorderBottom ? 'pb-4 border-b' : 'border-none'} group ${className}`} {...props}>
      <summary className={`flex items-center h-8 cursor-pointer ${summaryClassName}`}>
        <div className="text-lg flex w-full items-center gap-4 flex-1">
          <span className={`flex items-center justify-center flex-shrink-0 ${isCard ? 'absolute w-[calc(100%-2rem)] -bottom-3 z-10' : 'w-6 h-6'}`}>
            <ChevronDownIcon
              className={`w-6 h-6 transform-gpu transition-transform duration-200 text-gray-500 ${
                isCard ? 'group-open:rotate-180 mx-auto bg-white' : '-rotate-90 group-open:rotate-0'
              }`}
            />
          </span>

          {label}
        </div>
      </summary>
      {props.children}
    </details>
  );
}
