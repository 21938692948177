import React from 'react';

/* Navigation Things */
import { Link } from '@reach/router';

/* Data Things */
import { Accommodation, AccommodationContract, AccommodationContractSpecialCost } from '../../../../contexts/Accommodations/helper';
import { toCurrencyString, toDateString } from '../../../../constants/functions';
import { CHARGE_TYPE_MAP } from '../../../../constants';

/* Presentation Things */
import { Accordion } from '../../../../components/Accordion';
import { Hideable } from '../../../../components/Hideable';
import { Download } from '../../../../components/Download';
import { Badge } from '../../../../components/Badge';
import { Table } from '../../../../components/Table';

// Variables
const header = ['Munkaszám', 'Költség', ''];

export const BasicData = ({ show, accommodation }: { show: boolean; accommodation: Accommodation | null }) => {
  if (show) return null;

  return (
    <div className="mt-4 sm:mt-10">
      <h3 className="section-title">Kapcsolat</h3>

      {accommodation?.contact && (
        <div className="data-line">
          <div className="data-line__item">
            <div className="section-label">Név</div>

            <div className="data-line__item-data">{accommodation.contact.name ?? '-'}</div>
          </div>

          <div className="data-line__item">
            <div className="section-label">Beosztás</div>

            <div className="data-line__item-data">{accommodation.contact.role ?? '-'}</div>
          </div>

          <div className="data-line__item">
            <div className="section-label">Telefonszám</div>

            {accommodation.contact.phoneNumber ? (
              <a className="data-line__item-data" href={`tel:${accommodation.contact.phoneNumber}`}>
                {accommodation.contact.phoneNumber}
              </a>
            ) : (
              '-'
            )}
          </div>

          <div className="data-line__item">
            <div className="section-label">E-mail</div>

            {accommodation.contact.emailAddress ? (
              <a className="data-line__item-data" href={`mailto:${accommodation.contact.emailAddress}`}>
                {accommodation.contact.emailAddress}
              </a>
            ) : (
              '-'
            )}
          </div>
        </div>
      )}

      <h3 className="section-title mt-6 sm:mt-12">BRC projekt felelősök</h3>

      {accommodation?.managers.length ? (
        <div className="data-line">
          {accommodation.managers.map((manager) => (
            <div key={manager.value} className="data-line__item">
              <div className="section-label">{manager.text}</div>

              <a className="data-line__item-data" href={`tel:${manager.description}`}>
                {manager.description}
              </a>
            </div>
          ))}

          <div className="border-t" />
        </div>
      ) : (
        <span className="empty-data-text">Ehhez a szálláshoz nincsenek projekt felelősök rögzítve</span>
      )}

      <h3 className="section-title mt-6 sm:mt-12">Szerződés</h3>

      {accommodation?.contracts.map((contract, i) => (
        <Contract key={contract.id} contract={contract} isFirst={i === 0} />
      ))}
    </div>
  );
};

const Contract = ({ isFirst, contract }: { isFirst: boolean; contract: AccommodationContract }) => {
  return (
    <Accordion
      isBorderBottom
      open={isFirst}
      className="open:shadow-[0_0_1rem_#0d532e20] transform-gpu transition-[box-shadow,_border-radius,_transform,_margin,_padding] duration-150 ease-in-out open:rounded-md open:p-4 open:my-4"
      label={
        <div className="py-2 flex justify-between xs:justify-start flex-1">
          <div className="flex items-center text-md">
            <div className="flex text-sm text-gray-900 col-span-1">{toDateString(contract.startDate)}</div>

            <span className="flex items-center"> - </span>

            <div className="flex text-sm text-gray-900 col-span-1">{contract.endDate && <span className="flex-grow">{toDateString(contract.endDate)}</span>}</div>
          </div>

          {contract.isActive && <Badge label="Aktív" className="badge--green h-fit self-center ml-4" />}
        </div>
      }>
      <div className="data-line">
        <Hideable.Div className="data-line__item" hiddenFor="ProHumanManager">
          <div className="section-label">Felmondási idő</div>

          <div className="data-line__item-data">{contract.noticeDays ? contract.noticeDays + ' nap' : '-'}</div>
        </Hideable.Div>

        <div className="data-line__item">
          <div className="section-label">Férőhelyek száma</div>

          <div className="data-line__item-data">{contract.spaces}</div>
        </div>

        <Hideable.Div className="data-line__item" hiddenFor="ProHumanManager">
          <div className="section-label">Szálláshely költsége</div>

          <div className="data-line__item-data">
            {toCurrencyString(contract.cost.cost)}

            <span className="text-gray-500">{CHARGE_TYPE_MAP[contract.cost.chargeType]}</span>
          </div>
        </Hideable.Div>

        <Hideable.Div className="data-line__item" hiddenFor="ProHumanManager">
          <div className="section-label">Csatolmányok</div>

          {contract.attachments.length ? (
            <div className="data-line__item-data flex flex-col gap-y-2">
              {contract.attachments.map((x) => (
                <Download key={x.url} href={x.url} fileNameWithExtension={x.name} className="secondary-download-link" />
              ))}
            </div>
          ) : (
            <p className="text-slate-600 text-xs leading-relaxed">Nincs csatolmány</p>
          )}
        </Hideable.Div>

        <Hideable.Div className="data-line__item" hiddenFor="ProHumanManager">
          <div className="section-label">Napi minimum költség</div>

          <div className="data-line__item-data">{contract.cost.dailyMinimum !== null ? toCurrencyString(contract.cost.dailyMinimum) : '-'}</div>
        </Hideable.Div>
      </div>

      <div className="py-5 flex col-span-2 border-t">
        <div className="section-label flex items-center">Költség kivételek</div>
      </div>

      {contract.specialCosts.length ? (
        <Table className="hotels-prices-table col-span-2" data={contract.specialCosts} header={header} onRowRender={TableRow} />
      ) : (
        <span className="empty-data-text">Ehhez a szerződéshez nincs költésg kivétel rögzítve</span>
      )}
    </Accordion>
  );
};

const Row = (specialCost: AccommodationContractSpecialCost, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={specialCost.workNumber}>
    <div>
      <Link to={`/projektek/${specialCost.projectId}`} className="hot-link w-full">
        {specialCost.workNumber}
      </Link>
    </div>

    <Hideable.Div hiddenFor="ProHumanManager">{toCurrencyString(specialCost.cost)}</Hideable.Div>
  </div>
);

const TableRow = { default: Row };
