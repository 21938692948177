import { useCallback, useEffect, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { ChartPieIcon, OfficeBuildingIcon, TrendingDownIcon, UsersIcon, BanIcon, ExclamationIcon, ClipboardCheckIcon } from '@heroicons/react/outline';
import { CircularProgress } from '../../components/CircularProgress';
import { Widget } from '../../components/Widgets';
import { Button } from '../../components/Buttons';

// Data things
import { getProfitOrDeficitColor, toMonthString, toPrettifiedNumber } from '../../constants/functions';
import { IAccommodationStatisticsDTO } from '../../typings/DTOs';
import { useIsMounted } from '../../hooks';
import { TODAY } from '../../constants';
import { api } from '../../utils/api';

// Screens
import OverbookedAccommodations from './Overbooked';
import DeficitAccommodations from './Deficit';
import ExpiredAccommodations from './Expired/Accommodations';
import ExpiredProjects from './Expired/Projects';

type WidgetType = 'deficit' | 'expiredAccommodations' | 'expiredProjects' | 'overbooked';

const WIDGET_TABLE: Record<WidgetType, JSX.Element> = {
  deficit: <DeficitAccommodations />,
  overbooked: <OverbookedAccommodations />,
  expiredAccommodations: <ExpiredAccommodations />,
  expiredProjects: <ExpiredProjects />
};

export default function Dashboard(_: RouteComponentProps) {
  /* States */
  const [selectedWidget, setSelectedWidget] = useState<WidgetType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [widgets, setWidgets] = useState<IAccommodationStatisticsDTO | null>(null);
  const [error, setError] = useState('');

  /* Hooks */
  const _isMounted = useIsMounted();

  const onSelectWidget = (e: React.MouseEvent<HTMLDivElement>) => setSelectedWidget(e.currentTarget.dataset.type as WidgetType);

  const onGetWidgets = useCallback(async () => {
    try {
      setIsLoading(true);

      const widgets = await api<IAccommodationStatisticsDTO>('accommodation/widgets');

      if (!widgets.message && _isMounted.current) {
        setWidgets(widgets);
        setError('');
      } else throw new Error(widgets.message);
    } catch (error) {
      _isMounted.current && setError(String((error as Error).message || error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted]);

  useEffect(() => void onGetWidgets(), [onGetWidgets]);

  return (
    <div className="page-container max-w-6xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={onGetWidgets} />
        </div>
      ) : (
        <div className="pt-10 bg-white w-full grid gap-8 grid-cols-[repeat(2,_minmax(min-content_,1fr))] sml:grid-cols-[repeat(3,_minmax(min-content_,1fr))] xl2:grid-cols-[repeat(4,_minmax(min-content_,1fr))] grid-flow-row-dense">
          <Widget
            icon={<ChartPieIcon className="dashboard-widget-icon" />}
            title="Nyereség"
            className="col-span-2"
            contents={[
              { itemTitle: toMonthString(TODAY.minus({ month: 1 })), itemText: widgets?.profit.previousMonth ? `${toPrettifiedNumber(widgets.profit.previousMonth)}%` : '-' },
              {
                itemTitle: 'jelenlegi',
                itemText: widgets?.profit.current ? `${toPrettifiedNumber(widgets.profit.current)}%` : '-',
                textStyle: getProfitOrDeficitColor(widgets?.profit.current)
              },
              { itemTitle: toMonthString(TODAY), itemText: widgets?.profit.currentMonth ? `${toPrettifiedNumber(widgets.profit.currentMonth)}%` : '-' }
            ]}
            dashboard
            hiddenFor="ProHumanManager"
          />

          <Widget icon={<OfficeBuildingIcon className="dashboard-widget-icon" />} title="Munkavállalók szálláson" contents={[{ itemText: widgets?.bookedEmployees ?? '-' }]} dashboard />

          <Widget icon={<UsersIcon className="dashboard-widget-icon" />} title="Szabad kapacitás" contents={[{ itemText: widgets?.available ?? '-' }]} dashboard />

          <Widget
            data-type="deficit"
            onClick={onSelectWidget}
            icon={<TrendingDownIcon className="dashboard-widget-icon" />}
            title="Veszteséges szállások"
            contents={[{ itemText: widgets?.deficitAccommodation || '-', textStyle: widgets?.deficitAccommodation ? 'text-red-800' : '' }]}
            dashboard
          />

          <Widget
            data-type="overbooked"
            onClick={onSelectWidget}
            icon={<ExclamationIcon className="dashboard-widget-icon" />}
            title="Túlfoglalások"
            contents={[{ itemText: widgets?.overbooks || '-', textStyle: widgets?.overbooks ? 'text-red-800' : '' }]}
            dashboard
          />

          <Widget
            data-type="expiredAccommodations"
            onClick={onSelectWidget}
            icon={<OfficeBuildingIconWithX />}
            title="Beköltözések szállás szerződés nélkül"
            contents={[{ itemText: widgets?.expiredAccommodations || '-', textStyle: widgets?.expiredAccommodations ? 'text-red-800' : '' }]}
            dashboard
          />

          <Widget
            data-type="expiredProjects"
            onClick={onSelectWidget}
            icon={<ClipboardCheckIconWithX />}
            title="Beköltözések projekt szerződés nélkül"
            contents={[{ itemText: widgets?.expiredProjects || '-', textStyle: widgets?.expiredProjects ? 'text-red-800' : '' }]}
            dashboard
          />
        </div>
      )}

      {selectedWidget ? WIDGET_TABLE[selectedWidget] : null}
    </div>
  );
}

const OfficeBuildingIconWithX = () => (
  <span className="dashboard-widget-icon relative">
    <OfficeBuildingIcon className="text-inherit absolute top-0 left-0 transform-gpu -translate-x-1/2 scale-[.6] h-full" />
    <BanIcon className="text-inherit absolute top-0 left-0 transform-gpu -translate-x-1/2 h-full stroke-[1.5]" />
  </span>
);

const ClipboardCheckIconWithX = () => (
  <span className="dashboard-widget-icon relative">
    <ClipboardCheckIcon className="text-inherit absolute top-0 left-0 transform-gpu -translate-x-1/2 scale-[.6] h-full" />
    <BanIcon className="text-inherit absolute top-0 left-0 transform-gpu -translate-x-1/2 h-full stroke-[1.5]" />
  </span>
);
