import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { DateTime } from 'luxon';

export const MonthlyScroller = ({ date, onPreviousMonth, onNextMonth, className }: { date: DateTime; onPreviousMonth?(): void; onNextMonth?(): void; className?: string }) => {
  return (
    <div className={`flex items-center text-gray-900 ${className}`}>
      <ChevronLeftIcon className="icon-md" aria-hidden="true" role="button" onClick={onPreviousMonth} />

      <div className="text-lg font-semibold mx-4 w-40 text-center">{date.setLocale('hu').toFormat("y'. 'LLLL")}</div>

      <ChevronRightIcon className="icon-md" aria-hidden="true" role="button" onClick={onNextMonth} />
    </div>
  );
};
