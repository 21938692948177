import { GENDER_MAP } from '../../../constants';
import { Option } from '../../../components/Select/helper';
import { Sex } from '../../../typings/enum';
import { IEmployeeDTO, IRelativeDTO, ISelectableEmployeeDTO } from '../../../typings/DTOs';
import { DateTime } from 'luxon';

export interface EmployeeModel {
  id: number;
  name: string;
  gender: Sex;
  birthdate: DateTime;
  idCardNumber: string;
  probondId: string;
  taxIdentificationNumber: string;
  passportNumber: string;
  isRelative: boolean;
  relatives: SelectableEmployee[] | never[];
}

export class SelectableEmployee {
  public value: number;
  public text: string;
  public idCardNumber: string;
  public probondId: string;
  public taxIdentificationNumber: string;
  public passportNumber: string;
  public isRelative: boolean;
  public disabled: boolean;

  constructor(p: ISelectableEmployeeDTO | IEmployeeDTO) {
    this.value = p.id;
    this.text = p.name || '';
    this.isRelative = p.isRelative;
    this.disabled = false;
    this.idCardNumber = p.idCardNumber || '';
    this.probondId = p.probondId || '';
    this.taxIdentificationNumber = p.taxIdentificationNumber || '';
    this.passportNumber = p.passportNumber || '';
  }
}

export const transformIEmployeeDTOtoModel = (model: IEmployeeDTO): EmployeeModel => {
  return {
    id: model.id,
    name: model.name || '',
    gender: model.sex,
    birthdate: DateTime.fromISO(model.birthdate || ''),
    idCardNumber: model.idCardNumber || '',
    probondId: model.probondId || '',
    taxIdentificationNumber: model.taxIdentificationNumber || '',
    passportNumber: model.passportNumber || '',
    isRelative: model.isRelative,
    relatives: model.relatives.map((r: IRelativeDTO) => transformRelativeDTOtoSelectableEmployee(r))
  };
};

const transformRelativeDTOtoSelectableEmployee = (relative: IRelativeDTO): SelectableEmployee => {
  return {
    idCardNumber: relative.idCardNumber || '',
    probondId: relative.probondId || '',
    taxIdentificationNumber: relative.taxIdentificationNumber || '',
    passportNumber: relative.passportNumber || '',
    disabled: false,
    isRelative: relative.isRelative,
    text: relative.name || '',
    value: relative.id
  };
};

export const GENDERS_OPTION: Option[] = Object.keys(GENDER_MAP).map((x) => ({ value: Number(x), text: GENDER_MAP[+x as Sex] }));

// Az adózó polgár adóazonosító jelének képzési szabályai
// 1. Az adóazonosító jel tízjegyű szám.
// 2. Az adóazonosító számot az alábbiak szerint kell képezni:
//     a) az 1. számjegy konstans 8-as szám, mely az adóalany magánszemély voltára utal,
//     b) a 2-6. számjegyek a személy születési időpontja és az 1867. január 1. között eltelt
// napok száma,
//     c) a 7-9. számjegyek az azonos napon születettek megkülönböztetésére szolgáló
// véletlenszerűen képzett sorszám,
//     d) a 10. számjegy az 1-9. számjegyek felhasználásával matematikai módszerekkel képzett
// ellenőrző szám.
// 3. Az adóazonosító jel 10. számjegyét úgy kell képezni, hogy a 2. a)-c) pontok szerint
// képzett 9 számjegy mindegyikét szorozni kell azzal a sorszámmal, ahányadik helyet
// foglalja el az azonosítón belül. (Első számjegy szorozva eggyel, második számjegy
// szorozva kettővel és így tovább.)
export type TaxNumberCheckResult = {
  isValid: boolean;
  correctBirthdate?: Date;
}
export const taxNumberCheck = (taxNumber: string, birthDate: string): TaxNumberCheckResult => {
  const regexp = /^(8\d{9})$/;
  if (regexp.test(taxNumber)) {
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(taxNumber.substring(i, i + 1)) * (i + 1);
    }
    if (sum % 11 === parseInt(taxNumber.substring(9, 10))) {
      const date_1 = new Date('1867-01-01');
      const date_2 = new Date(birthDate);
      const diffDays = Math.ceil((date_2.getTime() - date_1.getTime()) / (1000 * 3600 * 24));
      const taxNumberDays = parseInt(taxNumber.substring(1, 6));
      if (diffDays === taxNumberDays) {
        return { isValid: true };
      } else {
        date_1.setDate(date_1.getDate() + taxNumberDays)
        return { isValid: false, correctBirthdate: date_1 };
      }
    } else {
      return { isValid: false };
    }
  } else {
    return { isValid: false };
  }
};
