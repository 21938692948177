import { APIResponseFeedback, FilterAndSorteablePaginatedList, Order } from "../../typings/common";
import { IProjectDTO, IProjectListDTO } from "../../typings/DTOs";
import { Project, ProjectsFilter } from "../../contexts/Projects/helper";

export const initialState: ProjectsState = {
    projects: {
        items: [],
        totalLength: 0,
        offset: 0,
        page: 1,
        isLoading: true,
        error: '',
        filter: {
            status: null
        },
        order: 'ASC',
        sort: 'workNumber'
    },
    response: null,
};

export interface ProjectsState {
    projects: FilterAndSorteablePaginatedList<ProjectsFilter, Project>
    response: APIResponseFeedback | null;
}

type ProjectsAction =
    | { type: 'success_get_projects'; payload: { projects: IProjectListDTO, page: number, order: Order, sort: keyof Project } }
    | { type: 'error_get_projects'; payload: { error: string } }
    | { type: 'success_get_project'; payload: { project: IProjectDTO } }
    | { type: 'success_post_project'; payload: { project: IProjectDTO } }
    | { type: 'error_post_project'; payload: { error: string } }
    | { type: 'success_put_project'; payload: { project: IProjectDTO } }
    | { type: 'error_put_project'; payload: { error: string } }
    | { type: 'set_filter'; payload: { filter: ProjectsFilter } }
    | { type: 'set_partial_state'; payload: Partial<ProjectsState> }

export function projectsReducer(state: ProjectsState = initialState, action: ProjectsAction): ProjectsState {
    switch (action.type) {
        case 'success_get_projects': {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: action.payload.projects.items.reduce((acc, curr) => ([...acc, new Project(curr)]), [] as Project[]),
                    totalLength: action.payload.projects.totalLength,
                    offset: action.payload.projects.offset,
                    order: action.payload.order,
                    sort: action.payload.sort,
                    page: action.payload.page,
                    isLoading: false,
                    error: ''
                },
            };
        }

        case 'error_get_projects': {
            return {
                ...state,
                ...state,
                projects: {
                    ...state.projects,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }

        case "success_get_project": {
            let isExist = false;

            state.projects.items.forEach((client, i) => {
                if (client.id === action.payload.project.id) {
                    isExist = true;
                    state.projects.items[i] = new Project(action.payload.project);
                }
            })

            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: isExist ? [...state.projects.items] : [new Project(action.payload.project), ...state.projects.items]
                }
            }
        }

        case "success_post_project": {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: [new Project(action.payload.project), ...state.projects.items],
                    totalLength: state.projects.totalLength + 1
                },
                response: { type: 'success', message: "Sikeres projekt létrehozás" }
            }
        }

        case "error_post_project": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_put_project": {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: state.projects.items.map(x => x.id === action.payload.project.id ? new Project(action.payload.project) : x)
                },
                response: { type: 'success', message: "Sikeres projekt szerkesztés" }
            }
        }

        case "error_put_project": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }


        case 'set_filter': {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    filter: action.payload.filter
                }
            }
        }

        case "set_partial_state": {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state;
    }
}
