import React, { useCallback, useReducer } from 'react';

/* Data Things */
import { IClientDTO, IClientListDTO, ICreateClientDTO, IUpdateClientDTO } from '../../../typings/DTOs';
import { clientsReducer, ClientsState, initialState } from '../../../reducers/Partners/Clients';
import { Client, ClientFilter } from './helper';
import { ApiErrorResult, api } from '../../../utils/api';
import { useIsMounted } from '../../../hooks';
import { Order } from '../../../typings/common';

/* Presentation Things */
import { Snackbar } from '../../../components/Snackbar';

interface IClientsContext extends ClientsState {
  onEditClient: (client: IUpdateClientDTO, id: number) => Promise<string | null>;
  onAddClient: (client: ICreateClientDTO) => Promise<string | null>;
  onGetClients: (text?: string, signal?: AbortSignal, page?: number, filter?: Object) => Promise<string | null>;
  onGetClient: (id: number) => Promise<string | null>;
  onFilter: (filter: ClientFilter) => void;
}

const ClientsContext = React.createContext<IClientsContext>({
  ...initialState,
  onEditClient: () => Promise.reject(),
  onAddClient: () => Promise.reject(),
  onGetClients: () => Promise.reject(),
  onGetClient: () => Promise.reject(),
  onFilter: () => null
});

function ClientsProvider(props: React.PropsWithChildren<{}>) {
  /* States */
  const [state, dispatch] = useReducer(clientsReducer, initialState);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetClients = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof Client = 'name', order: Order = 'ASC'): Promise<string | null> => {
      try {
        const clients = await api<IClientListDTO>('client', { search, filter, sort, order, limit: 15, offset: (page - 1) * 15 }, { signal });

        if (!clients.message && _isMounted.current) {
          dispatch({ type: 'success_get_clients', payload: { clients, page, sort, order } });

          return Promise.resolve(null);
        } else throw new Error(clients.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_get_clients', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onGetClient = useCallback(
    async (id: number): Promise<string | null> => {
      try {
        const client = await api<IClientDTO>(`client/${id}`);
        if (!client.message && _isMounted.current) {
          dispatch({ type: 'success_get_client', payload: { client } });

          return Promise.resolve(null);
        } else throw new Error(client.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_get_client', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onEditClient = useCallback(
    async (body: IUpdateClientDTO, id: number): Promise<string | null> => {
      try {
        const client = await api<IClientDTO>(`client/${id}`, undefined, { method: 'PUT', body });

        if (!client.message && _isMounted.current) {
          dispatch({ type: 'success_put_client', payload: { client } });

          return Promise.resolve(null);
        } else throw new Error(client.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_put_client', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAddClient = useCallback(
    async (body: ICreateClientDTO): Promise<string | null> => {
      try {
        const client = await api<IClientDTO>('client', undefined, { method: 'POST', body });

        if (!client.message && _isMounted.current) {
          dispatch({ type: 'success_post_client', payload: { client } });

          return Promise.resolve(null);
        } else throw new Error(client.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_post_client', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAutoCloseResponse = useCallback(() => dispatch({ type: 'set_partial_state', payload: { response: null } }), []);

  const onFilter = useCallback((filter: ClientFilter) => dispatch({ type: 'set_filter', payload: { filter } }), []);

  return (
    <ClientsContext.Provider value={{ ...state, onEditClient, onAddClient, onGetClient, onGetClients, onFilter }}>
      <Snackbar opened={!!state.response} label={state.response!?.message} type={state.response!?.type} onAutoClose={onAutoCloseResponse} />
      {props.children}
    </ClientsContext.Provider>
  );
}

export { ClientsProvider, ClientsContext };
