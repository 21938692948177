// Navigation things
import { RouteComponentProps } from '@reach/router';

const NotFound = (props: RouteComponentProps) => {
  return (
    <div className="full-page-error">
      <h1 className="error-text">{(props.location?.state as any)?.error || 'A kért oldal nem található'}</h1>
    </div>
  );
};

export default NotFound;
