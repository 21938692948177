import { useNavigate as useReachNavigate, useLocation } from "@reach/router"
import { useCallback } from "react";

export const useNavigate = (): { goBack(): void; goTo404(error?: string): void } => {
    const navigate = useReachNavigate();
    const location = useLocation();

    const goTo404 = useCallback((error: string = "A kért oldalhoz nem rendelkezel megfelelő jogosultsággal!") => navigate('/404', { state: { error }, replace: true }), [navigate])
    const goBack = useCallback(() => (location?.state ? navigate(-1) : navigate('./')), [navigate, location]);

    return { goTo404, goBack }
}