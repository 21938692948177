// Navigation things
import { RouteComponentProps, Router } from '@reach/router';

// Screens
import CreateOrEdit from './CreateOrEdit';
import Details from './Details';

const Hosts = (_: RouteComponentProps) => {
  return (
    <Router basepath="partnerek/szallas-partner" primary={false}>
      <Details path="/:id" />
      <CreateOrEdit path="szerkesztes/:id" />
      <CreateOrEdit path="hozzaadas" />
    </Router>
  );
};

export default Hosts;
