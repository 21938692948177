import { IAccommodationDTO, IAccommodationListDTO, IAccommodationWidgetsDTO } from "../../typings/DTOs";
import { APIResponseFeedback, FilterAndSorteablePaginatedList, Order } from "../../typings/common";
import { Accommodation, AccommodationListItem, AccommodationsFilter } from "../../contexts/Accommodations/helper";
import { END_OF_CURRENT_MONTH } from "../../constants";

export const initialState: AccommodationsState = {
    accommodations: {
        items: [],
        totalLength: 0,
        offset: 0,
        page: 1,
        isLoading: true,
        filter: {
            availableFrom: null,
            availableTo: null,
            nightsFrom: null,
            nightsTo: null,
            roiFrom: null,
            roiTo: null,
            host: null
        },
        error: '',
        order: 'ASC',
        sort: 'host'
    },
    widgets: null,
    response: null
};

export interface AccommodationsState {
    accommodations: FilterAndSorteablePaginatedList<AccommodationsFilter, AccommodationListItem>
    widgets: IAccommodationWidgetsDTO | null
    response: APIResponseFeedback | null;
}

type AccommodationsAction =
    | { type: 'success_init'; payload: { widgets: IAccommodationWidgetsDTO; accommodations: IAccommodationListDTO, sort: keyof AccommodationListItem, order: Order } }
    | { type: 'error_init'; payload: { error: string } }
    | { type: 'success_get_accommodations'; payload: { accommodations: IAccommodationListDTO, page: number, sort: keyof AccommodationListItem, order: Order } }
    | { type: 'error_get_accommodations'; payload: { error: string } }
    | { type: 'success_get_accommodation'; payload: { accommodation: IAccommodationDTO } }
    | { type: 'error_get_accommodation'; payload: { error: string } }
    | { type: 'success_post_accommodation'; payload: { accommodation: IAccommodationDTO } }
    | { type: 'error_post_accommodation'; payload: { error: string } }
    | { type: 'success_put_accommodation'; payload: { accommodation: IAccommodationDTO } }
    | { type: 'error_put_accommodation'; payload: { error: string } }
    | { type: 'set_filter'; payload: { filter: AccommodationsFilter } }
    | { type: 'set_partial_state'; payload: Partial<AccommodationsState> }

export function accommodationsReducer(state: AccommodationsState = initialState, action: AccommodationsAction): AccommodationsState {
    switch (action.type) {
        case 'success_init':
        case 'success_get_accommodations': {
            //@ts-ignore
            if ((action.payload?.widgets as IAccommodationWidgetsDTO)) {
                //@ts-ignore
                state.widgets = action.payload.widgets;
            }

            return {
                ...state,
                accommodations: {
                    ...state.accommodations,
                    items: action.payload.accommodations.items.reduce((acc, curr) => ([...acc, new AccommodationListItem(curr)]), [] as AccommodationListItem[]),
                    totalLength: action.payload.accommodations.totalLength,
                    offset: action.payload.accommodations.offset,
                    //@ts-ignore
                    page: action.payload?.page || 1,
                    order: action.payload.order,
                    sort: action.payload.sort,
                    isLoading: false,
                    error: !action.payload.accommodations.items.length ? "Nincs megjeleníthető szállás erre a hónapra" : ''
                },
            };
        }

        case "error_init": {
            return {
                ...state,
                accommodations: {
                    ...initialState.accommodations,
                    error: action.payload.error,
                    isLoading: false
                },
                widgets: null
            }
        }

        case 'error_get_accommodations': {
            return {
                ...state,
                ...state,
                accommodations: {
                    ...state.accommodations,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }

        case "success_get_accommodation": {
            return {
                ...state,
                accommodations: {
                    ...state.accommodations,
                    items: state.accommodations.items.map((accommodation) => {
                        if (accommodation.id === action.payload.accommodation.id) {
                            return new AccommodationListItem(action.payload.accommodation);
                        }
                        return accommodation;
                    })
                }
            }
        }

        case "error_get_accommodation": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_post_accommodation": {
            const accommodation = new Accommodation(action.payload.accommodation);
            const hasContractForCurrentMonth = accommodation.contracts.some(x => x.startDate <= END_OF_CURRENT_MONTH && (!x.endDate || x.endDate >= END_OF_CURRENT_MONTH));

            return {
                ...state,
                accommodations: {
                    ...state.accommodations,
                    items: hasContractForCurrentMonth ? [...state.accommodations.items, new AccommodationListItem(action.payload.accommodation)] : state.accommodations.items,
                    totalLength: hasContractForCurrentMonth ? state.accommodations.totalLength + 1 : state.accommodations.totalLength
                },
                response: { type: 'success', message: "Sikeres szállás létrehozás" }
            }
        }

        case "error_post_accommodation": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_put_accommodation": {
            return {
                ...state,
                accommodations: {
                    ...state.accommodations,
                    items: state.accommodations.items.map(x => x.id === action.payload.accommodation.id ? new AccommodationListItem(action.payload.accommodation, x) : x)
                },
                response: { type: 'success', message: "Sikeres szállás szerkesztés" }
            }
        }

        case "error_put_accommodation": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case 'set_filter': {
            return {
                ...state,
                accommodations: {
                    ...state.accommodations,
                    filter: action.payload.filter
                }
            }
        }

        case "set_partial_state": {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state;
    }
}
