import { ICreateUserDTO, IUpdateUserDTO } from "../../../typings/DTOs";
import { APP_ROLE_MAP } from "../../../constants";
import { AppRole } from "../../../typings/enum";
import { User } from "../../../contexts/Users/helper";

export const roles = [
  { name: APP_ROLE_MAP[AppRole.Reader], role: AppRole.Reader, description: 'Csak a munkavállalók aktuális szálláshelyeit láthatják' },
  { name: APP_ROLE_MAP[AppRole.ProHumanManager], role: AppRole.ProHumanManager, description: 'Munkaszámot rögzíthet, hozzárendelt munkaszámokat szerkeszthet' },
  { name: APP_ROLE_MAP[AppRole.BRCManager], role: AppRole.BRCManager, description: 'Szálláshelyet rögzíthet, hozzárendelt szálláshelyeken ki-, és beköltöztethet, riportokat nézhet meg' },
  { name: APP_ROLE_MAP[AppRole.Administrator], role: AppRole.Administrator, description: 'Mindenhez van jogosultsága' },
];

export const transformModelToIUpdateUserDTO = (model: User): IUpdateUserDTO => {
  return {
    phoneNumber: model.phoneNumber || null,
    name: model.name,
    email: model.email,
    role: model.role,
    isActive: model.isActive,
  };
}

export const transformModelToICreateUserDTO = (model: User): ICreateUserDTO => {
  return {
    phoneNumber: model.phoneNumber || null,
    name: model.name,
    email: model.email,
    role: model.role,
    isActive: true,
  };
}