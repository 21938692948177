import { useMsal } from '@azure/msal-react';
import { AppRole } from '../typings/enum';

const APP_ROLE_MAP: Record<AppRole, keyof typeof AppRole> = {
  [AppRole.Administrator]: 'Administrator',
  [AppRole.BRCManager]: 'BRCManager',
  [AppRole.ProHumanManager]: 'ProHumanManager',
  [AppRole.Reader]: 'Reader'
};

export const useAuthorization = (): { role: keyof typeof AppRole } => {
  const [activeAccount] = useMsal().accounts;

  if (Array.isArray(activeAccount?.idTokenClaims?.['roles']) && activeAccount?.idTokenClaims?.['roles']?.[0]) {
    const role = Number(activeAccount.idTokenClaims['roles'][0]) as AppRole;

    return { role: APP_ROLE_MAP[role] };
  }

  return { role: 'Reader' };
};
