import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

type Tab = { label: string; component: (isShow: boolean) => JSX.Element | null };

type Props = {
  tabs: Array<Tab>;
  className?: string;
  wrapperClassName?: string;
  tabWrapperClassName?: string;
};

export const TabBar = ({ tabs, className, wrapperClassName, tabWrapperClassName }: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className={`sticky z-20 bg-white pb-0 pt-4 sm:pb-6 ${wrapperClassName}`}>
        <div className={`border-b border-gray-200 ${className}`}>
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab, i) => (
              <TabItem key={i} label={tab.label} onActiveTab={setActiveTab} index={i} isActive={activeTab === i} />
            ))}
          </nav>
        </div>
      </div>

      <Transition
        key={activeTab}
        show
        appear
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo={`opacity-100 ${tabWrapperClassName}`}
        leave="transition-opacity duration-300"
        leaveFrom={`opacity-100 ${tabWrapperClassName}`}
        leaveTo="opacity-0">
        {tabs.map((tab, i) => (
          <React.Fragment key={i}>{tab.component(activeTab === i)}</React.Fragment>
        ))}
      </Transition>
    </>
  );
};

const TabItem = ({ label, onActiveTab, index, isActive }: { label: string; onActiveTab(index: number): void; index: number; isActive: boolean }) => {
  const handleActiveTab = () => onActiveTab(index);

  return (
    <button onClick={handleActiveTab} className={`tab-bar__nav-item ${isActive ? 'tab-bar__nav-item--active' : ''}`}>
      {label}
    </button>
  );
};
