import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

/* Presentation Things */
import { Dialog, DialogHandle } from '../../../components/Dialog';
import { SelectDialogBody } from '../../../components/Select';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Checkbox, Input } from '../../../components/Inputs';
import { Option } from '../../../components/Select/helper';
import { Button } from '../../../components/Buttons';

/* Data Things */
import { ISelectableAccommodationDTO } from '../../../typings/DTOs';
import { SelectableAccommodation } from '../../../contexts/Config/helper';
import { onChangeInput } from '../../../constants/functions';
import { ConfigContext } from '../../../contexts/Config';
import { TODAY } from '../../../constants';
import { Model } from '../helper';
import { api } from '../../../utils/api';

type MoveInFormProps = { hotelId?: number; isOpen: boolean; edtiableEmployeesCount: number; onSubmit(model: Partial<Model>): void; onClose(): void };

type FirstStepProps = {
  model: Model;
  editableFields: Record<keyof Model, boolean>;
  edtiableEmployeesCount: number;
  onSubmit(e: React.FormEvent): void;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onCheck(e: React.ChangeEvent<HTMLInputElement>): void;
  onChangeSecondStep(e: React.SetStateAction<'project' | 'secondaryProject' | 'accommodation' | undefined>): void;
};

export const MoveInForm = ({ onSubmit, onClose, hotelId, isOpen, edtiableEmployeesCount }: MoveInFormProps) => {
  /* Contexts */
  const { workNumbers } = useContext(ConfigContext);

  /* States */
  const [accommodations, setAccommodations] = useState<SelectableAccommodation[]>([]);
  const [secondStep, setSecondStep] = useState<'project' | 'secondaryProject' | 'accommodation'>();
  const [model, setModel] = useState<Model>({
    project: workNumbers[0] ?? null,
    secondaryProject: null,
    movingOut: null,
    accommodation: accommodations.find((x) => x.value === hotelId) ?? null,
    movingIn: TODAY,
    contribution: null
  });
  const [editableFields, setEditableFields] = useState<Record<keyof Model, boolean>>({
    project: edtiableEmployeesCount === 1,
    secondaryProject: edtiableEmployeesCount === 1,
    movingOut: edtiableEmployeesCount === 1,
    accommodation: edtiableEmployeesCount === 1,
    movingIn: edtiableEmployeesCount === 1,
    contribution: edtiableEmployeesCount === 1
  });

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(
      Object.keys(model).reduce((acc, curr) => {
        if (editableFields[curr as keyof Model]) {
          return { ...acc, [curr]: model[curr as keyof Model] };
        }
        return acc;
      }, {} as Partial<Model>)
    );
    onClose();
  };

  const handleClose = () => {
    setSecondStep(undefined);
    onClose();
  };

  const handlePrev = () => {
    setSecondStep(undefined);
    _dialog.current?.previousScreen();
  };

  const onGetAccommodations = useCallback(async () => {
    if (model.project) {
      try {
        const resp = await api<ISelectableAccommodationDTO[]>(`project/${model.project.value}/accommodations`);
        if (!resp.message) {
          setAccommodations(resp.map((x) => new SelectableAccommodation(x)));
        } else throw new Error(resp.message);
      } catch (error) {}
    }
  }, [model.project]);

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableFields((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const onChangeSecondaryProject = (o: Option) => setModel((prev) => ({ ...prev, secondaryProject: o }));

  const onChangeAccommodation = (o: Option) => setModel((prev) => ({ ...prev, accommodation: o as SelectableAccommodation }));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setModel);

  const onChangeProject = (o: Option) => setModel((prev) => ({ ...prev, project: o }));

  useEffect(() => {
    if (secondStep && _dialog.current) {
      _dialog.current.nextScreen();
    }
  }, [secondStep, _dialog]);

  useEffect(() => {
    if (!model.project && workNumbers.length) {
      setModel((prev) => ({ ...prev, project: workNumbers[0]! }));
    }
  }, [model.project, workNumbers]);

  useEffect(() => {
    if (accommodations.length) {
      setModel((prev) => ({ ...prev, accommodation: accommodations[0]! }));
    }
  }, [accommodations]);

  useEffect(() => void onGetAccommodations(), [onGetAccommodations]);

  useEffect(() => {
    if (isOpen) {
      setEditableFields({
        project: edtiableEmployeesCount === 1,
        secondaryProject: edtiableEmployeesCount === 1,
        movingOut: edtiableEmployeesCount === 1,
        accommodation: edtiableEmployeesCount === 1,
        movingIn: edtiableEmployeesCount === 1,
        contribution: edtiableEmployeesCount === 1
      });
    }
  }, [edtiableEmployeesCount, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      ref={_dialog}
      className="h-[85vh]"
      contents={[
        {
          title: 'Adatok megadása',
          body: (
            <FirstStep
              model={model}
              onSubmit={handleSubmit}
              onChange={handleOnChange}
              onChangeSecondStep={setSecondStep}
              onCheck={onCheck}
              editableFields={editableFields}
              edtiableEmployeesCount={edtiableEmployeesCount}
            />
          ),
          footer: <FirstFooter onClose={handleClose} />
        },
        {
          title: secondStep === 'project' ? 'Munkaszám kiválasztása' : secondStep === 'secondaryProject' ? 'Másodlagos munkaszám kiválasztása' : 'Szálláshely kiválasztása',
          body:
            secondStep === 'project' ? (
              <SelectDialogBody options={workNumbers} dialog="single" onSelect={onChangeProject} selecteds={model.project ? { [model.project.value]: model.project } : {}} />
            ) : secondStep === 'secondaryProject' ? (
              <SelectDialogBody options={workNumbers} dialog="single" onSelect={onChangeSecondaryProject} selecteds={model.secondaryProject ? { [model.secondaryProject.value]: model.secondaryProject } : {}} />
            ) : (
              <SelectDialogBody options={accommodations} dialog="single" onSelect={onChangeAccommodation} selecteds={model.accommodation ? { [model.accommodation.value]: model.accommodation } : {}} />
            ),
          footer: <Button title="Ok" className="grow" onClick={handlePrev} />
        }
      ]}
    />
  );
};

const FirstStep = ({ model, editableFields, edtiableEmployeesCount, onSubmit, onChange, onChangeSecondStep, onCheck }: FirstStepProps) => {
  const setSecondStepToSecondaryJobnumbers = () => {
    if (editableFields.secondaryProject) {
      onChangeSecondStep('secondaryProject');
    }
  };

  const setSecondStepToJobnumbers = () => {
    if (editableFields.project) {
      onChangeSecondStep('project');
    }
  };

  const setSecondStepToHotels = () => {
    if (editableFields.accommodation) {
      onChangeSecondStep('accommodation');
    }
  };

  return (
    <form className="space-y-8 m-5" id="movein" onSubmit={onSubmit}>
      <div className="flex items-center space-x-4">
        {edtiableEmployeesCount > 1 && <Checkbox name="project" checked={editableFields.project} onChange={onCheck} />}

        <div tabIndex={0} className={`dialog-select flex-1 ${!editableFields.project && 'dialog-select--disabled'}`} onClick={setSecondStepToJobnumbers}>
          <span className="dialog-select__label">Munkaszám</span>

          <div className="dialog-select__input">
            <span>{model.project ? model.project.text : ''}</span>
            <ChevronRightIcon className="icon-xs" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        {edtiableEmployeesCount > 1 && <Checkbox name="secondaryProject" checked={editableFields.secondaryProject} onChange={onCheck} />}

        <div tabIndex={0} className={`dialog-select flex-1 ${!editableFields.secondaryProject && 'dialog-select--disabled'}`} onClick={setSecondStepToSecondaryJobnumbers}>
          <span className="dialog-select__label">Másodlagos munkaszám</span>

          <div className="dialog-select__input">
            <span>{model.secondaryProject ? model.secondaryProject.text : ''}</span>
            <ChevronRightIcon className="icon-xs" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        {edtiableEmployeesCount > 1 && <Checkbox name="accommodation" checked={editableFields.accommodation} onChange={onCheck} />}

        <div tabIndex={0} className={`dialog-select flex-1 ${!editableFields.accommodation && 'dialog-select--disabled'}`} onClick={setSecondStepToHotels}>
          <span className="dialog-select__label">Szálláshely</span>

          <div className="dialog-select__input">
            <span>{model.accommodation?.description ?? ''}</span>
            <ChevronRightIcon className="icon-xs" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        {edtiableEmployeesCount > 1 && <Checkbox name="movingIn" checked={editableFields.movingIn} onChange={onCheck} />}

        <Input wrapperClassName="flex-1" type="date" label="Beköltözés dátuma" name="movingIn" disabled={!editableFields.movingIn} value={model.movingIn} onChange={onChange} required />
      </div>

      <div className="flex items-center space-x-4">
        {edtiableEmployeesCount > 1 && <Checkbox name="movingOut" checked={editableFields.movingOut} onChange={onCheck} />}

        <Input type="date" label="Kiköltözés dátuma" name="movingOut" disabled={!editableFields.movingOut} min={model.movingIn} value={model.movingOut} onChange={onChange} wrapperClassName="flex-1" />
      </div>

      <div className="flex items-center space-x-4">
        {edtiableEmployeesCount > 1 && <Checkbox name="contribution" checked={editableFields.contribution} onChange={onCheck} />}

        <Input wrapperClassName="flex-1" type="number" label="Munkavállaló havi hozzájárulása" unit="Ft" name="contribution" disabled={!editableFields.contribution} value={model.contribution || ''} onChange={onChange} />
      </div>
    </form>
  );
};

const FirstFooter = ({ onClose }: { onClose(): void }) => {
  return (
    <>
      <Button title="Mégsem" theme="secondary" className="grow" onClick={onClose} />
      <Button title="Kész" type="submit" className="grow" form="movein" />
    </>
  );
};
