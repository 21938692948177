import React, { useCallback, useEffect, useRef, useState } from 'react';

/* Data Things */
import { Order, SorteablePaginatedList } from '../../../typings/common';
import { ProjectEmployeeListItem } from '../../../contexts/Projects/helper';
import { IProjectEmployeeListDTO } from '../../../typings/DTOs';
import { ApiErrorResult, api } from '../../../utils/api';
import { MEDIUM_SCREEN } from '../../../constants';
import { useIsMounted } from '../../../hooks';
import { toDateString } from '../../../constants/functions';
import { IHeaderCell } from '../../../components/Table/helper';

/* Presentation Things */
import { PlusIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { Button, IconButton } from '../../../components/Buttons';
import { Table, TableRef } from '../../../components/Table';
import { Accordion } from '../../../components/Accordion';
import { Link } from '@reach/router';
import { Identification } from '../../../components/Identification';

/* Variables */
const header: Array<IHeaderCell<ProjectEmployeeListItem> | string> = [
  { text: 'Munkavállaló', sortName: 'name' },
  { text: 'Beköltözés', sortName: 'moveInDate' },
  { text: 'Kiköltözés', sortName: 'moveOutDate' },
  'Szállás partner',
  ''
];

export const History = ({ projectId, show }: { projectId: number; show: boolean }) => {
  /* State */
  const [employees, setEmployees] = useState<SorteablePaginatedList<ProjectEmployeeListItem>>({
    error: '',
    isLoading: true,
    items: [],
    offset: 0,
    totalLength: 0,
    sort: 'name',
    order: 'ASC',
    page: 1
  });

  /* Refs */
  const _table = useRef<TableRef>(null);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetPreviousEmployees = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof ProjectEmployeeListItem = 'name', order: Order = 'ASC') => {
      try {
        setEmployees((prev) => ({ ...prev, isLoading: true, error: '', page, filter, sort, order }));

        const resp = await api<IProjectEmployeeListDTO>(`project/${projectId}/employees`, { search, sort, order, page, limit: 30, offset: (page - 1) * 30, isActive: false }, { signal });

        if (!resp.message && _isMounted.current) {
          if (!resp.items.length) throw new Error('Nincs előzmény ezen a munkaszámon');

          setEmployees((p) => ({
            ...p,
            items: resp.items.map((x) => new ProjectEmployeeListItem(x)),
            totalLength: resp.totalLength,
            offset: resp.offset,
            isLoading: false
          }));

          return Promise.resolve(null);
        } else throw new Error(resp.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && setEmployees((p) => ({ ...p, offset: 0, totalLength: 0, isLoading: false, items: [], error: String(message || error) }));
        return Promise.reject(message);
      }
    },
    [_isMounted, projectId]
  );

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => void onGetPreviousEmployees(), [onGetPreviousEmployees]);

  if (!show) return null;

  return (
    <>
      <div className="table-header flex-row items-center">
        <h3 className="table-header__subtitle">Munkavállalók</h3>

        <div className="flex gap-x-2">
          <Button to="/bekoltoztetes" title="Új beköltözés" hiddenFor="Reader" className="hidden twsm:block rounded-md" />

          <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} to="/bekoltoztetes" hiddenFor="Reader" theme="primary" className="twsm:hidden" />

          <IconButton icon={<SortAscendingIcon className="icon-xs" />} className="twsm:hidden" theme="secondary" onClick={onOpenSort} disabled={!employees.items.length} />
        </div>
      </div>

      <Table
        ref={_table}
        className="projects-active-employees-table"
        maxDataCount={employees.totalLength}
        loading={employees.isLoading}
        error={employees.error}
        order={employees.order}
        data={employees.items}
        page={employees.page}
        sort={employees.sort}
        header={header}
        onChangePage={onGetPreviousEmployees}
        onSearch={onGetPreviousEmployees}
        onSort={onGetPreviousEmployees}
        onRowRender={TableRow}
      />
    </>
  );
};

const MobileRow = (employee: ProjectEmployeeListItem) => (
  <Accordion
    key={`${employee.id} - ${employee.moveInDate?.valueOf()} -${employee.moveOutDate?.valueOf()}`}
    className="card"
    isCard
    isBorderBottom
    label={
      <div className="card-header">
        <Link title={employee.name} to={`/munkavallalok/${employee.id}`} className="card-header__primary-text hot-link">
          {employee.name}
        </Link>

        <span className="card-header__secondary-text">
          <Identification mobile={true} idCardNumber={employee.idCardNumber} probondId={employee.probondId} taxIdentificationNumber={employee.taxIdentificationNumber} passportNumber={employee.passportNumber} />
        </span>
      </div>
    }>
    <div className="data">
      <div className="data-item">
        <span className="data-item__title">Beköltözés</span>

        <span className="data-item__data">{toDateString(employee.moveInDate)}</span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Kiköltözés</span>

        <span className="data-item__data">{toDateString(employee.moveOutDate)}</span>
      </div>

      {employee.host && (
        <div className="data-item">
          <span className="data-item__title">Szállás partner</span>

          <span className="data-item__data">
            <Link title={employee.host.name} to={`/partnerek/szallas-partner/${employee.host.id}`} className="truncate font-medium text-gray-900 hover:text-gray-600">
              {employee.host.name}
            </Link>
          </span>
        </div>
      )}

      {employee.accommodation && (
        <div className="data-item">
          <span className="data-item__title">Szálláshely</span>

          <span className="data-item__data">
            <Link title={employee.accommodation.address} to={`/szallasok/${employee.accommodation.id}`} className="truncate text-gray-900 hover:text-gray-600">
              {employee.accommodation.address}
            </Link>
          </span>
        </div>
      )}
    </div>
  </Accordion>
);

const Row = (employee: ProjectEmployeeListItem, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={`${employee.id} - ${employee.moveInDate?.valueOf()} -${employee.moveOutDate?.valueOf()}`}>
    <div>
      <Link to={`/munkavallalok/${employee.id}`} className="hot-link w-full">
        {employee.name}
      </Link>

      <Identification mobile={false} idCardNumber={employee.idCardNumber} probondId={employee.probondId} taxIdentificationNumber={employee.taxIdentificationNumber} passportNumber={employee.passportNumber} />
    </div>

    <div>{toDateString(employee.moveInDate)}</div>

    <div>{toDateString(employee.moveOutDate)}</div>

    <div>
      {employee.host && (
        <Link title={employee.host.name} to={`/partnerek/szallas-partner/${employee.host.id}`} className="hot-link w-full">
          {employee.host.name}
        </Link>
      )}

      {employee.accommodation && (
        <Link title={employee.accommodation.address} to={`/szallasok/${employee.accommodation.id}`} className="hot-link w-full">
          {employee.accommodation.address}
        </Link>
      )}
    </div>
  </div>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: MEDIUM_SCREEN };
