import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { Checkbox, Input, Radio } from '../../../components/Inputs';
import { CircularProgress } from '../../../components/CircularProgress';
import { Button } from '../../../components/Buttons';

// Data things
import { transformModelToICreateUserDTO, transformModelToIUpdateUserDTO, roles } from './helper';
import { useIsMounted, useNavigate } from '../../../hooks';
import { ApiErrorResult } from '../../../utils/api';
import { onChangeInput } from '../../../constants/functions';
import { UsersContext } from '../../../contexts/Users';
import { AppRole } from '../../../typings/enum';
import { User } from '../../../contexts/Users/helper';

export default function CreateOrEdit(props: RouteComponentProps<{ id: number }>) {
  /* Contexts */
  const { onAddUser, onEditUser, onGetUser, users } = useContext(UsersContext);

  /* Variables */
  const selectedUser = useMemo(() => users.items.find((x) => x.id === +props.id!), [users.items, props.id]);
  const isEditPage = props.id !== undefined;

  /* States */
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(isEditPage && !selectedUser);
  const [error, setError] = useState('');
  const [user, setUser] = useState<User>(selectedUser || { id: -1, email: '', name: '', phoneNumber: '', role: AppRole.Reader, isActive: true });

  /* Hooks */
  const _isMounted = useIsMounted();
  const { goBack } = useNavigate();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmiting(true);
      const editTransformed = transformModelToIUpdateUserDTO(user);
      const addTransformed = transformModelToICreateUserDTO(user);
      const resp = isEditPage ? await onEditUser(editTransformed, Number(props.id)) : await onAddUser(addTransformed);

      if (typeof resp !== 'string' && _isMounted.current) {
        goBack();
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsSubmiting(false);
    }
  };

  const handleGetUser = useCallback(async () => {
    if (props.id && !selectedUser) {
      try {
        setIsLoading(true);
        setError('');
        await onGetUser(Number(props.id));
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && setError(String(message || error));
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    }
  }, [props.id, selectedUser, _isMounted, onGetUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setUser);

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser);
    }
  }, [selectedUser]);

  useEffect(() => void handleGetUser(), [handleGetUser]);

  return (
    <div className="page-container max-w-4xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={handleGetUser} />
        </div>
      ) : (
        <>
          <h1 className="main-title">Felhasználó {isEditPage ? 'szerkesztése' : 'hozzáadása'}</h1>

          <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit}>
            <div className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Kapcsolattartás</h3>

              <div className="mt-6 grid gap-4 grid-cols-6">
                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input label="Név" name="name" value={user.name} onChange={handleChange} required />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input
                    type="email"
                    label="Email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input type="tel" label="Telefonszám" name="phoneNumber" value={user.phoneNumber} onChange={handleChange} />
                </div>

                {isEditPage && (
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <Checkbox title="Aktív?" htmlFor="active" checked={user.isActive} onChange={handleChange} theme="form" />
                  </div>
                )}
              </div>
            </div>

            <div className="bg-white rounded-md -space-y-px pt-8">
              {roles.map((permission, index) => (
                <label htmlFor={permission.name} key={index} className={`radio-group ${user.role === permission.role ? 'border-primary/50 z-10' : ''}`}>
                  <Radio id={permission.name} value={permission.role} name="role" checked={user.role === permission.role} onChange={handleChange} className="mt-1" />
                  <div className="ml-3 flex flex-col">
                    <span className="text-gray-800 block text-sm font-medium">{permission.name}</span>
                    <span className="text-gray-700 block text-sm">{permission.description}</span>
                  </div>
                </label>
              ))}
            </div>

            <div className="pt-5">
              <div className="flex space-x-3 justify-end">
                <Button type="button" title="Mégsem" theme="secondary" onClick={goBack} disabled={isSubmiting} />
                <Button type="submit" title="Mentés" loading={isSubmiting} disabled={isSubmiting} />
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
