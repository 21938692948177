export type Order = 'ASC' | 'DESC'

export interface List<T> {
    items: T[];
    isLoading: boolean;
    error: string
}

export interface Sort<Item> {
    sort: keyof Item;
    order: Order;
}

export interface PaginatedList<T> extends List<T> {
    totalLength: number;
    offset: number;
    page: number;
}

export interface FilterablePaginatedList<Filter, Item> extends PaginatedList<Item> {
    filter: Filter;
}

export interface FilterAndSorteablePaginatedList<Filter, Item> extends FilterablePaginatedList<Filter, Item>, Sort<Item> { }

export interface SorteablePaginatedList<Item> extends PaginatedList<Item>, Sort<Item> { }
export interface Searchable {
    isVisible: boolean;
}

export type APIResponseFeedback = {
    type: 'success' | 'error';
    message: string;
}

export class File {
    public id: number;
    public name: string;
    public url: string

    constructor(id: number, name: string | null, url: string | null) {
        this.id = id;
        this.name = name || "";
        this.url = url || ""
    }
}

export class AccommodationPreview {
    public id: number;
    public address: string;

    constructor(id: number, address: string | null) {
        this.id = id;
        this.address = address || '';
    }
}

export class ProjectPreview {
    public id: number;
    public workNumber: string;

    constructor(id: number, workNumber: string | null) {
        this.id = id;
        this.workNumber = workNumber || '';
    }
}

export class HostPreview {
    public id: number;
    public name: string;

    constructor(id: number, name: string | null) {
        this.id = id;
        this.name = name || '';
    }
}