import { AppRole } from '../../typings/enum';
import { Order } from '../../typings/common';

export interface IHeaderCell<Item> {
  text: string;
  sortName?: keyof Item;
  hiddenFor?: keyof typeof AppRole;
}

export interface IPagination {
  page?: number;
  visibleRowCount?: number;
  visiblePageCount?: number;
  maxDataCount: number;
  onChangePage(page: number): void;
}

export interface IHeaderCellItem<Item> {
  cell: IHeaderCell<Item> | string | JSX.Element;
  order?: Order;
  onSort?(sort: keyof Item, order: Order): void;
  isLast?: boolean;
}

export function isHeader<T>(item: IHeaderCell<T> | JSX.Element | string): item is IHeaderCell<T> {
  const x = item as IHeaderCell<T>;
  return x.text !== undefined ? true : false;
}

export function isVisibleHeaderCell<T>(item: IHeaderCell<T> | JSX.Element | string, role: keyof typeof AppRole) {
  return typeof item === 'object' && 'hiddenFor' in item ? (Array.isArray(item.hiddenFor) ? !item.hiddenFor.includes(role) : role !== item.hiddenFor) : true
}