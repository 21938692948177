import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

/* Navigation things */
import { RouteComponentProps } from '@reach/router';

/* Presentation things */
import { PlusIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../../components/Buttons';
import { Table, TableRef } from '../../../../components/Table';
import { Accordion } from '../../../../components/Accordion';
import { Badge } from '../../../../components/Badge';

// Data things
import { IClientWorkNumberListItem, transformIClientWorkNumberListItemDTO } from './helper';
import { Order, SorteablePaginatedList } from '../../../../typings/common';
import { IClientWorkNumberListDTO } from '../../../../typings/DTOs';
import { ApiErrorResult, api } from '../../../../utils/api';
import { ClientsContext } from '../../../../contexts/Partners/Clients';
import { toDateString } from '../../../../constants/functions';
import { SMALL_SCREEN } from '../../../../constants';
import { useIsMounted } from '../../../../hooks';
import { IHeaderCell } from '../../../../components/Table/helper';

// Variables
const header: Array<IHeaderCell<IClientWorkNumberListItem> | string> = ['Munkaszám', { text: 'Létrehozva', sortName: 'createdAt' }, { text: 'Aktív?', sortName: 'isActive' }, 'buttons'];

export function Details(props: RouteComponentProps<{ id: number }>) {
  /* Context */
  const { onGetClient, clients } = useContext(ClientsContext);

  /* Variables */
  const selectedClient = useMemo(() => clients.items.find((x) => x.id === +props.id!), [clients.items, props.id]);

  useEffect(() => {
    if (props.id) {
      onGetClient(Number(props.id));
    }
  }, [props.id, onGetClient]);

  return (
    <div className="page-container max-w-4xl">
      <div className="pt-4 sm:pt-8 bg-white">
        <h1 className="text-3xl font-extrabold text-gray-900">{selectedClient?.name || ''}</h1>

        <div className="flex items-center space-x-4 mt-2">
          <div className="flex items-center text-sm text-gray-500">{selectedClient?.taxNumber || ''}</div>

          {selectedClient && <Badge label={selectedClient.isActive ? 'Aktív' : 'Inaktív'} className={selectedClient.isActive ? 'badge--green' : 'badge--gray'} />}
        </div>
      </div>

      <div className="data-line">
        {selectedClient && (
          <div className="flex flex-row justify-between py-5 border-t col-span-2 border-b">
            <div className="section-label">Megjegyzés</div>

            <div className="data-line__item-data text-right">{selectedClient.description}</div>
          </div>
        )}
      </div>

      <WorkNumbers id={Number(props.id)} />
    </div>
  );
}

const WorkNumbers = ({ id }: { id: number }) => {
  /* State */
  const [workNumbers, setWorkNumbers] = useState<SorteablePaginatedList<IClientWorkNumberListItem>>({
    error: '',
    isLoading: true,
    items: [],
    offset: 0,
    totalLength: 0,
    sort: 'createdAt',
    order: 'DESC',
    page: 0
  });

  /* Refs */
  const _table = useRef<TableRef>(null);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetClients = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof IClientWorkNumberListItem = 'createdAt', order: Order = 'DESC'): Promise<string | null> => {
      try {
        setWorkNumbers((prev) => ({ ...prev, error: '', isLoading: true, sort, order, page }));

        const resp = await api<IClientWorkNumberListDTO>(`client/${id}/worknumbers`, { search, filter, sort, order, limit: 30, offset: (page - 1) * 30 }, { signal });

        if (!resp.message && _isMounted.current) {
          if (!resp.items.length) throw new Error('Nincs rendelkezésre álló munkaszám');

          setWorkNumbers((p) => ({
            ...p,
            isLoading: false,
            totalLength: resp.totalLength,
            offset: resp.offset,
            items: resp.items.map((x) => transformIClientWorkNumberListItemDTO(x))
          }));

          return Promise.resolve(null);
        } else throw new Error(resp.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        if (_isMounted.current) {
          setWorkNumbers((p) => ({ ...p, isLoading: false, items: [], error: String(message || error), offset: 0, totalLength: 0 }));
        }
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted, id]
  );

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => void onGetClients(), [onGetClients]);

  return (
    <>
      <div className="table-header flex-row items-center">
        <h3 className="table-header__subtitle">Munkaszámok</h3>

        <Button title="Új projekt" to={`/projektek/hozzaadas?ugyfel=${id}`} hiddenFor={['Reader', 'BRCManager']} className="hidden twsm:block" />

        <div className="flex gap-x-2 twsm:hidden">
          <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} to={`/projektek/hozzaadas?ugyfel=${id}`} hiddenFor={['Reader', 'BRCManager']} theme="primary" />

          <IconButton icon={<SortAscendingIcon className="icon-xs" />} theme="secondary" onClick={onOpenSort} disabled={!workNumbers.items.length} />
        </div>
      </div>

      <Table
        ref={_table}
        header={header}
        className="partners-client-details-table"
        loading={workNumbers.isLoading}
        error={workNumbers.error}
        order={workNumbers.order}
        data={workNumbers.items}
        page={workNumbers.page}
        sort={workNumbers.sort}
        onChangePage={onGetClients}
        onSearch={onGetClients}
        onSort={onGetClients}
        onRowRender={TableRow}
      />
    </>
  );
};

const Row = (row: IClientWorkNumberListItem, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={row.id}>
    <div>
      <span title={row.workNumber}>{row.workNumber}</span>
    </div>

    <div>{toDateString(row.createdAt)}</div>

    <div>
      <Badge label={row.isActive ? 'Aktív' : 'Inaktív'} className={row.isActive ? 'badge--green' : 'badge--gray'} />
    </div>

    <div className="action-2">
      <IconButton icon={<EyeIcon className="icon-sm" />} to={`/projektek/${row.id}`} />
      <IconButton icon={<PencilIcon className="icon-sm" />} to={`/projektek/szerkesztes/${row.id}`} hiddenFor={['Reader', 'BRCManager']} />
    </div>
  </div>
);

const MobileRow = (row: IClientWorkNumberListItem) => (
  <Accordion
    key={row.id}
    className="card"
    isCard
    isBorderBottom
    label={
      <div className="card-header flex-row items-center justify-between">
        <div className="flex-col items-start">
          <span className="card-header__primary-text">{row.workNumber}</span>
          <span className="card-header__secondary-text">{toDateString(row.createdAt)}</span>
        </div>

        <Badge label={row.isActive === true ? 'Aktív' : 'Inaktív'} className={row.isActive === true ? 'badge--green' : 'badge--gray'} />
      </div>
    }>
    <div className="data border-t-0">
      <div className="data-item data-item--action">
        <span className="data-item__data ml-auto gap-x-4">
          <IconButton icon={<EyeIcon className="icon-sm" />} to={`/projektek/${row.id}`} />

          <IconButton icon={<PencilIcon className="icon-sm" />} to={`/projektek/szerkesztes/${row.id}`} hiddenFor={['Reader', 'BRCManager']} />
        </span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: SMALL_SCREEN };
