/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { ProjectsProvider } from '../../contexts/Projects';

/* Screens */
import CreateOrEdit from './CreateOrEdit';
import Details from './Details';
import List from './List';

const Projects = (_: RouteComponentProps) => {
  return (
    <ProjectsProvider>
      <Router basepath="projektek" primary={false}>
        <Details path="/:id" />
        <CreateOrEdit path="szerkesztes/:id" />
        <CreateOrEdit path="hozzaadas" />
        <List default />
      </Router>
    </ProjectsProvider>
  );
};

export default Projects;
