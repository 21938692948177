import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';

/* Data Things */
import { Option, getSelectedOptions, getSelectedTitles, getFilteredOptions, getSelectedValue } from './helper';
import { useWindowDimensions } from '../../hooks';
import { MEDIUM_SCREEN, SELECT_DIALOG_OPTION_NORMAL_SIZE, SELECT_DIALOG_OPTION_SMALL_SIZE } from '../../constants';

/* Presentation Things */
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { Search } from '../Inputs';
import { Dialog } from '../Dialog';
import { Button } from '../Buttons';
import { Identification } from '../Identification';

// Animation props
const variants = {
  hidden: {
    height: '100%',
    transition: {
      duration: 1,
      easings: [0.25, 0, 0.75, 1]
    }
  },
  show: {
    height: '',
    transition: {
      duration: 1,
      easings: [0.25, 0, 0.75, 1]
    }
  }
};

type Props = {
  options: Array<Option>;
  dialog?: 'multi' | 'single';
  selected?: Array<number> | Array<{ value: number }> | Array<{ id: number }>;
  onSelect(values: Array<Option | number>): void;
  titleSelector?: (option: Option) => string;
  title: string;
  wrapperClassName?: string;
  required?: boolean;
  label?: boolean;
  showSelected?: boolean;
};

export type SelectHandle = {
  open: () => void;
};

type DialogOptionProps = {
  option: Option;
  selected: boolean;
  onSelect(option: Option): void;
  searchValue: string;
  dialog: Props['dialog'];
  style?: React.CSSProperties;
};

type DialogBodyProps<T> = {
  key?: number;
  options: Array<T & Option>;
  dialog?: 'multi' | 'single';
  selecteds?: Record<number, Option | false> | (T & Option);
  autoFocus?: boolean;
  onSelect(option: T & Option): void;
};

type DialogFooterProps = {
  disabled: boolean;
  onClose(): void;
  onSave(): void;
};

export const Select = React.forwardRef<SelectHandle, React.PropsWithChildren<Props>>(({ titleSelector = (o) => o.text, children, wrapperClassName, label, showSelected = true, selected = [], ...props }, ref) => {
  /* States */
  const [selectedTitles, setSelectedTitles] = useState('');
  const [selecteds, setSelecteds] = useState(getSelectedOptions(selected, props.options));
  const [isOpened, setIsOpened] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open() {
        setIsOpened(true);
      }
    }),
    []
  );

  const onSelectNative = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onSelect([Number(e.target.value)]);
  };

  const onSelectDialog = (option: Option) => {
    setSelecteds((prev) => {
      if (prev[option.value]) {
        const tmp = { ...prev };
        delete tmp[option.value];
        return tmp;
      }

      if (props.dialog === 'single') {
        return { [option.value]: option };
      }
      return { ...prev, [option.value]: option };
    });
  };

  const onOpenDialog = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (('code' in e && e.code === 'Space') || !('code' in e)) {
      e.stopPropagation();
      e.preventDefault();
      props.dialog !== undefined && setIsOpened(true);
    }
  };

  const onCloseDialog = () => {
    setIsOpened(false);
    setSelecteds(getSelectedOptions(selected, props.options));
  };

  const onSave = () => {
    props.onSelect(Object.values(selecteds));
    setIsOpened(false);
  };

  useEffect(() => {
    if (selected.length) {
      setSelecteds(getSelectedOptions(selected, props.options));
      setSelectedTitles(getSelectedTitles(selected, props.options, titleSelector));
    }
    // eslint-disable-next-line
  }, [selected, props.options]);

  return props.dialog === undefined ? (
    <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm cursor-pointer custom-ring" onClick={onOpenDialog}>
      <span className="block text-xs font-medium text-gray-900">{props.title}</span>
      <select className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 text-sm focus:ring-0" value={getSelectedValue(selected[0])} onChange={onSelectNative}>
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <>
      <Dialog
        open={isOpened}
        onClose={onCloseDialog}
        className="h-[85vh]"
        contents={[
          {
            title: props.title,
            body: <SelectDialogBody options={props.options} dialog={props.dialog} onSelect={onSelectDialog} selecteds={selecteds} autoFocus />,
            footer: <SelectDialogFooter disabled={Object.keys(selecteds).length === 0} onClose={onCloseDialog} onSave={onSave} />
          }
        ]}
      />

      {children || (
        <div tabIndex={0} className={`border border-gray-300 rounded-md px-3 py-2 shadow-sm cursor-pointer ${wrapperClassName} custom-ring focus:ring-offset-0`} onClick={onOpenDialog} onKeyDown={onOpenDialog}>
          {label !== false ? (
            <div className="flex justify-between items-baseline text-xs font-medium text-gray-900">
              <span>{props.title}</span> {!props.required && <span className="font-normal text-gray-400">Opcionális</span>}
            </div>
          ) : null}
          <span className="flex flex-row justify-between items-center cursor-pointer">
            <div className="block w-full h-5 border-0 text-gray-600 placeholder-gray-500 text-sm truncate">{showSelected ? selectedTitles : !selected.length ? 'Kiválasztás' : selected.length + ' db kiválasztva'}</div>

            <ChevronDownIcon className="icon-xs flex-shrink-0" />
          </span>
        </div>
      )}
    </>
  );
});

const SelectDialogFooter = ({ onClose, onSave, disabled }: DialogFooterProps) => {
  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onSave();
  };

  return (
    <>
      <Button title="Mégsem" onClick={handleClose} theme="secondary" className="flex-1" />
      <Button title="Kiválasztom" onClick={handleSave} disabled={disabled} className="flex-1" />
    </>
  );
};

export const SelectDialogBody = <T extends unknown>({ options, dialog, selecteds, key, autoFocus, onSelect }: DialogBodyProps<T>) => {
  /* States */
  const [searchValue, setSearchValue] = useState('');
  const { width: windowWidth } = useWindowDimensions();

  /* Variables */
  const visibleOptions = useMemo(() => getFilteredOptions(options, searchValue), [options, searchValue]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const Row = ({ index, style }: ListChildComponentProps) => {
    const option = visibleOptions[index];
    if (option) {
      const isSelected = selecteds ? ('value' in selecteds ? selecteds.value === option.value : !!selecteds[option.value]) : false;

      return <SelectDialogOption key={option.text} style={style} option={option} dialog={dialog} selected={isSelected} onSelect={onSelect} searchValue={searchValue} />;
    }
    return null;
  };

  return (
    <motion.div key={key} variants={variants} initial="hidden" animate="show" exit="hidden" className="h-full">
      <Search theme="dialog" className="mx-5" onChange={onSearch} autoFocus={autoFocus} />
      <ul className="h-[calc(100%-4rem)] divide-y divide-gray-200">
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              itemCount={visibleOptions.length}
              itemSize={windowWidth > MEDIUM_SCREEN && visibleOptions.length > 4 ? SELECT_DIALOG_OPTION_SMALL_SIZE : SELECT_DIALOG_OPTION_NORMAL_SIZE}
              height={height}
              width={width}>
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </ul>
    </motion.div>
  );
};

const SelectDialogOption = ({ option, dialog, selected, onSelect, searchValue, style }: DialogOptionProps) => {
  const handleSelect = () => onSelect(option);

  return (
    <li className={`flex px-5${option.disabled ? ' disabled' : ''}`} style={style} key={option.text}>
      <label className="py-4 flex items-center w-full">
        <input
          type={dialog === 'multi' ? 'checkbox' : 'radio'}
          className={`focus:ring-primary h-4 w-4 text-primary border-gray-300 ${dialog === 'multi' && 'rounded'}`}
          checked={selected}
          disabled={option.disabled}
          onChange={handleSelect}
        />
        <div className="flex flex-col items-start ml-3 text-sm">
          <span className="font-medium text-gray-700">{option.text}</span>

          {'description' in option && <span className="text-gray-500">{option.description}</span>}
          {'idCardNumber' in option && (
            <Identification
              mobile={false}
              idCardNumber={option.idCardNumber}
              probondId={option.probondId}
              taxIdentificationNumber={option.taxIdentificationNumber}
              passportNumber={option.passportNumber}
              searchValue={searchValue}
            />
          )}
        </div>
      </label>
    </li>
  );
};

export const FilterSelect = ({ label, onNextScreen, selecteds, required = false }: { label: string; onNextScreen(): void; selecteds?: string; required?: boolean }) => (
  <div
    onClick={onNextScreen}
    className="border flex flex-wrap max-w-full justify-start items-center border-gray-300 rounded-md px-3 py-2 shadow-sm cursor-pointer focus-visible:outline-offset-0 focus-within:outline-2 focus-within:outline focus-within:outline-primary overflow-hidden min-h-[38px]">
    <div tabIndex={0} className="flex-1 text-xs font-medium text-gray-900 text-left outline-none flex items-center justify-between">
      <span className="flex justify-between gap-2">
        {label}
        {!required && <span className="text-xs font-normal text-gray-400">(opcionális)</span>}
      </span>
      {!selecteds && <ChevronRightIcon className="icon-xs inline-block group-open:rotate-90 transition-transform text-gray-500" />}
    </div>

    {selecteds && (
      <div className="flex basis-full text-left justify-between items-center h-5 border-0 text-gray-600 placeholder-gray-500 text-sm ">
        <div className="max-w-full truncate">{selecteds}</div>
        <ChevronRightIcon className="icon-xs inline-block group-open:rotate-90 transition-transform text-gray-500" />
      </div>
    )}
  </div>
);
