import { useCallback, useEffect, useRef, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { LocationMarkerIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../components/Buttons';
import { MonthlyOverview } from './Overview';
import { BasicData } from './BasicInfo';
import { TabBar } from '../../../components/TabBar';
import { Badge } from '../../../components/Badge';

// Data Things
import { IAccommodationDTO, IMonthlyAccommodationWidgetsDTO } from '../../../typings/DTOs';
import { Accommodation } from '../../../contexts/Accommodations/helper';
import { useIsMounted } from '../../../hooks';
import { TODAY } from '../../../constants';
import { api } from '../../../utils/api';

interface IProps extends RouteComponentProps<{ id: number; year: number; month: number }> {}

export default function Details({ id, year, month, navigate, location }: IProps) {
  /* State */
  const [accommodation, setAccommodation] = useState<Accommodation | null>(null);
  const [widgets, setWidgets] = useState<IMonthlyAccommodationWidgetsDTO | null>(null);
  const [error, setError] = useState('');

  /* Variables */
  const abortController = useRef(new AbortController());

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetInitial = useCallback(async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    if (year && month && id) {
      setWidgets(null);
      setError('');

      try {
        const [widgets, accommodation] = await Promise.all([
          api<IMonthlyAccommodationWidgetsDTO>(`accommodation/${id}/widgets/${year}/${month}`, undefined, { signal: abortController.current.signal }),
          api<IAccommodationDTO>(`accommodation/${id}`, undefined, { signal: abortController.current.signal })
        ]);

        if (!widgets.message && !accommodation.message && _isMounted.current) {
          setAccommodation(new Accommodation(accommodation));
          setWidgets(widgets);
        } else throw Error(widgets.message);
      } catch (error) {
        const { message } = error as Error;
        _isMounted.current && setError(String(message || error));
      }
    }
  }, [_isMounted, id, year, month, abortController]);

  const goToEdit = () => navigate!(`/szallasok/szerkesztes/${id}`, { state: { accommodation } });

  useEffect(() => {
    if (!year || !month) {
      navigate!(`./${TODAY.year}/${TODAY.month}`, { replace: true, state: location?.state as any });
    }
  }, [navigate, year, month, location?.state]);

  useEffect(() => void onGetInitial(), [onGetInitial]);

  return id && year && month ? (
    <div className="page-container hotels-details max-w-7xl">
      {error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={onGetInitial} />
        </div>
      ) : (
        <>
          <div className="table-header pt-4 sm:py-8">
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between">
                <h1 className="main-title pt-0">{(accommodation?.host.name || (location?.state as any)?.hostName) ?? ''}</h1>
                <Button title="Szerkesztés" hiddenFor={['Reader', 'ProHumanManager']} className="rounded-md hidden twsm:block" onClick={goToEdit} />

                <IconButton icon={<PencilIcon className="icon-xs icon-without-hover" />} hiddenFor={['Reader', 'ProHumanManager']} theme="primary" className="rounded-md twsm:hidden" onClick={goToEdit} />
              </div>

              <div className="flex flex-row space-x-6">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {(accommodation?.address || (location?.state as any)?.address) ?? ''}
                </div>

                {accommodation?.contracts[0] && (
                  <div className="flex flex-col xs:flex-row gap-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <Badge label={accommodation.isActive ? 'Aktív' : 'Inaktív'} className={accommodation.isActive ? 'badge--green' : 'badge--gray'} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <TabBar
            wrapperClassName="mb-6"
            tabs={[
              {
                label: 'Havi áttekintés',
                component: (show: boolean) => <BasicData accommodation={accommodation} show={show} />
              },
              {
                label: 'Szálláshely adatai',
                component: (isShow: boolean) => <MonthlyOverview hotelId={id} navigate={navigate!} year={year} month={month} widgets={widgets} show={isShow} />
              }
            ]}
          />
        </>
      )}
    </div>
  ) : null;
}
