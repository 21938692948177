import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Navigation things
import { RouteComponentProps, useNavigate } from '@reach/router';

// Presentation things
import { Button } from '../../../components/Buttons';
import { Select } from '../../../components/Select';
import { Checkbox, Input } from '../../../components/Inputs';
import { Chips } from '../../../components/Chips';
import { InfoIcon } from '../../../components/Icons';
import { CircularProgress } from '../../../components/CircularProgress';

// Data Things
import { useAuthorization, useIsMounted } from '../../../hooks';
import { EmployeesContext } from '../../../contexts/Employees';
import { SelectableEmployee, GENDERS_OPTION, taxNumberCheck, transformIEmployeeDTOtoModel, EmployeeModel } from './helper';
import { onChangeInput } from '../../../constants/functions';
import { DateTime } from 'luxon';
import { Sex } from '../../../typings/enum';
import { api, ApiErrorResult } from '../../../utils/api';
import { IEmployeeDTO, IEmployeeListDTO } from '../../../typings/DTOs';

export default function CreateOrEdit(props: RouteComponentProps<{ id: number }>) {
  //Contexts
  const { onEditEmployee, onAddEmployee } = useContext(EmployeesContext);

  // Refs
  const idsRef_ = useRef<HTMLHeadingElement>(null);
  const taxIdentificationNumberRef_ = useRef<HTMLInputElement>(null);

  // Hooks
  const { role } = useAuthorization();
  const _isMounted = useIsMounted();
  const navigation = useNavigate();

  // Variables
  const isEditPage = props.id !== undefined;

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<IEmployeeDTO | null>(null);
  const [employeeModel, setEmployeeModel] = useState<EmployeeModel>({
    id: -1,
    birthdate: DateTime.now(),
    gender: Sex.Male,
    name: '',
    idCardNumber: '',
    probondId: '',
    taxIdentificationNumber: '',
    passportNumber: '',
    isRelative: false,
    relatives: []
  });
  const [selectableEmployees, setSelectableEmployees] = useState<SelectableEmployee[]>([]);
  const [error, setError] = useState('');
  const [missingId, setMissingID] = useState(false);

  const idEntered = useMemo(() => employeeModel.idCardNumber || employeeModel.probondId || employeeModel.taxIdentificationNumber || employeeModel.passportNumber, [employeeModel]);

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();

    if (!idEntered) {
      setMissingID(true);
      idsRef_.current && idsRef_.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setMissingID(false);

    try {
      let validTaxNumber = false;

      if (taxIdentificationNumberRef_.current) {
        validTaxNumber = taxIdentificationNumberRef_.current.checkValidity();
      }

      if (employeeModel.taxIdentificationNumber.length === 0 || (employeeModel.taxIdentificationNumber.length > 0 && validTaxNumber)) {
        setIsLoading(true);

        const resp = isEditPage
          ? await onEditEmployee(
              {
                birthdate: employeeModel.birthdate.toISODate(),
                idCardNumber: employeeModel.idCardNumber,
                probondId: employeeModel.probondId,
                taxIdentificationNumber: employeeModel.taxIdentificationNumber,
                passportNumber: employeeModel.passportNumber,
                isRelative: employeeModel.isRelative,
                relatives: employeeModel.relatives.map((x: SelectableEmployee) => x.value) as [],
                name: employeeModel.name,
                sex: employeeModel.gender
              },
              Number(props.id)
            )
          : await onAddEmployee({
              birthdate: employeeModel.birthdate.toISODate(),
              idCardNumber: employeeModel.idCardNumber,
              probondId: employeeModel.probondId,
              taxIdentificationNumber: employeeModel.taxIdentificationNumber,
              passportNumber: employeeModel.passportNumber,
              isRelative: employeeModel.isRelative,
              relatives: employeeModel.relatives.map((x: SelectableEmployee) => x.value) as [],
              relativeIdentifier: null,
              name: employeeModel.name,
              sex: employeeModel.gender
            });

        if (typeof resp !== 'string' && _isMounted.current) {
          goBack();
        }
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onChangeGender = (selected: number[]) => setEmployeeModel((prev) => ({ ...prev, gender: selected[0] as Sex }));
  const onChangeRelatives = (selected: number[]) => setEmployeeModel((prev) => ({ ...prev, relatives: (selected as []).map((r) => r) }));
  const onDeselectRelative = (value: number) => {
    setEmployeeModel((prev) => ({ ...prev, relatives: prev.relatives.filter((x: SelectableEmployee) => x.value !== value) }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeInput(e, setEmployeeModel);
    idEntered && setMissingID(false);
  };

  useEffect(() => {
    if(employeeModel.taxIdentificationNumber?.length && employeeModel.birthdate) {
      const result = taxNumberCheck(employeeModel.taxIdentificationNumber, employeeModel.birthdate.toISODate().substring(0, 10));
      if(!result.isValid) {
        taxIdentificationNumberRef_.current?.setCustomValidity('Kérjük ellenőrizze az adóazonosító számot!' + (result.correctBirthdate ? ` A helyes születési dátum: ${result.correctBirthdate.toLocaleDateString()}` : ''));
      } else {
        taxIdentificationNumberRef_.current?.setCustomValidity('');
      }
    } else {
      taxIdentificationNumberRef_.current?.setCustomValidity('');
    }
  }, [employeeModel.birthdate, employeeModel.taxIdentificationNumber])

  const navigateTo404 = useCallback((error: string) => props.navigate!('/404', { state: { error }, replace: true }), [props.navigate]);

  const goBack = () => (props?.location?.state ? navigation(-1) : navigation('./'));

  const onGetEmployee = useCallback(async () => {
    try {
      if (props.id) {
        setIsLoading(true);
        setError('');

        const resp = await api<IEmployeeDTO>(`employee/${Number(props.id)}`);
        if (!resp.message && _isMounted.current) {
          setResponse(resp);
        } else throw new Error(resp.message);
      }
    } catch (error) {
      const { message } = error as ApiErrorResult;
      _isMounted.current && setError(String(message || error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [props.id, _isMounted]);

  const resetRelatives = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOnChange(e);
    setEmployeeModel((prev) => ({ ...prev, relatives: [] }));
  };

  useEffect(() => {
    if (role === 'Reader') {
      navigateTo404('A kért oldalhoz nem rendelkezel megfelelő jogosultsággal!');
    }
  }, [role, navigateTo404]);

  useEffect(() => void onGetEmployee(), [onGetEmployee]);

  useEffect(() => {
    if (response) {
      setEmployeeModel(transformIEmployeeDTOtoModel(response));
    }
  }, [response]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await api<IEmployeeListDTO>('employee?sort=name&order=ASC');
        if (!resp.message) {
          setSelectableEmployees(resp.items.map((x) => new SelectableEmployee(x)).filter((r) => r.isRelative !== employeeModel.isRelative && r.value !== employeeModel.id));
        } else throw new Error(resp.message);
      } catch (error) {}
    })();
  }, [employeeModel.isRelative, employeeModel.id]);

  return (
    <div className="page-container max-w-4xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={onGetEmployee} />
        </div>
      ) : (
        <>
          <h1 className="main-title">Munkavállaló {isEditPage ? 'szerkesztése' : 'hozzáadása'}</h1>

          <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit}>
            <div className="mt-6 grid gap-4 grid-cols-6 grid-flow-dense">
              <div className="col-span-6 sm:col-span-2">
                <Input name="name" label="Munkavállaló neve" value={employeeModel.name} disabled={isLoading} onChange={handleOnChange} required />
              </div>

              <div className="col-span-3 sm:col-span-2">
                <Input type="date" name="birthdate" label="Születési idő" value={employeeModel.birthdate} disabled={isLoading} onChange={handleOnChange} required />
              </div>

              <div className="col-span-3 sm:col-span-2">
                <Select title="Nem" selected={[employeeModel.gender]} options={GENDERS_OPTION} onSelect={onChangeGender} required />
              </div>

              <div className="col-span-3 sm:col-span-2">
                <Checkbox name="isRelative" title="Hozzátartozó" htmlFor="employeeAdd" theme="form" onChange={resetRelatives} checked={employeeModel.isRelative} />
              </div>

              {/*
              Note that if it changes, the selected employees should reset too!
              The best UX is that the selected employees/relatives are saved,
              so when the user checks or unchecks the checkbox the selected employees or relatives are still selected
          */}
            </div>
            <div className="pt-6">
              <h3 ref={idsRef_} className="text-lg leading-6 font-medium text-gray-900">
                Azonosítók
              </h3>

              <div className="grid gap-4 grid-cols-6 mt-1">
                <div className={`mb-2 text-sm flex items-center justify-start gap-1 col-span-6 ${!missingId ? 'text-gray-400' : 'text-rose-500 bouncingAnimation'}`}>
                  <InfoIcon className={`w-5 h-5 ${missingId ? 'text-rose-500' : 'text-sky-500'}`} />
                  <p>Legalább egy azonosító megadása kötelező!</p>
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <Input name="passportNumber" label="Útlevélszám" value={employeeModel.passportNumber} disabled={isLoading} onChange={handleOnChange} />
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <Input name="idCardNumber" label="Szig. szám" value={employeeModel.idCardNumber} disabled={isLoading} onChange={handleOnChange} />
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <Input
                    ref={taxIdentificationNumberRef_}
                    name="taxIdentificationNumber"
                    label="Adóazonosító"
                    minLength={10}
                    maxLength={10}
                    value={employeeModel.taxIdentificationNumber}
                    disabled={isLoading}
                    onChange={handleOnChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <Input name="probondId" label="Probond ID" value={employeeModel.probondId} disabled={isLoading} onChange={handleOnChange} />
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="text-lg leading-6 text-gray-900">
                <h3 className="mr-2 inline font-medium">{employeeModel.isRelative ? 'Hozzárendelt munkavállalók' : 'Hozzátartozók'}</h3>
              </div>

              <div className="mt-6 grid gap-4 grid-cols-6">
                <div className="col-span-6 sm:col-span-2 col-start-1 sm:col-start-1">
                  <Select
                    title={employeeModel.isRelative ? 'Hozzárendelt munkavállalók' : 'Hozzátartozók'}
                    options={selectableEmployees}
                    dialog="multi"
                    selected={employeeModel.relatives}
                    onSelect={onChangeRelatives}
                    showSelected={false}
                  />
                </div>

                <div className="flex flex-wrap gap-x-4 gap-y-2 my-auto col-span-6 sm:col-span-4">
                  {employeeModel.relatives.map((x: SelectableEmployee) => (
                    <Chips key={x.value} label={x.text} onDelete={() => onDeselectRelative(x.value)} />
                  ))}
                </div>
              </div>
            </div>

            <div className="flex space-x-3 justify-end pt-5">
              <Button type="button" title="Mégsem" theme="secondary" disabled={isLoading} onClick={goBack} />
              <Button type="submit" title="Mentés" disabled={isLoading} loading={isLoading} />
            </div>
          </form>
        </>
      )}
    </div>
  );
}
