import { SVGProps } from 'react';
import { ID_NAMES } from '../../constants';

interface IconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

export const ProbondIdCard = ({ title, ...props }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <title>{ID_NAMES.probondId}</title>
    <g>
      <path
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 19.5h-15a2.25 2.25 0 0 1-2.25-2.25V6.75A2.25 2.25 0 0 1 4.5 4.5h15a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25Z"
      />
      <path
        fill="currentColor"
        d="M10.08 8.225a1.9 1.9 0 0 0 .56 1.351 1.933 1.933 0 0 0 2.097.415 1.926 1.926 0 0 0 1.04-1.035c.096-.231.145-.48.145-.73a1.894 1.894 0 0 0-.561-1.352 1.927 1.927 0 0 0-2.72 0 1.9 1.9 0 0 0-.561 1.351ZM12 14.216v-3.47a7.03 7.03 0 0 0-4.95 2.033A6.913 6.913 0 0 0 5 17.687h3.5a3.446 3.446 0 0 1 1.025-2.454A3.5 3.5 0 0 1 12 14.216ZM12 14.216v3.471a7.03 7.03 0 0 0 4.95-2.033A6.913 6.913 0 0 0 19 10.746h-3.5c0 .92-.369 1.803-1.025 2.454A3.515 3.515 0 0 1 12 14.216Z"
      />
    </g>
  </svg>
);

export const IdCard = ({ title, ...props }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <title>{ID_NAMES.idCardNumber}</title>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0z"
    />
  </svg>
);

export const TaxIdCard = ({ title, ...props }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <title>{ID_NAMES.taxIdentificationNumber}</title>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16 7v10m-2.5-2.348.732.549c.976.732 2.559.732 3.535 0 .977-.733.977-1.92 0-2.652C17.28 12.182 16.64 12 16 12c-.604 0-1.208-.183-1.67-.55-.92-.732-.92-1.918 0-2.65.922-.733 2.417-.733 3.34 0l.345.274M5.25 9H9m-3.75 3H9m-4.5 7.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
    />
  </svg>
);

export const PassportCard = ({ title, ...props }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <title>{ID_NAMES.passportNumber}</title>
    <g>
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 19.5h-15a2.25 2.25 0 0 1-2.25-2.25V6.75A2.25 2.25 0 0 1 4.5 4.5h15a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25Z" />
      <path
        strokeWidth={0}
        fill="currentColor"
        d="M16.736 12.238a4.855 4.855 0 0 0-.472-2.502 6.376 6.376 0 0 1-2.17 1.354 9.498 9.498 0 0 1-.107 2.386 8.584 8.584 0 0 0 2.75-1.238Zm-3.543 1.399c.16-.764.215-1.545.166-2.324a6.387 6.387 0 0 1-2.966 0c-.049.779.007 1.56.166 2.324a8.692 8.692 0 0 0 2.634 0Zm-2.431.784c.74.088 1.487.088 2.228 0a8.593 8.593 0 0 1-1.114 2.303 8.587 8.587 0 0 1-1.114-2.303Zm-.998-.944a9.435 9.435 0 0 1-.107-2.387 6.375 6.375 0 0 1-2.17-1.354 4.856 4.856 0 0 0-.472 2.502 8.584 8.584 0 0 0 2.75 1.239Zm6.79-.239a4.882 4.882 0 0 1-3.74 3.409c.439-.737.773-1.531.995-2.36a9.315 9.315 0 0 0 2.745-1.049Zm-9.356 0a9.31 9.31 0 0 0 2.745 1.05c.22.828.555 1.622.994 2.358a4.883 4.883 0 0 1-3.74-3.408Zm5.617-6.161a4.878 4.878 0 0 1 3.058 1.993c-.534.54-1.171.965-1.873 1.252a9.314 9.314 0 0 0-1.185-3.245ZM11.875 7a8.577 8.577 0 0 1 1.403 3.56 5.64 5.64 0 0 1-2.805 0A8.575 8.575 0 0 1 11.876 7Zm-.938.077a9.314 9.314 0 0 0-1.185 3.245A5.634 5.634 0 0 1 7.879 9.07a4.878 4.878 0 0 1 3.058-1.993Z"
      />
    </g>
  </svg>
);

export const MoreCard = ({ title, ...props }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <title>{title}</title>
    <g>
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 19.5h-15a2.25 2.25 0 0 1-2.25-2.25V6.75A2.25 2.25 0 0 1 4.5 4.5h15a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25Z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm6 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm6 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0z" />
    </g>
  </svg>
);
