import React, { useMemo, useRef, useState } from 'react';

//Data Things
import { AccommodationStatistics, generateCSV, getVisibleStatistics } from './helper';
import { getProfitOrDeficitColor, toCurrencyString, toMonthString } from '../../../constants/functions';
import { IAccommodationStatisticsListItemDTO } from '../../../typings/DTOs';
import { DATE_MONTH_INPUT_FORMAT, TODAY } from '../../../constants';
import { ApiErrorResult, api } from '../../../utils/api';
import { List, Order, Sort } from '../../../typings/common';
import { useIsMounted } from '../../../hooks';
import { IHeaderCell } from '../../../components/Table/helper';
import { DateTime } from 'luxon';

//Presentation Things
import { Button, IconButton } from '../../../components/Buttons';
import { Table, TableRef } from '../../../components/Table';
import { DownloadIcon } from '@heroicons/react/outline';
import { Hideable } from '../../../components/Hideable';
import { Input } from '../../../components/Inputs';
import { Link } from '@reach/router';

// Variables
const header: Array<IHeaderCell<AccommodationStatistics> | string> = [
  { text: 'Szállás partner', sortName: 'host' },
  { text: 'Éjszakák', sortName: 'nights' },
  { text: 'ROI', hiddenFor: 'ProHumanManager' },
  { text: 'Havi költség', sortName: 'expenses', hiddenFor: 'ProHumanManager' },
  { text: 'Havi bevétel', sortName: 'incomes', hiddenFor: 'ProHumanManager' },
  { text: 'Hozzájárulás', sortName: 'contributions', hiddenFor: 'ProHumanManager' },
  ''
];

const Statistics = (isShow: boolean) => {
  // States
  const [searchValue, setSearchValue] = useState('');
  const [statistics, setStatistics] = useState<List<AccommodationStatistics>>({ error: '', isLoading: false, items: [] });
  const [date, setDate] = useState(TODAY);
  const [sort, setSort] = useState<Sort<AccommodationStatistics>>({ sort: 'host', order: 'ASC' });

  // Variable
  const visibleStatistics = useMemo(() => getVisibleStatistics(searchValue, statistics.items, sort), [searchValue, statistics, sort]);

  //Hooks
  const _isMounted = useIsMounted();

  // Refs
  const _table = useRef<TableRef>(null);

  const onGetStatistics = async () => {
    try {
      setStatistics((prev) => ({ ...prev, isLoading: true }));

      const resp = await api<IAccommodationStatisticsListItemDTO[]>(`accommodation/statistics/${date.year}/${date.month}`);

      if (!resp.message && _isMounted.current) {
        if (!resp.length) throw new Error('Nem áll rendelkezésre szálláshelyi statisztika');

        setStatistics({
          items: resp.map((x) => new AccommodationStatistics(x)),
          isLoading: false,
          error: ''
        });
      } else throw new Error(resp.message);
    } catch (error) {
      if (_isMounted.current) {
        const { message } = error as ApiErrorResult;
        setStatistics({ isLoading: false, items: [], error: String(message || error) });
      }
    }
  };

  const onDownload = () => {
    generateCSV(statistics.items, `Szálláshely statisztika ${date.year} ${toMonthString(date)}`);
  };

  const onChangeMonth = (e: React.ChangeEvent<HTMLInputElement>) => setDate(DateTime.fromFormat(e.target.value, DATE_MONTH_INPUT_FORMAT));

  const onSort = (_: any, __: any, ___: any, ____: any, sort: keyof AccommodationStatistics = 'host', order: Order = 'ASC') => setSort({ sort, order });

  if (!isShow) return null;

  return (
    <>
      <form className="pt-4 bg-white">
        <div className="flex flex-col justify-between gap-4 xs:flex-row xs:items-center">
          <Input type="month" label="Hónap" value={date} onChange={onChangeMonth} required />

          <div className="flex items-center space-x-4 col-span-8 justify-end">
            <Button title="Lekérdezem" onClick={onGetStatistics} type="button" disabled={statistics.isLoading} />

            <IconButton theme="secondary" icon={<DownloadIcon className="icon-sm" />} className="h-full flex items-center" disabled={statistics.items.length === 0} onClick={onDownload} />
          </div>
        </div>
      </form>

      <div className="overflow-auto grow-1">
        <div className="riports-statistics-table-container">
          <Table
            ref={_table}
            className="riports-current-table"
            loading={statistics.isLoading}
            error={
              statistics.error
                ? statistics.error
                : !statistics.isLoading && !statistics.items.length
                ? 'Lekérdezéshez válassz ki egy hónapot.'
                : !visibleStatistics.length && searchValue.length
                ? 'A keresett kifejezésre nincs találat'
                : undefined
            }
            data={visibleStatistics}
            order={sort.order}
            sort={sort.sort}
            header={header}
            onSearch={setSearchValue}
            onSort={onSort}
            onRowRender={TableRow}
          />
        </div>
      </div>
    </>
  );
};

const Row = (statistics: AccommodationStatistics, gridTemplate: React.CSSProperties) => {
  return (
    <div className="data-table__body-row" style={gridTemplate} key={statistics.accommodation.id}>
      <div>
        <Link to={`/partnerek/szallas-partner/${statistics.host.id}`} className="hot-link w-full">
          {statistics.host.name}
        </Link>

        <Link to={`/szallasok/${statistics.accommodation.id}`} className="hot-link w-full">
          {statistics.accommodation.address}
        </Link>
      </div>

      <div>{statistics.nights}</div>

      <Hideable.Div hiddenFor="ProHumanManager">
        <span className={getProfitOrDeficitColor(statistics.roi)}>{toCurrencyString(statistics.roi)}</span>
      </Hideable.Div>

      <Hideable.Div hiddenFor="ProHumanManager">
        <span>{toCurrencyString(statistics.expenses)}</span>
      </Hideable.Div>

      <Hideable.Div hiddenFor="ProHumanManager">
        <span>{toCurrencyString(statistics.incomes)}</span>
      </Hideable.Div>

      <Hideable.Div hiddenFor="ProHumanManager">
        <span>{statistics.contributions !== null ? toCurrencyString(statistics.contributions) : '-'}</span>
      </Hideable.Div>
    </div>
  );
};

const TableRow = { default: Row };

export default Statistics;
