import { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren<React.FieldsetHTMLAttributes<HTMLFieldSetElement>> {
  label: string;
  required?: boolean;
  divided?: boolean;
}

export const Fieldset = ({ label, className, children, required = false, divided = false, ...props }: IProps) => {
  return (
    <fieldset className={`flex-1 ${className}`} {...props}>
      <legend className={required ? `block font-medium` : `flex font-medium gap-y-2 items-center`}>
        {label} {!required && <span className="text-gray-400 text-sm font-normal">(opcionális)</span>}
      </legend>
      <div className={`flex-1 mt-4 grid place-items-stretch ${divided ? 'grid-cols-[1fr,_4rem,_1fr]' : 'gap-4 grid-cols-[1fr,_1fr]'}`}>{children}</div>
    </fieldset>
  );
};

export const Divider = () => {
  return <span className="grid place-items-center">-</span>;
};
