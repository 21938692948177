import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Navigation things
import { RouteComponentProps, useNavigate } from '@reach/router';

// Data Things
import { useAuthorization, useIsMounted } from '../../../../hooks';
import { ClientsContext } from '../../../../contexts/Partners/Clients';
import { onChangeInput } from '../../../../constants/functions';

// Presentation things
import { Checkbox, Input, TextArea } from '../../../../components/Inputs';
import { Button } from '../../../../components/Buttons';

export function CreateOrEdit(props: RouteComponentProps<{ id: number }>) {
  // Context
  const { onEditClient, onAddClient, onGetClient, clients } = useContext(ClientsContext);

  // Variables
  const existClient = useMemo(() => clients.items.find((x) => x.id === +props.id!), [clients, props.id]);
  const isEditPage = props.id !== undefined;

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState(existClient || { description: '', isActive: !isEditPage, name: '', taxNumber: '' });

  // Hooks
  const { role } = useAuthorization();
  const _isMounted = useIsMounted();
  const navigation = useNavigate();

  const onSubmit = async (ev: React.FormEvent) => {
    try {
      ev.preventDefault();
      setIsLoading(true);

      const resp = isEditPage
        ? await onEditClient({ description: client.description, name: client.name, taxNumber: client.taxNumber, isAcitve: client.isActive }, Number(props.id))
        : await onAddClient({ description: client.description, name: client.name, taxNumber: client.taxNumber });

      if (typeof resp !== 'string' && _isMounted.current) {
        navigateToBack();
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onToogleIsActive = () => setClient((prev) => ({ ...prev, isActive: !prev.isActive }));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setClient);

  const navigateTo404 = useCallback((error: string) => props.navigate!('/404', { state: { error }, replace: true }), [props.navigate]);

  const navigateToBack = () => (props?.location?.state ? navigation(-1) : navigation('./'));

  useEffect(() => {
    if (role !== 'Administrator') {
      navigateTo404('A kért oldalhoz nem rendelkezel megfelelő jogosultsággal!');
    }
  }, [role, navigateTo404]);

  useEffect(() => {
    if (props.id) {
      onGetClient(Number(props.id));
    }
  }, [onGetClient, props.id]);

  useEffect(() => {
    if (existClient) {
      setClient(existClient);
    }
  }, [existClient]);

  return (
    <div className="page-container max-w-4xl">
      <h1 className="main-title">Ügyfél {isEditPage ? 'szerkesztése' : 'hozzáadása'}</h1>

      <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit}>
        <div className="pt-4 sm:pt-8">
          <div className="mt-6 grid gap-4 grid-cols-6">
            <div className="col-span-6 sm:col-span-3 md:col-span-2">
              <Input label="Cégnév" name="name" value={client.name} onChange={handleOnChange} required disabled={isLoading} />
            </div>

            <div className="col-span-6 sm:col-span-3 md:col-span-2">
              <Input label="Adószám" name="taxNumber" value={client.taxNumber} onChange={handleOnChange} required disabled={isLoading} />
            </div>

            {isEditPage && (
              <div className="col-span-6 sm:col-span-3 md:col-span-2">
                <Checkbox title="Aktív?" htmlFor="active" checked={client.isActive} onChange={onToogleIsActive} theme="form" disabled={isLoading} />
              </div>
            )}

            <div className="col-span-6">
              <TextArea title="Megjegyzés" rows={4} name="description" value={client.description} onChange={handleOnChange} disabled={isLoading} />
            </div>
          </div>
        </div>

        <div className="flex space-x-3 justify-end pt-5">
          <Button type="button" title="Mégsem" theme="secondary" onClick={navigateToBack} disabled={isLoading} />
          <Button type="submit" title="Mentés" disabled={isLoading} loading={isLoading} />
        </div>
      </form>
    </div>
  );
}
