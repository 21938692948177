import ReactDOM from 'react-dom';
import { useEffect, useRef } from 'react';

// Presentational things
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '../Buttons';

type Props = {
  onAutoClose?(): void;
  opened: boolean;
  label: string;
  type: 'info' | 'success' | 'error';
  actionButton?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
};

const appRoot = document.getElementById('notification') as Element;

export function Snackbar({ label, type, actionButton, opened, onAutoClose }: Props) {
  const timeout = useRef<NodeJS.Timeout | number>();

  useEffect(() => {
    if (opened && onAutoClose) {
      timeout.current && clearTimeout(timeout.current as number);

      timeout.current = setTimeout(onAutoClose, 5000);
    }
  }, [opened, timeout, onAutoClose]);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {opened && (
        <motion.div
          transition={{ ease: 'easeInOut', duration: 0.15 }}
          initial={type !== 'info' ? { opacity: 0, x: '1rem' } : { opacity: 0, y: '1rem' }}
          animate={type !== 'info' ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }}
          exit={type !== 'info' ? { opacity: 0, x: '1rem' } : { opacity: 0, y: '1rem' }}
          aria-live="assertive"
          className={`fixed w-fit ${type === 'info' ? 'bottom-6 right-0 left-0 mx-auto' : 'top-6 right-6'} ${
            type === 'success' ? 'bg-green-600' : type === 'error' ? 'bg-red-500' : 'bg-white'
          } pl-4 py-2 gap-4 flex items-center shadow-lg rounded-lg ring-primary-darker/10 ring-2 z-40`}>
          {type !== 'info' && (
            <div className="flex items-center justify-center w-fit grow-0 shrink-1">
              {type === 'success' ? <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" /> : <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />}
            </div>
          )}

          <div className="flex items-center">
            <p className={`text-sm ${actionButton ? '' : 'pr-4'} ${type !== 'info' ? 'text-white' : ''}`}>{label}</p>

            <div className="flex items-center">{actionButton && <Button {...actionButton} theme="tertiary" className="text-primary hover:text-primary-darker" />}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    appRoot
  );
}
