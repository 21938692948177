import { IAccommodationEmployeeListItemDTO } from '../../../../typings/DTOs';
import { FilterAndSorteablePaginatedList } from '../../../../typings/common';
import { DateTime } from 'luxon';

export class AccommodationEmployeeListItem {
  public id: number;
  public employee: {
    id: number;
    name: string;
    idCardNumber: string;
    passportNumber: string;
    taxIdentificationNumber: string;
    probondId: string;
  };
  public moveInDate: DateTime;
  public moveOutDate: DateTime | null;
  public project: {
    id: number;
    workNumber: string;
  } | null;
  public secondaryProject: {
    id: number;
    workNumber: string;
  } | null;
  public monthlyContributions: number | null;

  constructor(p: IAccommodationEmployeeListItemDTO) {
    this.id = p.id;
    this.employee = {
      id: p.employee.id,
      name: p.employee.name || '',
      idCardNumber: p.employee.idCardNumber || '',
      passportNumber: p.employee.passportNumber || '',
      taxIdentificationNumber: p.employee.taxIdentificationNumber || '',
      probondId: p.employee.probondId || ''
    };
    this.moveInDate = p.moveInDate ? DateTime.fromISO(p.moveInDate) : DateTime.now();
    this.moveOutDate = p.moveOutDate ? DateTime.fromISO(p.moveOutDate) : null;
    this.project = p.project
      ? {
          id: p.project.id,
          workNumber: p.project.workNumber || ''
        }
      : null;
    this.secondaryProject = p.secondaryProject
      ? {
          id: p.secondaryProject.id,
          workNumber: p.secondaryProject.workNumber || ''
        }
      : null;
    this.monthlyContributions = p.monthlyContributions;
  }
}

export type AccommodationEmployeesFilter = {
  project: number | null;
  moveInFrom: DateTime | null;
  moveInTo: DateTime | null;
  moveOutFrom: DateTime | null;
  moveOutTo: DateTime | null;
};

export const INITIAL_LIST: FilterAndSorteablePaginatedList<AccommodationEmployeesFilter, AccommodationEmployeeListItem> = {
  isLoading: true,
  totalLength: 0,
  error: '',
  items: [],
  offset: 0,
  page: 1,
  filter: {
    moveOutFrom: null,
    moveInFrom: null,
    moveOutTo: null,
    project: null,
    moveInTo: null
  },
  sort: 'employee',
  order: 'ASC'
};
