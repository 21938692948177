import { Sex, ChargeType, AppRole, UsageStatisticsIncomeType } from '../typings/enum';
import { DateTime } from 'luxon';

export const DATE_INPUT_FORMAT = "yyyy'-'MM'-'dd";
export const DATE_MONTH_INPUT_FORMAT = "yyyy'-'MM";

export const SMALL_SCREEN = 640;
export const MEDIUM_SCREEN = 768;
export const LARGE_SCREEN = 1024;
export const XLARGE_SCREEN = 1280;

export const SELECT_DIALOG_OPTION_NORMAL_SIZE = 64;
export const SELECT_DIALOG_OPTION_SMALL_SIZE = 50;

export const GENDER_MAP: Record<Sex, string> = {
  [Sex.Male]: 'Férfi',
  [Sex.Female]: 'Nő'
};

export const CHARGE_TYPE_MAP: Record<ChargeType, string> = {
  [ChargeType.Daily]: '/fő/éj',
  [ChargeType.Monthly]: '/hónap'
};

export const APP_ROLE_MAP: Record<AppRole, string> = {
  [AppRole.Administrator]: 'Adminisztrátor',
  [AppRole.BRCManager]: 'BRC projekt felelős',
  [AppRole.ProHumanManager]: 'Prohuman projekt felelős',
  [AppRole.Reader]: 'Olvasó'
};

export const USAGE_STATISTICS_INCOM_TYPE_MAP: Record<UsageStatisticsIncomeType, string> = {
  [UsageStatisticsIncomeType.Usage]: 'Szállás díj',
  [UsageStatisticsIncomeType.MinimumUsageDifference]: 'Minimum szállás díj különbözet',
  [UsageStatisticsIncomeType.SecondaryDiscount]: 'Másodlagos projekten elszámolt kedvezmény'
};

export const TODAY = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

export const END_OF_CURRENT_MONTH = DateTime.local().set({ day: DateTime.local().daysInMonth, hour: 0, minute: 0, second: 0 });

export const ID_NAMES = {
  probondId: 'Probond azonosító',
  taxIdentificationNumber: 'Adóazonosító jel',
  passportNumber: 'Útlevélszám',
  idCardNumber: 'Személyi igazolvány szám'
} as const;
