import { useState, useEffect } from 'react';
export function useWindowDimensions() {
  const [dimension, setDimension] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setDimension({ width: window.innerWidth, height: window.innerHeight });
    }, 100);

    window.addEventListener('resize', debouncedResizeHandler);

    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []);

  return dimension;
}

function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,

  wait: number
) {
  let timer: number | NodeJS.Timeout;

  return (...args: T): Promise<U> => {
    clearTimeout(timer as number);

    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(callback(...args)), wait);
    });
  };
}
