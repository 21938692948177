/* Navigation things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { EmployeesProvider } from '../../contexts/Employees';

/* Screens */
import CreateOrEdit from './CreateOrEdit';
import Details from './Details';
import List from './List';

const Employees = (_: RouteComponentProps) => {
  return (
    <EmployeesProvider>
      <Router basepath="munkavallalok" primary={false}>
        <Details path="/:id" />
        <CreateOrEdit path="szerkesztes/:id" />
        <CreateOrEdit path="hozzaadas" />
        <List default />
      </Router>
    </EmployeesProvider>
  );
};

export default Employees;
