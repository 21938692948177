import { basicSort, downloadFile, toDateString } from '../../../constants/functions';
import { USAGE_STATISTICS_INCOM_TYPE_MAP } from '../../../constants';
import { IAccommodationUsageListItemDTO } from '../../../typings/DTOs';
import { UsageStatisticsIncomeType } from '../../../typings/enum';
import { DateTime } from 'luxon';
import { unparse } from 'papaparse';
import { Sort } from '../../../typings/common';

export class AccommodationUsage {
  public employee: {
    id: number;
    name: string;
    idCardNumber: string;
    passportNumber: string;
    taxIdentificationNumber: string;
    probondId: string;
  } | null;
  public costType: UsageStatisticsIncomeType;
  public moveInDate: DateTime | null;
  public moveOutDate: DateTime | null;
  public nights: number;
  public client: {
    id: number;
    name: string;
  };
  public project: {
    id: number;
    workNumber: string;
    clientWorkNumber: string;
  };
  public cost: number;
  public dailyCost: number | null;
  public host: {
    id: number;
    name: string;
  };
  public accommodation: {
    id: number;
    address: string;
  };
  constructor(p: IAccommodationUsageListItemDTO) {
    this.employee = p.employee
      ? {
          id: p.employee.id,
          name: p.employee.name || '',
          idCardNumber: p.employee.idCardNumber || '',
          passportNumber: p.employee.passportNumber || '',
          taxIdentificationNumber: p.employee.taxIdentificationNumber || '',
          probondId: p.employee.probondId || ''
        }
      : null;
    this.costType = p.costType;
    this.moveInDate = p.moveInDate ? DateTime.fromISO(p.moveInDate) : null;
    this.moveOutDate = p.moveOutDate ? DateTime.fromISO(p.moveOutDate) : null;
    this.nights = p.nights;
    this.client = {
      id: p.client.id,
      name: p.client.name || ''
    };
    this.project = {
      id: p.project.id,
      workNumber: p.project.workNumber || '',
      clientWorkNumber: p.project.clientWorkNumber || ''
    };
    this.cost = p.cost;
    this.dailyCost = p.dailyCost;
    this.host = {
      id: p.host.id,
      name: p.host.name || ''
    };
    this.accommodation = {
      id: p.accommodation.id,
      address: p.accommodation.address || ''
    };
  }
}

export const getVisibleStatistics = (searchValue: string, accommodations: AccommodationUsage[], sort: Sort<AccommodationUsage>): AccommodationUsage[] => {
  const search = searchValue.toLowerCase();
  return accommodations
    .filter((x) => {
      if (
        [
          x.project.workNumber.toLowerCase(),
          x.employee?.name.toLowerCase(),
          x.employee?.idCardNumber.toLowerCase(),
          x.employee?.passportNumber.toLowerCase(),
          x.employee?.taxIdentificationNumber.toLowerCase(),
          x.employee?.probondId.toLowerCase(),
          x.accommodation.address.toLowerCase()
        ]
          .join(' ')
          .includes(search)
      ) {
        return true;
      }
      return false;
    })
    .sort((x, y) => basicSort(x, y, sort));
};

export const generateCSV = (accommodations: AccommodationUsage[], title: string) => {
  const data = accommodations.reduce((acc, curr) => {
    return [
      ...acc,
      [
        USAGE_STATISTICS_INCOM_TYPE_MAP[curr.costType],
        (curr.employee && curr.employee.name) || '',
        (curr.employee && curr.employee.idCardNumber) || '',
        (curr.employee && curr.employee.passportNumber) || '',
        (curr.employee && curr.employee.taxIdentificationNumber) || '',
        (curr.employee && curr.employee.probondId) || '',
        curr.host.name,
        curr.accommodation.address,
        toDateString(curr.moveInDate),
        toDateString(curr.moveOutDate),
        curr.nights,
        curr.dailyCost,
        curr.cost,
        curr.client.name,
        curr.project.workNumber,
        curr.project.clientWorkNumber
      ]
    ];
  }, [] as unknown[]);

  downloadFile(
    `${title}.csv`,
    unparse({
      data,
      fields: [
        'Díj típus',
        'Név',
        'Személyi igazolvány szám',
        'Útlevélszám',
        'Adóazonosító',
        'ProbondID',
        'Szállás Partner',
        'Szállás',
        'Beköltözés',
        'Kiköltözés',
        'Éjszakák',
        'Napi díj',
        'Összesen',
        'Ügyfél',
        'BRC munkaszám',
        'Ügyfél munkaszám'
      ]
    })
  );
};
