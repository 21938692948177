import { loginRequest, msalInstance } from "./auth";
import { DateTime } from "luxon";

type Request = Partial<Omit<RequestInit, "body">> & {
  body?: any
}

type QueryParams = Record<string, string | number | boolean | Object>;

export const api = async <T>(path: string, queryParams?: QueryParams, params: Request = {}): Promise<T & ApiErrorResult> => {
  const url = new URL(process.env.REACT_APP_API_BASE + path)

  const [activeAccount] = msalInstance.getAllAccounts();
  const { accessToken } = await msalInstance.acquireTokenSilent({ scopes: loginRequest.scopes, account: activeAccount });

  if (queryParams) {
    const params = JSON.parse(JSON.stringify(queryParams));

    Object.keys(params).forEach(key => {
      if (typeof params[key] === "object") {
        const object = params[key] as Record<string, string | DateTime>;
        //Remove null values from object
        Object.keys(object).forEach(key => {
          if (object[key] === null) {
            delete object[key]
          }
        });

        url.searchParams.set(key, new URLSearchParams(object as Record<string, string>).toString())
      } else {
        url.searchParams.set(key, String(params[key]))
      }
    })
  }

  if (params?.body) {
    params.body = params.body instanceof FormData ? params.body : JSON.stringify(params.body)
  }

  if (!params?.headers) {
    const headers = new Headers();
    headers.append('Accept', 'application/json');

    if (accessToken) {
      headers.append("Authorization", `Bearer ${accessToken}`);
    }

    if (!(params?.body instanceof FormData)) {
      headers.append('Content-Type', 'application/json');
    }

    params.headers = headers;
  }

  return fetch(url.toString(), params)
    .then(response => {
      return new Promise<T & ApiErrorResult>((resolve, reject) => {
        if (response.status < 400 && response.ok) {
          response.json()
            .then(resp => resolve(resp))
            .catch(() => resolve(null!))
        } else if (response.status === 204) {
          reject({ message: ' ', code: response.status });
        } else {
          response.text().then(err => {
            try {
              const errorMessage = JSON.parse(err)
              const message = errorMessage?.message || errorMessage?.error || 'Valami hiba történt, kérjük próbáld újra később.';
              switch (response.status) {
                case 401:
                  reject({ message: 'Valami hiba történt, kérjük jelentkezz be újra.', code: response.status });
                  break;

                case 403:
                  reject({ message: 'A kért adat megtekintéséhez nincs jogod!', code: response.status });
                  break;

                default:
                  reject({ message, code: response.status });
                  break;
              }
            } catch (e) {
              reject({ message: 'Valami hiba történt, kérjük próbáld újra később.', code: response.status });
            }
          });
        }
      });
    })
    .catch(error => {
      return new Promise<T & ApiErrorResult>((_, reject) => {
        if (error?.name !== 'AbortError') {
          reject({ message: error.message || `Valami hiba történt, kérjük próbáld újra később. (${String(error)})`, code: error.code || -1 });
        }
      });
    });
};

export interface ApiErrorResult {
  message: string
  code: number
}

