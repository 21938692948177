import { DATE_INPUT_FORMAT, DATE_MONTH_INPUT_FORMAT } from '.';
import { IHeaderCell } from '../components/Table/helper';
import { DateTime } from 'luxon';
import { Sort } from '../typings/common';

export const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, setState: (value: React.SetStateAction<any>) => void) => {
  switch (e.target.type) {
    case 'number':
      const parsed = parseInt(e.target.value);
      setState((prev: any) => ({ ...prev, [e.target.name]: isNaN(parsed) ? null : parsed }));
      break;
    case 'date':
      const parsedDate = DateTime.fromFormat(e.target.value, DATE_INPUT_FORMAT);
      setState((prev: any) => ({ ...prev, [e.target.name]: parsedDate.isValid ? parsedDate : null }));
      break;
    case 'month':
      const parsedMonth = DateTime.fromFormat(e.target.value, DATE_MONTH_INPUT_FORMAT);
      setState((prev: any) => ({ ...prev, [e.target.name]: parsedMonth.isValid ? parsedMonth : null }));
      break;
    case 'checkbox':
      setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
      break;
    case 'radio':
      setState((prev: any) => ({ ...prev, [e.target.name]: Number(e.target.value) }));
      break;

    default:
      setState((prev: any) => ({ ...prev, [e.target.name]: String(e.target.value) }));
  }
};

export const isEmptyObject = (object: Object) => {
  let isEmpty = true;
  for (const key in object) {
    if (object[key as keyof object] !== null) {
      isEmpty = false;
      break;
    }
  }

  return isEmpty;
};

export const titleSelector = (o: { description?: string; text?: string }): string => {
  if ('description' in o) {
    return o.description || '';
  }
  return o?.text || '';
};

export const basicSort = <T>(x: T, y: T, { sort, order }: Sort<T>): number => {
  const second = y?.[sort] ?? 0;
  const first = x?.[sort] ?? 0;

  if (first instanceof DateTime && second instanceof DateTime) {
    return order === 'ASC' ? first.toMillis() - second.toMillis() : second.toMillis() - first.toMillis();
  }

  if (typeof first === 'object' && typeof second === 'object') {
    //@ts-ignore
    return basicSort(first, second, { sort: 'name', order });
  }

  if (typeof first === 'string' && typeof second === 'string') {
    return order === 'ASC' ? first.localeCompare(second) : second.localeCompare(first);
  }

  if (typeof first === 'number' && typeof second === 'number') {
    return order === 'ASC' ? first - second : second - first;
  }

  return 0;
};

export const toDateString = (date?: DateTime | null) => date?.setLocale('hu').toFormat('D') ?? '-';

export const toMonthString = (date: DateTime) => date.setLocale('hu').monthLong;

export const toCurrencyString = Intl.NumberFormat('hu', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 }).format;

export const toPrettifiedNumber = Intl.NumberFormat('hu', { maximumFractionDigits: 2 }).format;

/**
  ***[TODO] make typesafe version of this function!**

 * This function generate a css `gridTemplateColumns` property for Table. 
 * The generation logic is based on the `header` param value.
**/
export const createGridTemplate = <T>(header: Array<string | IHeaderCell<T> | JSX.Element>, hasFilter: boolean = false) => {
  const tmp: typeof header = [];
  const template = { gridTemplateColumns: '' };

  const format = (s: string) => tmp.push('minmax(0,' + s + ')');

  header.forEach((item, i) => {
    const header = { text: '', object: false };

    if (typeof item === 'string') {
      header.text = item;
    } else if ('text' in item) {
      header.text = item.text;
      header.object = true;
    } else {
      header.text = '';
    }

    switch (header.text.toLowerCase()) {
      case '': {
        i === 0 ? format('16px') : format('0px');
        break;
      }
      case 'munkaszám':
      case 'brc munkaszám':
        format('3.5fr');
        break;
      case 'szállás partner':
      case 'ügyfél':
      case 'ügyfél munkaszám':
      case 'szálláshely':
      case 'szálláshely címe':
      case 'munkavállaló':
      case 'név':
      case 'email':
      case 'jogosultság':
        format('3fr');
        break;
      case 'beköltözés':
      case 'kiköltözés':
      case 'adószám':
      case 'létrehozva':
      case 'telefonszám':
      case 'kapcsolattartó':
      case 'születési idő':
      case 'szerződés lejárta':
      case 'hozzájárulás':
      case 'jelenleg szálláson':
      case 'szerződés lejárata':
        format('2fr');
        break;
      case 'napi díj':
        format('1.5fr');
        break;
      case 'éjszakák':
      case 'összesen':
      case 'szabad':
      case 'roi':
        header.object ? format('1.2fr') : format('0.8fr');
        break;
      case 'buttons':
        format('56px');
        break;
      default:
        format('1fr');
    }
  });

  hasFilter && tmp.push('56px');
  template.gridTemplateColumns = tmp.join(' ');
  return template;
};

export const getProfitOrDeficitColor = (n: number | null | undefined) => (typeof n !== 'number' ? 'text-gray-900' : n > 0 ? 'text-green-800' : 'text-red-800');

export const downloadFile = (name: string, data: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const universalBOM = '\uFEFF';
    link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data));
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
