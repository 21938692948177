import { useCallback } from "react";
import { api } from "../utils/api";


export function useDownload(path: string, fileNameWithExtension: string): () => Promise<void> {
    const onDownload = useCallback(async () => {
        if (path && fileNameWithExtension) {
            try {
                const resp = await api<string>(path);
                if (resp) {
                    const anchor = document.createElement("a");
                    document.body.appendChild(anchor);
                    anchor.href = resp;
                    anchor.download = fileNameWithExtension;
                    anchor.click();
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, [path, fileNameWithExtension])

    return onDownload
}
