import { useRef, useState } from 'react';

/* Presentation Things */
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { Button } from '../Buttons';

/* Data Things */
import { Order, Sort as SortType } from '../../typings/common';
import { Dialog, DialogHandle } from '../Dialog';
import { IHeaderCell } from './helper';

type Props<T> = {
  open: boolean;
  items: IHeaderCell<T>[];
  onClose: () => void;
  onSubmit: (sort: keyof T, order: Order) => any;
  sort: keyof T;
  order: Order;
};

type FilterBodyProps<T> = {
  onChange: React.Dispatch<React.SetStateAction<SortType<T>>>;
  items: IHeaderCell<T>[];
  sort: SortType<T>;
};

type CardProps<T> = {
  item: IHeaderCell<T>;
  isActive: boolean;
  sort: FilterBodyProps<T>['sort'];
  onChange: FilterBodyProps<T>['onChange'];
};

export const MobileTableSort = <T extends unknown>({ open, items, sort, order, onClose, onSubmit }: Props<T>) => {
  /* States */
  const [tempModel, setTempModel] = useState({ sort, order });

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const handleClose = () => {
    setTempModel({ sort, order });
    onClose();
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(tempModel.sort, tempModel.order);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      ref={_dialog}
      onClose={handleClose}
      className="h-[100vh] sm:h-[80vh]"
      contents={[
        {
          title: 'Rendezés',
          body: <FilterBody items={items} sort={tempModel} onChange={setTempModel} />,
          footer: <Button title="Kész" className="grow" onClick={handleSubmit} />
        }
      ]}
    />
  );
};

const FilterBody = <T extends unknown>({ items, sort, onChange }: FilterBodyProps<T>) => {
  return (
    <div className="sort">
      {items.map((x) => (
        <Card key={x.text} item={x} isActive={x.sortName === sort.sort} sort={sort} onChange={onChange} />
      ))}
    </div>
  );
};

const Card = <T extends unknown>({ item, sort, onChange, isActive }: CardProps<T>) => {
  const onOrderToogle = () => onChange({ sort: item.sortName!, order: sort.order === 'ASC' ? 'DESC' : 'ASC' });

  return (
    <div className={`sort-item ${isActive ? 'border-primary text-primary' : ''}`} onClick={onOrderToogle}>
      {item.text}
      <span className="flex items-center gap-x-0.5">
        <ArrowUpIcon className={`icon-xxs relative left-0.5 ${isActive && sort.order === 'DESC' ? 'text-primary' : 'text-gray-300'}`} />
        <ArrowDownIcon className={`icon-xxs relative right-0.5 ${isActive && sort.order === 'ASC' ? 'text-primary' : 'text-gray-300'}`} />
      </span>
    </div>
  );
};
