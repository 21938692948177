import { AccommodationPreview, APIResponseFeedback, FilterAndSorteablePaginatedList, HostPreview, Order, ProjectPreview } from '../../typings/common';
import { IBookingDTO, IEmployeeAccommodationListItemDTO, IEmployeeDTO, IEmployeeListDTO } from '../../typings/DTOs';
import { Employee, EmployeeAccommodationListItem, EmployeeFilter } from '../../contexts/Employees/helper';

export const initialState: EmployeesState = {
  employees: {
    items: [],
    totalLength: 0,
    offset: 0,
    page: 1,
    isLoading: true,
    error: '',
    filter: {
      project: null,
      sex: null
    },
    sort: 'name',
    order: 'ASC'
  },
  response: null
};

export interface EmployeesState {
  employees: FilterAndSorteablePaginatedList<EmployeeFilter, Employee>;
  response: APIResponseFeedback | null;
}

type EmployeesAction =
  | { type: 'success_get_employees'; payload: { employees: IEmployeeListDTO; page: number; sort: keyof Employee; order: Order } }
  | { type: 'error_get_employees'; payload: { error: string } }
  | { type: 'success_get_employee'; payload: { employee: IEmployeeDTO; accommodations: IEmployeeAccommodationListItemDTO[] } }
  | { type: 'success_post_employee'; payload: { employee: IEmployeeDTO } }
  | { type: 'error_post_employee'; payload: { error: string } }
  | { type: 'success_post_employees'; payload: { employees: IEmployeeDTO[] } }
  | { type: 'success_put_employee'; payload: { employee: IEmployeeDTO } }
  | { type: 'error_put_employee'; payload: { error: string } }
  | { type: 'set_filter'; payload: { filter: EmployeeFilter } }
  | { type: 'set_employee_accommodtion'; payload: { booking: IBookingDTO } }
  | { type: 'set_partial_state'; payload: Partial<EmployeesState> };

export function employeesReducer(state: EmployeesState = initialState, action: EmployeesAction): EmployeesState {
  switch (action.type) {
    case 'success_get_employees': {
      return {
        ...state,
        employees: {
          ...state.employees,
          items: action.payload.employees.items.reduce((acc, curr) => [...acc, new Employee(curr)], [] as Employee[]),
          totalLength: action.payload.employees.totalLength,
          offset: action.payload.employees.offset,
          order: action.payload.order,
          page: action.payload.page,
          sort: action.payload.sort,
          isLoading: false,
          error: ''
        }
      };
    }

    case 'error_get_employees': {
      return {
        ...state,
        ...state,
        employees: {
          ...state.employees,
          isLoading: false,
          error: action.payload.error
        }
      };
    }

    case 'success_get_employee': {
      let isExist = false;
      const newEmployee = new Employee(action.payload.employee);
      newEmployee.accommodations = action.payload.accommodations.map((x) => new EmployeeAccommodationListItem(x));

      if (newEmployee.accommodations[0]?.isActive) {
        newEmployee.accommodation = newEmployee.accommodations[0].accommodation;
        newEmployee.project = newEmployee.accommodations[0].project;
        newEmployee.secondaryProject = newEmployee.accommodations[0].secondaryProject;
        newEmployee.host = newEmployee.accommodations[0].host;
      }

      state.employees.items.forEach((client, i) => {
        if (client.id === action.payload.employee.id) {
          isExist = true;
          state.employees.items[i] = newEmployee;
        }
      });

      return {
        ...state,
        employees: {
          ...state.employees,
          items: isExist ? [...state.employees.items] : [newEmployee, ...state.employees.items]
        }
      };
    }

    case 'success_post_employee': {
      return {
        ...state,
        employees: {
          ...state.employees,
          items: [new Employee(action.payload.employee), ...state.employees.items],
          totalLength: state.employees.totalLength + 1
        },
        response: { type: 'success', message: 'Sikeres munkavállaló létrehozás' }
      };
    }

    case 'error_post_employee': {
      return {
        ...state,
        response: { type: 'error', message: action.payload.error }
      };
    }

    case 'success_put_employee': {
      return {
        ...state,
        employees: {
          ...state.employees,
          items: state.employees.items.map((x) =>
            x.id === action.payload.employee.id ? new Employee({ ...action.payload.employee, project: x.project, secondaryProject: x.secondaryProject, host: x.host, accommodation: x.accommodation }) : x
          )
        },
        response: { type: 'success', message: 'Sikeres munkavállaló szerkesztés' }
      };
    }

    case 'error_put_employee': {
      return {
        ...state,
        response: { type: 'error', message: action.payload.error }
      };
    }

    case 'set_filter': {
      return {
        ...state,
        employees: {
          ...state.employees,
          filter: action.payload.filter
        }
      };
    }

    case 'set_employee_accommodtion': {
      const employeeId = action.payload.booking.employee.id;
      const bookingId = action.payload.booking.id;

      state.employees.items.forEach((x) => {
        if (x.id === employeeId) {
          x.accommodation = new AccommodationPreview(action.payload.booking.accommodation.id, action.payload.booking.accommodation.address);
          x.project = new ProjectPreview(action.payload.booking.project.id, action.payload.booking.project.workNumber);
          x.secondaryProject = action.payload.booking.secondaryProject ? new ProjectPreview(action.payload.booking.secondaryProject.id, action.payload.booking.secondaryProject.workNumber) : null;
          x.host = new HostPreview(action.payload.booking.host.id, action.payload.booking.host.name);
          x.accommodations = x.accommodations?.map((y) => (y.id === bookingId ? new EmployeeAccommodationListItem(action.payload.booking) : y)) ?? null;
        }
      });

      return {
        ...state,
        employees: { ...state.employees } //for rerender
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}
