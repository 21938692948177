import React, { useContext, useEffect, useRef, useState } from 'react';

/* Presentation Things */
import { Dialog, DialogHandle } from '../../../../components/Dialog';
import { TableChips } from '../../../../components/Table';
import { Fieldset } from '../../../../components/Fieldset';
import { Button } from '../../../../components/Buttons';
import { Radio } from '../../../../components/Inputs';
import { Chips } from '../../../../components/Chips';

/* Data Things */
import { ClientsContext } from '../../../../contexts/Partners/Clients';

export const Filter = (open: boolean, onClose: () => void, onSubmit: (filter: Object) => Promise<void>) => {
  /* Contexts */
  const {
    clients: { filter },
    onFilter
  } = useContext(ClientsContext);

  /* States */
  const [tempModel, setTempModel] = useState(filter);

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const handleSubmit = () => {
    onSubmit(tempModel);
    onFilter(tempModel);
    onClose();
  };

  const onDeselectStatus = () => {
    onSubmit({ status: null });
    onFilter({ status: null });
  };

  const onChangeTempModel = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTempModel({ status: ev.target.value as 'active' | 'inactive' });
  };

  useEffect(() => setTempModel(filter), [filter]);

  return (
    <>
      <Dialog
        open={open}
        ref={_dialog}
        onClose={onClose}
        contents={[
          {
            title: 'Szűrő',
            body: <FilterBody model={tempModel} onChangeModel={onChangeTempModel} />,
            footer: <Button title="Kész" className="grow" onClick={handleSubmit} />
          }
        ]}
      />

      <TableChips hasActiveFilter={filter.status !== null}>
        <Chips label={filter.status === 'active' ? 'Aktív' : 'Inaktív'} onDelete={onDeselectStatus} />
      </TableChips>
    </>
  );
};

const FilterBody = ({ model, onChangeModel }: any) => {
  return (
    <div className="p-5 flex flex-col gap-y-8 text-sm pb-8">
      <Fieldset label="Státusz">
        <Radio title="Aktív" htmlFor="active" value="active" theme="form" onChange={onChangeModel} checked={model.status === 'active'} />
        <Radio title="Inaktív" htmlFor="inactive" value="inactive" theme="form" onChange={onChangeModel} checked={model.status === 'inactive'} />
      </Fieldset>
    </div>
  );
};
