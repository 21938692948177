import React from 'react';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  htmlFor?: string;
  theme?: 'form';
}

export const Radio = ({ title, htmlFor, theme, ...rest }: IProps) => {
  return title || htmlFor ? (
    <div
      className={
        theme === 'form' ? `border border-gray-300 rounded-md px-3 py-4 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary` : '' + rest.className
      }>
      <label className={`flex justify-between items-center w-full text-sm font-medium text-gray-900 ${theme !== 'form' && 'flex-row-reverse'}`}>
        {theme !== 'form' ? <span className="text-gray-600">{title && htmlFor && title}</span> : title && htmlFor && title}

        <input {...rest} id={rest.id || htmlFor} type="radio" className={`focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded-full ${theme !== 'form' && 'mr-4'}`} />
      </label>
    </div>
  ) : (
    <input {...rest} id={rest.id || htmlFor} type="radio" className={`focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded-full ${rest.className}`} />
  );
};
