import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';

// Navigation things
import { Link, RouteComponentProps, useNavigate, useParams } from '@reach/router';

// Presentation things
import { ChartPieIcon, FilterIcon, OfficeBuildingIcon, PlusIcon, SortAscendingIcon, TrendingDownIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/outline';
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../components/Buttons';
import { MonthlyScroller } from '../../../components/MonthlyScroller';
import { Table, TableRef } from '../../../components/Table';
import { Accordion } from '../../../components/Accordion';
import { Hideable } from '../../../components/Hideable';
import { Widget } from '../../../components/Widgets';
import { Filter } from './Filter';

// Data things
import { getProfitOrDeficitColor, toCurrencyString, toMonthString, toPrettifiedNumber } from '../../../constants/functions';
import { AccommodationListItem, AccommodationsFilter } from '../../../contexts/Accommodations/helper';
import { END_OF_CURRENT_MONTH, MEDIUM_SCREEN } from '../../../constants';
import { AccommodationsContext } from '../../../contexts/Accommodations';
import { IHeaderCell } from '../../../components/Table/helper';
import { Order } from '../../../typings/common';

// Variables
const header: IHeaderCell<AccommodationListItem>[] = [
  { text: 'Szállás partner', sortName: 'host' },
  { text: 'Szabad', sortName: 'available' },
  { text: 'Éjszakák', sortName: 'nights' },
  { text: 'Roi', sortName: 'roi', hiddenFor: 'ProHumanManager' }
];

export default function List(props: RouteComponentProps<{ year: number; month: number }>) {
  /* Context */
  const { accommodations, widgets, onGetAccommodations, onGetAccommodationsWithWidgets } = useContext(AccommodationsContext);

  /* State */
  const [selectedMonth, setSelectedMonth] = useState(props.month && props.year ? END_OF_CURRENT_MONTH.set({ month: Number(props.month), year: Number(props.year) }) : END_OF_CURRENT_MONTH);
  const [isMonthChange, setIsMonthChange] = useState(false);

  /* Refs */
  const _table = useRef<TableRef>(null);

  const handleGetAccommodations = useCallback(
    (search: string, signal?: AbortSignal, page?: number, filter?: AccommodationsFilter, sort?: keyof AccommodationListItem, order?: Order) => {
      return onGetAccommodations(selectedMonth, search, signal, page, filter, sort, order);
    },
    [selectedMonth, onGetAccommodations]
  );

  const onPreviousMonth = () => {
    const { year, month } = selectedMonth.minus({ month: 1 });
    setIsMonthChange(true);
    setSelectedMonth((prev) => prev.set({ year, month }));
    props.navigate!(`/szallasok/${year}/${month}`);
  };

  const onNextMonth = () => {
    const { year, month } = selectedMonth.plus({ month: 1 });
    setIsMonthChange(true);
    setSelectedMonth((prev) => prev.set({ year, month }));
    props.navigate!(`/szallasok/${year}/${month}`);
  };

  const onOpenFilter = () => _table.current?.onToogleFilter();

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => {
    if (isMonthChange || !accommodations.items.length) {
      onGetAccommodationsWithWidgets(selectedMonth.year, selectedMonth.month).finally(() => setIsMonthChange(false));
    }

    // eslint-disable-next-line
  }, [onGetAccommodationsWithWidgets, selectedMonth.year, selectedMonth.month, isMonthChange]);

  return (
    <div className="page-container hotels-list max-w-6xl">
      <div className="table-header items-center pt-4 pb-8 sm:py-8">
        <h1 className="main-title pt-0">Szállások</h1>

        <MonthlyScroller date={selectedMonth} onNextMonth={onNextMonth} onPreviousMonth={onPreviousMonth} />
      </div>

      <div className="bg-white w-full grid gap-4 sm:grid-cols-[repeat(2,_minmax(min-content_,1fr))] xl:grid-cols-[repeat(4,_minmax(min-content_,1fr))] 2xl:grid-flow-col 2xl:grid-cols-none">
        {widgets?.nights !== null ? (
          <Widget icon={<OfficeBuildingIcon className="icon-md widget-container__icon-wrap-icon" />} title="Vendég éjszakák" contents={[{ itemText: (!isMonthChange && widgets?.nights) || '-' }]} />
        ) : (
          <Widget icon={<UserGroupIcon className="icon-md widget-container__icon-wrap-icon" />} title="Szabad kapacitás" contents={[{ itemText: (!isMonthChange && widgets?.available) || '-' }]} />
        )}

        <Widget icon={<UsersIcon className="icon-md widget-container__icon-wrap-icon" />} title="Munkavállalók szálláson" contents={[{ itemText: (!isMonthChange && widgets?.bookedEmployees) || '-' }]} />

        <Widget
          icon={<TrendingDownIcon className="icon-md widget-container__icon-wrap-icon" />}
          title="Veszteséges szállások"
          contents={[{ itemText: (!isMonthChange && widgets?.deficitAccommodation) || '-', textStyle: 'text-red-800' }]}
          hiddenFor="ProHumanManager"
        />

        <Widget
          icon={<ChartPieIcon className="icon-md widget-container__icon-wrap-icon" />}
          title="Nyereség"
          contents={[
            { itemTitle: toMonthString(selectedMonth.minus({ month: 1 })), itemText: !isMonthChange && widgets?.profit.previousMonth ? `${toPrettifiedNumber(widgets.profit.previousMonth)}%` : '-' },
            {
              itemTitle: 'jelenlegi',
              itemText: !isMonthChange && widgets?.profit.current ? `${toPrettifiedNumber(widgets.profit.current)}%` : '-',
              textStyle: getProfitOrDeficitColor(widgets?.profit.current)
            },
            { itemTitle: toMonthString(selectedMonth), itemText: !isMonthChange && widgets?.profit.currentMonth ? `${toPrettifiedNumber(widgets.profit.currentMonth)}%` : '-' }
          ]}
          hiddenFor="ProHumanManager"
        />
      </div>

      <div className="table-header gap-x-2 flex-row justify-end">
        <IconButton to="/szallasok/hozzaadas" icon={<PlusIcon className="icon-xs icon-without-hover" />} hiddenFor={['Reader', 'ProHumanManager']} className="twsm:hidden" />

        <IconButton icon={<FilterIcon className="icon-xs" />} className="twmd:hidden" theme="secondary" onClick={onOpenFilter} disabled={!accommodations.items.length} />

        <IconButton icon={<SortAscendingIcon className="icon-xs" />} className="twmd:hidden" theme="secondary" onClick={onOpenSort} disabled={!accommodations.items.length} />

        <Button to="/szallasok/hozzaadas" title="Új szállás" hiddenFor={['Reader', 'ProHumanManager']} className="hidden twsm:block" />
      </div>

      <Table
        ref={_table}
        className="hotels-list-table"
        maxDataCount={accommodations.totalLength}
        loading={accommodations.isLoading || isMonthChange}
        filter={accommodations.filter}
        error={accommodations.error}
        order={accommodations.order}
        data={accommodations.items}
        page={accommodations.page}
        sort={accommodations.sort}
        header={header}
        onChangePage={handleGetAccommodations}
        onFilter={handleGetAccommodations}
        onSearch={handleGetAccommodations}
        onSort={handleGetAccommodations}
        onFilterRender={Filter}
        onRowRender={TableRow}
      />
    </div>
  );
}

const Row = (row: AccommodationListItem, gridTemplate: React.CSSProperties) => {
  /* Hooks */
  const navigate = useNavigate();
  const { month, year } = useParams();

  const goToDetails = () => {
    let path = `/szallasok/${row.id}`;
    if (year && month) {
      path += `/${year}/${month}`;
    }
    navigate(path, { state: { hostName: row.host.name, address: row.address } });
  };

  return (
    <div className="data-table__body-row" style={gridTemplate} key={row.id}>
      <div>
        {row.host && (
          <Link title={row.host.name} to={`/partnerek/szallas-partner/${row.host.id}`} className="hot-link w-full">
            {row.host.name}
          </Link>
        )}

        <span>{row.address}</span>
      </div>

      <div>{row.available ?? '-'}</div>

      <div>{row.nights ?? '-'}</div>

      <Hideable.Div hiddenFor="ProHumanManager">{row.roi !== null ? <span className={row.roi >= 0 ? 'text-green-800' : 'text-red-800'}>{toCurrencyString(row.roi)}</span> : '-'}</Hideable.Div>

      <div className="action-2">
        <EyeIcon className="icon-sm cursor-pointer" onClick={goToDetails} role="link" />

        <IconButton icon={<PencilIcon className="icon-sm" />} to={`/szallasok/szerkesztes/${row.id}`} hiddenFor={['Reader', 'ProHumanManager']} />
      </div>
    </div>
  );
};

const MobileRow = (row: AccommodationListItem) => {
  /* Hooks */
  const navigate = useNavigate();
  const { month, year } = useParams();

  const goToDetails = () => {
    let path = `/szallasok/${row.id}`;
    if (year && month) {
      path += `/${year}/${month}`;
    }
    navigate(path, { state: { hostName: row.host.name, address: row.address } });
  };

  return (
    <Accordion
      key={row.id}
      isCard
      isBorderBottom
      className="card"
      summaryClassName="h-auto"
      label={
        <div className="card-header twsm:flex-row twsm:justify-between">
          <div className="flex flex-col items-start">
            <Link title={row.host.name} to={`/partnerek/szallas-partner/${row.host.id}`} className="card-header__primary-text hot-link">
              {row.host.name}
            </Link>

            <span className="card-header__secondary-text truncate">{row.address}</span>
          </div>

          <Hideable.Div className="data items-centerm twsm:min-w-[160px] mt-0 border-t-0" hiddenFor="ProHumanManager">
            <div className="data-item gap-4">
              <span className="data-item__title">ROI</span>

              {row.roi !== null ? <span className={row.roi >= 0 ? 'text-green-800' : 'text-red-800'}>{toCurrencyString(row.roi)}</span> : '-'}
            </div>
          </Hideable.Div>
        </div>
      }>
      <div className="data">
        <div className="data-item">
          <span className="data-item__title">Foglalt</span>

          <span className="data-item__data">{row.nights}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Szabad</span>

          <span className="data-item__data">{row.available}</span>
        </div>

        <div className="data-item data-item--action">
          <span className="data-item__data gap-x-4 ml-auto">
            <EyeIcon className="icon-sm" onClick={goToDetails} role="link" />

            <IconButton icon={<PencilIcon className="icon-sm" />} to={`/szallasok/szerkesztes/${row.id}`} hiddenFor={['Reader', 'ProHumanManager']} />
          </span>
        </div>
      </div>
    </Accordion>
  );
};

const TableRow = { default: Row, mobile: MobileRow, breakpoint: MEDIUM_SCREEN };
