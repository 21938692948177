import { APIResponseFeedback, List } from "../../typings/common";
import { IUserDTO } from "../../typings/DTOs";
import { User } from "../../contexts/Users/helper";

export const initialState: UsersState = {
    users: {
        items: [],
        isLoading: true,
        error: ''
    },
    response: null
};

export interface UsersState {
    users: List<User>;
    response: APIResponseFeedback | null;
}

type UsersAction =
    | { type: 'success_get_users'; payload: { users: IUserDTO[] } }
    | { type: 'error_get_users'; payload: { error: string } }
    | { type: 'success_get_user'; payload: { user: IUserDTO } }
    | { type: 'success_post_user'; payload: { user: IUserDTO } }
    | { type: 'error_post_user'; payload: { error: string } }
    | { type: 'success_put_user'; payload: { user: IUserDTO } }
    | { type: 'error_put_user'; payload: { error: string } }
    | { type: 'set_partial_state'; payload: Partial<UsersState> }

export function usersReducer(state: UsersState = initialState, action: UsersAction): UsersState {
    switch (action.type) {
        case 'success_get_users': {
            return {
                ...state,
                users: {
                    ...state.users,
                    items: action.payload.users.reduce((acc, curr) => ([...acc, new User(curr)]), [] as User[]),
                    isLoading: false,
                    error: ''
                },
            };
        }

        case 'error_get_users': {
            return {
                ...state,
                users: {
                    ...state.users,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }

        case "success_get_user": {
            let isExist = false;

            state.users.items.forEach((client, i) => {
                if (client.id === action.payload.user.id) {
                    isExist = true;
                    state.users.items[i] = new User(action.payload.user);
                }
            })

            return {
                ...state,
                users: {
                    ...state.users,
                    items: isExist ? [...state.users.items] : [new User(action.payload.user), ...state.users.items]
                }
            }
        }

        case "success_post_user": {
            return {
                ...state,
                users: {
                    ...state.users,
                    items: [new User(action.payload.user), ...state.users.items]
                },
                response: { type: 'success', message: "Sikeres felhasználó létrehozás" }
            }
        }

        case "error_post_user": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_put_user": {
            return {
                ...state,
                users: {
                    ...state.users,
                    items: state.users.items.map(x => x.id === action.payload.user.id ? new User(action.payload.user) : x)
                },
                response: { type: 'success', message: "Sikeres felhasználó szerkesztés" }
            }
        }

        case "error_put_user": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "set_partial_state": {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state;
    }
}
