import { ICreateHostCompanyDTO, ICreateHostIndividualDTO, IUpdateHostCompanyDTO, IUpdateHostIndividualDTO } from "../../../../typings/DTOs";
import { IndividualHost, CompanyHost } from "../../../../contexts/Partners/Hosts/helper"
import { DateTime } from "luxon";

export const EMPTY_COMPANY_HOST = { name: '', taxNumber: '', bankAccountNumber: '', description: '', isActive: true, contact: { name: '', emailAddress: '', phoneNumber: '' } };

export const EMPTY_INDIVIDUAL_HOST = {
    name: '',
    birthName: "",
    birthdate: DateTime.now(),
    taxIdentificationNumber: "",
    socialSecurityNumber: "",
    idCardNumber: "",
    bankAccountNumber: '',
    description: '',
    isActive: true,
    contact: { homeAddress: '', residenceAddress: '', phoneNumber: '', emailAddress: '' }
};

export const getSelectedView = (host?: IndividualHost | CompanyHost): 'individual' | 'company' => {
    return !host || host instanceof CompanyHost ? "company" : "individual"
}

export const transformToIUpdateHostCompanyDTO = (p: CompanyHost | typeof EMPTY_COMPANY_HOST): IUpdateHostCompanyDTO => {
    return {
        bankAccountNumber: p.bankAccountNumber,
        description: p.description,
        isActive: p.isActive,
        taxNumber: p.taxNumber,
        name: p.name,
        contact: p.contact
    }
}

export const transformToICreateHostCompanyDTO = (p: CompanyHost | typeof EMPTY_COMPANY_HOST): ICreateHostCompanyDTO => {
    return {
        bankAccountNumber: p.bankAccountNumber,
        description: p.description,
        taxNumber: p.taxNumber,
        name: p.name,
        contact: p.contact
    }
}

export const transformIUpdateHostIndividualDTO = (p: IndividualHost | typeof EMPTY_INDIVIDUAL_HOST): IUpdateHostIndividualDTO => {
    return {
        bankAccountNumber: p.bankAccountNumber,
        description: p.description,
        isActive: p.isActive,
        taxIdentificationNumber: p.taxIdentificationNumber,
        birthName: p.birthName,
        birthdate: p.birthdate.toISODate(),
        idCardNumber: p.idCardNumber || null,
        socialSecurityNumber: p.socialSecurityNumber || null,
        name: p.name,
        contact: p.contact
    }
}

export const transformICreateHostIndividualDTO = (p: IndividualHost | typeof EMPTY_INDIVIDUAL_HOST): ICreateHostIndividualDTO => {
    return {
        bankAccountNumber: p.bankAccountNumber,
        description: p.description,
        taxIdentificationNumber: p.taxIdentificationNumber,
        birthName: p.birthName,
        birthdate: p.birthdate.toISODate(),
        idCardNumber: p.idCardNumber,
        socialSecurityNumber: p.socialSecurityNumber,
        name: p.name,
        contact: p.contact
    }
}