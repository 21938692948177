import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

//Data Things
import {
  DEFAULT_CONTRACT,
  TODAY,
  Model,
  SpecialCostModel,
  ContractModel,
  ContactModel,
  transformIAccommodationContractDTOsToContractModels,
  transformModelToICreateAccommodationDTO,
  transformModelToIUpdateAccommodationDTO,
  transformIAccommodationDTOToModel
} from './helper';
import { SelectableHost, SelectableBRCManager, SelectableProject } from '../../../contexts/Config/helper';
import { useAuthorization, useNavigate, useIsMounted } from '../../../hooks';
import { onChangeInput, toDateString } from '../../../constants/functions';
import { AccommodationsContext } from '../../../contexts/Accommodations';
import { AccommodationContact } from '../../../contexts/Accommodations/helper';
import { ApiErrorResult, api } from '../../../utils/api';
import { IAccommodationDTO } from '../../../typings/DTOs';
import { DATE_INPUT_FORMAT } from '../../../constants';
import { ConfigContext } from '../../../contexts/Config';
import { ChargeType } from '../../../typings/enum';
import { DateTime } from 'luxon';
import { File } from '../../../typings/common';

// Presentation things
import { PaperClipIcon, TrashIcon } from '@heroicons/react/outline';
import { Button, IconButton } from '../../../components/Buttons';
import { CircularProgress } from '../../../components/CircularProgress';
import { Accordion } from '../../../components/Accordion';
import { PlusIcon, ChevronUpIcon, ChevronDownIcon, ChevronDoubleUpIcon, ChevronDoubleDownIcon } from '@heroicons/react/solid';
import { Download } from '../../../components/Download';
import { Select } from '../../../components/Select';
import { Input } from '../../../components/Inputs';
import { Badge } from '../../../components/Badge';
import { Chips } from '../../../components/Chips';

type SpecialCostProps = {
  onChange(specialCost: SpecialCostModel): void;
  onDelete(id: number): void;
  isFirst: boolean;
  specialCost: SpecialCostModel;
  workNumbers: SelectableProject[];
};

type ContractProps = {
  onChange(contract: ContractModel): void;
  onDelete(id: number): void;
  minStartDate?: DateTime;
  isFirst: boolean;
  isLast: boolean;
  isThereMore: boolean;
  contract: ContractModel;
  onContractOrderChange(tempId: number, moveTo: 'up'|'down'|'first'|'last'): void;
};

export default function CreateOrEdit(props: RouteComponentProps<{ id: number }>) {
  /* Context */
  const { onAddAccommodation, onEditAccommodation } = useContext(AccommodationsContext);
  const { hosts, brcManagers, workNumbers } = useContext(ConfigContext);

  /* Variables */
  const defaultHostId = Number(new URLSearchParams(props.location?.search).get('partner'));
  const isEditPage = props.id !== undefined;

  /* States */
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(isEditPage);
  const [contracts, setContracts] = useState([DEFAULT_CONTRACT(1)]);
  const [response, setResponse] = useState<IAccommodationDTO | null>(null);
  const [contact, setContact] = useState<ContactModel>({ emailAddress: null, name: null, phoneNumber: null, role: null });
  const [model, setModel] = useState<Model>({ address: '', managers: [], host: hosts.find((x) => x.value === defaultHostId) ?? hosts[0] ?? null });
  const [error, setError] = useState('');

  /* Refs */
  const _contractForm = useRef<HTMLFormElement>(null);

  /* Hooks */
  const { goBack, goTo404 } = useNavigate();
  const { role } = useAuthorization();
  const _isMounted = useIsMounted();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (model.host) {
        setIsSubmiting(true);
        const editTransformed = transformModelToIUpdateAccommodationDTO(model, contact, contracts);
        const addTransformed = transformModelToICreateAccommodationDTO(model, contact, contracts);
        const resp = isEditPage ? await onEditAccommodation(editTransformed, Number(props.id)) : await onAddAccommodation(addTransformed);

        if (typeof resp !== 'string' && _isMounted.current) {
          goBack();
        }
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsSubmiting(false);
    }
  };

  const onGetAccommodation = useCallback(async () => {
    try {
      if (props.id) {
        setIsLoading(true);
        setError('');

        const resp = await api<IAccommodationDTO>(`accommodation/${Number(props.id)}`);
        if (!resp.message && _isMounted.current) {
          setResponse(resp);
        } else throw new Error(resp.message);
      }
    } catch (error) {
      const { message } = error as ApiErrorResult;
      _isMounted.current && setError(String(message || error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [props.id, _isMounted]);

  const onDeselectBRCManager = (value: number) => {
    setModel((prev) => ({ ...prev, managers: prev.managers.filter((x: SelectableBRCManager) => x.value !== value) }));
  };

  const onAddContract = () => {
    const isValidForm = _contractForm.current?.checkValidity();

    if (isValidForm) {
      setContracts((prev) => {
        if (prev[prev.length - 1]) {
          if (!prev[prev.length - 1]!.endDate) {
            prev[prev.length - 1]!.endDate = prev[prev.length - 1]!.startDate >= TODAY ? prev[prev.length - 1]!.startDate.plus({ days: 1 }) : TODAY;
          }

          const newContract = DEFAULT_CONTRACT(Date.now(), prev[prev.length - 1]!.endDate?.plus({ days: 1 }));

          return [...prev, newContract]; //reverse order
        }

        return prev;
      });
    } else {
      alert('Ellenőrizd a szerződések adatait, hogy minden adat ki legyen töltve és ne legyen átfedés!');
      // _contractForm.current?.reportValidity();
    }
  };

  const onSelectBRCManagers = (values: SelectableBRCManager[]) => setModel((prev) => ({ ...prev, managers: values }));

  const onContractChange = (contract: ContractModel) => setContracts((prev) => prev.map((x) => (x.tempId === contract.tempId ? contract : x)));

  const onContractOrderChange = (tempId: number, moveTo: 'up'|'down'|'first'|'last') => {
    const currentIndex = contracts.findIndex(x=>x.tempId === tempId);
    const newOrder = [...contracts];
    let item:ContractModel | undefined = undefined;
    //everything is reversed because of the accordion order
    switch (moveTo) {
      case 'down':
        item = newOrder[currentIndex]!;
        newOrder[currentIndex] = newOrder[currentIndex - 1]!;
        newOrder[currentIndex - 1] = item;
        break;
      case 'up':
        item = newOrder[currentIndex]!;
        newOrder[currentIndex] = newOrder[currentIndex + 1]!;
        newOrder[currentIndex + 1] = item;
        break;
      case 'last':
        item = newOrder[currentIndex]!;
        newOrder[currentIndex] = newOrder[0]!;
        newOrder[0] = item;
        break;
      case 'first':
        item = newOrder[currentIndex]!;
        newOrder[currentIndex] = newOrder[newOrder.length - 1]!;
        newOrder[newOrder.length - 1] = item;
        break;
    }

    setContracts(newOrder);
  };

  const onDeleteContract = (tempId: number) => setContracts((prev) => prev.filter((x) => x.tempId !== tempId));

  const onContactChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setContact);

  const onModelChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setModel);

  const onSelectHost = (values: SelectableHost[]) => setModel((prev) => ({ ...prev, host: values[0]! }));

  useEffect(() => void onGetAccommodation(), [onGetAccommodation]);

  useEffect(() => {
    if (role === 'Reader' || role === 'ProHumanManager') {
      goTo404();
    }
  }, [role, goTo404]);

  useEffect(() => {
    if (hosts.length && !model.host) {
      setModel((prev) => ({ ...prev, host: hosts.find((x) => x.value === defaultHostId) ?? hosts[0] ?? null }));
    }
  }, [hosts, defaultHostId, model.host]);

  useEffect(() => {
    if (response) {
      setContracts(transformIAccommodationContractDTOsToContractModels(response.contracts, workNumbers).sort((a, b) => (a.startDate < b.startDate ? -1 : 1)));
      setContact(new AccommodationContact(response.contact));
      setModel(transformIAccommodationDTOToModel(response, hosts, brcManagers));
    }
  }, [response, workNumbers, hosts, brcManagers]);

  return (
    <div className="page-container max-w-4xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={onGetAccommodation} />
        </div>
      ) : (
        <>
          <h1 className="main-title">Szállás {isEditPage ? 'szerkesztése' : 'hozzáadása'}</h1>

          <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit} ref={_contractForm}>
            <div className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Szállás információk</h3>

              <div className="mt-6 grid gap-4 grid-cols-6">
                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Select title="Szállás partner" options={hosts} dialog="single" selected={model.host ? [model.host.value] : []} onSelect={onSelectHost} required />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input label="Szállás címe" name="address" value={model.address} onChange={onModelChange} required />
                </div>
              </div>
            </div>

            <div className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Kapcsolattartás</h3>

              <div className="mt-6 grid gap-4 grid-cols-6">
                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input label="Név" name="name" value={contact.name} onChange={onContactChange} />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input label="Beosztás" name="role" value={contact.role} onChange={onContactChange} />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input type="tel" label="Telefonszám" name="phoneNumber" value={contact.phoneNumber} onChange={onContactChange} />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input type="email" label="Email" name="emailAddress" value={contact.emailAddress} onChange={onContactChange} />
                </div>
              </div>
            </div>

            <div className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">BRC projekt felelősök</h3>

              <div className="mt-6 grid gap-x-4 gap-y-6 grid-cols-6">
                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Select title="BRC projekt felelősök" options={brcManagers} dialog="multi" selected={model.managers} onSelect={onSelectBRCManagers} showSelected={false} required />
                </div>

                <div className="flex flex-wrap gap-x-4 gap-y-2 my-auto col-span-6 sm:col-span-3 md:col-span-4">
                  {model.managers.map((x: SelectableBRCManager) => (
                    <Chips key={x.value} label={x.text} onDelete={() => onDeselectBRCManager(x.value)} />
                  ))}
                </div>
              </div>
            </div>

            <div className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900 flex flex-row justify-between">
                Szerződések
                <Button title="Szerződés hozzáadása" type="button" theme="primary" onClick={onAddContract} className="hidden xs:block" />
                <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} className="rounded-md xs:hidden" onClick={onAddContract} />
              </h3>

              <div className="flex flex-col-reverse">
                {contracts.map((x, i) => (
                  <Contract
                    key={x.tempId}
                    contract={x}
                    minStartDate={contracts[i - 1]?.endDate?.plus({ day: 1 }) ?? undefined}
                    isThereMore={contracts.length > 1}
                    isLast={i === 0}
                    isFirst={i === contracts.length - 1}
                    onDelete={onDeleteContract}
                    onChange={onContractChange}
                    onContractOrderChange={onContractOrderChange}
                  />
                ))}
              </div>
            </div>

            <div className="flex space-x-3 justify-end pt-5">
              <Button title="Mégsem" type="button" theme="secondary" onClick={goBack} disabled={isSubmiting} />
              <Button title="Mentés" type="submit" loading={isSubmiting} disabled={isSubmiting} />
            </div>
          </form>
        </>
      )}
    </div>
  );
}

const Contract = ({ onDelete, onChange, onContractOrderChange, minStartDate, isFirst, isLast, isThereMore, contract }: ContractProps) => {
  /* Context */
  const { workNumbers } = useContext(ConfigContext);

  /* Variables */
  const selectableWorkNumbers = useMemo(() => workNumbers.filter((x) => !contract.specialCosts.some((y) => y.workNumber.value === x.value)), [workNumbers, contract.specialCosts]);
  const isActive = contract.startDate <= TODAY && (!contract.endDate || contract.endDate >= TODAY);

  /* Refs */
  const _isMounted = useIsMounted();

  /* State */
  const [isUploading, setIsUploading] = useState(false);

  const handleChangeSpecialCost = (specialCost: SpecialCostModel) => {
    console.log('specialCost', specialCost);
    onChange({ ...contract, specialCosts: contract.specialCosts.map((x) => (x.tempId === specialCost.tempId ? specialCost : x)) });
  };

  const handleChangeContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.type) {
      case 'number':
        const parsed = parseInt(e.target.value);
        onChange({ ...contract, [e.target.name]: isNaN(parsed) ? null : parsed });
        break;
      case 'date':
        const parsedDate = DateTime.fromFormat(e.target.value, DATE_INPUT_FORMAT);
        onChange({ ...contract, [e.target.name]: parsedDate.isValid ? parsedDate : null });
        break;
    }
  };

  const onSelectAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        setIsUploading(true);

        const newAttachments: File[] = [];
        const requests: Promise<File>[] = [];

        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files.item(i)!;
          const body = new FormData();
          body.append('file', file);

          requests.push(api<{ id: number }>('file', undefined, { method: 'POST', body }).then(({ id }) => ({ id, name: file.name, url: file.webkitRelativePath })));
        }

        await Promise.allSettled(requests).then((results) => results.forEach((x) => x.status === 'fulfilled' && newAttachments.push(x.value)));

        onChange({ ...contract, tempAttachments: [...contract.tempAttachments, ...newAttachments] });
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsUploading(false);
    }
  };

  const onDeleteAttachment = (id: number) => {
    onChange({ ...contract, attachments: contract.attachments.filter((a) => a.id !== id) });
  };

  const onDeleteTempAttachment = (id: number) => {
    onChange({ ...contract, tempAttachments: contract.tempAttachments.filter((a) => a.id !== id) });
  };

  const handleChangeCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = parseInt(e.target.value);
    onChange({ ...contract, cost: { ...contract.cost, [e.target.name]: isNaN(parsed) ? null : parsed } });
  };

  const onAddSpecialCost = () => {
    if (selectableWorkNumbers[0]) {
      onChange({ ...contract, specialCosts: [...contract.specialCosts, { workNumber: selectableWorkNumbers[0], cost: null, id: null, tempId: selectableWorkNumbers[0].value }] });
    }
  };

  const onSelectCostChargeType = (value: number[]) => onChange({ ...contract, cost: { ...contract.cost, chargeType: value[0]! } });

  const onDeleteSpecialCost = (tempId: number) => onChange({ ...contract, specialCosts: contract.specialCosts.filter((x) => x.tempId !== tempId) });

  const onDeleteContract = () => onDelete(contract.tempId);

  const handletContractOrderChange = (order: 'up'|'down'|'first'|'last') => () => onContractOrderChange(contract.tempId, order);

  return (
    <Accordion
      isBorderBottom={!isLast}
      // open={isFirst || !contract.endDate}
      className="open:shadow-[0_0_1rem_#0d532e20] transform-gpu transition-[box-shadow,_border-radius,_transform,_margin,_padding] duration-150 ease-in-out open:rounded-md open:p-4 open:my-4"
      label={
        <>
          <span className="flex flex-1 items-center text-sm">
            {contract.startDate && toDateString(contract.startDate)} - {contract.endDate ? toDateString(contract.endDate) : ''}
            {isActive && <Badge label="Aktív" className="badge--green h-fit self-center ml-4" />}
          </span>

          {!isFirst && <IconButton className="self-end" icon={<ChevronDoubleUpIcon className="icon-sm" />} onClick={handletContractOrderChange('first')} theme="secondary" />}
          {!isFirst &&<IconButton className="self-end" icon={<ChevronUpIcon className="icon-sm" />} onClick={handletContractOrderChange('up')} theme="secondary" />}
          {!isLast && <IconButton className="self-end" icon={<ChevronDownIcon className="icon-sm" />} onClick={handletContractOrderChange('down')} theme="secondary" />}
          {!isLast && <IconButton className="self-end" icon={<ChevronDoubleDownIcon className="icon-sm" />} onClick={handletContractOrderChange('last')} theme="secondary" />}
          {isThereMore && <IconButton className="self-end" icon={<TrashIcon className="icon-sm" />} onClick={onDeleteContract} theme="secondary" />}
        </>
      }>
      <div className="mt-6 grid gap-4 grid-cols-6">
        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Input type="date" label="Szerződés kezdete" name="startDate" min={minStartDate} value={contract.startDate} onChange={handleChangeContract} required />
        </div>

        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Input type="date" label="Szerződés vége" name="endDate" min={contract.startDate} value={contract.endDate} onChange={handleChangeContract} required={!isFirst} />
        </div>

        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Input type="number" label="Felmondási idő" unit="nap" name="noticeDays" min={0} value={contract.noticeDays} onChange={handleChangeContract} />
        </div>

        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Input type="number" label="Férőhelyek száma" name="spaces" min={0} value={contract.spaces} onChange={handleChangeContract} required />
        </div>
      </div>

      <div className="pt-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Költség</h3>

        <div className="mt-6 grid gap-4 grid-cols-6">
          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Select
              title="Költség gyakorisága"
              options={[
                { text: 'Napi (/fő/éj)', value: ChargeType.Daily },
                { text: 'Havi (/hó)', value: ChargeType.Monthly }
              ]}
              onSelect={onSelectCostChargeType}
              selected={[contract.cost.chargeType]}
              required
            />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="number" label="Szálláshely költsége" unit="Ft" name="cost" value={contract.cost.cost} onChange={handleChangeCost} required />
          </div>

          {contract.cost.chargeType === ChargeType.Daily && (
            <div className="col-span-6 sm:col-span-3 md:col-span-2">
              <Input type="number" label="Napi minimum költség" unit="Ft" name="dailyMinimum" value={contract.cost.dailyMinimum} onChange={handleChangeCost} />
            </div>
          )}
        </div>
      </div>

      <span className="table-header flex flex-row items-center justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900 flex">Költség kivételek</h3>

        <Button type="button" title="Kivétel hozzáadása" theme="secondary" onClick={onAddSpecialCost} disabled={!selectableWorkNumbers.length} className="hidden xs:block" />

        <IconButton icon={<PlusIcon className="icon-xs" />} className="rounded-md xs:hidden" theme="secondary" onClick={onAddSpecialCost} disabled={!selectableWorkNumbers.length} />
      </span>

      {contract.specialCosts.map((x, i) => (
        <SpecialCost key={x.tempId} workNumbers={selectableWorkNumbers} specialCost={x} onChange={handleChangeSpecialCost} isFirst={i === 0} onDelete={onDeleteSpecialCost} />
      ))}

      <div className="table-header">
        <div className="flex flex-col items-start gap-y-2 w-full">
          <div className="flex justify-between items-center w-full mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Csatolmányok</h3>

            <label htmlFor={'upload_' + contract.id}>
              <span className="button flex items-center gap-x-4 button--secondary">{isUploading ? <CircularProgress className="icon-sm" /> : <PaperClipIcon className="icon-sm" />}</span>

              <input type="file" id={'upload_' + contract.id} name={'upload_' + contract.id} hidden multiple onChange={onSelectAttachment} />
            </label>
          </div>

          {contract.attachments.length > 0 && (
            <div className="links">
              {contract.attachments.map((x) => (
                <div className="flex items-center">
                  <Download key={x.id} href={x.url} fileNameWithExtension={x.name} className="links-item flex-1 truncate !pb-0 !border-none" />
                  <IconButton
                    className="border-none flex-0 basis-fit"
                    icon={<TrashIcon className="icon-sm" />}
                    onClick={() => {
                      onDeleteAttachment(x.id);
                    }}
                    theme="secondary"
                  />
                </div>
              ))}
            </div>
          )}
          {contract.tempAttachments.length > 0 && (
            <div className="links">
              {contract.tempAttachments.map((x) => (
                <div className="flex items-center">
                  <Download key={x.id} href={x.url} fileNameWithExtension={x.name} className="links-item flex-1 truncate !pb-0 !border-none" />
                  <IconButton
                    className="border-none flex-0 basis-fit"
                    icon={<TrashIcon className="icon-sm" />}
                    onClick={() => {
                      onDeleteTempAttachment(x.id);
                    }}
                    theme="secondary"
                  />
                </div>
              ))}
            </div>
          )}
          {!contract.attachments.length && !contract.tempAttachments.length && <p className="text-slate-600 text-xs leading-relaxed">Nincs csatolmány</p>}
        </div>
      </div>
    </Accordion>
  );
};

const SpecialCost = ({ onDelete, workNumbers, isFirst, specialCost, onChange }: SpecialCostProps) => {
  const onChangeCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = parseInt(e.target.value);
    onChange({ ...specialCost, cost: isNaN(parsed) ? 0 : parsed });
  };

  const onChangeWorkNumber = (values: SelectableProject[]) => {
    if (values[0]) {
      onChange({ ...specialCost, workNumber: values[0] });
    }
  };

  const handleDelete = () => onDelete(specialCost.tempId);

  return (
    <div className={`pb-8 ${isFirst ? '' : 'border-t border-t-gray-300'} last:pb-0`}>
      <div className="mt-6 grid gap-4 grid-cols-6">
        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Select title="Munkaszám" options={[specialCost.workNumber, ...workNumbers]} dialog="single" selected={[specialCost.workNumber]} onSelect={onChangeWorkNumber} required />
        </div>

        <div className="col-span-6 sm:col-span-3 md:col-span-4 grid grid-cols-4 gap-4">
          <Input wrapperClassName="col-start-1 col-end-4 md:col-end-3" type="number" label="Szálláshely költsége" value={specialCost.cost} onChange={onChangeCost} unit="Ft" placeholder="0" required />

          <IconButton className="col-start-4 self-center col-end-4 place-self-end justify-center" icon={<TrashIcon className="icon-xs xs:icon-sm" />} onClick={handleDelete} theme="secondary" />
        </div>
      </div>
    </div>
  );
};
