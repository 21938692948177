import React, { useContext, useEffect, useRef } from 'react';

/* Presentation Things */
import { FilterIcon, PlusIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../../components/Buttons';
import { Table, TableRef } from '../../../../components/Table';
import { Accordion } from '../../../../components/Accordion';
import { Filter } from './Filter';
import { Badge } from '../../../../components/Badge';

/* Data Things */
import { IndividualHost, CompanyHost } from '../../../../contexts/Partners/Hosts/helper';
import { HostsContext } from '../../../../contexts/Partners/Hosts';
import { LARGE_SCREEN } from '../../../../constants';
import { IHeaderCell } from '../../../../components/Table/helper';

/* Variables */
const header: Array<IHeaderCell<IndividualHost | CompanyHost> | string> = [{ text: 'Szállás partner', sortName: 'name' }, 'Kapcsolattartó', 'Telefonszám', 'Adószám', { text: 'Aktív?', sortName: 'isActive' }];

const HostsList = (isShow: boolean) => {
  /* Context */
  const { onGetHosts, hosts } = useContext(HostsContext);

  /* Refs */
  const _table = useRef<TableRef>(null);

  const onOpenFilter = () => _table.current?.onToogleFilter();

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => {
    if (hosts.items.length < 2 && hosts.page === 1) {
      onGetHosts();
    }
    // eslint-disable-next-line
  }, []);

  if (!isShow) return null;

  return (
    <>
      <div className="table-header gap-x-2 flex-row justify-end">
        <Button title="Új szállás partner" to="./szallas-partner/hozzaadas" hiddenFor="Reader" className="hidden twsm:block" />

        <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} to="./szallas-partner/hozzaadas" hiddenFor="Reader" theme="primary" className="twsm:hidden" />

        <IconButton icon={<FilterIcon className="icon-xs " />} theme="secondary" className="twlg:hidden" onClick={onOpenFilter} disabled={!hosts.items.length} />

        <IconButton icon={<SortAscendingIcon className="icon-xs" />} theme="secondary" className="twlg:hidden" onClick={onOpenSort} disabled={!hosts.items.length} />
      </div>

      <Table
        ref={_table}
        header={header}
        className="partners-host-list-table"
        maxDataCount={hosts.totalLength}
        loading={hosts.isLoading}
        filter={hosts.filter}
        error={hosts.error}
        order={hosts.order}
        data={hosts.items}
        page={hosts.page}
        sort={hosts.sort}
        onChangePage={onGetHosts}
        onFilter={onGetHosts}
        onSearch={onGetHosts}
        onSort={onGetHosts}
        onFilterRender={Filter}
        onRowRender={TableRow}
      />
    </>
  );
};

const Row = (host: IndividualHost | CompanyHost, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={host.id}>
    <div>
      <span title={host.name}>{host.name}</span>
    </div>

    <div>
      <span title={(host as CompanyHost).contact?.name || ''}>{(host as CompanyHost).contact?.name || '-'}</span>
    </div>

    <div>
      <a href={`tel:${host.contact.phoneNumber}`} className="hot-link">
        {host.contact.phoneNumber}
      </a>
    </div>

    <div>{(host as CompanyHost)?.taxNumber || (host as IndividualHost)?.taxIdentificationNumber}</div>

    <div>
      <Badge label={host.isActive ? 'Aktív' : 'Inaktív'} className={host.isActive ? 'badge--green' : 'badge--gray'} />
    </div>

    <div className="action-2">
      <IconButton icon={<EyeIcon className="icon-sm" />} to={`./szallas-partner/${host.id}`} />

      <IconButton icon={<PencilIcon className="icon-sm" />} to={`./szallas-partner/szerkesztes/${host.id}`} hiddenFor="Reader" />
    </div>
  </div>
);

const MobileRow = (host: IndividualHost | CompanyHost) => (
  <Accordion
    key={host.id}
    className="card"
    isCard
    isBorderBottom
    label={
      <div className="card-header flex-row justify-between items-center">
        <div className="flex-col items-start">
          <span className="card-header__primary-text truncate block">{host.name}</span>

          <span className="card-header__secondary-text">{(host as CompanyHost)?.taxNumber || (host as IndividualHost)?.taxIdentificationNumber}</span>
        </div>
        <div className="flex gap-8 justify-end items-center">
          <div className="data border-t-0 mt-0 hidden twsm:flex">
            <div className="data-item mt-0 min-w-[170px] gap-4">
              <span className="data-item__title">Telefonszám</span>

              <span className="data-item__data">
                <a href={`tel:${host.contact.phoneNumber}`} className="hot-link">
                  {host.contact.phoneNumber}
                </a>
              </span>
            </div>
          </div>
          <Badge label={host.isActive ? 'Aktív' : 'Inaktív'} className={host.isActive ? 'badge--green' : 'badge--gray'} />
        </div>
      </div>
    }>
    <div className="data twsm:border-t-0 twsm:mt-4">
      <div className="data-item">
        <span className="data-item__title">Kapcsolattartó</span>

        <span className="data-item__data">{(host as CompanyHost).contact?.name || '-'}</span>
      </div>

      <div className="data-item twsm:hidden">
        <span className="data-item__title">Telefonszám</span>

        <span className="data-item__data">
          {host.contact.phoneNumber ? (
            <a href={`tel:${host.contact.phoneNumber}`} className="hot-link">
              {host.contact.phoneNumber}
            </a>
          ) : (
            '-'
          )}
        </span>
      </div>

      <div className="data-item data-item--action">
        <span className="data-item__data gap-x-4 ml-auto">
          <IconButton icon={<EyeIcon className="icon-sm" />} to={`./szallas-partner/${host.id}`} />

          <IconButton icon={<PencilIcon className="icon-sm" />} to={`./szallas-partner/szerkesztes/${host.id}`} hiddenFor="Reader" />
        </span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: LARGE_SCREEN };

export default HostsList;
