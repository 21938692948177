import { SelectableClient, SelectableHost, SelectableProHumanManager, SelectableProject, SelectableAccommodation, SelectableBRCManager } from "../../contexts/Config/helper";
import { ISelectableAccommodationDTO, ISelectableClientDTO, ISelectableHostDTO, ISelectableProjectDTO, ISelectableProHumanManagerDTO } from "../../typings/DTOs";
import { APIResponseFeedback } from "../../typings/common";

export interface ConfigState {
    isLoading: boolean;
    hosts: SelectableHost[];
    clients: SelectableClient[];
    brcManagers: SelectableBRCManager[];
    proHumanManagers: SelectableProHumanManager[];
    workNumbers: SelectableProject[];
    accommodations: SelectableAccommodation[];
    error: string;
    feedback: APIResponseFeedback | null;
}

export const initialState: ConfigState = {
    isLoading: true,
    hosts: [],
    clients: [],
    brcManagers: [],
    proHumanManagers: [],
    workNumbers: [],
    accommodations: [],
    error: '',
    feedback: null
};

type ConfigAction =
    {
        type: 'success_init'; payload: {
            clients: ISelectableClientDTO[];
            hosts: ISelectableHostDTO[];
            brcManagers: ISelectableProHumanManagerDTO[],
            proHumanManagers: ISelectableProHumanManagerDTO[],
            workNumbers: ISelectableProjectDTO[],
            accommodations: ISelectableAccommodationDTO[]
        }
    }
    | { type: 'error_init'; payload: { error: string } }
    | { type: 'on_feedback'; payload: { feedback: APIResponseFeedback | null } }

export function configReducer(state: ConfigState = initialState, action: ConfigAction): ConfigState {
    switch (action.type) {
        case 'success_init': {
            return {
                ...state,
                brcManagers: action.payload.brcManagers.map(x => new SelectableBRCManager(x)),
                proHumanManagers: action.payload.proHumanManagers.map(x => new SelectableProHumanManager(x)),
                workNumbers: action.payload.workNumbers.map(x => new SelectableProject(x)),
                clients: action.payload.clients.map(x => new SelectableClient(x)),
                hosts: action.payload.hosts.map(x => new SelectableHost(x)),
                accommodations: action.payload.accommodations.map(x => new SelectableAccommodation(x)),
                isLoading: false,
                error: ''
            };
        }

        case 'error_init': {
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        }

        case 'on_feedback': {
            return {
                ...state,
                feedback: action.payload.feedback
            }
        }

        default:
            return state;
    }
}
