import { IEmployeeAccommodationListItemDTO, IEmployeeDTO, IEmployeeListItemDTO } from '../../typings/DTOs';
import { AccommodationPreview, HostPreview, ProjectPreview } from '../../typings/common';
import { DateTime } from 'luxon';
import { TODAY } from '../../constants';
import { Sex } from '../../typings/enum';

export class EmployeeAccommodationListItem {
  public id: number;
  public host: HostPreview;
  public accommodation: AccommodationPreview;
  public project: ProjectPreview;
  public secondaryProject: ProjectPreview | null;
  public moveInDate: DateTime;
  public moveOutDate: DateTime | null;
  public monthlyContributions: number | null;

  public get isActive() {
    return this.moveInDate <= TODAY && (!this.moveOutDate || this.moveOutDate > TODAY);
  }

  constructor(p: IEmployeeAccommodationListItemDTO) {
    this.id = p.id;
    this.host = new HostPreview(p.host.id, p.host.name);
    this.accommodation = new AccommodationPreview(p.accommodation.id, p.accommodation.address);
    this.project = new ProjectPreview(p.project.id, p.project.workNumber);
    this.secondaryProject = p.secondaryProject ? new ProjectPreview(p.secondaryProject.id, p.secondaryProject.workNumber) : null;
    this.moveInDate = p.moveInDate ? DateTime.fromISO(p.moveInDate) : DateTime.now();
    this.moveOutDate = p.moveOutDate ? DateTime.fromISO(p.moveOutDate) : null;
    this.monthlyContributions = p.monthlyContributions;
  }
}

export class Employee {
  public id: number;
  public name: string;
  public birthdate: DateTime;
  public idCardNumber: string;
  public probondId: string;
  public taxIdentificationNumber: string;
  public passportNumber: string;
  public isRelative: boolean;
  public relatives: [] | [{ id: number; name: string }] | never[];
  public gender: Sex;
  public project: ProjectPreview | null;
  public secondaryProject: ProjectPreview | null;
  public accommodation: AccommodationPreview | null;
  public host: HostPreview | null;
  public accommodations: EmployeeAccommodationListItem[] | null = null;

  constructor(p: IEmployeeListItemDTO | IEmployeeDTO) {
    this.id = p.id;
    this.name = p.name || '';
    this.birthdate = p.birthdate ? DateTime.fromISO(p.birthdate) : DateTime.now();
    this.project = p.project ? new ProjectPreview(p.project.id, p.project.workNumber) : null;
    this.secondaryProject = p.secondaryProject ? new ProjectPreview(p.secondaryProject.id, p.secondaryProject.workNumber) : null;
    this.idCardNumber = p.idCardNumber || '';
    this.probondId = p.probondId || '';
    this.taxIdentificationNumber = p.taxIdentificationNumber || '';
    this.passportNumber = p.passportNumber || '';
    this.isRelative = p.isRelative;
    this.relatives = p.relatives || [];
    this.gender = p.sex;
    this.accommodation = null;
    this.host = null;

    if (this.isIEmployeeListItemDTO(p) && p.accommodation && p.host && p.project) {
      this.accommodation = new AccommodationPreview(p.accommodation.id, p.accommodation.address);
      this.project = new ProjectPreview(p.project.id, p.project.workNumber);
      this.host = new HostPreview(p.host.id, p.host.name);
    }
  }

  private isIEmployeeListItemDTO(p: IEmployeeListItemDTO | IEmployeeDTO): p is IEmployeeListItemDTO {
    return (p as IEmployeeListItemDTO)?.accommodation !== undefined;
  }
}

export type EmployeeFilter = { project: number | null; sex: Sex | null };
