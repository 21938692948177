import React, { useCallback, useEffect, useRef, useState } from 'react';

// Navigation things
import { Link } from '@reach/router';

// Presentation things
import { DeficitAccommodationsFilter, Filter } from './Filter';
import { FilterIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { Table, TableRef } from '../../../components/Table';
import { IconButton } from '../../../components/Buttons';
import { Accordion } from '../../../components/Accordion';
import { Hideable } from '../../../components/Hideable';

// Data things
import { FilterAndSorteablePaginatedList, Order } from '../../../typings/common';
import { AccommodationListItem } from '../../../contexts/Accommodations/helper';
import { IAccommodationListDTO } from '../../../typings/DTOs';
import { ApiErrorResult, api } from '../../../utils/api';
import { toCurrencyString } from '../../../constants/functions';
import { MEDIUM_SCREEN } from '../../../constants';
import { useIsMounted } from '../../../hooks';
import { IHeaderCell } from '../../../components/Table/helper';

const header: IHeaderCell<AccommodationListItem>[] = [
  { text: 'Szállás partner', sortName: 'host' },
  { text: 'Éjszakák', sortName: 'nights' },
  { text: 'Szabad', sortName: 'available' },
  { text: 'ROI', sortName: 'roi', hiddenFor: 'ProHumanManager' }
];

const DeficitAccommodations = () => {
  /* States */
  const [accommodations, setAccommodations] = useState<FilterAndSorteablePaginatedList<DeficitAccommodationsFilter, AccommodationListItem>>({
    items: [],
    error: '',
    isLoading: true,
    offset: 0,
    page: 1,
    totalLength: 0,
    filter: {
      availableFrom: null,
      availableTo: null,
      nightsFrom: null,
      nightsTo: null,
      roiFrom: null,
      roiTo: null,
      host: null
    },
    sort: 'host',
    order: 'ASC'
  });

  /* Hooks */
  const _isMounted = useIsMounted();

  /* Refs */
  const _table = useRef<TableRef>(null);

  const onGetAccommodations = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof AccommodationListItem = 'host', order: Order = 'ASC'): Promise<string | null> => {
      try {
        //@ts-ignore
        setAccommodations((prev) => ({ ...prev, error: '', isLoading: true, sort, order, page, filter }));

        const accommodations = await api<IAccommodationListDTO>('accommodation/deficit', { search, filter, sort, order, limit: 50, offset: (page - 1) * 50 }, { signal });

        if (!accommodations.message && _isMounted.current) {
          if (!accommodations.items.length) throw new Error('Nincs veszteségesen üzemelő szálláshely');
          setAccommodations((p) => ({
            ...p,
            items: accommodations.items.map((x) => new AccommodationListItem(x)),
            totalLength: accommodations.totalLength,
            offset: accommodations.offset,
            isLoading: false
          }));
          return Promise.resolve(null);
        } else throw new Error(accommodations.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        if (_isMounted.current) {
          setAccommodations((p) => ({ ...p, items: [], error: String(message || error), isLoading: false }));
        }
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onOpenFilter = () => _table.current?.onToogleFilter();

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => void onGetAccommodations(), [onGetAccommodations]);

  return (
    <>
      <div className="table-header flex-row items-center">
        <h3>Veszteségesen üzemelő szálláshelyek</h3>

        <div className="flex items-center gap-x-2 twmd:hidden">
          <IconButton icon={<FilterIcon className="icon-xs" />} theme="secondary" onClick={onOpenFilter} disabled={!accommodations.items.length} />

          <IconButton icon={<SortAscendingIcon className="icon-xs" />} theme="secondary" onClick={onOpenSort} disabled={!accommodations.items.length} />
        </div>
      </div>

      <Table
        ref={_table}
        className="dashboard-table"
        maxDataCount={accommodations.totalLength}
        loading={accommodations.isLoading}
        filter={accommodations.filter}
        error={accommodations.error}
        data={accommodations.items}
        order={accommodations.order}
        sort={accommodations.sort}
        page={accommodations.page}
        header={header}
        onChangePage={onGetAccommodations}
        onSearch={onGetAccommodations}
        onFilter={onGetAccommodations}
        onSort={onGetAccommodations}
        onFilterRender={Filter}
        onRowRender={TableRow}
      />
    </>
  );
};

const Row = (accommodation: AccommodationListItem, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={accommodation.id}>
    <div>
      <Link title={accommodation.host.name} to={`/partnerek/szallas-partner/${accommodation.host.id}`} className="hot-link w-full">
        {accommodation.host.name}
      </Link>

      <Link title={accommodation.address} to={`/szallasok/${accommodation.id}`} className="hot-link w-full">
        {accommodation.address}
      </Link>
    </div>

    <div>{accommodation.nights}</div>

    <div>{accommodation.available}</div>

    <Hideable.Div hiddenFor="ProHumanManager">
      <span className="text-red-800">{accommodation.roi !== null ? toCurrencyString(accommodation.roi) : '-'}</span>
    </Hideable.Div>
  </div>
);

const MobileRow = (accommodation: AccommodationListItem) => (
  <Accordion
    key={accommodation.id}
    isCard
    isBorderBottom
    className="card"
    summaryClassName="h-auto"
    label={
      <div className="card-header twsm:flex-row twsm:justify-between twsm:items-center">
        <div className="flex flex-col items-start">
          <Link title={accommodation.host.name} to={`/partnerek/szallas-partner/${accommodation.host.id}`} className="card-header__primary-text hot-link">
            {accommodation.host.name}
          </Link>

          <Link title={accommodation.address} to={`/szallasok/${accommodation.id}`} className="card-header__secondary-text hot-link">
            {accommodation.address}
          </Link>
        </div>

        <Hideable.Div className="data border-t-0 mt-0 twsm:min-w-[160px]" hiddenFor="ProHumanManager">
          <div className="data-item gap-4">
            <span className="data-item__title">ROI</span>

            <span className="data-item__data text-red-800">{accommodation.roi !== null ? toCurrencyString(accommodation.roi) : '-'}</span>
          </div>
        </Hideable.Div>
      </div>
    }>
    <div className="data">
      <div className="data-item">
        <span className="data-item__title">Éjszakák</span>

        <span className="data-item__data">{accommodation.nights}</span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Szabad</span>

        <span className="data-item__data">{accommodation.available}</span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: MEDIUM_SCREEN };

export default DeficitAccommodations;
