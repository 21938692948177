import React from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation Things
import { TabBar } from '../../components/TabBar';

// Screens
import Statistics from './Statistics';
import Usage from './Usage';

export default function Riports(_: React.PropsWithChildren<RouteComponentProps>) {
  return (
    <div className="page-container pb-0 max-h-screen">
      <h1 className="main-title pt-4 sm:pt-8 bg-white">Riportok</h1>

      <TabBar
        tabs={[
          {
            label: 'Szálláshely használat',
            component: Usage
          },
          {
            label: 'Szálláshely statisztika',
            component: Statistics
          }
        ]}
        wrapperClassName="sm:pb-0"
        tabWrapperClassName="grow-1 flex flex-col overflow-y-clip"
      />
    </div>
  );
}
