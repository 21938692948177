export enum Sex {
    Male = 1,
    Female
}

export enum ChargeType {
    Daily = 1,
    Monthly
}

export enum AppRole {
    Administrator = 1,
    ProHumanManager,
    BRCManager,
    Reader
}

export enum UsageStatisticsIncomeType {
    Usage = 1,
    MinimumUsageDifference,
    SecondaryDiscount
}