import { ISelectableAccommodationDTO, ISelectableClientDTO, ISelectableHostDTO, ISelectableProjectDTO, ISelectableProHumanManagerDTO, ISelectableBRCManagerDTO } from "../../typings/DTOs";

export class SelectableClient {
    public value: number;
    public text: string;
    public description: string;

    constructor(p: ISelectableClientDTO) {
        this.value = p.id;
        this.text = p.name || "";
        this.description = p.taxNumber || "";
    }
}

export class SelectableHost {
    public value: number;
    public text: string;
    public description: string;

    constructor(p: ISelectableHostDTO) {
        this.value = p.id;
        this.text = p.name || "";
        this.description = p.taxNumber || "";
    }
}

export class SelectableProHumanManager {
    public value: number;
    public text: string;
    public description: string;

    constructor(p: ISelectableProHumanManagerDTO) {
        this.value = p.id;
        this.text = p.name || "";
        this.description = p.phoneNumber || "";
    }
}

export class SelectableBRCManager {
    public value: number;
    public text: string;
    public description: string;

    constructor(p: ISelectableBRCManagerDTO) {
        this.value = p.id;
        this.text = p.name || "";
        this.description = p.phoneNumber || "";
    }
}

export class SelectableProject {
    public value: number;
    public text: string;

    constructor(p: ISelectableProjectDTO) {
        this.value = p.id;
        this.text = p.workNumber || "";
    }
}

export class SelectableAccommodation {
    public value: number;
    public text: string;
    public description: string;

    constructor(p: ISelectableAccommodationDTO) {
        this.value = p.id;
        this.text = p.name || "";
        this.description = p.address || "";
    }
}
