import React, { useMemo, useRef, useState } from 'react';

//Data Things
import { DATE_MONTH_INPUT_FORMAT, TODAY, USAGE_STATISTICS_INCOM_TYPE_MAP } from '../../../constants';
import { AccommodationUsage, generateCSV, getVisibleStatistics } from './helper';
import { toCurrencyString, toDateString, toMonthString } from '../../../constants/functions';
import { IAccommodationUsageListItemDTO } from '../../../typings/DTOs';
import { UsageStatisticsIncomeType } from '../../../typings/enum';
import { ApiErrorResult, api } from '../../../utils/api';
import { List, Order, Sort } from '../../../typings/common';
import { useIsMounted } from '../../../hooks';
import { IHeaderCell } from '../../../components/Table/helper';
import { DateTime } from 'luxon';

//Presentation Things
import { DownloadIcon } from '@heroicons/react/outline';
import { Button, IconButton } from '../../../components/Buttons';
import { Table, TableRef } from '../../../components/Table';
import { Input } from '../../../components/Inputs';
import { Link } from '@reach/router';
import { Identification } from '../../../components/Identification';

// Variables
const header: Array<IHeaderCell<AccommodationUsage> | string> = [
  { text: 'Munkavállaló', sortName: 'employee' },
  { text: 'Szálláshely', sortName: 'host' },
  { text: 'Beköltözés', sortName: 'moveInDate' },
  { text: 'Kiköltözés', sortName: 'moveOutDate' },
  { text: 'Éjszakák', sortName: 'nights' },
  { text: 'Napi díj', sortName: 'dailyCost' },
  { text: 'Összesen', sortName: 'cost' },
  { text: 'Ügyfél', sortName: 'client' },
  'BRC munkaszám',
  'Ügyfél munkaszám',
  ''
];

const Usage = (isShow: boolean) => {
  /* States */
  const [searchValue, setSearchValue] = useState('');
  const [statistics, setStatistics] = useState<List<AccommodationUsage>>({ error: '', isLoading: false, items: [] });
  const [date, setDate] = useState(TODAY);
  const [sort, setSort] = useState<Sort<AccommodationUsage>>({ sort: 'employee', order: 'ASC' });

  /* Variable */
  const visibleStatistics = useMemo(() => getVisibleStatistics(searchValue, statistics.items, sort), [searchValue, statistics, sort]);

  /* Hooks */
  const _isMounted = useIsMounted();

  /* Refs */
  const _table = useRef<TableRef>(null);

  const onGetStatistics = async () => {
    try {
      setStatistics((prev) => ({ ...prev, isLoading: true }));

      const resp = await api<IAccommodationUsageListItemDTO[]>(`accommodation/usage/${date.year}/${date.month}`);

      if (!resp.message && _isMounted.current) {
        if (!resp.length) throw new Error('Nem áll rendelkezésre szálláshely használat statisztika');
        setStatistics({
          items: resp.map((x) => new AccommodationUsage(x)),
          isLoading: false,
          error: ''
        });
      } else throw new Error(resp.message);
    } catch (error) {
      if (_isMounted.current) {
        const { message } = error as ApiErrorResult;
        setStatistics({ isLoading: false, items: [], error: String(message || error) });
      }
    }
  };

  const onChangeMonth = (e: React.ChangeEvent<HTMLInputElement>) => setDate(DateTime.fromFormat(e.target.value, DATE_MONTH_INPUT_FORMAT));

  const onDownload = () => generateCSV(statistics.items, `Szálláshely használat ${date.year} ${toMonthString(date)}`);

  const onSort = (_: any, __: any, ___: any, ____: any, sort: keyof AccommodationUsage = 'employee', order: Order = 'ASC') => setSort({ sort, order });

  if (!isShow) return null;

  return (
    <>
      <form className="pt-4 bg-white">
        <div className="flex flex-col justify-between gap-4 xs:flex-row xs:items-center">
          <Input type="month" label="Hónap" value={date} onChange={onChangeMonth} required />

          <div className="flex items-center space-x-4 col-span-8 justify-end">
            <Button title="Lekérdezem" onClick={onGetStatistics} type="button" disabled={statistics.isLoading} />

            <IconButton theme="secondary" icon={<DownloadIcon className="icon-sm" />} className="h-full flex items-center" disabled={statistics.items.length === 0} onClick={onDownload} />
          </div>
        </div>
      </form>

      <div className="overflow-auto grow-1">
        <div className="riports-usage-table-container">
          <Table
            ref={_table}
            className="riports-current-table"
            loading={statistics.isLoading}
            error={
              statistics.error
                ? statistics.error
                : !statistics.isLoading && !statistics.items.length
                ? 'Lekérdezéshez válassz ki egy időszakot és egy szálláshelyet.'
                : !visibleStatistics.length && searchValue.length
                ? 'A keresett kifejezésre nincs találat'
                : undefined
            }
            data={visibleStatistics}
            order={sort.order}
            sort={sort.sort}
            header={header}
            onSearch={setSearchValue}
            onSort={onSort}
            onRowRender={TableRow}
          />
        </div>
      </div>
    </>
  );
};

const Row = (usage: AccommodationUsage, gridTemplate: React.CSSProperties) => {
  return (
    <div
      className="data-table__body-row"
      style={gridTemplate}
      key={`${usage.costType}-${usage.employee?.id}-${usage.project.id}-${usage.accommodation.id}-${usage.dailyCost}-${usage.moveInDate?.valueOf()}-${usage.moveOutDate?.valueOf()} `}>
      {usage.costType === UsageStatisticsIncomeType.Usage ? (
        <div className="truncate">
          <Link to={`/munkavallalok/${usage.employee?.id}`} className="hot-link w-full">
            {usage.employee?.name}
          </Link>
          <Identification
            mobile={false}
            idCardNumber={usage.employee?.idCardNumber}
            probondId={usage.employee?.probondId}
            taxIdentificationNumber={usage.employee?.taxIdentificationNumber}
            passportNumber={usage.employee?.passportNumber}
          />
        </div>
      ) : (
        <div>
          <span>{USAGE_STATISTICS_INCOM_TYPE_MAP[usage.costType]}</span>
        </div>
      )}

      <div>
        <span title={usage.host.name}>{usage.host.name}</span>

        <span title={usage.accommodation.address}>{usage.accommodation.address}</span>
      </div>

      <div>{toDateString(usage.moveInDate)}</div>

      <div>{toDateString(usage.moveOutDate)}</div>

      <div>{usage.nights}</div>

      <div>{usage.dailyCost !== null ? toCurrencyString(usage.dailyCost) : '-'}</div>

      <div>{toCurrencyString(usage.cost)}</div>

      <div>{usage.client.name}</div>

      <div>
        <Link to={`/projektek/${usage.project.id}`} className="hot-link w-full">
          {usage.project.workNumber}
        </Link>
      </div>

      <div className="w-full truncate" title={usage.project.clientWorkNumber}>
        {usage.project.clientWorkNumber}
      </div>
    </div>
  );
};

const TableRow = { default: Row };

export default Usage;
