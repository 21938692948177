import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Navigation things
import { Link, RouteComponentProps } from '@reach/router';

// Presentation things
import { IdCard, PassportCard, ProbondIdCard, TaxIdCard } from '../../../components/Icons';
import { Button, IconButton } from '../../../components/Buttons';
import { CircularProgress } from '../../../components/CircularProgress';
import { PencilIcon } from '@heroicons/react/solid';
import { Accordion } from '../../../components/Accordion';
import { Hideable } from '../../../components/Hideable';
import { Table } from '../../../components/Table';
import { Badge } from '../../../components/Badge';

// Data things
import { toCurrencyString, toDateString } from '../../../constants/functions';
import { EmployeeAccommodationListItem } from '../../../contexts/Employees/helper';
import { GENDER_MAP, LARGE_SCREEN } from '../../../constants';
import { getVisibleAccommodations } from './helper';
import { EmployeesContext } from '../../../contexts/Employees';
import { ApiErrorResult } from '../../../utils/api';
import { useIsMounted } from '../../../hooks';

// Routes
import EditMoveIn from '../../MoveIn/Edit';

// Variables
const header = ['Szállás partner', 'Munkaszám', 'Beköltözés', 'Kiköltözés', 'Havi hozzájárulás', 'buttons'];

export default function Details(props: RouteComponentProps<{ id: number }>) {
  /* Context */
  const { onGetEmployee, onChangeEmployeeAccommodation, employees } = useContext(EmployeesContext);

  /* Variables */
  const selectedEmployee = useMemo(() => employees.items.find((x) => x.id === +props.id!), [employees.items, props.id]);
  const hasData = Array.isArray(selectedEmployee?.accommodations);

  /* States */
  const [editableEmployee, setEditableEmployee] = useState<EmployeeAccommodationListItem | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(!hasData);
  const [error, setError] = useState('');

  /* Variables */
  const visibleAcommodations = useMemo(() => getVisibleAccommodations(searchValue, selectedEmployee?.accommodations), [selectedEmployee?.accommodations, searchValue]);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetInitial = useCallback(async () => {
    try {
      setIsLoading(!hasData);
      setError('');

      const resp = await onGetEmployee(Number(props.id));
      if (typeof resp === 'string') throw new Error(resp);
    } catch (error) {
      const { message } = error as ApiErrorResult;
      _isMounted.current && !hasData && setError(String(message || error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [props.id, onGetEmployee, _isMounted]);

  const onOpenEdit = (employee: EmployeeAccommodationListItem) => setEditableEmployee(employee);

  const onCloseEdit = () => setEditableEmployee(null);

  useEffect(() => void onGetInitial(), [onGetInitial]);

  return (
    <div className="page-container max-w-6xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={onGetInitial} />
        </div>
      ) : selectedEmployee ? (
        <>
          <div className="flex items-center justify-between py-10">
            {selectedEmployee && <h1 className="main-title pt-0">{selectedEmployee.name}</h1>}

            <Button to={`/munkavallalok/szerkesztes/${props.id}`} title="Szerkesztés" className="rounded-md hidden xs:block" />

            <IconButton icon={<PencilIcon className="icon-xs icon-without-hover" />} to={`/munkavallalok/szerkesztes/${props.id}`} hiddenFor="Reader" theme="primary" className="rounded-md xs:hidden" />
          </div>

          <div className="data-line">
            <div className="data-line__item">
              <div className="section-label">Nem</div>

              <div className="flex text-sm text-gray-900 mt-0 col-span-2">
                <span className="flex-grow">{GENDER_MAP[selectedEmployee.gender]}</span>
              </div>
            </div>

            <div className="data-line__item">
              <div className="section-label">Születési idő</div>

              <div className="flex text-sm text-gray-900 mt-0 col-span-2">
                <span className="flex-grow">{toDateString(selectedEmployee.birthdate)}</span>
              </div>
            </div>

            <div className="data-line__item">
              <div className="section-label">Igazolványszám</div>

              <div className="flex flex-col text-sm text-gray-900 mt-0 col-span-2 items-end">
                {selectedEmployee.passportNumber && (
                  <div>
                    <span className="align-middle">{selectedEmployee.passportNumber}</span>
                    <PassportCard className="w-8 h-8 inline ml-2 text-amber-800" />
                  </div>
                )}
                {selectedEmployee.idCardNumber && (
                  <div>
                    <span className="align-middle">{selectedEmployee.idCardNumber}</span>
                    <IdCard className="w-8 h-8 inline ml-2 text-gray-500" />
                  </div>
                )}
                {selectedEmployee.taxIdentificationNumber && (
                  <div>
                    <span className="align-middle">{selectedEmployee.taxIdentificationNumber}</span>
                    <TaxIdCard className="w-8 h-8 inline ml-2 text-green-600" />
                  </div>
                )}
                {selectedEmployee.probondId && (
                  <div>
                    <span className="align-middle">{selectedEmployee.probondId}</span>
                    <ProbondIdCard className="w-8 h-8 inline ml-2 text-[#0D6EFD]" />
                  </div>
                )}
              </div>
            </div>

            <div className="data-line__item">
              <div className="section-label">{selectedEmployee.isRelative ? 'Hozzárendelt munkavállalók' : 'Hozzátartozók'}</div>

              <div className="flex flex-col gap-2 text-sm text-gray-900 mt-0 col-span-2">
                {selectedEmployee.relatives.length > 0
                  ? selectedEmployee.relatives.map((r) => (
                      <Link title={r.name} to={`/munkavallalok/${r.id}`} className="hot-link w-full text-right">
                        {r.name}
                      </Link>
                    ))
                  : '-'}
              </div>
            </div>

            <div className="py-5 border-t pl-4 sm:col-span-2" />
          </div>

          <div className="table-header">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Szállások</h3>
          </div>

          <EditMoveIn open={editableEmployee !== null} onClose={onCloseEdit} onSave={onChangeEmployeeAccommodation} employee={editableEmployee} accommodationId={editableEmployee?.accommodation.id!} />

          <Table
            className="employees-details-table"
            data={visibleAcommodations}
            error={!selectedEmployee.accommodations?.length ? 'Nincs megjeleníthető adat' : !visibleAcommodations.length && searchValue.length ? 'A keresett kifejezésre nincs találat' : undefined}
            header={header}
            onSearch={setSearchValue}
            onRowRender={{ default: (row, gridTemplate) => Row(row, gridTemplate, onOpenEdit), mobile: (row) => MobileRow(row, onOpenEdit), breakpoint: LARGE_SCREEN }}
          />
        </>
      ) : null}
    </div>
  );
}

const Row = (employee: EmployeeAccommodationListItem, gridTemplate: React.CSSProperties, onOpenEdit: (employee: EmployeeAccommodationListItem) => void) => {
  const handleOpenEdit = () => onOpenEdit(employee);

  return (
    <div className="data-table__body-row" style={gridTemplate} key={employee.id}>
      <div className="exception-row">
        <div className="min-w-0 flex-1 text-sm flex flex-col items-start">
          <Link title={employee.host.name} to={`/partnerek/szallas-partner/${employee.host.id}`} className="hot-link w-full">
            {employee.host.name}
          </Link>

          <Link title={employee.accommodation.address} to={`/szallasok/${employee.accommodation.id}`} className="hot-link w-full">
            {employee.accommodation.address}
          </Link>
        </div>
        {employee.isActive && <Badge label="Aktív" className="badge--green self-center" />}
      </div>

      <div className="flex flex-col">
        {employee.project ? (
          <>
            <Link to={`/projektek/${employee.project.id}`} className="hot-link w-full">
              {employee.project.workNumber}
            </Link>
            {employee.secondaryProject && `(${employee.secondaryProject.workNumber})`}
          </>
        ) : (
          '-'
        )}
      </div>

      <div>{toDateString(employee.moveInDate)}</div>

      <div>{toDateString(employee.moveOutDate)}</div>

      <div>{employee.monthlyContributions !== null ? toCurrencyString(employee.monthlyContributions) : '-'}</div>

      <IconButton icon={<PencilIcon className="icon-sm" aria-hidden="true" />} onClick={handleOpenEdit} hiddenFor="Reader" />
    </div>
  );
};

const MobileRow = (employee: EmployeeAccommodationListItem, onOpenEdit: (employee: EmployeeAccommodationListItem) => void) => {
  const handleOpenEdit = () => onOpenEdit(employee);

  return (
    <Accordion
      key={employee.id}
      className="card"
      isCard
      isBorderBottom
      label={
        <div className="card-header flex-row justify-between items-center">
          <div className="flex-col items-start">
            <Link title={employee.host.name} to={`/partnerek/szallas-partner/${employee.host.id}`} className="card-header__primary-text hot-link w-full">
              {employee.host.name}
            </Link>
            <Link title={employee.accommodation.address} to={`/szallasok/${employee.accommodation.id}`} className="card-header__secondary-text hot-link w-full">
              {employee.accommodation.address}
            </Link>
          </div>
          {employee.isActive && <Badge label="Aktív" className="badge--green self-center" />}
        </div>
      }>
      <div className="data">
        <div className="data-item">
          <span className="data-item__title">Munkaszám</span>

          <span className="data-item__data">{employee?.project?.workNumber ?? '-'}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Másodlagos munkaszám</span>

          {employee?.secondaryProject?.workNumber ?? '-'}
        </div>

        <div className="data-item">
          <span className="data-item__title">Beköltözés</span>

          <span className="data-item__data">{toDateString(employee.moveInDate)}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Kiköltözés</span>

          <span className="data-item__data">{toDateString(employee.moveOutDate)}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Hozzájárulás</span>

          <span className="data-item__data">{employee.monthlyContributions !== null ? toCurrencyString(employee.monthlyContributions) : '-'}</span>
        </div>

        <Hideable.Div className="data-item data-item--action justify-end" hiddenFor="Reader">
          <IconButton icon={<PencilIcon className="icon-sm" aria-hidden="true" />} onClick={handleOpenEdit} />
        </Hideable.Div>
      </div>
    </Accordion>
  );
};
