import { useCallback, useEffect } from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { useAuthorization } from '../../hooks';
import { ClientsProvider } from '../../contexts/Partners/Clients';
import { HostsProvider } from '../../contexts/Partners/Hosts';

/* Presentation Things */
import { TabBar } from '../../components/TabBar';

/* Screens */
import ClientsList from './Clients/List';
import HostsList from './Hosts/List';
import Clients from './Clients';
import Hosts from './Hosts';

const Default = (_: RouteComponentProps) => {
  return (
    <div className="page-container max-w-4xl">
      <h1 className="main-title bg-white pt-4 sm:pt-8">Partnerek</h1>

      <TabBar
        wrapperClassName="pb-0"
        tabs={[
          {
            label: 'Ügyfelek',
            component: ClientsList
          },
          {
            label: 'Szállás partnerek',
            component: HostsList
          }
        ]}
      />
    </div>
  );
};

const Partners = (props: RouteComponentProps) => {
  /* Hooks */
  const { role } = useAuthorization();

  const navigateTo404 = useCallback((error: string) => props.navigate!('/404', { state: { error }, replace: true }), [props.navigate]);

  useEffect(() => {
    if (role === 'ProHumanManager') {
      navigateTo404('A kért oldalhoz nem rendelkezel megfelelő jogosultsággal!');
    }
  }, [role, navigateTo404]);

  return (
    <ClientsProvider>
      <HostsProvider>
        <Router basepath="partnerek" primary={false}>
          <Hosts path="szallas-partner/*" />
          <Clients path="ugyfel/*" />
          <Default default />
        </Router>
      </HostsProvider>
    </ClientsProvider>
  );
};

export default Partners;
