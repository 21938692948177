import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

// Presentation things
import { FilterSelect, SelectDialogBody } from '../../../../components/Select';
import { Dialog, DialogHandle } from '../../../../components/Dialog';
import { Divider, Fieldset } from '../../../../components/Fieldset';
import { TableChips } from '../../../../components/Table';
import { Button } from '../../../../components/Buttons';
import { Input } from '../../../../components/Inputs';
import { Chips } from '../../../../components/Chips';

// Data things
import { isEmptyObject, onChangeInput, toDateString } from '../../../../constants/functions';
import { AccommodationEmployeesFilter } from './helper';
import { SelectableProject } from '../../../../contexts/Config/helper';
import { ConfigContext } from '../../../../contexts/Config';

type FilterBodyProps = {
  onNextScreen(): void;
  onChange: React.Dispatch<React.SetStateAction<AccommodationEmployeesFilter>>;
  onSubmit(e: React.FormEvent): void;
  selectedProject?: SelectableProject;
  model: AccommodationEmployeesFilter;
};

type Props = {
  filter: AccommodationEmployeesFilter;
  open: boolean;
  onChangeFilter: (filter: AccommodationEmployeesFilter) => void;
  onSubmit?: (filter: AccommodationEmployeesFilter) => void;
  onClose: () => void;
};

export const Filter = ({ open, filter, onChangeFilter, onClose, onSubmit }: Props) => {
  /* Contexts */
  const { workNumbers } = useContext(ConfigContext);

  /* States */
  const [tempModel, setTempModel] = useState(filter);

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  /* Variables */
  const selectedProject = useMemo(() => workNumbers.find((p) => p.value === tempModel.project), [workNumbers, tempModel.project]);
  const hasActiveFilter = useMemo(() => !isEmptyObject(filter), [filter]);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (onSubmit) {
      onChangeFilter(tempModel);
      onSubmit(tempModel);
      onClose();
    }
  };

  const handleClose = () => {
    setTempModel(filter);
    onClose();
  };

  const onSelectProject = (option: SelectableProject | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (option instanceof SelectableProject) {
      setTempModel((prev) => ({ ...prev, project: option.value }));
    } else {
      _dialog.current?.previousScreen();
    }
  };

  const onDeselectMoveIn = () => {
    if (onSubmit) {
      onChangeFilter({ ...tempModel, moveInFrom: null, moveInTo: null });
      onSubmit({ ...tempModel, moveInFrom: null, moveInTo: null });
    }
  };

  const onDeselectMoveOut = () => {
    if (onSubmit) {
      onChangeFilter({ ...tempModel, moveOutFrom: null, moveOutTo: null });
      onSubmit({ ...tempModel, moveOutFrom: null, moveOutTo: null });
    }
  };

  const onDeselectProject = () => {
    if (onSubmit) {
      onChangeFilter({ ...tempModel, project: null });
      onSubmit({ ...tempModel, project: null });
    }
  };

  const onNextScreen = () => _dialog.current?.nextScreen();

  useEffect(() => setTempModel(filter), [filter]);

  return (
    <>
      <Dialog
        open={open}
        ref={_dialog}
        onClose={handleClose}
        className="h-[100vh] sm:h-[80vh]"
        contents={[
          {
            title: 'Szűrő',
            body: <FilterBody onNextScreen={onNextScreen} model={tempModel} onChange={setTempModel} selectedProject={selectedProject} onSubmit={handleSubmit} />,
            footer: <Button title="Kész" type="submit" className="grow" form="employeesFilter" />
          },
          {
            title: 'Munkaszám kiválasztása',
            body: <SelectDialogBody options={workNumbers} dialog="single" onSelect={onSelectProject} selecteds={selectedProject} />,
            footer: <Button title="Kiválasztom" className="grow" onClick={_dialog.current?.previousScreen} />
          }
        ]}
      />

      <TableChips hasActiveFilter={hasActiveFilter}>
        {filter.project && selectedProject && <Chips key="project" label={selectedProject.text} onDelete={onDeselectProject} />}

        {(filter.moveInFrom || filter.moveInTo) && (
          <Chips key="moveIn" label="Beköltözve" from={filter.moveInFrom ? toDateString(filter.moveInFrom) : undefined} to={filter.moveInTo ? toDateString(filter.moveInTo) : undefined} onDelete={onDeselectMoveIn} />
        )}

        {(filter.moveOutFrom || filter.moveOutTo) && (
          <Chips
            key="moveOut"
            label="Kiköltözve"
            from={filter.moveOutFrom ? toDateString(filter.moveOutFrom) : undefined}
            to={filter.moveOutTo ? toDateString(filter.moveOutTo) : undefined}
            onDelete={onDeselectMoveOut}
          />
        )}
      </TableChips>
    </>
  );
};

const FilterBody = ({ onNextScreen, onChange, onSubmit, model, selectedProject }: FilterBodyProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, onChange);

  return (
    <form className="p-5 flex flex-col gap-y-8 text-sm pb-8" onSubmit={onSubmit} id="employeesFilter">
      <FilterSelect label="Munkaszám kiválasztása" selecteds={selectedProject?.text} onNextScreen={onNextScreen} />

      <Fieldset label="Beköltözés" divided>
        <Input filter wrapperClassName="flex-1" type="date" label="Kezdés" value={model.moveInFrom} name="moveInFrom" onChange={handleChange} />
        <Divider />
        <Input filter wrapperClassName="flex-1" type="date" label="Befejezés" min={model.moveInFrom} value={model.moveInTo} name="moveInTo" onChange={handleChange} />
      </Fieldset>

      <Fieldset label="Kiköltözés" divided>
        <Input filter wrapperClassName="flex-1" type="date" label="Kezdés" value={model.moveOutFrom} name="moveOutFrom" onChange={handleChange} />
        <Divider />
        <Input filter wrapperClassName="flex-1" type="date" label="Befejezés" min={model.moveOutFrom} value={model.moveOutTo} name="moveOutTo" onChange={handleChange} />
      </Fieldset>
    </form>
  );
};
