import React, { useContext, useEffect, useMemo, useState } from 'react';

// Navigation Things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { Button, IconButton } from '../../../components/Buttons';
import { PencilIcon } from '@heroicons/react/solid';
import { Accordion } from '../../../components/Accordion';
import { PlusIcon } from '@heroicons/react/outline';
import { Badge } from '../../../components/Badge';
import { Table } from '../../../components/Table';

// Data things
import { APP_ROLE_MAP, LARGE_SCREEN } from '../../../constants';
import { getVisibleUsers } from './helper';
import { UsersContext } from '../../../contexts/Users';
import { User } from '../../../contexts/Users/helper';

// Variables
const header = ['Név', 'Email', 'Telefonszám', 'Jogosultság', 'Aktív?', 'buttons'];

export default function UsersList(_: RouteComponentProps) {
  /* Context */
  const { onGetUsers, users } = useContext(UsersContext);

  /* State */
  const [serachValue, setSerachValue] = useState('');

  /* Variables */
  const visibleUsers = useMemo(() => getVisibleUsers(users.items, serachValue), [serachValue, users.items]);

  useEffect(() => {
    if (!users.items.length && !users.error) {
      onGetUsers();
    }
  }, [onGetUsers, users.items, users.error]);

  return (
    <div className="page-container max-w-6xl">
      <div className="table-header pt-4 sm:py-8 flex-row items-center">
        <h1 className="main-title pt-0">Felhasználók</h1>

        <Button title="Új felhasználó" to="/felhasznalok/hozzaadas" hiddenFor="Reader" className="hidden twsm:block" />

        <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} to="/felhasznalok/hozzaadas" hiddenFor="Reader" theme="primary" className="twsm:hidden" />
      </div>

      <Table
        className="users-list-table"
        loading={users.isLoading}
        error={users.error || (!visibleUsers.length && serachValue.length ? 'A keresett kifejezésre nincs találat' : undefined)}
        data={visibleUsers}
        header={header}
        onSearch={setSerachValue}
        onRowRender={TableRow}
      />
    </div>
  );
}

const Row = (user: User, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={user.id}>
    <div>
      <span title={user.name}>{user.name}</span>
    </div>

    <div>
      <a href={`mailto:${user.email}`} title={user.email} className="hot-link w-full">
        {user.email}
      </a>
    </div>

    <div>
      <a href={`tel:${user.phoneNumber}`} className="hot-link w-full">
        {user.phoneNumber}
      </a>
    </div>

    <div title={APP_ROLE_MAP[user.role]}>
      <span>{APP_ROLE_MAP[user.role]}</span>
    </div>

    <div className="text-right">
      <Badge label={user.isActive ? 'Aktív' : 'Inaktív'} className={user.isActive ? 'badge--green' : 'badge--gray'} />
    </div>

    <div className="action-1">
      <IconButton icon={<PencilIcon className="icon-sm" />} to={`/felhasznalok/szerkesztes/${user.id}`} hiddenFor="Reader" />
    </div>
  </div>
);

const MobileRow = (user: User) => (
  <Accordion
    key={user.id}
    className="card"
    isCard
    isBorderBottom
    label={
      <div className="card-header flex-row justify-between items-center">
        <div className="flex flex-col items-start">
          <span className="card-header__primary-text">{user.name}</span>
          <span className="card-header__secondary-text">{APP_ROLE_MAP[user.role]}</span>
        </div>
        <div className="flex justify-end items-center gap-8">
          <span className="data-item hidden twmd:flex">
            <a href={`mailto:${user.email}`} title={user.email} className="hot-link">
              {user.email}
            </a>
          </span>
          <Badge label={user.isActive ? 'Aktív' : 'Inaktív'} className={user.isActive ? 'badge--green' : 'badge--gray'} />
        </div>
      </div>
    }>
    <div className="data twmd:border-t-0 mt-4">
      <div className="data-item">
        <span className="data-item__title">Telefonszám</span>

        <span className="data-item__data">
          {user.phoneNumber ? (
            <a href={`tel:${user.phoneNumber}`} title={user.phoneNumber} className="hot-link">
              {user.phoneNumber}
            </a>
          ) : (
            '-'
          )}
        </span>
      </div>

      <div className="data-item twmd:hidden">
        <span className="data-item__title">Email</span>

        <span className="data-item__data">
          <a href={`mailto:${user.email}`} title={user.email} className="hot-link">
            {user.email}
          </a>
        </span>
      </div>

      <div className="data-item data-item--action justify-end">
        <IconButton icon={<PencilIcon className="icon-sm" />} to={`/felhasznalok/szerkesztes/${user.id}`} hiddenFor="Reader" />
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: LARGE_SCREEN };
