import { Router, Redirect } from '@reach/router';
import { useEffect } from 'react';

/* Data Things */
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest, msalInstance } from './utils/auth';
import { InteractionStatus } from '@azure/msal-browser';

/* Presentation Things */
import { CircularProgress } from './components/CircularProgress';

/* Routes */
import Layout from '../src/components/Layout';
import Dashboard from './routes/Dashboard';
import Employees from './routes/Employees';
import Hotels from './routes/Hotels';
import Partners from './routes/Partners';
import Projects from './routes/Projects';
import Riports from './routes/Riports';
import Settings from './routes/Users';
import NotFound from './routes/404';
import MoveIn from './routes/MoveIn';
import MoveOut from './routes/MoveOut';

const App = () => {
  /* Hooks */
  const { instance, inProgress } = useMsal();
  const [activeAccount] = instance.getAllAccounts();
  const isAuthenticated = useIsAuthenticated();

  console.log({ activeAccount, isAuthenticated, inProgress });

  useEffect(() => {
    if (inProgress === InteractionStatus.None && (!isAuthenticated || !activeAccount)) {
      instance.loginRedirect(loginRequest);
    }
  }, [instance, inProgress, isAuthenticated, activeAccount]);

  return (
    <>
      <AuthenticatedTemplate>
        <Router primary={false}>
          <Layout path="/">
            <Dashboard path="/fooldal" />
            <Employees path="/munkavallalok/*" />
            <Hotels path="/szallasok/*" />
            <Partners path="/partnerek/*" />
            <Projects path="/projektek/*" />
            <Riports path="/riportok" />
            <Settings path="/felhasznalok/*" />
            <MoveIn path="/bekoltoztetes/*" />
            <MoveOut path="/kikoltoztetes/*" />
            <Redirect from="/" to="/fooldal" noThrow />

            <NotFound default />
          </Layout>
        </Router>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <CircularProgress theme="full-height" />
      </UnauthenticatedTemplate>
    </>
  );
};

const Index = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  );
};

export default Index;
