import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

/* Presentation Things */
import { FilterSelect, SelectDialogBody } from '../../../components/Select';
import { Dialog, DialogHandle } from '../../../components/Dialog';
import { Divider, Fieldset } from '../../../components/Fieldset';
import { TableChips } from '../../../components/Table';
import { Hideable } from '../../../components/Hideable';
import { Button } from '../../../components/Buttons';
import { Input } from '../../../components/Inputs';
import { Chips } from '../../../components/Chips';

/* Data Things */
import { isEmptyObject, onChangeInput } from '../../../constants/functions';
import { AccommodationsContext } from '../../../contexts/Accommodations';
import { AccommodationsFilter } from '../../../contexts/Accommodations/helper';
import { SelectableHost } from '../../../contexts/Config/helper';
import { ConfigContext } from '../../../contexts/Config';

type FilterBodyProps = {
  onNextScreen(): void;
  onChange: React.Dispatch<React.SetStateAction<AccommodationsFilter>>;
  selectedHost?: SelectableHost;
  model: AccommodationsFilter;
};

export const Filter = (open: boolean, onClose: () => void, onSubmit?: (filter: AccommodationsFilter) => Promise<void>) => {
  /* Contexts */
  const { hosts } = useContext(ConfigContext);
  const {
    accommodations: { filter },
    onFilter
  } = useContext(AccommodationsContext);

  /* States */
  const [tempModel, setTempModel] = useState(filter);

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  /* Variables */
  const hasActiveFilter = useMemo(() => !isEmptyObject(filter), [filter]);
  const selectedHost = useMemo(() => hosts.find((p) => p.value === tempModel.host), [hosts, tempModel.host]);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(tempModel);
      onFilter(tempModel);
      onClose();
    }
  };

  const handleClose = () => {
    setTempModel(filter);
    onClose();
  };

  const onSelectHost = (option: SelectableHost | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (option instanceof SelectableHost) {
      setTempModel((prev) => ({ ...prev, host: option.value }));
    } else {
      _dialog.current?.previousScreen();
    }
  };

  const onDeselectAvailable = () => {
    if (onSubmit) {
      onFilter({ ...tempModel, availableFrom: null, availableTo: null });
      onSubmit({ ...tempModel, availableFrom: null, availableTo: null });
    }
  };

  const onDeselectNights = () => {
    if (onSubmit) {
      onFilter({ ...tempModel, nightsFrom: null, nightsTo: null });
      onSubmit({ ...tempModel, nightsFrom: null, nightsTo: null });
    }
  };

  const onDeselectHost = () => {
    if (onSubmit) {
      onFilter({ ...tempModel, host: null });
      onSubmit({ ...tempModel, host: null });
    }
  };

  const onDeselectROI = () => {
    if (onSubmit) {
      onFilter({ ...tempModel, roiFrom: null, roiTo: null });
      onSubmit({ ...tempModel, roiFrom: null, roiTo: null });
    }
  };

  const onNextScreen = () => _dialog.current?.nextScreen();

  useEffect(() => setTempModel(filter), [filter]);

  return (
    <>
      <Dialog
        open={open}
        ref={_dialog}
        onClose={handleClose}
        className="h-[100vh] sm:h-[80vh]"
        contents={[
          {
            title: 'Szűrő',
            body: <FilterBody onNextScreen={onNextScreen} model={tempModel} onChange={setTempModel} selectedHost={selectedHost} />,
            footer: <Button title="Kész" className="grow" onClick={handleSubmit} />
          },
          {
            title: 'Szállás partner kiválasztása',
            body: <SelectDialogBody options={hosts} dialog="single" onSelect={onSelectHost} selecteds={selectedHost} />,
            footer: <Button title="Kiválasztom" className="grow" onClick={_dialog.current?.previousScreen} />
          }
        ]}
      />

      <TableChips hasActiveFilter={hasActiveFilter}>
        {filter.host && selectedHost && <Chips key="1" label={selectedHost.text} onDelete={onDeselectHost} />}

        {(filter.nightsFrom || filter.nightsTo) && <Chips key="2" label="Éjszakák" from={filter.nightsFrom} to={filter.nightsTo} onDelete={onDeselectNights} unit="fő" />}

        {(filter.availableFrom || filter.availableTo) && <Chips key="3" label="Szabad" from={filter.availableFrom} to={filter.availableTo} onDelete={onDeselectAvailable} unit="fő" />}

        {(filter.roiFrom || filter.roiTo) && <Chips key="4" label="ROI" from={filter.roiFrom} to={filter.roiTo} onDelete={onDeselectROI} />}
      </TableChips>
    </>
  );
};

const FilterBody = ({ onNextScreen, onChange, model, selectedHost }: FilterBodyProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, onChange);

  return (
    <div className="p-5 flex flex-col gap-y-8 text-sm pb-8">
      <FilterSelect label="Szállás partner kiválasztása" selecteds={selectedHost?.text} onNextScreen={onNextScreen} />

      <Fieldset label="Éjszakák" divided>
        <Input filter wrapperClassName="flex-1" value={model.nightsFrom} type="number" label="" name="nightsFrom" placeholder="minimum" unit="fő" onChange={handleChange} />
        <Divider />
        <Input filter wrapperClassName="flex-1" value={model.nightsTo} type="number" min={model.nightsFrom} label="" name="nightsTo" placeholder="maximum" unit="fő" onChange={handleChange} />
      </Fieldset>

      <Fieldset label="Szabad" divided>
        <Input filter wrapperClassName="flex-1" value={model.availableFrom} type="number" label="" name="availableFrom" placeholder="minimum" unit="fő" onChange={handleChange} />
        <Divider />
        <Input filter wrapperClassName="flex-1" value={model.availableTo} type="number" min={model.availableFrom} label="" name="availableTo" placeholder="maximum" unit="fő" onChange={handleChange} />
      </Fieldset>

      <Hideable.Div hiddenFor="ProHumanManager">
        <Fieldset label="ROI" divided>
          <Input filter wrapperClassName="flex-1" value={model.roiFrom} type="number" label="" name="roiFrom" placeholder="minimum" unit="Ft" onChange={handleChange} />
          <Divider />
          <Input filter wrapperClassName="flex-1" value={model.roiTo} type="number" min={model.roiFrom} label="" name="roiTo" placeholder="maximum" unit="Ft" onChange={handleChange} />
        </Fieldset>
      </Hideable.Div>
    </div>
  );
};
