import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Navigation things
import { Link, RouteComponentProps } from '@reach/router';

// Presentation things
import { Button, IconButton } from '../../../components/Buttons';
import { CircularProgress } from '../../../components/CircularProgress';
import { PencilIcon } from '@heroicons/react/solid';
import { Accordion } from '../../../components/Accordion';
import { Download } from '../../../components/Download';
import { History } from './History';
import { TabBar } from '../../../components/TabBar';
import { Active } from './Active';
import { Badge } from '../../../components/Badge';
import { Table } from '../../../components/Table';

// Data things
import { ProjectContract, ProjectContractSpecialCost } from '../../../contexts/Projects/helper';
import { toCurrencyString, toDateString } from '../../../constants/functions';
import { CHARGE_TYPE_MAP, MEDIUM_SCREEN } from '../../../constants';
import { ProjectsContext } from '../../../contexts/Projects';
import { useIsMounted } from '../../../hooks';
import { Switch } from '../../../components/Inputs/Switch';

// Variables
const header = ['Szállás partner', 'Bevétel', 'Minimum napi bevétel', ''];

export default function Details(props: RouteComponentProps<{ id: number }>) {
  /* Context */
  const { onGetProject, projects } = useContext(ProjectsContext);

  /* Variables */
  const selectedProject = useMemo(() => projects.items.find((x) => x.id === +props.id!), [projects.items, props.id]);

  /* States */
  const [isLoading, setIsLoading] = useState(!selectedProject?.contracts?.length);
  const [error, setError] = useState('');
  const [onlyActiveContracts, setOnlyActiveContracts] = useState(false);

  const displayedContracts = useMemo(() => selectedProject?.contracts?.filter((x) => !onlyActiveContracts || x.isActive).sort((a,b) => (a.startDate < b.startDate ? 1 : -1)), [onlyActiveContracts, selectedProject?.contracts]);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetInitial = useCallback(async () => {
    try {
      setError('');
      setIsLoading(true);
      await onGetProject(Number(props.id));
    } catch (error) {
      _isMounted.current && setError(String(error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted, onGetProject, props.id]);

  useEffect(() => void onGetInitial(), [onGetInitial]);

  return (
    <div className="page-container project-details">
      {error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={onGetInitial} />
        </div>
      ) : (
        <>
          {selectedProject && (
            <div className="table-header pb-8 pt-4 sm:py-8 flex-col items-start gap-y-2">
              <div className="flex items-center justify-between w-full">
                <h1 className="text-3xl font-extrabold text-gray-900">{selectedProject.workNumber}</h1>

                <Button title="Szerkesztés" to={`/projektek/szerkesztes/${props.id}`} hiddenFor={['Reader', 'ProHumanManager', 'BRCManager']} className="hidden xs:block" />

                <IconButton
                  icon={<PencilIcon className="icon-sm icon-without-hover" />}
                  to={`/projektek/szerkesztes/${props.id}`}
                  hiddenFor={['Reader', 'ProHumanManager', 'BRCManager']}
                  theme="primary"
                  className="xs:hidden"
                />
              </div>

              <div className="flex space-x-4">
                <span className="mt-2 flex items-center text-sm text-gray-500">{selectedProject.client.text}</span>

                <span className="mt-2 flex items-center text-sm text-gray-500">Ügyfél munkaszám: {selectedProject.clientWorkNumber}</span>
              </div>
            </div>
          )}

          {isLoading ? (
            <CircularProgress className="flex-1" />
          ) : (
            <>
              <h3 className="section-title">Prohuman projekt felelősök</h3>

              {selectedProject && (
                <div className="data-line">
                  {selectedProject.proHumanManagers.length > 0 ? (
                    selectedProject.proHumanManagers.map((manager, i) => (
                      <div key={i} className="data-line__item">
                        <div className="section-label">{manager.text}</div>

                        <a className="data-line__item-data" href={`tel:${manager.description}`}>
                          {manager.description}
                        </a>
                      </div>
                    ))
                  ) : (
                    <span className="empty-data-text">Ehhez a projekthez nincsenek Prohuman projekt felelősök rögzítve</span>
                  )}
                </div>
              )}

              <div className="table-header flex-row items-center flex-wrap gap-y-2 gap-x-6">
                <h3 className="table-header__subtitle">Szerződések</h3>
                <div className="flex items-center max-twmd:ml-auto twmd:mr-auto gap-2 text-sm text-gray-400 font-medium">
                  <span onClick={() => setOnlyActiveContracts(false)} className={!onlyActiveContracts ? 'text-primary' : 'cursor-pointer'}>
                    Összes
                  </span>
                  <Switch checked={onlyActiveContracts} onChange={setOnlyActiveContracts} />
                  <span onClick={() => setOnlyActiveContracts(true)} className={onlyActiveContracts ? 'text-primary' : 'cursor-pointer'}>
                    Aktív
                  </span>
                </div>
              </div>

              {displayedContracts?.map((x) => (
                <Contract key={x.id} contract={x} />
              ))}

              <TabBar
                tabs={[
                  { label: 'Aktív elszállásolt', component: (show: boolean) => <Active projectId={Number(props.id)} show={show} /> },
                  { label: 'Előzmény', component: (show: boolean) => <History projectId={Number(props.id)} show={show} /> }
                ]}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

const Contract = ({ contract }: { contract: ProjectContract }) => {
  return (
    <Accordion
      isBorderBottom
      // open={contract.isActive}
      className="open:shadow-[0_0_1rem_#0d532e20] transform-gpu transition-[box-shadow,_border-radius,_transform,_margin,_padding] duration-150 ease-in-out open:rounded-md open:p-4 open:my-4"
      label={
        <div className="py-2 flex justify-between xs:justify-start flex-1">
          <div className="flex items-center text-sm twsm:text-md font-medium text-gray-900">
            <div className="flex col-span-1">{toDateString(contract.startDate)}</div>
            <span className="flex items-center px-1 twsm:px-2 font-normal">-</span>
            <div className="flex col-span-1">{contract.endDate ? toDateString(contract.endDate) : 'Határozatlan'}</div>
          </div>
          
          <div className="px-3 flex items-center text-sm text-gray-400">
            <div className="flex col-span-1">|</div>
          </div>

          <div className="flex items-center text-sm twsm:text-md font-medium text-gray-900">
            <div className="flex col-span-1">{Array.from(new Set(contract.specialCosts?.map(x=>x.host.name))).join(', ')}</div>
          </div>

          {contract.isActive && <Badge label="Aktív" className="badge--green h-fit self-center ml-4" />}
        </div>
      }>
      <div className="data-line">
        <div className="data-line__item">
          <div className="section-label">Felmondási idő</div>

          <div className="data-line__item-data">
            {contract.noticeDays ?? '-'}

            {contract.noticeDays !== null && <span className="text-gray-500"> nap</span>}
          </div>
        </div>

        <div className="data-line__item">
          <div className="section-label">Csatolmányok</div>

          {contract.attachments.length ? (
            <div className="data-line__item-data flex flex-col gap-y-2">
              {contract.attachments.map((x) => (
                <Download key={x.url} href={x.url} fileNameWithExtension={x.name} className="secondary-download-link" />
              ))}
            </div>
          ) : (
            <p className="text-slate-600 text-xs leading-relaxed">Nincs csatolmány</p>
          )}
        </div>
      </div>

      <div className="py-5 flex col-span-2 border-t">
        <div className="section-label flex items-center">Bevételek</div>
      </div>

      <Table className="projects-exceptions-table col-span-2" data={contract.specialCosts} header={header} onRowRender={TableRow} />
    </Accordion>
  );
};

const MobileRow = (specialCost: ProjectContractSpecialCost) => (
  <Accordion
    key={specialCost.id}
    className="card"
    isCard
    isBorderBottom
    label={
      <div className="card-header">
        <Link title={specialCost.host.name} to={`/partnerek/szallas-partner/${specialCost.host.id}`} className="card-header__primary-text hot-link">
          {specialCost.host.name}
        </Link>

        <Link title={specialCost.accommodation.address} to={`/szallasok/${specialCost.accommodation.id}`} className="card-header__secondary-text hot-link">
          {specialCost.accommodation.address}
        </Link>
      </div>
    }>
    <div className="data">
      <div className="data-item">
        <span className="data-item__title">Bevétel</span>

        <span className="data-item__data">
          {toCurrencyString(specialCost.cost.cost)}
          {CHARGE_TYPE_MAP[specialCost.cost.chargeType]}
        </span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Minimum bevétel</span>

        <span className="data-item__data">{specialCost.cost.dailyMinimum !== null ? toCurrencyString(specialCost.cost.dailyMinimum) : '-'}</span>
      </div>
    </div>
  </Accordion>
);

const Row = (specialCost: ProjectContractSpecialCost, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={specialCost.id}>
    <div>
      <Link title={specialCost.host.name} to={`/partnerek/szallas-partner/${specialCost.host.id}`} className="hot-link w-full">
        {specialCost.host.name}
      </Link>

      <Link title={specialCost.accommodation.address} to={`/szallasok/${specialCost.accommodation.id}`} className="hot-link w-full">
        {specialCost.accommodation.address}
      </Link>
    </div>

    <div>
      {toCurrencyString(specialCost.cost.cost)}
      <span className="text-gray-500">{CHARGE_TYPE_MAP[specialCost.cost.chargeType]}</span>
    </div>

    <div>{specialCost.cost.dailyMinimum !== null ? toCurrencyString(specialCost.cost.dailyMinimum) : '-'}</div>
  </div>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: MEDIUM_SCREEN };
