import { CircularProgress } from '../CircularProgress';
import { useAuthorization } from '../../hooks';
import { AppRole } from '../../typings/enum';
import { Link } from '@reach/router';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean;
  to?: string;
  theme?: 'primary' | 'secondary' | 'tertiary';
  trailingIcon?: JSX.Element;
  iconClassName?: string;
  hiddenFor?: keyof typeof AppRole | (keyof typeof AppRole)[];
}

export const Button = ({ title, to, theme = 'primary', trailingIcon, loading, hiddenFor, className, ...rest }: IProps) => {
  /* Hooks */
  const { role } = useAuthorization();

  if (hiddenFor && (Array.isArray(hiddenFor) ? hiddenFor.includes(role) : role === hiddenFor)) return null;

  return to ? (
    <Link to={to} tabIndex={0} className={`button button--${theme} ${className}`}>
      {title}
    </Link>
  ) : (
    <button {...rest} tabIndex={0} className={`button button--${theme} ${className}`}>
      {trailingIcon || loading ? (
        <div className="flex items-center gap-x-4 justify-center">
          {title}

          {loading && <CircularProgress theme="button" />}

          {!loading && trailingIcon}
        </div>
      ) : (
        title
      )}
    </button>
  );
};
