import React, { useEffect, useMemo, useRef, useState } from 'react';

// Presentation Things
import { Dialog, DialogHandle } from '../../components/Dialog';
import { Divider, Fieldset } from '../../components/Fieldset';
import { Checkbox, Input } from '../../components/Inputs';
import { TableChips } from '../../components/Table';
import { Button } from '../../components/Buttons';
import { Chips } from '../../components/Chips';

// Data Things
import { isEmptyObject, onChangeInput, toDateString } from '../../constants/functions';
import { MoveInEmployeesFilter } from './helper';

type FilterBodyProps = {
  onChange: React.Dispatch<React.SetStateAction<MoveInEmployeesFilter>>;
  onSubmit(e: React.FormEvent): void;
  model: MoveInEmployeesFilter;
};

type Props = {
  filter: MoveInEmployeesFilter;
  open: boolean;
  onChangeFilter: (filter: MoveInEmployeesFilter) => void;
  onSubmit?: (filter: MoveInEmployeesFilter) => void;
  onClose: () => void;
};

export const Filter = ({ open, filter, onChangeFilter, onClose, onSubmit }: Props) => {
  /* States */
  const [tempModel, setTempModel] = useState(filter);

  /* Variables */
  const hasActiveFilter = useMemo(() => !isEmptyObject(filter), [filter]);

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (onSubmit) {
      onChangeFilter(tempModel);
      onSubmit(tempModel);
      onClose();
    }
  };

  const handleClose = () => {
    setTempModel(filter);
    onClose();
  };

  const onDeselectContribution = () => {
    if (onSubmit) {
      onChangeFilter({ ...tempModel, contribution: null });
      onSubmit({ ...tempModel, contribution: null });
    }
  };

  const onDeselectMoveIn = () => {
    if (onSubmit) {
      onChangeFilter({ ...tempModel, moveInFrom: null, moveInTo: null });
      onSubmit({ ...tempModel, moveInFrom: null, moveInTo: null });
    }
  };

  const onDeselectMoveOut = () => {
    if (onSubmit) {
      onChangeFilter({ ...tempModel, moveOutFrom: null, moveOutTo: null });
      onSubmit({ ...tempModel, moveOutFrom: null, moveOutTo: null });
    }
  };

  useEffect(() => setTempModel(filter), [filter]);

  return (
    <>
      <Dialog
        open={open}
        ref={_dialog}
        onClose={handleClose}
        contents={[
          {
            title: 'Szűrő',
            body: <FilterBody model={tempModel} onChange={setTempModel} onSubmit={handleSubmit} />,
            footer: <Button title="Kész" type="submit" className="grow" form="moveInFilter" />
          }
        ]}
      />

      <TableChips hasActiveFilter={hasActiveFilter}>
        {filter.contribution && <Chips label={filter.contribution ? 'Hozzájárult' : 'Nem járult hozzá'} onDelete={onDeselectContribution} />}

        {(filter.moveInFrom || filter.moveInTo) && (
          <Chips label="Beköltözve" from={filter.moveInFrom ? toDateString(filter.moveInFrom) : undefined} to={filter.moveInTo ? toDateString(filter.moveInTo) : undefined} onDelete={onDeselectMoveIn} />
        )}

        {(filter.moveOutFrom || filter.moveOutTo) && (
          <Chips label="Kiköltözve" from={filter.moveOutFrom ? toDateString(filter.moveOutFrom) : undefined} to={filter.moveOutTo ? toDateString(filter.moveOutTo) : undefined} onDelete={onDeselectMoveOut} />
        )}
      </TableChips>
    </>
  );
};

const FilterBody = ({ onChange, onSubmit, model }: FilterBodyProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, onChange);

  return (
    <form className="p-5 flex flex-col gap-y-8 text-sm pb-8" onSubmit={onSubmit} id="moveInFilter">
      <div className="grid grid-cols-1 gap-4">
        <Checkbox title="Hozzájárulás" name="contribution" htmlFor="contribution" theme="form" checked={model.contribution === true} onChange={handleChange} />
      </div>

      <Fieldset label="Beköltözés" divided>
        <Input filter wrapperClassName="flex-1" type="date" label="Kezdés" value={model.moveInFrom} name="moveInFrom" onChange={handleChange} />
        <Divider />
        <Input filter wrapperClassName="flex-1" type="date" label="Befejezés" min={model.moveInFrom} value={model.moveInTo} name="moveInTo" onChange={handleChange} />
      </Fieldset>

      <Fieldset label="Kiköltözés" divided>
        <Input filter wrapperClassName="flex-1" type="date" label="Kezdés" value={model.moveOutFrom} name="moveOutFrom" onChange={handleChange} />
        <Divider />
        <Input filter wrapperClassName="flex-1" type="date" label="Befejezés" min={model.moveOutFrom} value={model.moveOutTo} name="moveOutTo" onChange={handleChange} />
      </Fieldset>
    </form>
  );
};
