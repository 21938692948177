import { Switch as HeadlessSwitch } from '@headlessui/react';
import { forwardRef } from 'react';

type SwitchProps = {
  checked: boolean;
  onChange?(checked: boolean): void;
};

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(({ checked, onChange }, ref) => {
  return (
    <HeadlessSwitch
      ref={ref}
      checked={checked}
      onChange={onChange}
      className={classNames('bg-primary/10 border-transparent relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 focus:outline-none')}>
      <span
        aria-hidden="true"
        className={classNames(checked ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-primary shadow ring-0 transition duration-200 ease-in-out')}
      />
    </HeadlessSwitch>
  );
});

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
