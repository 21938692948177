import {
  IAccommodationContactDTO,
  IAccommodationContractDTO,
  IAccommodationContractSpecialCostDTO,
  IAccommodationDTO,
  IAccommodationListItemDTO,
  IExpiredAccommodationListItemDTO,
  IOverbookedAccommodationListItemDTO
} from '../../typings/DTOs';
import { SelectableBRCManager } from '../Config/helper';
import { ChargeType } from '../../typings/enum';
import { DateTime } from 'luxon';
import { TODAY } from '../../constants';
import { File } from '../../typings/common';

export class AccommodationContractSpecialCost {
  public id: number;
  public projectId: number;
  public workNumber: string;
  public cost: number;

  constructor(p: IAccommodationContractSpecialCostDTO) {
    this.id = p.id;
    this.projectId = p.projectId;
    this.workNumber = p.workNumber;
    this.cost = p.cost;
  }
}
export class AccommodationContract {
  public id: number;
  public startDate: DateTime;
  public endDate: DateTime | null;
  public noticeDays: number | null;
  public spaces: number;
  public cost: {
    chargeType: ChargeType;
    cost: number;
    dailyMinimum: number | null;
  };
  public specialCosts: Array<AccommodationContractSpecialCost>;
  public attachments: File[];

  constructor(p: IAccommodationContractDTO) {
    this.id = p.id;
    this.startDate = p.startDate ? DateTime.fromISO(p.startDate) : DateTime.now();
    this.endDate = p.endDate ? DateTime.fromISO(p.endDate) : null;
    this.noticeDays = p.noticeDays;
    this.spaces = p.spaces;
    this.cost = {
      chargeType: p.cost.chargeType,
      cost: p.cost.cost,
      dailyMinimum: p.cost.dailyMinimum
    };
    this.specialCosts = (p.specialCosts || []).map((x) => new AccommodationContractSpecialCost(x));
    this.attachments = p.attachments ? p.attachments.map((x) => new File(x.id, x.name, x.url)) : [];
  }

  get isActive() {
    return this.startDate <= TODAY && (!this.endDate || this.endDate >= TODAY);
  }
}

export class AccommodationContact {
  public name: string | null;
  public role: string | null;
  public phoneNumber: string | null;
  public emailAddress: string | null;

  constructor(p: IAccommodationContactDTO) {
    this.name = p.name;
    this.role = p.role;
    this.phoneNumber = p.phoneNumber;
    this.emailAddress = p.emailAddress;
  }
}

export class AccommodationListItem {
  public id: number;
  public address: string;
  public host: {
    id: number;
    name: string;
  };
  public nights: number | null = null;
  public available: number | null = null;
  public roi: number | null = null;

  constructor(p: IAccommodationDTO | IAccommodationListItemDTO, existItem?: AccommodationListItem) {
    this.id = p.id;
    this.address = p.address || existItem?.address || '';
    this.host = {
      id: p.host.id,
      name: p.host.name || ''
    };

    if (this.isIAccommodationDTO(p)) {
      this.available = p.contracts.find((x) => new AccommodationContract(x).isActive)?.spaces ?? null;
      this.nights = existItem?.nights ?? null;
      this.roi = existItem?.roi ?? null;
    } else {
      this.nights = p.nights;
      this.available = p.available;
      this.roi = p.roi;
    }
  }

  private isIAccommodationDTO(p: IAccommodationDTO | IAccommodationListItemDTO): p is IAccommodationDTO {
    return (p as IAccommodationDTO)?.contracts !== undefined;
  }
}

export class Accommodation {
  public id: number;
  public address: string;
  public host: {
    id: number;
    name: string;
  };
  public managers: Array<SelectableBRCManager>;
  public contact: AccommodationContact;
  public contracts: Array<AccommodationContract>;

  constructor(p: IAccommodationDTO) {
    this.id = p.id;
    this.address = p.address || '';
    this.host = {
      id: p.host.id,
      name: p.host.name || ''
    };
    this.managers = (p.managers || []).map((x) => new SelectableBRCManager(x));
    this.contact = new AccommodationContact(p.contact);
    this.contracts = p.contracts
      .map((x) => new AccommodationContract(x))
      .sort((a, b) => {
        if (a.isActive) {
          return -1;
        } else if (b.isActive) {
          return 1;
        }

        return b.startDate.valueOf() - a.startDate.valueOf();
      });
  }

  public get isActive(): boolean {
    return this.contracts.some((x) => x.isActive);
  }
}

export class ExpiredAccommodationListItem {
  public employee: {
    id: number;
    name: string;
    idNumbers: string;
  };
  public accommodation: {
    id: number;
    address: string;
  };
  public moveInDate: DateTime;
  public moveOutDate: DateTime | null;
  public lastContractEndDate: DateTime;

  constructor(p: IExpiredAccommodationListItemDTO) {
    this.employee = {
      id: p.employee.id,
      name: p.employee.name || '',
      idNumbers:
        (p.employee.idCardNumber ? p.employee.idCardNumber + ' | ' : '') +
          (p.employee.taxIdentificationNumber ? p.employee.taxIdentificationNumber + ' | ' : '') +
          (p.employee.passportNumber ? p.employee.passportNumber + ' | ' : '') +
          (p.employee.probondId ? p.employee.probondId : '') || ''
    };
    if (this.employee.idNumbers && this.employee.idNumbers.slice(-1) === ' ') {
      this.employee.idNumbers = this.employee.idNumbers.slice(0, -3);
    }
    this.accommodation = {
      id: p.accommodation.id,
      address: p.accommodation.address || ''
    };
    this.moveInDate = p.moveInDate ? DateTime.fromISO(p.moveInDate) : DateTime.now();
    this.moveOutDate = p.moveOutDate ? DateTime.fromISO(p.moveOutDate) : null;
    this.lastContractEndDate = p.lastContractEndDate ? DateTime.fromISO(p.lastContractEndDate) : DateTime.now();
  }
}

export class OverbookedAccommodationListItem {
  public accommodation: {
    id: number;
    address: string;
  };
  public booked: number;
  public maximumCapacity: number;

  constructor(p: IOverbookedAccommodationListItemDTO) {
    this.accommodation = {
      id: p.accommodation.id,
      address: p.accommodation.address || ''
    };
    this.maximumCapacity = p.maximumCapacity;
    this.booked = p.booked;
  }
}

export type AccommodationsFilter = {
  host: number | null;
  nightsFrom: number | null;
  nightsTo: number | null;
  availableFrom: number | null;
  availableTo: number | null;
  roiFrom: number | null;
  roiTo: number | null;
};
