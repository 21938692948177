import React, { cloneElement, forwardRef, ReactElement, Ref, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

// Presentation Things
import { ArrowDownIcon, ArrowUpIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { CircularProgress } from '../CircularProgress';
import { MobileTableSort } from './Sort';
import { FilterIcon } from '@heroicons/react/outline';
import { IconButton } from '../Buttons';
import { Search } from '../Inputs';

// Data Things
import { IHeaderCell, IHeaderCellItem, IPagination, isHeader, isVisibleHeaderCell } from './helper';
import { useAuthorization, useIsMounted, useWindowDimensions } from '../../hooks';
import { createGridTemplate, isEmptyObject } from '../../constants/functions';
import { Order } from '../../typings/common';

export type TableRef = { onToogleFilter: () => void; onToogleSort: () => void };

interface IProps<Item> {
  maxDataCount?: number;
  loading?: boolean;
  error?: string;
  page?: number;
  filter?: Object;
  sort?: keyof Item;
  order?: Order;
  data: Array<Item>;
  header: Array<string | IHeaderCell<Item> | JSX.Element>;
  className?: string;
  onSearch?(text: string, abortSignal?: AbortSignal, page?: number, filter?: Object, sort?: keyof Item, order?: Order): Promise<Item[] | string | null> | void;
  onFilter?(text: string, abortSignal?: AbortSignal, page?: number, filter?: Object, sort?: keyof Item, order?: Order): Promise<Item[] | string | null>;
  onSort?(text: string, abortSignal?: AbortSignal, page?: number, filter?: Object, sort?: keyof Item, order?: Order): Promise<Item[] | string | null> | void;
  onChangePage?(text: string, abortSignal?: AbortSignal, page?: number, filter?: Object, sort?: keyof Item, order?: Order): Promise<string | null>;
  onRowRender: { default: (row: Item, gridTemplate: React.CSSProperties) => JSX.Element | null; mobile?: (row: Item) => JSX.Element; breakpoint?: number };
  onFilterRender?(open: boolean, onClose: () => void, onSubmit: (filter: Object) => Promise<void>, filter: Object): JSX.Element;
}

const TableInner = <Item extends unknown>(props: IProps<Item>, ref: Ref<TableRef>) => {
  /* Ref */
  const visibleRowCount = useRef(props.data.length || 1);
  const abort = useRef(new AbortController());

  /* Hooks */
  const { width: windowWidth } = useWindowDimensions();
  const { role } = useAuthorization();
  const _isMounted = useIsMounted();

  /* States */
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isSortOpened, setIsSortOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(props.loading);
  const [error, setError] = useState(props.error);

  /* Variables */
  const header = useMemo(() => props.header.filter((x) => isVisibleHeaderCell(x, role)), [props.header, role]);
  const gridTemplate = useMemo(() => createGridTemplate(header, Boolean(props.filter)), [header, props.filter]);
  const sortHeaders = useMemo(() => header.filter((x) => isHeader(x) && x.sortName), [header]);

  const _renderHeaderCells = (): JSX.Element[] => {
    const cells: JSX.Element[] = [];

    for (let i = 0; i < header.length; i++) {
      const currentCell = header[i]!;

      const order = isHeader(currentCell) && props.sort === currentCell.sortName ? props.order : undefined;

      cells.push(<HeaderCellItem key={i} cell={currentCell} order={order} onSort={handleSort} />);
    }

    if (props.onFilterRender) {
      cells.push(<HeaderCellItem key="last" cell={<IconButton icon={<FilterIcon className="icon-xs" />} onClick={onToogleFilter} theme="secondary" className="px-2" disabled={props.data.length === 0} />} />);
    }

    if (cells[cells.length - 1]) {
      cells[cells.length - 1] = cloneElement(cells[cells.length - 1]!, { isLast: true });
    }

    return cells;
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setSearchValue(e.target.value);
      setIsLoading(true);

      abort.current.abort();
      abort.current = new AbortController();

      const resp = await props.onSearch?.(e.target.value, abort.current.signal, 1, props.filter, props.sort, props.order);
      if (typeof resp !== 'string' && e.target.value) throw new Error('A keresett kifejezésre nincs találat');

      if (typeof resp !== 'string' && _isMounted.current) {
        setError(undefined);
      } else throw new Error((resp || '') as string);
    } catch (error) {
      if (_isMounted.current) {
        const { message } = error as any;
        setError(String(message || 'A keresett kifejezésre nincs találat'));
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const handleFilter = async (filter: Object) => {
    try {
      setIsLoading(true);

      abort.current.abort();
      abort.current = new AbortController();

      const resp = await props.onFilter?.(searchValue, abort.current.signal, 1, filter, props.sort, props.order);

      if (typeof resp !== 'string' && !isEmptyObject(filter)) throw new Error('A megadott szűrési feltételre nincs találat');

      if (typeof resp !== 'string' && _isMounted.current) {
        setError(undefined);
      } else throw new Error((resp || '') as string);
    } catch (error) {
      if (_isMounted.current) {
        const { message } = error as any;
        setError(String(message || 'A megadott szűrési feltételre nincs találat'));
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const handleChangePage = async (page: number) => {
    if (props.onChangePage) {
      try {
        abort.current.abort();
        abort.current = new AbortController();
        setIsLoading(true);

        const resp = await props.onChangePage(searchValue, abort.current.signal, page, props.filter, props.sort, props.order);
        if (typeof resp !== 'string' && _isMounted.current) {
          setError(undefined);
        } else throw new Error(resp as string);
      } catch (error) {
        if (_isMounted.current) {
          const { message } = error as any;
          setError(String(message || 'Valami hiba történt'));
        }
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    }
  };

  const handleSort = async (sort: keyof Item, order: Order) => {
    if (props.onSort) {
      try {
        abort.current.abort();
        abort.current = new AbortController();
        setIsLoading(true);

        const resp = await props.onSort(searchValue, abort.current.signal, 1, props.filter, sort, order);
        if (typeof resp !== 'string' && _isMounted.current) {
          setError(undefined);
        } else throw new Error(resp as string);
      } catch (error) {
        if (_isMounted.current) {
          const { message } = error as any;
          setError(String(message || 'Valami hiba történt'));
        }
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    }
  };

  const handleRowRender = (row: Item) => {
    return props.onRowRender.mobile && props.onRowRender.breakpoint && props.onRowRender.breakpoint >= windowWidth ? props.onRowRender.mobile(row) : props.onRowRender.default(row, gridTemplate);
  };

  const onToogleFilter = () => setIsFilterOpened((prev) => !prev);

  const onToogleSort = () => setIsSortOpened((prev) => !prev);

  useEffect(() => {
    if (props.data.length > visibleRowCount.current) {
      visibleRowCount.current = props.data.length;
    }
  }, [props.data.length]);

  useEffect(() => setIsLoading(props.loading), [props.loading]);

  useEffect(() => setError(props.error), [props.error]);

  useImperativeHandle(ref, () => ({ onToogleFilter, onToogleSort }), []);

  return (
    <div className={`${props.className} min-w-full border-b`}>
      {props.onSearch && <div className="sticky top-[64px] lg:top-[0] z-20 pt-4 bg-white">{<Search className="border-x" onChange={handleSearch} />}</div>}

      <div className={`sticky z-10 data-table-header flex flex-col bg-white border-x ${isFilterOpened ? 'shadow-sm' : ''} ${props.onSearch ? 'top-[125px] lg:top-[61px]' : 'top-[0] border-t border-gray-200'}`}>
        <div className={`w-full grid gap-x-4 items-center bg-gray-50 border-b border-gray-200 px-4 py-3`} style={gridTemplate}>
          {_renderHeaderCells()}
        </div>
      </div>

      {props.sort && props.order && <MobileTableSort items={sortHeaders as IHeaderCell<Item>[]} open={isSortOpened} order={props.order} sort={props.sort} onSubmit={handleSort} onClose={onToogleSort} />}

      {props.onFilterRender && props.filter && props.onFilterRender(isFilterOpened, onToogleFilter, handleFilter, props.filter)}

      <div className="data-table__body relative bg-white divide-y divide-gray-200 border-x grid">
        {props.data.map((row) => handleRowRender(row))}

        {isLoading && <CircularProgress className="absolute top-0 left-0 w-full h-full bg-gray-100/40" />}

        {error && !props.data.length && <span className="flex items-center justify-center w-full h-16 text-sm text-gray-500 col-span-3">{error}</span>}
      </div>

      {props.maxDataCount && props.onChangePage && props.page && props.data.length < props.maxDataCount ? (
        <Pagination page={props.page} visibleRowCount={visibleRowCount.current} maxDataCount={props.maxDataCount} onChangePage={handleChangePage} />
      ) : null}
    </div>
  );
};

const HeaderCellItem = <Item extends unknown>({ cell, isLast, order, onSort }: IHeaderCellItem<Item>) => {
  const onToogleOrder = () => onSort!((cell as IHeaderCell<Item>).sortName as keyof Item, order === 'ASC' ? 'DESC' : 'ASC');

  const onDESCOrder = () => onSort!((cell as IHeaderCell<Item>).sortName as keyof Item, 'DESC');

  const onASCOrder = () => onSort!((cell as IHeaderCell<Item>).sortName as keyof Item, 'ASC');

  // TODO: Do something with the "buttons" scenario, make it typesafe!
  return !isHeader(cell) ? (
    <div className={`${isLast ? 'cell-last' : ''} text-xs text-blue uppercase tracking-wider font-bold self-center whitespace-pre-line`}>{cell !== 'buttons' ? cell : ''}</div>
  ) : (
    <div className={`${isLast ? 'cell-last' : ''} text-xs text-blue uppercase tracking-wider font-bold self-center whitespace-pre-line flex items-center`}>
      <span className="cursor-pointer" onClick={onToogleOrder}>
        {cell.text !== 'buttons' ? cell.text : ''}
      </span>

      {cell.sortName && onSort && (
        <span className="flex items-center">
          <ArrowUpIcon className={`icon-xs relative left-0.5 ${order === 'DESC' ? ' text-gray-600' : 'text-gray-300'} cursor-pointer`} onClick={onDESCOrder} />
          <ArrowDownIcon className={`icon-xs relative right-0.5 ${order === 'ASC' ? ' text-gray-600' : 'text-gray-300'} cursor-pointer`} onClick={onASCOrder} />
        </span>
      )}
    </div>
  );
};

const Pagination = ({ page = 1, maxDataCount, visibleRowCount = 3, visiblePageCount = 5, onChangePage }: IPagination) => {
  /* Variables */
  const pagers = [...new Array(Math.ceil(maxDataCount / visibleRowCount))];
  const mid = Math.floor(visiblePageCount / 2);

  const onPrevPage = () => onChangePage(page - 1);

  const onNexPage = () => onChangePage(page + 1);

  return (
    <div className="flex items-center justify-end border-t border-l border-r border-gray-200 pl-4 pr-2">
      <nav className="relative -bottom-px flex items-center h-12">
        <button className="group pagination-item pagination-item--arrow" onClick={onPrevPage} disabled={page <= 1}>
          <ChevronLeftIcon className={`group-hover:text-gray-700 icon-xs text-gray-400 ${page <= 1 && 'group-hover:text-gray-400'}`} aria-hidden="true" />
        </button>

        {pagers.map((_, i) => {
          const currentPage = i + 1;
          /* 
              This if check purpose is to always display exactly `visiblePageCount` count Page component.
              1. scenario: currentPage is in the middle
              2. scenario: currentPage is in the right side, so the left side has more Page component
              3. scenario: currentPage is in the left side, so the right side has more Page component
            */
          if ((currentPage >= page - mid || (page + mid >= pagers.length && pagers.length - currentPage < visiblePageCount)) && (currentPage <= page + mid || (page - mid < 1 && currentPage <= visiblePageCount))) {
            return (
              <button
                key={i}
                className={`pagination-item${currentPage === page ? ' border-primary text-primary hover:border-primary-darker hover:text-primary-darker' : ''}`}
                aria-current="page"
                onClick={() => onChangePage(currentPage)}>
                {currentPage}
              </button>
            );
          }
          return undefined;
        })}

        <button className="group pagination-item pagination-item--arrow" onClick={onNexPage} disabled={page >= pagers.length}>
          <ChevronRightIcon className={`group-hover:text-gray-700 icon-xs text-gray-400 ${page >= pagers.length && 'group-hover:text-gray-400'}`} aria-hidden="true" />
        </button>
      </nav>
    </div>
  );
};

export const TableChips = (props: React.PropsWithChildren<{ hasActiveFilter: boolean }>) => {
  return props.hasActiveFilter ? (
    <div className="p-2 border-x border-b">
      <AnimatePresence>
        <motion.div layout className="flex flex-wrap gap-y-4 gap-x-4 ml-2">
          <AnimatePresence>{props.children}</AnimatePresence>
        </motion.div>
      </AnimatePresence>
    </div>
  ) : null;
};

export const Table = forwardRef(TableInner) as <T extends unknown>(p: IProps<T> & { ref?: Ref<TableRef> }) => ReactElement;
