import React from 'react';
import { useAuthorization } from '../../hooks';
import { AppRole } from '../../typings/enum';
import { Link } from '@reach/router';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: JSX.Element;
  to?: string;
  theme?: 'primary' | 'secondary';
  hiddenFor?: keyof typeof AppRole | (keyof typeof AppRole)[];
}

export const IconButton = ({ icon, to, theme, hiddenFor, className, ...rest }: IProps) => {
  /* Hooks */
  const { role } = useAuthorization();

  if (hiddenFor && (Array.isArray(hiddenFor) ? hiddenFor.includes(role) : role === hiddenFor)) return null;

  return to ? (
    <Link to={to} tabIndex={0} className={`${theme ? 'icon-button button--' + theme : ''} ${className}`}>
      {icon}
    </Link>
  ) : (
    <button type="button" tabIndex={0} {...rest} className={`${theme ? 'icon-button button--' + theme : ''} ${className}`}>
      {icon}
    </button>
  );
};
