import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { PaperClipIcon, TrashIcon } from '@heroicons/react/outline';
import { Select, SelectHandle } from '../../../components/Select';
import { Button, IconButton } from '../../../components/Buttons';
import { CircularProgress } from '../../../components/CircularProgress';
import { Accordion } from '../../../components/Accordion';
import { Download } from '../../../components/Download';
import { Option } from '../../../components/Select/helper';
import { Input } from '../../../components/Inputs';
import { Badge } from '../../../components/Badge';
import { Chips } from '../../../components/Chips';

// Data things
import { DEFAULT_CONTRACT, Model, ContractModel, SpecialCostModel, transformProjectToModels, transformModelToICreateProjectDTO, transformModelToIUpdateProjectDTO } from './helper';
import { SelectableAccommodation, SelectableClient, SelectableProHumanManager } from '../../../contexts/Config/helper';
import { useAuthorization, useIsMounted, useNavigate } from '../../../hooks';
import { onChangeInput, toDateString, titleSelector } from '../../../constants/functions';
import { DATE_INPUT_FORMAT, TODAY } from '../../../constants';
import { ApiErrorResult, api } from '../../../utils/api';
import { ProjectsContext } from '../../../contexts/Projects';
import { ConfigContext } from '../../../contexts/Config';
import { DateTime } from 'luxon';
import { File } from '../../../typings/common';
import { ChargeType } from '../../../typings/enum';

type ContractProps = {
  onChange(contract: ContractModel): void;
  onDelete(id: number): void;
  isNotFirst: boolean;
  isThereMore: boolean;
  contract: ContractModel;
};

type AccommodationChargeProps = {
  onChange(specialCost: SpecialCostModel): void;
  onDelete(id: number): void;
  isFirst: boolean;
  specialCost: SpecialCostModel;
  accommodations: SelectableAccommodation[];
};

export default function CreateOrEdit(props: RouteComponentProps<{ id: number }>) {
  /* Context */
  const { clients, proHumanManagers, accommodations } = useContext(ConfigContext);
  const { projects, onGetProject, onAddProject, onEditProject } = useContext(ProjectsContext);

  /* Variables */
  const selectedProject = useMemo(() => projects.items.find((x) => x.id === +props.id!), [projects.items, props.id]);
  const defaultClientId = Number(new URLSearchParams(props.location?.search).get('ugyfel'));
  const isEditPage = props.id !== undefined;
  const hasData = Array.isArray(selectedProject?.contracts);

  /* States */
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(isEditPage && !hasData);
  const [contracts, setContracts] = useState([DEFAULT_CONTRACT(1)]);
  const [model, setModel] = useState<Model>({ clientWorkNumber: '', workNumber: '', proHumanManagers: [], client: clients.find((x) => x.value === defaultClientId) ?? clients[0] ?? null });
  const [error, setError] = useState('');

  /* Refs */
  const _contractForm = useRef<HTMLFormElement>(null);

  /* Hooks */
  const { goBack, goTo404 } = useNavigate();
  const { role } = useAuthorization();
  const _isMounted = useIsMounted();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (model.client) {
        setIsSubmiting(true);
        const editTransformed = transformModelToIUpdateProjectDTO(model, contracts);
        const addTransformed = transformModelToICreateProjectDTO(model, contracts);
        const resp = isEditPage ? await onEditProject(editTransformed, Number(props.id)) : await onAddProject(addTransformed);

        if (typeof resp !== 'string' && _isMounted.current) {
          goBack();
        }
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsSubmiting(false);
    }
  };

  const onAddContract = () => {
    const isValidForm = _contractForm.current?.reportValidity();

    if (isValidForm) {
      setContracts((prev) => {
        if (prev[prev.length - 1]) {
          const newContract = DEFAULT_CONTRACT(Date.now(), prev[prev.length - 1]!.endDate?.plus({ days: 1 }));

          return [...prev, newContract];
        }

        return prev;
      });
    }
  };

  const handleGetProject = useCallback(async () => {
    if (props.id && !hasData) {
      try {
        setIsLoading(true);
        setError('');
        await onGetProject(Number(props.id));
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && setError(String(message || error));
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    }
  }, [props.id, hasData, _isMounted, onGetProject]);

  const onDeselectProHumanManager = (value: number) => setModel((prev) => ({ ...prev, proHumanManagers: prev.proHumanManagers.filter((x) => x.value !== value) }));

  const onSelectProHumanManagers = (proHumanManagers: SelectableProHumanManager[]) => setModel((prev) => ({ ...prev, proHumanManagers }));

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setModel);

  const onContractChange = (contract: ContractModel) => setContracts((prev) => prev.map((x) => (x.tempId === contract.tempId ? contract : x)));

  const onDeleteContract = (tempId: number) => setContracts((prev) => prev.filter((x) => x.tempId !== tempId));

  const onSelectClient = (selected: Option[]) => setModel((prev) => ({ ...prev, client: selected[0] as SelectableClient }));

  useEffect(() => void handleGetProject(), [handleGetProject]);

  useEffect(() => {
    if (role !== 'Administrator') {
      goTo404();
    }
  }, [role, goTo404]);

  useEffect(() => {
    if (selectedProject && clients.length) {
      const { contracts, model } = transformProjectToModels(selectedProject, clients, proHumanManagers, accommodations);
      setContracts(contracts);
      setModel(model);
    }
  }, [selectedProject, clients, proHumanManagers, accommodations]);

  useEffect(() => {
    if (clients.length && !model.client) {
      setModel((prev) => ({ ...prev, client: clients.find((x) => x.value === defaultClientId) ?? clients[0] ?? null }));
    }
  }, [clients, defaultClientId, model.client]);

  return (
    <div className="page-container max-w-4xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={handleGetProject} />
        </div>
      ) : (
        <>
          <h1 className="main-title">Projekt {isEditPage ? 'szerkesztése' : 'hozzáadása'}</h1>

          <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit} ref={_contractForm}>
            <div className="pt-8">
              <div className="mt-6 grid gap-4 grid-cols-6">
                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Select title="Ügyfél" options={clients} dialog="single" selected={model.client ? [model.client] : []} onSelect={onSelectClient} required />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input type="text" label="BRC munkaszám" name="workNumber" value={model.workNumber} onChange={handleChangeInput} required />
                </div>

                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Input type="text" label="Ügyfél munkaszám" name="clientWorkNumber" value={model.clientWorkNumber} onChange={handleChangeInput} required />
                </div>
              </div>
            </div>

            <div className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Prohuman projekt felelősök</h3>

              <div className="mt-6 grid gap-x-4 gap-y-6 grid-cols-6">
                <div className="col-span-6 sm:col-span-3 md:col-span-2">
                  <Select title="Prohuman projekt felelősök" options={proHumanManagers} dialog="multi" selected={model.proHumanManagers} onSelect={onSelectProHumanManagers} showSelected={false} required />
                </div>

                <div className="flex flex-wrap gap-x-4 gap-y-2 my-auto col-span-6 sm:col-span-3 md:col-span-4">
                  {model.proHumanManagers.map((x: SelectableProHumanManager) => (
                    <Chips key={x.value} label={x.text} onDelete={() => onDeselectProHumanManager(x.value)} />
                  ))}
                </div>
              </div>
            </div>

            <div className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900 flex flex-row justify-between">
                Szerződések
                <Button type="button" title="Szerződés hozzáadása" theme="primary" onClick={onAddContract} />
              </h3>

              <div className="flex flex-col-reverse">
                {contracts.map((x, i) => (
                  <Contract key={x.tempId} contract={x} isThereMore={contracts.length > 1} isNotFirst={i !== 0} onDelete={onDeleteContract} onChange={onContractChange} />
                ))}
              </div>
            </div>

            <div className="flex space-x-3 justify-end pt-5">
              <Button type="button" title="Mégsem" theme="secondary" onClick={goBack} disabled={isSubmiting} />
              <Button type="submit" title="Mentés" loading={isSubmiting} disabled={isSubmiting} />
            </div>
          </form>
        </>
      )}
    </div>
  );
}

const Contract = ({ onDelete, onChange, isNotFirst, isThereMore, contract }: ContractProps) => {
  /* Context */
  const { accommodations } = useContext(ConfigContext);

  /* Variables */
  const selectedAccommodations = useMemo(() => contract.specialCosts.map((x) => x.accommodation.value), [contract.specialCosts]);
  const selectableAccommodations = useMemo(() => accommodations.filter((x) => !selectedAccommodations.some((y) => y === x.value)), [accommodations, selectedAccommodations]);
  const isActive = contract.startDate <= TODAY && (!contract.endDate || contract.endDate >= TODAY);

  /* Refs */
  const _accommodation = useRef<SelectHandle>(null);
  const _isMounted = useIsMounted();

  /* State */
  const [isUploading, setIsUploading] = useState(false);

  /* Attachments */
  const onSelectAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        setIsUploading(true);

        const newAttachments: File[] = [];
        const requests: Promise<File>[] = [];

        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files.item(i)!;
          const body = new FormData();
          body.append('file', file);

          requests.push(api<{ id: number }>('file', undefined, { method: 'POST', body }).then(({ id }) => ({ id, name: file.name, url: file.webkitRelativePath })));
        }

        await Promise.allSettled(requests).then((results) => results.forEach((x) => x.status === 'fulfilled' && newAttachments.push(x.value)));

        onChange({ ...contract, tempAttachments: [...contract.tempAttachments, ...newAttachments] });
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsUploading(false);
    }
  };

  const onDeleteAttachment = (id: number) => {
    onChange({ ...contract, attachments: contract.attachments.filter((a) => a.id !== id) });
  };

  const onDeleteTempAttachment = (id: number) => {
    onChange({ ...contract, tempAttachments: contract.tempAttachments.filter((a) => a.id !== id) });
  };

  /* Contracts */
  const handleChangeContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.type) {
      case 'number':
        const parsed = parseInt(e.target.value);
        onChange({ ...contract, [e.target.name]: isNaN(parsed) ? null : parsed });
        break;
      case 'date':
        const parsedDate = DateTime.fromFormat(e.target.value, DATE_INPUT_FORMAT);
        onChange({ ...contract, [e.target.name]: parsedDate.isValid ? parsedDate : null });
        break;
    }
  };

  const onDeleteContract = () => {
    onDelete(contract.tempId);
  };

  /* Accommodation Charge */
  const handleChangeAccommodationCharge = (charge: SpecialCostModel) => {
    onChange({ ...contract, specialCosts: contract.specialCosts.map((x) => (x.tempId === charge.tempId ? charge : x)) });
  };

  const onAddAccommodationCharge = (selecteds: SelectableAccommodation[]) => {
    onChange({
      ...contract,
      specialCosts: [
        ...contract.specialCosts,
        ...selecteds.map((x) => ({
          id: null,
          accommodation: x,
          tempId: x.value,
          cost: { cost: 0, dailyMinimum: null, chargeType: ChargeType.Daily }
        }))
      ]
    });
  };

  const onOpenAccommodationChargeAdd = () => _accommodation.current?.open();

  const onDeleteAccommodationCharge = (tempId: number) => onChange({ ...contract, specialCosts: contract.specialCosts.filter((x) => x.tempId !== tempId) });

  return (
    <Accordion
      isBorderBottom={isNotFirst}
      className="open:shadow-[0_0_1rem_#0d532e20] transform-gpu transition-[box-shadow,_border-radius,_transform,_margin,_padding] duration-150 ease-in-out open:rounded-md open:p-4 open:my-4"
      label={
        <>
          <span className="flex flex-1 items-center text-sm">
            {contract.startDate && toDateString(contract.startDate)} - {contract.endDate ? toDateString(contract.endDate) : ''}
            {isActive && <Badge label="Aktív" className="badge--green h-fit self-center ml-4" />}
          </span>
          {isThereMore && <IconButton className="self-end" icon={<TrashIcon className="icon-sm" />} onClick={onDeleteContract} theme="secondary" />}
        </>
      }>
      <div className="pt-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Szerződés adatai</h3>

        <div className="mt-6 grid gap-4 grid-cols-6">
          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="date" label="Szerződés kezdete" name="startDate" value={contract.startDate} onChange={handleChangeContract} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="date" label="Szerződés vége" name="endDate" min={contract.startDate} value={contract.endDate} onChange={handleChangeContract} />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="number" label="Felmondási idő" unit="nap" name="noticeDays" value={contract.noticeDays} onChange={handleChangeContract} />
          </div>
        </div>
      </div>

      <div className="table-header">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Bevételek</h3>

        <Select title="Bevételhez tartozó szállások" options={selectableAccommodations} dialog="multi" selected={selectedAccommodations} onSelect={onAddAccommodationCharge} ref={_accommodation}>
          <Button type="button" title="Bevétel hozzáadása" theme="secondary" onClick={onOpenAccommodationChargeAdd} disabled={!selectableAccommodations.length} />
        </Select>
      </div>

      <div className="contracts">
        {contract.specialCosts.map((x, i) => (
          <AccommodationCharge key={x.tempId} accommodations={selectableAccommodations} specialCost={x} onChange={handleChangeAccommodationCharge} isFirst={i === 0} onDelete={onDeleteAccommodationCharge} />
        ))}
      </div>

      <div className="table-header">
        <div className="flex flex-col items-start gap-y-2 w-full">
          <div className="flex justify-between items-center w-full mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Csatolmányok</h3>

            <label htmlFor={'upload_' + contract.id}>
              <span className="button flex items-center gap-x-4 button--secondary">{isUploading ? <CircularProgress className="icon-sm" /> : <PaperClipIcon className="icon-sm" />}</span>

              <input type="file" id={'upload_' + contract.id} name={'upload_' + contract.id} hidden multiple onChange={onSelectAttachment} />
            </label>
          </div>

          {contract.attachments.length > 0 && (
            <div className="links">
              {contract.attachments.map((x) => (
                <div className="flex items-center">
                  <Download key={x.id} href={x.url} fileNameWithExtension={x.name} className="links-item flex-1 truncate !pb-0 !border-none" />
                  <IconButton
                    className="border-none flex-0 basis-fit"
                    icon={<TrashIcon className="icon-sm" />}
                    onClick={() => {
                      onDeleteAttachment(x.id);
                    }}
                    theme="secondary"
                  />
                </div>
              ))}
            </div>
          )}
          {contract.tempAttachments.length > 0 && (
            <div className="links">
              {contract.tempAttachments.map((x) => (
                <div className="flex items-center">
                  <Download key={x.id} href={x.url} fileNameWithExtension={x.name} className="links-item flex-1 truncate !pb-0 !border-none" />
                  <IconButton
                    className="border-none flex-0 basis-fit"
                    icon={<TrashIcon className="icon-sm" />}
                    onClick={() => {
                      onDeleteTempAttachment(x.id);
                    }}
                    theme="secondary"
                  />
                </div>
              ))}
            </div>
          )}
          {!contract.attachments.length && !contract.tempAttachments.length && <p className="text-slate-600 text-xs leading-relaxed">Nincs csatolmány</p>}
        </div>
      </div>
    </Accordion>
  );
};

const AccommodationCharge = ({ onDelete, accommodations, isFirst, specialCost, onChange }: AccommodationChargeProps) => {
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = parseInt(e.target.value);
    onChange({ ...specialCost, cost: { ...specialCost.cost, [e.target.name]: isNaN(parsed) ? null : parsed } });
  };

  const onSelectCostChargeType = (value: number[]) => {
    onChange({
      ...specialCost,
      cost: {
        ...specialCost.cost,
        dailyMinimum: value[0] === ChargeType.Monthly ? null : specialCost.cost.dailyMinimum,
        chargeType: value[0]!
      }
    });
  };

  const onChangeAccommodation = (values: SelectableAccommodation[]) => {
    if (values[0]) {
      onChange({ ...specialCost, accommodation: values[0] });
    }
  };

  const handleDelete = () => onDelete(specialCost.tempId);

  return (
    <div className={`pb-8 ${isFirst ? '' : 'border-t border-t-gray-300'} last:pb-0`}>
      <div className="mt-6 grid gap-4 grid-cols-6">
        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Select
            title="Szálláshely"
            options={[specialCost.accommodation, ...accommodations]}
            dialog="single"
            selected={[specialCost.accommodation]}
            onSelect={onChangeAccommodation}
            titleSelector={titleSelector}
            required
          />
        </div>

        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Select
            title="Költség gyakorisága"
            options={[
              { text: 'Napi (/fő/éj)', value: ChargeType.Daily },
              { text: 'Havi (/hó)', value: ChargeType.Monthly }
            ]}
            onSelect={onSelectCostChargeType}
            selected={[specialCost.cost.chargeType]}
            required
          />
        </div>

        <div className="col-span-6 sm:col-span-3 md:col-span-2">
          <Input type="number" label="Szálláshely bevétele" placeholder="0" unit="Ft" name="cost" value={specialCost.cost.cost} onChange={onChangeInput} required />
        </div>

        <div className="flex space-x-4 col-span-6 sm:col-span-3 md:col-span-2">
          {specialCost.cost.chargeType === ChargeType.Daily && (
            <Input wrapperClassName="flex-1" type="number" label="Napi minimum bevétel" placeholder="0" unit="Ft" name="dailyMinimum" value={specialCost.cost?.dailyMinimum} onChange={onChangeInput} />
          )}
          <IconButton icon={<TrashIcon className="icon-xs xs:icon-sm" />} onClick={handleDelete} type="button" theme="secondary" className="self-center" />
        </div>
      </div>
    </div>
  );
};
