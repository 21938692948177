import { IBookedEmployeeDTO } from '../../typings/DTOs';
import { basicSort } from '../../constants/functions';
import { DateTime } from 'luxon';
import { Sort } from '../../typings/common';

export class SelectableEmployee {
  public value: number;
  public text: string;
  public idCardNumber: string;
  public probondId: string;
  public taxIdentificationNumber: string;
  public passportNumber: string;
  public movingIn: DateTime;
  public accommodation: {
    id: number;
    address: string;
  };
  public project: {
    id: number;
    workNumber: string;
  };

  public constructor(p: IBookedEmployeeDTO) {
    this.value = p.id;
    this.text = p.name || '';
    this.idCardNumber = p.idCardNumber || '';
    this.probondId = p.probondId || '';
    this.taxIdentificationNumber = p.taxIdentificationNumber || '';
    this.passportNumber = p.passportNumber || '';
    this.movingIn = p.moveInDate ? DateTime.fromISO(p.moveInDate) : DateTime.now();
    this.accommodation = {
      id: p.accommodation.id,
      address: p.accommodation.address || ''
    };
    this.project = {
      id: p.project.id,
      workNumber: p.project.workNumber || ''
    };
  }
}

export class EmployeeMovingOut {
  public id: number;
  public name: string;
  public idCardNumber: string;
  public probondId: string;
  public taxIdentificationNumber: string;
  public passportNumber: string;
  public movingIn: DateTime;
  public accommodation: {
    id: number;
    address: string;
  };
  public project: {
    id: number;
    workNumber: string;
  };
  movingOut: DateTime | null;

  constructor(p: SelectableEmployee) {
    this.id = p.value;
    this.name = p.text;
    this.idCardNumber = p.idCardNumber;
    this.probondId = p.probondId;
    this.taxIdentificationNumber = p.taxIdentificationNumber;
    this.passportNumber = p.passportNumber;
    this.movingIn = p.movingIn;
    this.accommodation = p.accommodation;
    this.project = p.project;
    this.movingOut = null;
  }
}

export const getVisibleEmployees = (employees: EmployeeMovingOut[], searchValue: string, filter: MoveOutEmployeesFilter, sort: Sort<EmployeeMovingOut>): EmployeeMovingOut[] => {
  searchValue = searchValue.toLowerCase();

  const filteredEmployees = employees.filter((emp) => {
    if (filter.moveInFrom && ((emp.movingIn && emp.movingIn < filter.moveInFrom) || !emp.movingIn)) {
      return false;
    }

    if (filter.moveInTo && ((emp.movingIn && emp.movingIn > filter.moveInTo) || !emp.movingIn)) {
      return false;
    }

    if (filter.moveOutFrom && ((emp.movingOut && emp.movingOut < filter.moveOutFrom) || !emp.movingOut)) {
      return false;
    }

    if (filter.moveOutTo && ((emp.movingOut && emp.movingOut > filter.moveOutTo) || !emp.movingOut)) {
      return false;
    }

    return true;
  });

  return filteredEmployees.filter((x) => x.name.toLowerCase().includes(searchValue)).sort((x, y) => basicSort(x, y, sort));
};
export const checkIsNotSaveable = (employees: EmployeeMovingOut[]) => {
  return employees.some((x) => !x.movingOut);
};

export const transformEmployeeMovingOutToICreateMoveOutDTO = (employee: EmployeeMovingOut) => {
  return {
    employeeId: employee.id,
    moveOutDate: employee.movingOut ? employee.movingOut.toISODate() : null
  };
};

export const processCSVfile = (raw: string): string[] => {
  const rows = raw.split(/\r\n|\n/).filter(Boolean);
  rows.splice(0, 1);

  return rows;
};

export type MoveOutEmployeesFilter = {
  moveInFrom: DateTime | null;
  moveInTo: DateTime | null;
  moveOutFrom: DateTime | null;
  moveOutTo: DateTime | null;
};
