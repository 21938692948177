import React, { Fragment, useEffect, useMemo, useState } from 'react';

/* Navigation things */
import { Link, RouteComponentProps, LinkGetProps, useLocation } from '@reach/router';

/* Providers */
import { ConfigProvider } from '../../contexts/Config';

/* Data Things */
import { InteractionStatus } from '@azure/msal-browser';
import { useAuthorization } from '../../hooks';
import { DateTime } from 'luxon';
import { AppRole } from '../../typings/enum';
import { useMsal } from '@azure/msal-react';

/* Presentation Things */
import { MenuAlt2Icon, CogIcon, HomeIcon, UsersIcon, XIcon, OfficeBuildingIcon, ClipboardCheckIcon, UserGroupIcon, ClipboardListIcon, LogoutIcon, UserCircleIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';

/* Images */
import BRCLogo from '../../assets/svg/brc-logo.svg';

const isPartiallyActive = ({ isPartiallyCurrent }: LinkGetProps) => (isPartiallyCurrent ? { className: 'menu-item menu-item--active' } : {});

const calculateNavigation = (role: keyof typeof AppRole) => {
  const TODAY = DateTime.now();
  return [
    { name: 'Főoldal', href: '/fooldal', icon: <HomeIcon className="menu-item__icon" /> },
    {
      name: 'Szállások',
      href: `/szallasok/${TODAY.year}/${TODAY.month}`,
      icon: <OfficeBuildingIcon className="menu-item__icon" />
    },
    {
      name: 'Projektek',
      href: '/projektek',
      icon: <ClipboardCheckIcon className="menu-item__icon" />
    },
    {
      name: 'Munkavállalók',
      href: '/munkavallalok',
      icon: <UsersIcon className="menu-item__icon" />
    },
    role !== 'ProHumanManager' && { name: 'Partnerek', href: '/partnerek', icon: <UserGroupIcon className="menu-item__icon" /> },
    {
      name: 'Riportok',
      href: '/riportok',
      icon: <ClipboardListIcon className="menu-item__icon" />
    },
    role !== 'ProHumanManager' && role !== 'BRCManager' && { name: 'Felhasználók', href: '/felhasznalok', icon: <CogIcon className="menu-item__icon" /> }
  ].filter(Boolean);
};

export default function MasterLayout(props: React.PropsWithChildren<RouteComponentProps>) {
  /* States */
  const [sidebarOpen, setSidebarOpen] = useState(false);

  /* Hooks */
  const { instance, accounts, inProgress } = useMsal();
  const { pathname } = useLocation();
  const { role } = useAuthorization();

  /* Variables */
  const navigation = useMemo(() => calculateNavigation(role), [role]);

  const oLogout = async () => {
    try {
      if (inProgress === InteractionStatus.None) {
        instance.logoutRedirect({ account: accounts[0] });
      }
    } catch (error) {}
  };

  const onClose = () => setSidebarOpen(false);

  const onOpen = () => setSidebarOpen(true);

  useEffect(() => setSidebarOpen(false), [pathname]);

  return (
    <ConfigProvider>
      {/* Mobile menu */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
              <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="absolute top-0 right-0 -mr-14 p-1">
                  <button type="button" className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:bg-gray-600" onClick={onClose}>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 px-4 flex items-center">
                <img className="h-8 w-auto" src={BRCLogo} alt="BRC" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="h-full flex flex-col">
                  <div className="space-y-1">
                    {navigation.map(
                      (item) =>
                        item && (
                          <Link key={item.name} to={item.href} getProps={isPartiallyActive} className="menu-item">
                            {item.icon}
                            {item.name}
                          </Link>
                        )
                    )}
                  </div>

                  <div className="flex items-center mt-auto pt-10 divide-x divide-gray-200 px-4">
                    <span className="menu-item pl-0 py-4 flex-grow">
                      <UserCircleIcon className="menu-item__icon" />
                      {accounts[0]?.name ?? accounts[0]?.username ?? ''}
                    </span>

                    <span className="menu-item pl-4 pr-0 grow-0 shrink-0">
                      <LogoutIcon className="menu-item__icon mr-0" onClick={oLogout} />
                    </span>
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <nav className="hidden fixed w-64 bg-gray-50 border-r border-gray-200 py-4 inset-y-0 lg:flex flex-col">
        <Link to="/fooldal" className="px-4 flex items-center">
          <img className="h-12 w-auto" src={BRCLogo} alt="BRC Logo" />
        </Link>

        <div className="flex-grow mt-5 space-y-1">
          {navigation.map(
            (item) =>
              item && (
                <Link key={item.name} to={item.href} getProps={isPartiallyActive} className="menu-item">
                  {item.icon}
                  {item.name}
                </Link>
              )
          )}
        </div>

        {/* Sub menu */}
        <div className="flex items-center divide-x divide-gray-200 px-4">
          <span className="menu-item pl-0 py-4 flex-grow">
            <UserCircleIcon className="menu-item__icon" />
            {accounts[0]?.name ?? accounts[0]?.username ?? ''}
          </span>

          <button className="menu-item pl-4 pr-0 grow-0 shrink-0">
            <LogoutIcon className="menu-item__icon mr-0" onClick={oLogout} />
          </button>
        </div>
      </nav>

      {/* Mobile menu */}
      <header className="sticky top-0 bg-white z-40 h-16 border-b border-gray-200 flex flex-row items-center lg:hidden">
        <button type="button" className="px-6 h-full flex items-center justify-center border-r border-gray-200 text-gray-500" onClick={onOpen}>
          <MenuAlt2Icon className="icon-sm" aria-hidden="true" />
        </button>

        <div className="flex flex-1 h-full items-center justify-center">
          <Link to="/fooldal" className="relative -left-8">
            <img className="h-10 w-auto" src={BRCLogo} alt="BRC Logo" />
          </Link>
        </div>
      </header>

      {/* Content area */}
      <div className="w-full mx-auto lg:pl-72 px-4 sm:px-8">{props.children}</div>
    </ConfigProvider>
  );
}
