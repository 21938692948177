import React, { useMemo } from 'react';
import { DATE_INPUT_FORMAT, DATE_MONTH_INPUT_FORMAT } from '../../../constants';
import { DateTime } from 'luxon';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'min' | 'max'> {
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'] | DateTime | null;
  min?: React.InputHTMLAttributes<HTMLInputElement>['min'] | DateTime | null;
  max?: React.InputHTMLAttributes<HTMLInputElement>['max'] | DateTime | null;
  label: string;
  unit?: string;
  filter?: boolean;
  wrapperClassName?: string;
}

const format: Record<string, string> = {
  month: DATE_MONTH_INPUT_FORMAT,
  date: DATE_INPUT_FORMAT
};

export const Input = React.forwardRef<HTMLInputElement, IProps>(({ label, unit, wrapperClassName, filter = false, ...rest }, ref) => {
  //@ts-ignore
  const value = useMemo(() => (rest.value === null ? '' : rest.value instanceof DateTime ? rest.value.toFormat(format[rest.type]) : rest.value), [rest.value, rest.type]);
  //@ts-ignore
  const min = useMemo(() => (rest.min instanceof DateTime ? rest.min.toFormat(format[rest.type]) : rest.min || undefined), [rest.min, rest.type]);
  //@ts-ignore
  const max = useMemo(() => (rest.max instanceof DateTime ? rest.max.toFormat(format[rest.type]) : rest.max || '9999-12-31'), [rest.max, rest.type]);

  return (
    <div className={`border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary ${wrapperClassName} ${rest.disabled && 'opacity-40'}`}>
      <div className="flex justify-between">
        {label && <span className="block text-xs font-medium text-gray-900">{label}</span>}

        {!rest.required && !filter && <span className="text-xs text-gray-500">Opcionális</span>}
      </div>

      <div className="relative">
        <input ref={ref} {...rest} className={`block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm ${rest.className}`} value={value} min={min} max={max} />

        {unit && (
          <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
            <span className="text-gray-500 text-sm">{unit}</span>
          </div>
        )}
      </div>
    </div>
  );
});
