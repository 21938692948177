import React, { useCallback, useReducer, useRef } from 'react';

/* Data Things */
import { IAccommodationDTO, IAccommodationListDTO, IAccommodationWidgetsDTO, ICreateAccommodationDTO, IUpdateAccommodationDTO } from '../../typings/DTOs';
import { accommodationsReducer, AccommodationsState, initialState } from '../../reducers/Accommodations';
import { AccommodationListItem, AccommodationsFilter } from './helper';
import { ApiErrorResult, api } from '../../utils/api';
import { useIsMounted } from '../../hooks';
import { DateTime } from 'luxon';
import { Order } from '../../typings/common';

/* Presentation Things */
import { Snackbar } from '../../components/Snackbar';

interface IAccommodationsContext extends AccommodationsState {
  onGetAccommodationsWithWidgets: (year: number, month: number) => Promise<void>;
  onEditAccommodation: (accommodation: IUpdateAccommodationDTO, id: number) => Promise<string | null>;
  onAddAccommodation: (accommodation: ICreateAccommodationDTO) => Promise<string | null>;
  onGetAccommodations: (selectedMonth: DateTime, search?: string, signal?: AbortSignal, page?: number, filter?: AccommodationsFilter, sort?: keyof AccommodationListItem, order?: Order) => Promise<string | null>;
  onFilter: (filter: AccommodationsFilter) => void;
}

const AccommodationsContext = React.createContext<IAccommodationsContext>({
  ...initialState,
  onGetAccommodationsWithWidgets: () => Promise.reject(),
  onEditAccommodation: () => Promise.reject(),
  onAddAccommodation: () => Promise.reject(),
  onGetAccommodations: () => Promise.reject(),
  onFilter: () => null
});

function AccommodationsProvider(props: React.PropsWithChildren<{}>) {
  /* States */
  const [state, dispatch] = useReducer(accommodationsReducer, initialState);

  /* Refs */
  const abortController = useRef(new AbortController());

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetAccommodationsWithWidgets = useCallback(
    async (year: number, month: number) => {
      try {
        abortController.current.abort();
        abortController.current = new AbortController();

        const [accommodations, widgets] = await Promise.all([
          api<IAccommodationListDTO>(`accommodation/${year}/${month}`, { limit: 15, sort: 'host', order: 'ASC' }, { signal: abortController.current.signal }),
          api<IAccommodationWidgetsDTO>(`accommodation/widgets/${year}/${month}`, undefined, { signal: abortController.current.signal })
        ]);

        if (!accommodations.message && !widgets.message && _isMounted.current) {
          dispatch({ type: 'success_init', payload: { accommodations, widgets, sort: 'host', order: 'ASC' } });
        } else throw Error(accommodations.message || widgets.message);
      } catch (error) {
        if (_isMounted.current) {
          const { message } = error as ApiErrorResult;
          dispatch({ type: 'error_init', payload: { error: message } });
        }
      }
    },
    [_isMounted, abortController]
  );

  const onGetAccommodations = useCallback(
    async (selectedMonth: DateTime, search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof AccommodationListItem = 'host', order: Order = 'ASC'): Promise<string | null> => {
      try {
        const accommodations = await api<IAccommodationListDTO>(`accommodation/${selectedMonth.year}/${selectedMonth.month}`, { search, filter, sort, order, limit: 15, offset: (page - 1) * 15 }, { signal });

        if (!accommodations.message && _isMounted.current) {
          dispatch({ type: 'success_get_accommodations', payload: { accommodations, page, sort, order } });

          return Promise.resolve(null);
        } else throw new Error(accommodations.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_get_accommodations', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onEditAccommodation = useCallback(
    async (body: IUpdateAccommodationDTO, id: number): Promise<string | null> => {
      try {
        const accommodation = await api<IAccommodationDTO>(`accommodation/${id}`, undefined, { method: 'PUT', body });

        if (!accommodation.message && _isMounted.current) {
          dispatch({ type: 'success_put_accommodation', payload: { accommodation } });

          return Promise.resolve(null);
        } else throw new Error(accommodation.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_put_accommodation', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAddAccommodation = useCallback(
    async (body: ICreateAccommodationDTO): Promise<string | null> => {
      try {
        const accommodation = await api<IAccommodationDTO>('accommodation', undefined, { method: 'POST', body });

        if (!accommodation.message && _isMounted.current) {
          dispatch({ type: 'success_post_accommodation', payload: { accommodation } });

          return Promise.resolve(null);
        } else throw new Error(accommodation.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_post_accommodation', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAutoCloseResponse = useCallback(() => dispatch({ type: 'set_partial_state', payload: { response: null } }), []);

  const onFilter = useCallback((filter: AccommodationsFilter) => dispatch({ type: 'set_filter', payload: { filter } }), []);

  return (
    <AccommodationsContext.Provider value={{ ...state, onGetAccommodationsWithWidgets, onEditAccommodation, onAddAccommodation, onGetAccommodations, onFilter }}>
      <Snackbar opened={!!state.response} label={state.response!?.message} type={state.response!?.type} onAutoClose={onAutoCloseResponse} />
      {props.children}
    </AccommodationsContext.Provider>
  );
}

export { AccommodationsProvider, AccommodationsContext };
