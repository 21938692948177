export type Option =
  | { value: number; text: string; disabled?: boolean }
  | { value: number; text: string; description: string; disabled?: boolean }
  | {
      value: number;
      text: string;
      idCardNumber: string;
      probondId: string;
      taxIdentificationNumber: string;
      passportNumber: string;
      disabled?: boolean;
    };

export const getSelectedValue = (val?: number | { value: number } | { id: number }) => {
  if (val === undefined) return undefined;
  return typeof val === 'number' ? val : 'value' in val ? val.value : val.id;
};

export const getSelectedOptions = (selecteds: (number | { value: number } | { id: number })[], options: Option[]): Record<number, Option> => {
  return selecteds.reduce((acc, curr) => {
    const value = getSelectedValue(curr);

    const option = options.find((x) => x.value === value);

    if (option && value !== undefined) {
      return { ...acc, [value]: option };
    }
    return acc;
  }, {} as Record<number, Option>);
};

export const getSelectedTitles = (selectedValues: (number | { value: number } | { id: number })[], options: Option[], titleSelector: (option: Option) => string) => {
  return selectedValues
    .reduce((acc, y) => {
      const option = options.find((x) => x.value === getSelectedValue(y));

      if (option) {
        return [...acc, titleSelector(option)];
      }

      return acc;
    }, [] as string[])
    .join(', ');
};

export const getFilteredOptions = (options: Option[], searchValue: string) => {
  searchValue = searchValue.replaceAll(' ', '').toLowerCase();
  return options.filter(
    (option) =>
      option.text.toLowerCase().replaceAll(' ', '').includes(searchValue) ||
      ('description' in option && option.description.toLowerCase().replaceAll(' ', '').includes(searchValue)) ||
      ('passportNumber' in option && [option.passportNumber, option.idCardNumber, option.taxIdentificationNumber, option.probondId].join('|').toLowerCase().replaceAll(' ', '').includes(searchValue))
  );
};
