import { SelectableAccommodation, SelectableProject } from '../../../contexts/Config/helper';
import { AccommodationEmployeeListItem } from '../../Hotels/Details/Overview/helper';
import { IUpdateBookingDTO } from '../../../typings/DTOs';
import { TODAY } from '../../../constants';
import { Model } from '../helper';

export const getInitialState = (accommodations: SelectableAccommodation[], workNumbers: SelectableProject[], employee: Partial<AccommodationEmployeeListItem> | null, accommodationId: number): Model => {
  return {
    project: workNumbers.find((x) => x.value === employee?.project?.id) ?? null,
    secondaryProject: workNumbers.find((x) => x.value === employee?.secondaryProject?.id) ?? null,
    movingOut: employee?.moveOutDate ?? null,
    accommodation: accommodations.find((x) => x.value === accommodationId) ?? null,
    movingIn: employee?.moveInDate ?? TODAY,
    contribution: employee?.monthlyContributions ?? null
  };
};

export const transformModelToIUpdateBookingDTO = (model: Model): IUpdateBookingDTO => {
  return {
    accommodationId: model.accommodation!.value,
    projectId: model.project!.value,
    monthlyContributions: model.contribution,
    moveInDate: model.movingIn.toISODate(),
    moveOutDate: model.movingOut ? model.movingOut.toISODate() : null,
    secondaryProjectId: model.secondaryProject?.value || null
  };
};
