import React, { useCallback, useReducer } from 'react';

/* Data Things */
import { IEmployeeDTO, ICreateEmployeeDTO, IUpdateEmployeeDTO, IEmployeeListDTO, IEmployeeAccommodationListItemDTO, IBookingDTO } from '../../typings/DTOs';
import { EmployeesState, employeesReducer, initialState } from '../../reducers/Employees';
import { Employee, EmployeeFilter } from './helper';
import { ApiErrorResult, api } from '../../utils/api';
import { useIsMounted } from '../../hooks';
import { Order } from '../../typings/common';

/* Presentation Things */
import { Snackbar } from '../../components/Snackbar';

interface IEmployeesContext extends EmployeesState {
  onChangeEmployeeAccommodation: (booking: IBookingDTO) => void;
  onEditEmployee: (employee: IUpdateEmployeeDTO, id: number) => Promise<string | null>;
  onAddEmployee: (employee: ICreateEmployeeDTO) => Promise<string | null>;
  onGetEmployees: (text?: string, signal?: AbortSignal, page?: number, filter?: Object) => Promise<string | null>;
  onGetEmployee: (id: number) => Promise<string | null>;
  onFilter: (filter: EmployeeFilter) => void;
}

const EmployeesContext = React.createContext<IEmployeesContext>({
  ...initialState,
  onChangeEmployeeAccommodation: () => null,
  onEditEmployee: () => Promise.reject(),
  onAddEmployee: () => Promise.reject(),
  onGetEmployees: () => Promise.reject(),
  onGetEmployee: () => Promise.reject(),
  onFilter: () => null
});

function EmployeesProvider(props: React.PropsWithChildren<{}>) {
  /* States */
  const [state, dispatch] = useReducer(employeesReducer, initialState);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetEmployees = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1, filter: Object = {}, sort: keyof Employee = 'name', order: Order = 'ASC'): Promise<string | null> => {
      try {
        const employees = await api<IEmployeeListDTO>('employee', { search, filter, sort, order, limit: 50, offset: (page - 1) * 50 }, { signal });

        if (!employees.message && _isMounted.current) {
          dispatch({ type: 'success_get_employees', payload: { employees, page, sort, order } });

          return Promise.resolve(null);
        } else throw new Error(employees.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_get_employees', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onGetEmployee = useCallback(
    async (id: number): Promise<string | null> => {
      try {
        const [employee, accommodations] = await Promise.all([api<IEmployeeDTO>(`employee/${id}`), api<IEmployeeAccommodationListItemDTO[]>(`employee/${id}/accommodations`)]);

        if (!employee.message && !accommodations.message && _isMounted.current) {
          dispatch({ type: 'success_get_employee', payload: { employee, accommodations } });

          return Promise.resolve(null);
        } else throw new Error(employee.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onEditEmployee = useCallback(
    async (body: IUpdateEmployeeDTO, id: number): Promise<string | null> => {
      try {
        const employee = await api<IEmployeeDTO>(`employee/${id}`, undefined, { method: 'PUT', body });

        if (!employee.message && _isMounted.current) {
          dispatch({ type: 'success_put_employee', payload: { employee } });

          return Promise.resolve(null);
        } else throw new Error(employee.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_put_employee', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAddEmployee = useCallback(
    async (body: ICreateEmployeeDTO): Promise<string | null> => {
      try {
        const employee = await api<IEmployeeDTO>('employee', undefined, { method: 'POST', body });

        if (!employee.message && _isMounted.current) {
          dispatch({ type: 'success_post_employee', payload: { employee } });
          return Promise.resolve(null);
        } else throw new Error(employee.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_post_employee', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onChangeEmployeeAccommodation = useCallback((booking: IBookingDTO) => dispatch({ type: 'set_employee_accommodtion', payload: { booking } }), []);

  const onAutoCloseResponse = useCallback(() => dispatch({ type: 'set_partial_state', payload: { response: null } }), []);

  const onFilter = useCallback((filter: EmployeeFilter) => dispatch({ type: 'set_filter', payload: { filter } }), []);

  return (
    <EmployeesContext.Provider value={{ ...state, onChangeEmployeeAccommodation, onEditEmployee, onAddEmployee, onGetEmployee, onGetEmployees, onFilter }}>
      <Snackbar opened={!!state.response} label={state.response!?.message} type={state.response!?.type} onAutoClose={onAutoCloseResponse} />
      {props.children}
    </EmployeesContext.Provider>
  );
}

export { EmployeesProvider, EmployeesContext };
