import React, { useCallback, useEffect, useReducer, useRef } from 'react';

/* Data Things */
import { ISelectableAccommodationDTO, ISelectableClientDTO, ISelectableHostDTO, ISelectableProjectDTO, ISelectableProHumanManagerDTO, ISelectableBRCManagerDTO } from '../../typings/DTOs';
import { ConfigState, configReducer, initialState } from '../../reducers/Config';
import { APIResponseFeedback } from '../../typings/common';
import { ApiErrorResult, api } from '../../utils/api';
import { useIsMounted } from '../../hooks';
import { useLocation } from '@reach/router';

/* Presentation Things */
import { Snackbar } from '../../components/Snackbar';

interface IConfigContext extends ConfigState {
  onFeedback(feedback: APIResponseFeedback): void;
}

const ConfigContext = React.createContext<IConfigContext>({ ...initialState, onFeedback: () => null });

const REFRESH_ROUTES = ['fooldal', 'bekoltoztetes', 'kikoltoztetes', 'szerkesztes', 'hozzaadas', 'munkavallalok', 'szallasok', 'projektek'];

function ConfigProvider(props: React.PropsWithChildren<{}>) {
  /* States */
  const [state, dispatch] = useReducer(configReducer, initialState);

  /* Variables */
  const abortController = useRef(new AbortController());

  /* Hooks */
  const _isMounted = useIsMounted();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (REFRESH_ROUTES.some((x) => location.pathname.includes(x))) {
        abortController.current.abort();
        abortController.current = new AbortController();

        try {
          const [proHumanManagers, brcManagers, clients, hosts, workNumbers, accommodations] = await Promise.all([
            api<ISelectableProHumanManagerDTO[]>('user/managers?isProHumanManager=true', undefined, { signal: abortController.current.signal }),
            api<ISelectableBRCManagerDTO[]>('user/managers?isProHumanManager=false', undefined, { signal: abortController.current.signal }),
            api<ISelectableClientDTO[]>('client/clients', undefined, { signal: abortController.current.signal }),
            api<ISelectableHostDTO[]>('host/hosts', undefined, { signal: abortController.current.signal }),
            api<ISelectableProjectDTO[]>('project/numbers', undefined, { signal: abortController.current.signal }),
            api<ISelectableAccommodationDTO[]>('accommodation/accommodations', undefined, { signal: abortController.current.signal })
          ]);

          if (!proHumanManagers.message && !brcManagers.message && !clients.message && !hosts.message && !workNumbers.message && _isMounted.current) {
            dispatch({ type: 'success_init', payload: { clients, proHumanManagers, brcManagers, hosts, workNumbers, accommodations } });
          }
        } catch (error) {
          if (_isMounted.current) {
            const { message } = error as ApiErrorResult;
            dispatch({ type: 'error_init', payload: { error: message } });
          }
        }
      }
    })();
  }, [_isMounted, location.pathname, abortController]);

  const onAutoCloseFeedback = useCallback(() => dispatch({ type: 'on_feedback', payload: { feedback: null } }), []);

  const onFeedback = useCallback((feedback: APIResponseFeedback) => dispatch({ type: 'on_feedback', payload: { feedback } }), []);

  return (
    <ConfigContext.Provider value={{ ...state, onFeedback }}>
      <Snackbar opened={!!state.feedback} label={state.feedback!?.message} type={state.feedback!?.type} onAutoClose={onAutoCloseFeedback} />
      {props.children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };
