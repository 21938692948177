import { IUserDTO } from "../../typings/DTOs";
import { AppRole } from "../../typings/enum";

export class User {
    public id: number;
    public role: AppRole;
    public name: string;
    public email: string;
    public phoneNumber: string;
    public isActive: boolean;

    constructor(p: IUserDTO) {
        this.id = p.id;
        this.role = p.role;
        this.name = p.name || "";
        this.email = p.email || "";
        this.phoneNumber = p.phoneNumber || "";
        this.isActive = p.isActive;
    }
}