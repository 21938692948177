import React, { useContext, useMemo, useState } from 'react';

/* Presentation Things */
import { Button } from '../../../components/Buttons';
import { Dialog } from '../../../components/Dialog';
import { Table } from '../../../components/Table';

/* Data Things */
import { ICreateEmployeeDTO, IEmployeeDTO } from '../../../typings/DTOs';
import { ApiErrorResult, api } from '../../../utils/api';
import { ConfigContext } from '../../../contexts/Config';
import { toDateString } from '../../../constants/functions';
import { useIsMounted } from '../../../hooks';
import { GENDER_MAP } from '../../../constants';
import { DateTime } from 'luxon';
import { Identification } from '../../../components/Identification';
import { TaxNumberCheckResult, taxNumberCheck } from '../../Employees/CreateOrEdit/helper';

/* Variables */
const header = ['Munkavállaló', 'Születétési idő', 'Nem', ''];

type MissingEmployeesFormProps = { onAddEmployees(employees: IEmployeeDTO[]): void; onClose(): void; isOpen: boolean; employees: ICreateEmployeeDTO[] };

export const MissingEmployeesForm = ({ employees, onClose, onAddEmployees, isOpen }: MissingEmployeesFormProps) => {
  /* State */
  const [isLoading, setIsLoading] = useState(false);

  /* Context */
  const { onFeedback } = useContext(ConfigContext);

  /* Hooks */
  const _isMounted = useIsMounted();

  const invalidEmloyees = useMemo(() => employees.map(x => ({...x, taxValidation: !!x.taxIdentificationNumber ? taxNumberCheck(x.taxIdentificationNumber, x.birthdate) : null}) ).filter((x) => x.taxValidation?.isValid === false || !x.sex || !x.birthdate || !x.name), [employees])

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const newEmployees = await api<IEmployeeDTO[]>('employee/mass', undefined, { method: 'POST', body: employees });
      
      if (_isMounted.current && !newEmployees.message) {
        onFeedback({ type: 'success', message: 'Sikeres hozzáadása' });
        onAddEmployees(newEmployees);
        onClose();
      } else throw new Error(newEmployees.message);
    } catch (error) {
      const { message } = error as ApiErrorResult;
      _isMounted.current && onFeedback({ type: 'error', message: String(message || error) });
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      contents={invalidEmloyees.length > 0 ? [
        {
          title: 'Hibás adószámok / Hiányzó adatok',
          body: <Table className="missing-employees-table" data={invalidEmloyees} header={header} onRowRender={{default: InvalidRow}} />,
          footer: <Button title="Bezárás" className="grow" onClick={onClose} />
        }
      ] : [
        {
          title: 'Hiányzó munkavállalók',
          body: <Table className="missing-employees-table" data={employees} header={header} onRowRender={TableRow} />,
          footer: <Button title="Hozzáadás" className="grow" onClick={onSubmit} loading={isLoading} disabled={isLoading} />
        }
      ]}
    />
  );
};

const Row = (employee: ICreateEmployeeDTO, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={employee.idCardNumber || employee.probondId || employee.taxIdentificationNumber || employee.taxIdentificationNumber || employee.passportNumber}>
    <div>
      <span>{employee.name}</span>

      <Identification
        mobile={false}
        idCardNumber={employee.idCardNumber || ''}
        probondId={employee.probondId || ''}
        taxIdentificationNumber={employee.taxIdentificationNumber || ''}
        passportNumber={employee.passportNumber || ''}
      />
    </div>

    <div>{toDateString(DateTime.fromISO(employee.birthdate))}</div>

    <div>{GENDER_MAP[employee.sex]}</div>
  </div>
);

const InvalidRow = (employee: ICreateEmployeeDTO & { taxValidation: TaxNumberCheckResult | null }, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={employee.idCardNumber || employee.probondId || employee.taxIdentificationNumber || employee.taxIdentificationNumber || employee.passportNumber}>
    <div>
    <span>{employee.name ? employee.name : "Nincs név megadva!"}</span>

      <Identification
        mobile={false}
        taxIdentificationNumber={employee.taxIdentificationNumber || ''}
      />
      {employee.taxValidation?.isValid === false && "Hibás adószám!"}
      {!employee.idCardNumber && !employee.passportNumber && !employee.probondId && !employee.taxIdentificationNumber && "Hiányzó azonosító adatok!"}
    </div>

    <div>
      {employee.birthdate ? toDateString(DateTime.fromISO(employee.birthdate)) : "Hiányzik"}
      {/* TOFIX: correctDate is off by a day */}
      {/* {employee.taxValidation?.correctBirthdate && 
        <>
          <br/>
          {`${toDateString(DateTime.fromJSDate(employee.taxValidation.correctBirthdate))}?`}
        </>
      } */}
    </div>

    <div>{employee.sex ? GENDER_MAP[employee.sex] : "Hiányzik"}</div>
  </div>
);

const TableRow = { default: Row };
