import { toPrettifiedNumber } from '../../constants/functions';
import { useAuthorization } from '../../hooks';
import { EyeIcon } from '@heroicons/react/outline';
import { AppRole } from '../../typings/enum';

type Props = {
  icon: JSX.Element;
  title: string;
  contents: { itemText: string | number; itemTitle?: string; textStyle?: string }[];
  titleClassName?: string;
  dashboard?: boolean;
  hiddenFor?: keyof typeof AppRole | (keyof typeof AppRole)[];
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Widget = ({ icon, title, contents, titleClassName, className, hiddenFor, dashboard = false, ...rest }: Props) => {
  /* Hooks */
  const { role } = useAuthorization();

  if (hiddenFor && (Array.isArray(hiddenFor) ? hiddenFor.includes(role) : role === hiddenFor)) return null;

  return dashboard ? (
    <div
      className={`relative flex flex-col flex-1 items-center text-center justify-center bg-primary/5 p-2 xs:p-4 gap-2 xs:gap-4 shadow-md rounded-lg transform-gpu transition-[transform_background] duration-300 ease-in-out outline-0 ${
        rest.onClick ? 'cursor-pointer xs:hover:bg-primary/10 xs:hover:shadow-xl xs:hover:scale-105' : ''
      } ${className}`}
      {...rest}>
      {contents.length === 1 && <div className="text-primary-lighter rounded-md flex items-center justify-center">{icon}</div>}

      {rest.onClick && <EyeIcon className="icon-md absolute top-1 left-1 opacity-20" />}

      <div className="h-full w-full flex justify-evenly">
        {contents.length > 1 && (
          <div className="h-full flex flex-col justify-evenly items-center">
            <div className="text-primary-lighter rounded-md flex items-center justify-center">{icon}</div>
            <p className={`text-sm font-medium text-gray-500 ${titleClassName}`}>{title}</p>
          </div>
        )}

        {contents.length === 1 && contents[0] ? (
          <p className={`text-xl xs:text-2xl xsl:text-3xl xl:text-3xl font-semibold ${contents[0].textStyle || 'text-gray-900'}`}>
            {typeof contents[0].itemText === 'number' ? toPrettifiedNumber(contents[0].itemText) : contents[0].itemText}
          </p>
        ) : (
          <div className="flex justify-between items-center gap-4">
            {contents.map((content, i, array) => {
              const halfOfArray = array.length / 2;
              const isMiddle = halfOfArray % 2 !== 0 && i + 1 === Math.round(halfOfArray);
              return (
                <div key={i} className={`font-medium flex flex-col text-base sm:text-xl ${isMiddle ? 'text-base xs:text-3xl mx-1 xs:mx-2 gap-1' : ''} ${content.textStyle || 'text-gray-900'}`}>
                  {typeof content.itemText === 'number' ? toPrettifiedNumber(content.itemText) : content.itemText}

                  <span className="text-sm text-gray-500">{content.itemTitle}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {contents.length === 1 && <p className={`text-sm font-medium text-gray-500 ${titleClassName}`}>{title}</p>}
    </div>
  ) : (
    <div className={`relative -top-1 flex flex-row flex-1 items-start bg-white px-5 py-3 shadow rounded-lg ${className}`} {...rest}>
      <div className="bg-primary-lighter self-center rounded-md flex items-center justify-center p-2 xl:p-2.5 2xl:p-3">{icon}</div>

      <div className="h-full ml-5 flex flex-col justify-evenly">
        <p className={`text-sm xl:text-xs 2xl:text-sm font-medium text-gray-500 ${titleClassName}`}>{title}</p>

        {contents.length === 1 && contents[0] ? (
          <p className={`xl:text-xl 2xl:text-2xl font-semibold text-gray-900 ${contents[0].textStyle}`}>{typeof contents[0].itemText === 'number' ? toPrettifiedNumber(contents[0].itemText) : contents[0].itemText}</p>
        ) : (
          <div className="flex flex-row justify-between space-x-3">
            {contents.map((content, i) => (
              <div key={i} className={`text-gray-900 font-medium flex flex-col xl:text-sm 2xl:text-md xl:leading-3 2xl:leading-4 xl:w-16 ${content.textStyle}`}>
                {typeof content.itemText === 'number' ? toPrettifiedNumber(content.itemText) : content.itemText}

                <span className="-text-2xs text-gray-500 mt-px">{content.itemTitle}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
