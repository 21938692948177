import { IAccommodationStatisticsListItemDTO } from "../../../typings/DTOs";
import { basicSort, downloadFile } from "../../../constants/functions";
import { unparse } from 'papaparse'
import { Sort } from "../../../typings/common";

export class AccommodationStatistics {
    public host: {
        id: number;
        name: string;
    };
    public accommodation: {
        id: number;
        address: string;
    };
    public nights: number;
    public incomes: number;
    public expenses: number;
    public roi: number;
    public contributions: number | null;

    constructor(p: IAccommodationStatisticsListItemDTO) {
        this.host = {
            id: p.host.id,
            name: p.host.name || ""
        };
        this.accommodation = {
            id: p.accommodation.id,
            address: p.accommodation.address || ""
        };
        this.nights = p.nights;
        this.incomes = p.incomes;
        this.expenses = p.expenses;
        this.roi = p.roi
        this.contributions = p.contributions;
    }
}

export const getVisibleStatistics = (searchValue: string, accommodations: AccommodationStatistics[], sort: Sort<AccommodationStatistics>): AccommodationStatistics[] => {
    const search = searchValue.toLowerCase();
    return accommodations.filter(x => {
        if (x.accommodation.address.toLowerCase().includes(search) || x.host.name.toLowerCase().includes(search)) {
            return true;
        }
        return false
    }).sort((x, y) => basicSort(x, y, sort))
}

export const generateCSV = (accommodations: AccommodationStatistics[], title: string) => {
    const data = accommodations.reduce((acc, curr) => {
        return [...acc, ([curr.host.name, curr.accommodation.address, curr.nights, curr.expenses, curr.incomes, curr.contributions])]
    }, [] as unknown[])

    downloadFile(`${title}.csv`, unparse({ data, fields: ["Szállás partner", "Szállás", "Éjszakák", "Havi költség", "Havi bevétel", "Hozzájárulás"] }))
}