import React, { useContext, useEffect, useRef } from 'react';

/* Presentation Things */
import { PlusIcon, FilterIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../../components/Buttons';
import { Table, TableRef } from '../../../../components/Table';
import { Accordion } from '../../../../components/Accordion';
import { Filter } from './Filter';
import { Badge } from '../../../../components/Badge';

/* Data Things */
import { ClientsContext } from '../../../../contexts/Partners/Clients';
import { SMALL_SCREEN } from '../../../../constants';
import { IHeaderCell } from '../../../../components/Table/helper';
import { Client } from '../../../../contexts/Partners/Clients/helper';

// Variables
const header: Array<IHeaderCell<Client> | string> = [{ text: 'Ügyfél', sortName: 'name' }, 'Adószám', { text: 'Aktív?', sortName: 'isActive' }];

const ClientsList = (isShow: boolean) => {
  /* Context */
  const { clients, onGetClients } = useContext(ClientsContext);

  /* Refs */
  const _table = useRef<TableRef>(null);

  const onOpenFilter = () => _table.current?.onToogleFilter();

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => {
    if (clients.items.length < 2 && clients.page === 1) {
      onGetClients();
    }

    // eslint-disable-next-line
  }, []);

  if (!isShow) return null;

  return (
    <>
      <div className="table-header gap-x-2 flex-row justify-end items-center">
        <Button to="./ugyfel/hozzaadas" title="Új ügyfél" hiddenFor={['Reader', 'BRCManager']} className="hidden twsm:block" />

        <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} to="./ugyfel/hozzaadas" hiddenFor={['Reader', 'BRCManager']} theme="primary" className="twsm:hidden" />

        <IconButton icon={<FilterIcon className="icon-xs " />} className="twsm:hidden" theme="secondary" onClick={onOpenFilter} disabled={!clients.items.length} />

        <IconButton icon={<SortAscendingIcon className="icon-xs" />} className="twsm:hidden" theme="secondary" onClick={onOpenSort} disabled={!clients.items.length} />
      </div>

      <Table
        ref={_table}
        header={header}
        className="partners-client-list-table"
        maxDataCount={clients.totalLength}
        loading={clients.isLoading}
        filter={clients.filter}
        error={clients.error}
        order={clients.order}
        data={clients.items}
        page={clients.page}
        sort={clients.sort}
        onChangePage={onGetClients}
        onFilter={onGetClients}
        onSearch={onGetClients}
        onSort={onGetClients}
        onFilterRender={Filter}
        onRowRender={TableRow}
      />
    </>
  );
};

const Row = (client: Client, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={client.id}>
    <div>
      <span title={client.name}>{client.name}</span>
    </div>

    <div>
      <span title={client.taxNumber}>{client.taxNumber}</span>
    </div>

    <div>
      <Badge label={client.isActive ? 'Aktív' : 'Inaktív'} className={client.isActive ? 'badge--green' : 'badge--gray'} />
    </div>

    <div className="action-2">
      <IconButton icon={<EyeIcon className="icon-sm" />} to={`./ugyfel/${client.id}`} />

      <IconButton icon={<PencilIcon className="icon-sm" />} to={`./ugyfel/szerkesztes/${client.id}`} hiddenFor={['Reader', 'BRCManager']} />
    </div>
  </div>
);

const MobileRow = (client: Client) => (
  <Accordion
    key={client.id}
    className="card"
    isCard
    isBorderBottom
    label={
      <div className="card-header flex-row items-center justify-between">
        <div className="flex-col items-start">
          <span className="card-header__primary-text">{client.name}</span>
          <span className="card-header__secondary-text">{client.taxNumber}</span>
        </div>

        <Badge label={client.isActive ? 'Aktív' : 'Inaktív'} className={client.isActive ? 'badge--green' : 'badge--gray'} />
      </div>
    }>
    <div className="data border-t-0">
      <div className="data-item data-item--action">
        <span className="data-item__data ml-auto gap-x-4">
          <IconButton icon={<EyeIcon className="icon-sm" />} to={`./ugyfel/${client.id}`} />

          <IconButton icon={<PencilIcon className="icon-sm" />} to={`./ugyfel/szerkesztes/${client.id}`} hiddenFor={['Reader', 'BRCManager']} />
        </span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: SMALL_SCREEN };

export default ClientsList;
