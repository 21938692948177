import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { Select, SelectHandle } from '../../components/Select';
import { Button, IconButton } from '../../components/Buttons';
import { MoveOutForm } from './Form';
import { UploadIcon } from '@heroicons/react/solid';
import { Accordion } from '../../components/Accordion';
import { TrashIcon } from '@heroicons/react/outline';
import { Checkbox } from '../../components/Inputs';
import { PlusIcon } from '@heroicons/react/solid';
import { Snackbar } from '../../components/Snackbar';
import { Filter } from './Filter';
import { Table } from '../../components/Table';
import { Identification } from '../../components/Identification';

// Data Things
import { MoveOutEmployeesFilter, SelectableEmployee, EmployeeMovingOut, checkIsNotSaveable, getVisibleEmployees, transformEmployeeMovingOutToICreateMoveOutDTO, processCSVfile } from './helper';
import { ApiErrorResult, api } from '../../utils/api';
import { IBookedEmployeeDTO } from '../../typings/DTOs';
import { ConfigContext } from '../../contexts/Config';
import { XLARGE_SCREEN } from '../../constants';
import { toDateString } from '../../constants/functions';
import { useIsMounted } from '../../hooks';
import { Order, Sort } from '../../typings/common';
import { DateTime } from 'luxon';

/* Template */ //@ts-ignore
import CSVtemplate from '../../assets/files/moveouttemplate.csv';

type RowProps = { employee: EmployeeMovingOut; isChecked: boolean; gridTemplate?: React.CSSProperties; onToogleEmployee(id: number): void; onDeleteEmployee(id: number): void };

export default function MoveOut(props: RouteComponentProps) {
  /* States */
  const [selectableEmployees, setSelectableEmployees] = useState<SelectableEmployee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeMovingOut[]>([]);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState<{ [id: number]: boolean }>({});
  const [filter, setFilter] = useState<MoveOutEmployeesFilter>({
    moveInFrom: null,
    moveInTo: null,
    moveOutFrom: null,
    moveOutTo: null
  });
  const [sort, setSort] = useState<Sort<EmployeeMovingOut>>({ sort: 'name', order: 'ASC' });

  /* Context */
  const { onFeedback } = useContext(ConfigContext);

  /* Variables */
  const editableEmployeesCount = useMemo(() => Object.keys(editable).length, [editable]);
  const visibleEmployees = useMemo(() => getVisibleEmployees(selectedEmployees, searchValue, filter, sort), [selectedEmployees, searchValue, filter, sort]);
  const isNotSaveable = useMemo(() => checkIsNotSaveable(selectedEmployees), [selectedEmployees]);

  /* Refs */
  const _employeeSelect = useRef<SelectHandle>(null);
  const _isMounted = useIsMounted();

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await api('booking/moveout', undefined, { method: 'PUT', body: selectedEmployees.map((x) => transformEmployeeMovingOutToICreateMoveOutDTO(x)) });
      if (_isMounted.current && props.navigate) {
        props.navigate('/munkavallalok');
        onFeedback({ type: 'success', message: 'Sikeres kiköltöztetés' });
      }
    } catch (error) {
      if (_isMounted.current) {
        const { message } = error as ApiErrorResult;
        onFeedback({ message, type: 'error' });
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onToogleEmployee = (id: number) => {
    setEditable((prev) => {
      if (prev[id]) {
        const tmp = { ...prev };
        delete tmp[id];
        return tmp;
      } else {
        return { ...prev, [id]: true };
      }
    });
  };

  const onDeleteEmployee = (id: number) => {
    setSelectedEmployees((prev) => prev.filter((x) => x.id !== id));
    setEditable((prev) => {
      const tmp = { ...prev };
      delete tmp[id];
      return tmp;
    });
  };

  const onToogleEmployees = () => {
    if (selectedEmployees.length === editableEmployeesCount) {
      setEditable({});
    } else {
      setEditable(() => visibleEmployees.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
    }
  };

  const onChangeEmployees = (movingOut: DateTime) => {
    setSelectedEmployees((employees) => employees.map((emp) => (editable[emp.id] ? { ...emp, movingOut } : emp)));
    setEditable({});
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];

      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        if (typeof event.target?.result === 'string') {
          const cardNumbers = processCSVfile(event.target?.result);

          if (cardNumbers.length > 0) {
            const existEmployees = selectableEmployees.filter((x) => cardNumbers.some((y) => y === x.idCardNumber || y === x.passportNumber || y === x.taxIdentificationNumber || y === x.probondId));
            const transformedExistEmployes = existEmployees.map((x) => new EmployeeMovingOut(x));

            if (!transformedExistEmployes.length) {
              onFeedback({ message: 'Nincs ilyen személy a rendszerben', type: 'error' });
            } else {
              setSelectedEmployees((prev) => [...prev, ...transformedExistEmployes].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i));
              setEditable((prev) => transformedExistEmployes.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), prev));
            }
          }
        }
      };

      fileReader.readAsText(file);
    }
  };

  const _rowRenderer = (employee: EmployeeMovingOut, gridTemplate: React.CSSProperties) => {
    return <Row key={employee.id} employee={employee} isChecked={!!editable[employee.id]} gridTemplate={gridTemplate} onToogleEmployee={onToogleEmployee} onDeleteEmployee={onDeleteEmployee} />;
  };

  const _mobileRowRenderer = (employee: EmployeeMovingOut) => (
    <MobileRow key={employee.id} employee={employee} isChecked={!!editable[employee.id]} onToogleEmployee={onToogleEmployee} onDeleteEmployee={onDeleteEmployee} />
  );

  const onSelectEmployees = (options: SelectableEmployee[]) => {
    setSelectedEmployees((prev) => {
      options = options.filter((x) => !prev.some((y) => x.value === y.id));
      return [...prev, ...options.map((x) => new EmployeeMovingOut(x))];
    });

    setEditable((prev) => options.reduce((acc, curr) => ({ ...acc, [curr.value]: true }), prev));
  };
  const onOpenEmployeesSelect = () => _employeeSelect?.current?.open();

  const onCloseDialog = () => setIsShowDialog(false);

  const onOpenDialog = () => setIsShowDialog(true);

  const onChangeFilter = useCallback((filter: MoveOutEmployeesFilter) => setFilter(filter), []);

  const onSort = (_: any, __: any, ___: any, ____: any, sort: keyof EmployeeMovingOut = 'name', order: Order = 'ASC') => setSort({ sort, order });

  useEffect(() => {
    (async () => {
      try {
        const resp = await api<IBookedEmployeeDTO[]>('employee/booked');
        if (!resp.message && _isMounted.current) {
          setSelectableEmployees(resp.map((x) => new SelectableEmployee(x)));
        } else throw new Error(resp.message);
      } catch (error) {}
    })();
  }, [_isMounted]);

  return (
    <div className="page-container max-w-8xl">
      <MoveOutForm onClose={onCloseDialog} onSubmit={onChangeEmployees} isOpen={isShowDialog} />

      <div className="flex flex-row pt-4 pb-8 sm:py-8 justify-between sm:items-center">
        <h1 className="main-title pt-0">Kiköltöztetés</h1>

        <div className="flex space-x-4 items-center">
          <label htmlFor="upload">
            <span className="icon-button button--secondary">
              <UploadIcon className="icon-xs" />
            </span>
            <input type="file" id="upload" hidden onChange={onFileChange} />
          </label>

          <Select title="Munkavállalók" options={selectableEmployees} dialog="multi" selected={selectedEmployees} onSelect={onSelectEmployees} ref={_employeeSelect}>
            <Button title="Munkavállalók kiválasztása" className="rounded-md hidden twsm:block" onClick={onOpenEmployeesSelect} disabled={!selectableEmployees.length} />

            <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} theme="primary" className="rounded-md twsm:hidden" onClick={onOpenEmployeesSelect} disabled={!selectableEmployees.length} />
          </Select>
        </div>
      </div>

      <div className="table-header top-[118px] pt-2 pb-8">
        <div className="flex flex-col space-y-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Kiválasztott munkavállalók</h3>

          <a className="text-sm underline text-gray-600" download="sablon.csv" href={CSVtemplate}>
            Sablon letöltése
          </a>
        </div>

        <Button title="Kiköltöztetés" className="ml-auto mb-2" disabled={selectedEmployees.length === 0 || isNotSaveable || isLoading} onClick={onSubmit} loading={isLoading} />
      </div>

      <div className="overflow-x-auto">
        <div className="riports-table-container">
          <Table
            className="moving-out-table"
            data={visibleEmployees}
            filter={filter}
            order={sort.order}
            sort={sort.sort}
            header={[
              visibleEmployees.length > 0 ? <Checkbox checked={editableEmployeesCount === visibleEmployees.length} onChange={onToogleEmployees} /> : '',
              { text: 'Munkavállaló', sortName: 'name' },
              { text: 'Szálláshely', sortName: 'accommodation' },
              { text: 'Beköltözés', sortName: 'movingIn' },
              { text: 'Kiköltözés', sortName: 'movingOut' },
              'Munkaszám'
            ]}
            onSearch={setSearchValue}
            onSort={onSort}
            onFilterRender={(open, onClose, onSubmit) => <Filter filter={filter} open={open} onClose={onClose} onSubmit={onSubmit} onChangeFilter={onChangeFilter} />}
            onRowRender={{ default: _rowRenderer, mobile: _mobileRowRenderer, breakpoint: XLARGE_SCREEN }}
            error={searchValue && !visibleEmployees.length ? 'A keresett kifejezésre nincs találat' : !selectedEmployees.length ? 'Válassz ki munkavállalókat' : ''}
          />
        </div>
      </div>

      <Snackbar opened={editableEmployeesCount > 0} label={`${editableEmployeesCount} munkavállaló van kiválasztva`} type="info" actionButton={{ title: 'Szerkesztés', onClick: onOpenDialog }} />
    </div>
  );
}

const Row = ({ employee, isChecked, gridTemplate, onToogleEmployee, onDeleteEmployee }: RowProps) => {
  const handleDeleteEmployee = () => onDeleteEmployee(employee.id);

  const handleToogleEmployee = () => onToogleEmployee(employee.id);

  return (
    <>
      <div className="data-table__body-row" style={gridTemplate} key={employee.id}>
        <div className="checkbox-cell">
          <Checkbox className="p-0" id={String(employee.id)} checked={isChecked} onChange={handleToogleEmployee} />
        </div>

        <label htmlFor={String(employee.id)}>
          <span>{employee.name}</span>

          <span className="text-gray-500">
            <Identification mobile={false} idCardNumber={employee.idCardNumber} probondId={employee.probondId} taxIdentificationNumber={employee.taxIdentificationNumber} passportNumber={employee.passportNumber} />
          </span>
        </label>

        <div>
          <span title={employee.accommodation.address}>{employee.accommodation.address}</span>
        </div>

        <div>{toDateString(employee.movingIn)}</div>

        <div>{toDateString(employee.movingOut)}</div>

        <div>{employee.project.workNumber ?? '-'}</div>

        <div className="action-1">
          <TrashIcon className="icon-sm cursor-pointer" aria-hidden="true" onClick={handleDeleteEmployee} />
        </div>
      </div>
    </>
  );
};

const MobileRow = ({ employee, isChecked, onToogleEmployee, onDeleteEmployee }: RowProps) => {
  const handleDeleteEmployee = () => onDeleteEmployee(employee.id);

  const handleToogleEmployee = () => onToogleEmployee(employee.id);

  return (
    <Accordion
      className="card"
      summaryClassName="h-auto"
      isCard
      isBorderBottom
      label={
        <div className="flex items-center w-full">
          <label className="flex items-center gap-x-4">
            <Checkbox className="p-0" id={String(employee.id)} checked={isChecked} onChange={handleToogleEmployee} />

            <div className="text-sm flex flex-col">
              <span className="font-medium text-gray-700">{employee.name}</span>

              <span className="text-gray-500">
                <Identification mobile={true} idCardNumber={employee.idCardNumber} probondId={employee.probondId} taxIdentificationNumber={employee.taxIdentificationNumber} passportNumber={employee.passportNumber} />
              </span>
            </div>
          </label>

          <TrashIcon className="icon-sm ml-auto" aria-hidden="true" onClick={handleDeleteEmployee} />
        </div>
      }>
      <div className="data">
        <div className="data-item">
          <span className="data-item__title">Szálláshely</span>

          <span className="flex items-center text-gray-800 text-right">{employee.accommodation.address ?? '-'}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Beköltözés</span>

          <span className="flex items-center text-gray-800">{toDateString(employee.movingIn)}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Kiköltözés</span>

          <span className="flex items-center text-gray-800">{toDateString(employee.movingOut)}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Munkaszám</span>

          <span className="flex items-center text-gray-800">{employee.project.workNumber ?? '-'}</span>
        </div>
      </div>
    </Accordion>
  );
};
