import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Navigation things
import { Link, NavigateFn } from '@reach/router';

//Screens
import EditMoveIn from '../../../MoveIn/Edit';

// Presentation things
import { ChartPieIcon, FilterIcon, OfficeBuildingIcon, PlusIcon, SortAscendingIcon, TrendingDownIcon, TrendingUpIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/outline';
import { Button, IconButton } from '../../../../components/Buttons';
import { MonthlyScroller } from '../../../../components/MonthlyScroller';
import { Table, TableRef } from '../../../../components/Table';
import { PencilIcon } from '@heroicons/react/solid';
import { Accordion } from '../../../../components/Accordion';
import { Hideable } from '../../../../components/Hideable';
import { Widget } from '../../../../components/Widgets';
import { Filter } from './Filter';
import { Identification } from '../../../../components/Identification';

// Data Things
import { getProfitOrDeficitColor, toCurrencyString, toDateString, toPrettifiedNumber } from '../../../../constants/functions';
import { IAccommodationEmployeeListDTO, IBookingDTO, IMonthlyAccommodationWidgetsDTO } from '../../../../typings/DTOs';
import { AccommodationEmployeeListItem, AccommodationEmployeesFilter, INITIAL_LIST } from './helper';
import { ApiErrorResult, api } from '../../../../utils/api';
import { MEDIUM_SCREEN } from '../../../../constants';
import { useIsMounted } from '../../../../hooks';
import { IHeaderCell } from '../../../../components/Table/helper';
import { DateTime } from 'luxon';
import { Order } from '../../../../typings/common';

// Variables
const header: Array<IHeaderCell<AccommodationEmployeeListItem> | string> = [
  { text: 'Munkavállaló', sortName: 'employee' },
  { text: 'Beköltözés', sortName: 'moveInDate' },
  { text: 'Kiköltözés', sortName: 'moveOutDate' },
  'Munkaszám'
];
interface IProps {
  widgets: IMonthlyAccommodationWidgetsDTO | null;
  navigate: NavigateFn;
  hotelId: number;
  year: number;
  month: number;
  show: boolean;
}

type RowProps = { row: AccommodationEmployeeListItem; gridTemplate?: React.CSSProperties; onOpenEdit(emp: AccommodationEmployeeListItem): void };

export const MonthlyOverview = ({ navigate, year, month, hotelId, widgets, show }: IProps) => {
  /* States */
  const [editableEmployee, setEditableEmployee] = useState<AccommodationEmployeeListItem | null>(null);
  const [employees, setEmployees] = useState(INITIAL_LIST);

  /* Variables */
  const selectedMonth = useMemo(() => DateTime.now().set({ year, month }), [year, month]);

  /* Refs */
  const abortController = useRef(new AbortController());
  const _table = useRef<TableRef>(null);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetEmployees = useCallback(
    async (search: string = '', _?: AbortSignal, page: number = 1, filter: any = {}, sort: keyof AccommodationEmployeeListItem = 'employee', order: Order = 'ASC') => {
      abortController.current.abort();
      abortController.current = new AbortController();

      try {
        setEmployees((p) => ({ ...p, isLoading: true, sort, order, page }));

        const resp = await api<IAccommodationEmployeeListDTO>(`accommodation/${hotelId}/${year}/${month}`, { search, filter, sort, order, limit: 30, offset: (page - 1) * 30 }, { signal: abortController.current.signal });

        if (!resp.message && _isMounted.current) {
          if (!resp.items.length) throw new Error('Nincs elszállásolt munkavállaló');

          setEmployees((p) => ({
            ...p,
            items: resp.items.map((x) => new AccommodationEmployeeListItem(x)),
            totalLength: resp.totalLength,
            offset: resp.offset,
            isLoading: false,
            error: ''
          }));

          return Promise.resolve(null);
        } else throw new Error(resp.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && setEmployees((p) => ({ ...p, items: [], totalLength: 0, offset: 0, isLoading: false, error: String(message || error) }));
        return Promise.reject(message);
      }
    },
    [hotelId, year, month, _isMounted, abortController]
  );

  const onChangeBooking = useCallback(
    (booking: IBookingDTO) => {
      setEmployees((prev) => {
        const isDelete = Number(hotelId) !== booking.accommodation.id; //TODO CHECK START+END DATE TOO

        if (isDelete) {
          return {
            ...prev,
            items: prev.items.filter((x) => x.id !== booking.id),
            totalLength: prev.totalLength - 1
          };
        } else {
          return {
            ...prev,
            items: prev.items.map((x) => (x.id === booking.id ? new AccommodationEmployeeListItem(booking) : x))
          };
        }
      });
    },
    [hotelId]
  );

  const onPreviousMonth = () => {
    const { year, month } = selectedMonth.minus({ month: 1 });
    navigate(`/szallasok/${hotelId}/${year}/${month}`, { replace: true });
  };

  const onNextMonth = () => {
    const { year, month } = selectedMonth.plus({ month: 1 });
    navigate(`/szallasok/${hotelId}/${year}/${month}`, { replace: true });
  };

  const onChangeFilter = useCallback((filter: AccommodationEmployeesFilter) => setEmployees((p) => ({ ...p, filter })), []);

  const navigateToMoveIn = () => navigate('/bekoltoztetes', { state: { hotelId } });

  const onOpenEdit = (employee: AccommodationEmployeeListItem) => setEditableEmployee(employee);

  const onOpenFilter = () => _table.current?.onToogleFilter();

  const onCloseEdit = () => setEditableEmployee(null);

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => void onGetEmployees(), [onGetEmployees]);

  if (show) return null;

  return (
    <>
      <EditMoveIn open={editableEmployee !== null} onClose={onCloseEdit} onSave={onChangeBooking} employee={editableEmployee} accommodationId={Number(hotelId)} />

      <MonthlyScroller className="justify-center bg-white pb-5" date={selectedMonth} onPreviousMonth={onPreviousMonth} onNextMonth={onNextMonth} />

      <div className="bg-white w-full grid gap-4 grid-cols-1 xsl:grid-cols-6 xl1:flex">
        {widgets?.nights !== null ? (
          <Widget icon={<OfficeBuildingIcon className="widget-container__icon-wrap-icon" />} title="Vendég éjszakák" contents={[{ itemText: widgets?.nights ?? '-' }]} className="col-span-2" />
        ) : (
          <Widget icon={<UserGroupIcon className="widget-container__icon-wrap-icon" />} title="Szabad kapacitás" contents={[{ itemText: widgets?.available ?? '-' }]} className="col-span-2" />
        )}

        <Widget icon={<UsersIcon className="widget-container__icon-wrap-icon" />} title="Munkavállalók szálláson" contents={[{ itemText: widgets?.bookedEmployees ?? '-' }]} className="col-span-2" />

        <Widget
          icon={<ChartPieIcon className="widget-container__icon-wrap-icon" />}
          title="ROI"
          contents={[{ itemText: widgets?.roi ? toPrettifiedNumber(widgets.roi) + '%' : '-', textStyle: getProfitOrDeficitColor(widgets?.roi) }]}
          className="col-span-2"
          hiddenFor="ProHumanManager"
        />

        <Widget
          icon={<TrendingDownIcon className="widget-container__icon-wrap-icon" />}
          title="Kiadás"
          contents={[{ itemText: widgets?.expenses ? toCurrencyString(widgets.expenses) : '-' }]}
          hiddenFor="ProHumanManager"
          className="col-span-3"
        />

        <Widget
          icon={<TrendingUpIcon className="widget-container__icon-wrap-icon" />}
          title="Bevétel"
          contents={[{ itemText: widgets?.incomes ? toCurrencyString(widgets.incomes) : '-' }]}
          hiddenFor="ProHumanManager"
          className="col-span-3"
        />
      </div>

      <div className="table-header flex-row items-center">
        <h3 className="table-header__subtitle">Elszállásolt munkavállalók</h3>

        <div className="flex gap-x-2">
          <IconButton onClick={navigateToMoveIn} icon={<PlusIcon className="icon-xs icon-without-hover" />} hiddenFor="Reader" className="twsm:hidden" />

          <IconButton icon={<FilterIcon className="icon-xs" />} className="twmd:hidden" theme="secondary" onClick={onOpenFilter} disabled={!employees.items.length} />

          <IconButton icon={<SortAscendingIcon className="icon-xs" />} className="twmd:hidden" theme="secondary" onClick={onOpenSort} disabled={!employees.items.length} />

          <Button onClick={navigateToMoveIn} title="Új beköltözés" hiddenFor="Reader" className="rounded-md hidden twsm:block" />
        </div>
      </div>

      <Table
        ref={_table}
        className="hotels-monthly-overview-table"
        maxDataCount={employees.totalLength}
        loading={employees.isLoading}
        filter={employees.filter}
        error={employees.error}
        order={employees.order}
        data={employees.items}
        sort={employees.sort}
        page={employees.page}
        header={header}
        onChangePage={onGetEmployees}
        onSearch={onGetEmployees}
        onFilter={onGetEmployees}
        onSort={onGetEmployees}
        onFilterRender={(open, onClose, onSubmit) => <Filter filter={employees.filter} open={open} onClose={onClose} onSubmit={onSubmit} onChangeFilter={onChangeFilter} />}
        onRowRender={{
          default: (row, gridTemplate) => <Row key={row.id} row={row} gridTemplate={gridTemplate} onOpenEdit={onOpenEdit} />,
          mobile: (row) => <MobileRow key={row.id} row={row} onOpenEdit={onOpenEdit} />,
          breakpoint: MEDIUM_SCREEN
        }}
      />
    </>
  );
};

const Row = ({ row, gridTemplate, onOpenEdit }: RowProps) => {
  const handleOpenEdit = () => onOpenEdit(row);

  return (
    <div className="data-table__body-row" style={gridTemplate}>
      <div>
        <Link title={row.employee.name} to={`/munkavallalok/${row.employee.id}`} className="hot-link w-full">
          {row.employee.name}
        </Link>

        <Identification
          mobile={false}
          idCardNumber={row.employee.idCardNumber}
          probondId={row.employee.probondId}
          taxIdentificationNumber={row.employee.taxIdentificationNumber}
          passportNumber={row.employee.passportNumber}
        />
      </div>

      <div>{toDateString(row.moveInDate)}</div>

      <div>{toDateString(row.moveOutDate)}</div>

      <div>
        {row.project ? (
          <>
            <Link to={`/projektek/${row.project.id}`} className="hot-link w-full">
              {row.project.workNumber}
            </Link>
            {row.secondaryProject && `(${row.secondaryProject.workNumber})`}
          </>
        ) : (
          '-'
        )}
      </div>

      <IconButton icon={<PencilIcon className="icon-sm" />} hiddenFor="Reader" className="action-1" onClick={handleOpenEdit} />
    </div>
  );
};

const MobileRow = ({ row, onOpenEdit }: RowProps) => {
  const handleOpenEdit = () => onOpenEdit(row);

  return (
    <Accordion
      className="card"
      isCard
      isBorderBottom
      summaryClassName="pt-0"
      label={
        <div className="card-header">
          <Link title={row.employee.name} to={`/munkavallalok/${row.employee.id}`} className="card-header__primary-text hot-link">
            {row.employee.name}
          </Link>

          <div className="card-header__secondary-text">
            <Identification
              mobile={true}
              idCardNumber={row.employee.idCardNumber}
              probondId={row.employee.probondId}
              taxIdentificationNumber={row.employee.taxIdentificationNumber}
              passportNumber={row.employee.passportNumber}
            />
          </div>
        </div>
      }>
      <div className="data">
        <div className="data-item">
          <span className="data-item__title">Beköltözés</span>

          <span className="data-item__data">{toDateString(row.moveInDate)}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Kiköltözés</span>

          <span className="data-item__data">{toDateString(row.moveOutDate)}</span>
        </div>

        <div className="data-item">
          <span className="data-item__title">Munkaszám</span>

          {row.project ? (
            <Link to={`/projektek/${row.project.id}`} className="hot-link">
              {row.project.workNumber}
            </Link>
          ) : (
            '-'
          )}
        </div>

        <div className="data-item">
          <span className="data-item__title">Másodlagos munkaszám</span>

          {row.secondaryProject ? (
            <Link to={`/projektek/${row.secondaryProject.id}`} className="hot-link">
              {row.secondaryProject.workNumber}
            </Link>
          ) : (
            '-'
          )}
        </div>

        <Hideable.Div className="data-item data-item--action justify-end" hiddenFor="Reader">
          <IconButton icon={<PencilIcon className="icon-sm" />} onClick={handleOpenEdit} />
        </Hideable.Div>
      </div>
    </Accordion>
  );
};
