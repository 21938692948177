import React, { useContext, useEffect, useRef } from 'react';

// Navigation things
import { Link, RouteComponentProps } from '@reach/router';

// Presentation things
import { FilterIcon, PlusIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../components/Buttons';
import { Table, TableRef } from '../../../components/Table';
import { Accordion } from '../../../components/Accordion';
import { Filter } from './Filter';
import { Identification } from '../../../components/Identification';

// Data Things
import { GENDER_MAP, LARGE_SCREEN } from '../../../constants';
import { EmployeesContext } from '../../../contexts/Employees';
import { toDateString } from '../../../constants/functions';
import { IHeaderCell } from '../../../components/Table/helper';
import { Employee } from '../../../contexts/Employees/helper';

const header: Array<IHeaderCell<Employee> | string> = [{ text: 'Munkavállaló', sortName: 'name' }, { text: 'Születési idő', sortName: 'birthdate' }, { text: 'Nem', sortName: 'gender' }, 'Munkaszám', 'Szálláshely'];

export default function List(_: RouteComponentProps) {
  /* Context */
  const { employees, onGetEmployees } = useContext(EmployeesContext);

  /* Refs */
  const _table = useRef<TableRef>(null);

  const onOpenFilter = () => _table.current?.onToogleFilter();

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => {
    if (employees.items.length < 2 && employees.page === 1) {
      onGetEmployees();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-container employees-list max-w-7xl">
      <div className="flex flex-col xsl:flex-row pb-0 pt-4 sm:py-8 gap-y-4 justify-between md:items-center">
        <h1 className="main-title pt-0">Munkavállalók</h1>

        <div className="flex flex-col xs:flex-row xs:items-center gap-4">
          <div className="flex gap-x-4">
            <Button to="/kikoltoztetes" title="Kiköltöztetés" hiddenFor="Reader" theme="secondary" />

            <Button to="/bekoltoztetes" title="Beköltöztetés" hiddenFor="Reader" theme="secondary" />

            <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} to="./hozzaadas" hiddenFor="Reader" theme="primary" className="twsm:hidden" />
          </div>

          <Button to="./hozzaadas" title="Új munkavállaló" hiddenFor="Reader" className="hidden twsm:block" />
        </div>
      </div>

      <div className="flex gap-x-2 mt-4 justify-end twlg:hidden">
        <IconButton icon={<FilterIcon className="icon-xs" />} theme="secondary" onClick={onOpenFilter} disabled={!employees.items.length} />

        <IconButton icon={<SortAscendingIcon className="icon-xs" />} theme="secondary" onClick={onOpenSort} disabled={!employees.items.length} />
      </div>

      <Table
        ref={_table}
        header={header}
        className="employees-list-table"
        maxDataCount={employees.totalLength}
        loading={employees.isLoading}
        filter={employees.filter}
        error={employees.error}
        order={employees.order}
        data={employees.items}
        sort={employees.sort}
        page={employees.page}
        onChangePage={onGetEmployees}
        onFilter={onGetEmployees}
        onSearch={onGetEmployees}
        onSort={onGetEmployees}
        onFilterRender={Filter}
        onRowRender={TableRow}
      />
    </div>
  );
}

const Row = (employee: Employee, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={employee.id}>
    <div>
      <span title={employee.name}>{employee.name}</span>

      <Identification mobile={false} idCardNumber={employee.idCardNumber} probondId={employee.probondId} taxIdentificationNumber={employee.taxIdentificationNumber} passportNumber={employee.passportNumber} />
    </div>

    <div>{toDateString(employee.birthdate)}</div>

    <div>{GENDER_MAP[employee.gender]}</div>

    <div>
      {employee.project ? (
        <>
          <Link to={`/projektek/${employee.project.id}`} title={employee.project.workNumber} className="hot-link w-full">
            {employee.project.workNumber}
          </Link>
          {employee.secondaryProject && `(${employee.secondaryProject.workNumber})`}
        </>
      ) : (
        '-'
      )}
    </div>

    <div>
      {employee.accommodation && employee.host ? (
        <>
          <Link title={employee.host.name} to={`/partnerek/szallas-partner/${employee.host.id}`} className="hot-link w-full">
            {employee.host.name}
          </Link>
          <Link title={employee.accommodation.address} to={`/szallasok/${employee.accommodation.id}`} className="hot-link w-full">
            {employee.accommodation.address}
          </Link>
        </>
      ) : (
        '-'
      )}
    </div>

    <div className="action-2">
      <IconButton icon={<EyeIcon className="icon-sm" aria-hidden="true" />} to={`./${employee.id}`} />

      <IconButton icon={<PencilIcon className="icon-sm" aria-hidden="true" />} to={`./szerkesztes/${employee.id}`} hiddenFor="Reader" />
    </div>
  </div>
);

const MobileRow = (employee: Employee) => (
  <Accordion
    key={employee.id}
    className="card"
    isCard
    isBorderBottom
    label={
      <div className="card-header">
        <span className="card-header__primary-text">{employee.name}</span>

        <span className="card-header__secondary-text">
          <Identification mobile={true} idCardNumber={employee.idCardNumber} probondId={employee.probondId} taxIdentificationNumber={employee.taxIdentificationNumber} passportNumber={employee.passportNumber} />
        </span>
      </div>
    }>
    <div className="data">
      <div className="data-item">
        <span className="data-item__title">Nem</span>

        <span className="data-item__data">{GENDER_MAP[employee.gender]}</span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Születési idő</span>

        <span className="data-item__data">{toDateString(employee.birthdate)}</span>
      </div>

      <div className="data-item hidden twmd:flex">
        <span className="data-item__title">Munkaszám</span>

        <span className="data-item__data">
          {employee.project ? (
            <Link to={`/projektek/${employee.project.id}`} className="hot-link">
              {employee.project.workNumber}
            </Link>
          ) : (
            '-'
          )}
        </span>
      </div>

      <div className="data-item hidden twmd:flex">
        <span className="data-item__title">Másodlagos munkaszám</span>

        <span className="data-item__data">
          {employee.secondaryProject ? (
            <>
              <Link to={`/projektek/${employee.secondaryProject.id}`} className="hot-link">
                {employee.secondaryProject.workNumber}
              </Link>
            </>
          ) : (
            '-'
          )}
        </span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Szállás partner</span>

        <span className="data-item__data">
          {employee.host ? (
            <Link title={employee.host.name} to={`/partnerek/szallas-partner/${employee.host.id}`} className="w-full hot-link">
              {employee.host.name}
            </Link>
          ) : (
            '-'
          )}
        </span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Szálláshely</span>

        <span className="data-item__data">
          {employee.accommodation ? (
            <Link title={employee.accommodation.address} to={`/szallasok/${employee.accommodation.id}`} className="hot-link">
              {employee.accommodation.address}
            </Link>
          ) : (
            '-'
          )}
        </span>
      </div>

      <div className="data-item data-item--action">
        <span className="data-item__data gap-x-4 ml-auto">
          <IconButton icon={<EyeIcon className="icon-sm" aria-hidden="true" />} to={`./${employee.id}`} />

          <IconButton icon={<PencilIcon className="icon-sm" aria-hidden="true" />} to={`./szerkesztes/${employee.id}`} hiddenFor="Reader" />
        </span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: LARGE_SCREEN };
