import React from 'react';

/* Presentationt Things */
import { SearchIcon } from '@heroicons/react/solid';

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  theme?: 'table' | 'dialog';
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export const Search = ({ theme = 'table', placeholder = 'Keresés', ...rest }: IProps) => (
  <div
    className={`${rest.className} ${
      theme === 'table'
        ? 'border-t border-b flex flex-row items-center rounded-none border-gray-200 focus-within:ring-0'
        : 'flex items-center pl-2 top-0 h-14 mb-2 bg-white border-b border-gray-200 focus-within:border-b focus-within:border-primary'
    } `}>
    <SearchIcon className={`icon-sm absolute ${theme === 'table' ? 'left-3' : 'left-5'} pointer-events-none" aria-hidden="true`} />

    <input
      {...rest}
      placeholder={placeholder}
      type="search"
      className={
        theme === 'table'
          ? 'h-full w-full border-transparent py-3 pl-10 pr-3 text-sm text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400'
          : 'w-full pl-7 border-transparent text-sm text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400'
      }
    />
  </div>
);
