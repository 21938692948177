import { useCallback, useEffect } from 'react';

// Navigation things
import { RouteComponentProps, Router } from '@reach/router';

//Provider
import { UsersProvider } from '../../contexts/Users';

//Data Things
import { useAuthorization } from '../../hooks';

// Screens
import CreateOrEdit from './CreateOrEdit';
import List from './List';

const Users = (props: RouteComponentProps) => {
  /* Hooks */
  const { role } = useAuthorization();

  const navigateTo404 = useCallback((error: string) => props.navigate!('/404', { state: { error }, replace: true }), [props.navigate]);

  useEffect(() => {
    if (role === 'ProHumanManager' || role === 'BRCManager') {
      navigateTo404('A kért oldalhoz nem rendelkezel megfelelő jogosultsággal!');
    }
  }, [role, navigateTo404]);

  return (
    <UsersProvider>
      <Router basepath="felhasznalok" primary={false}>
        <CreateOrEdit path="szerkesztes/:id" />
        <CreateOrEdit path="hozzaadas" />
        <List default />
      </Router>
    </UsersProvider>
  );
};

export default Users;
