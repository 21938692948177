import { IHostIndividualDTO, IHostCompanyContactDTO, IHostIndividualContactDTO, IHostListItemDTO, IHostCompanyDTO, IHostAccommodationListItemDTO } from "../../../typings/DTOs";
import { DateTime } from "luxon";

export class HostAccommodation {
    public id: number;
    public address: string;
    public booked: number | null;
    public available: number;
    public isActive: boolean;
    public roi: number | null;
    public expenses: number | null;
    public incomes: number | null;

    constructor(p: IHostAccommodationListItemDTO) {
        this.id = p.id;
        this.address = p.address || "";
        this.booked = p.booked;
        this.available = p.available;
        this.isActive = p.isActive;
        this.roi = p.roi;
        this.expenses = p.expenses;
        this.incomes = p.incomes;
    }
}

class IndividualHostContact {
    public homeAddress: string;
    public residenceAddress: string;
    public phoneNumber: string;
    public emailAddress: string;

    constructor(p: IHostIndividualContactDTO) {
        this.homeAddress = p.homeAddress || "";
        this.residenceAddress = p.residenceAddress || "";
        this.emailAddress = p.emailAddress || "";
        this.phoneNumber = p.phoneNumber || "";
    }
}

export class IndividualHost {
    public id: number;
    public name: string;
    public birthName: string;
    public birthdate: DateTime;
    public taxIdentificationNumber: string;
    public socialSecurityNumber: string;
    public idCardNumber: string;
    public bankAccountNumber: string;
    public isActive: boolean;
    public description: string;
    public contact: IndividualHostContact;
    public accommodations: HostAccommodation[] | null = null

    constructor(p: IHostListItemDTO | IHostIndividualDTO) {
        if (this.isIHostIndividualDTO(p)) {
            this.id = p.id;
            this.name = p.name || "";
            this.birthName = p.birthName || "";
            this.birthdate = p.birthdate ? DateTime.fromISO(p.birthdate) : DateTime.now();
            this.taxIdentificationNumber = p.taxIdentificationNumber || "";
            this.socialSecurityNumber = p.socialSecurityNumber || "";
            this.idCardNumber = p.idCardNumber || "";
            this.bankAccountNumber = p.bankAccountNumber || "";
            this.isActive = p.isActive;
            this.description = p.description || "";
            this.contact = new IndividualHostContact(p.contact)
        } else {
            this.id = p.id;
            this.taxIdentificationNumber = p.taxNumber || "";
            this.name = p.name || "";
            this.birthdate = DateTime.now();
            this.isActive = p.isActive;
            this.birthName = "";
            this.socialSecurityNumber = "";
            this.idCardNumber = "";
            this.bankAccountNumber = "";
            this.description = "";
            this.contact = new IndividualHostContact({ emailAddress: "", homeAddress: "", phoneNumber: p.phoneNumber, residenceAddress: "" })
        }
    }

    private isIHostIndividualDTO = (p: IHostListItemDTO | IHostIndividualDTO): p is IHostIndividualDTO => {
        return (p as IHostIndividualDTO).birthName !== undefined;
    }
}

class CompanyHostContact {
    public name: string;
    public phoneNumber: string;
    public emailAddress: string;

    constructor(p: IHostCompanyContactDTO) {
        this.name = p.name || "";
        this.emailAddress = p.emailAddress || "";
        this.phoneNumber = p.phoneNumber || "";
    }
}

export class CompanyHost {
    public id: number;
    public name: string;
    public taxNumber: string;
    public bankAccountNumber: string;
    public isActive: boolean;
    public description: string;
    public contact: CompanyHostContact;
    public accommodations: HostAccommodation[] | null = null

    constructor(p: IHostListItemDTO | IHostCompanyDTO) {
        if (this.isIHostCompanyDTO(p)) {
            this.id = p.id;
            this.name = p.name || "";
            this.taxNumber = p.taxNumber || "";
            this.bankAccountNumber = p.bankAccountNumber || "";
            this.isActive = p.isActive;
            this.description = p.description || "";
            this.contact = new CompanyHostContact(p.contact)
        } else {
            this.id = p.id;
            this.taxNumber = p.taxNumber || "";
            this.name = p.name || "";
            this.isActive = p.isActive;
            this.bankAccountNumber = "";
            this.description = "";
            this.contact = new CompanyHostContact({ emailAddress: "", name: p.contactName, phoneNumber: p.phoneNumber })
        }
    }

    private isIHostCompanyDTO = (p: IHostListItemDTO | IHostCompanyDTO): p is IHostCompanyDTO => {
        return (p as IHostCompanyDTO).description !== undefined;
    }
}

export type HostFilter = { status: 'active' | 'inactive' | null }