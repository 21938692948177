export const TextArea = ({ ...rest }) => (
  <div
    className={`border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary sm:col-span-6 ${
      rest.disabled && 'opacity-40'
    }`}>
    <span className="block text-xs font-medium text-gray-900">{rest.title}</span>

    <textarea className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm resize-none" {...rest} />
  </div>
);
