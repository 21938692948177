import React, { useContext, useEffect, useRef } from 'react';

// Navigation things
import { Link, RouteComponentProps } from '@reach/router';

// Presentation things
import { FilterIcon, PlusIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Button, IconButton } from '../../../components/Buttons';
import { Table, TableRef } from '../../../components/Table';
import { Accordion } from '../../../components/Accordion';
import { Filter } from './Filter';
import { Badge } from '../../../components/Badge';

// Data things
import { ProjectsContext } from '../../../contexts/Projects';
import { LARGE_SCREEN } from '../../../constants';
import { IHeaderCell } from '../../../components/Table/helper';
import { Project } from '../../../contexts/Projects/helper';

// Variables
const header: Array<IHeaderCell<Project> | string> = [{ text: 'Munkaszám', sortName: 'workNumber' }, { text: 'Ügyfél', sortName: 'client' }, 'Ügyfél munkaszám', { text: 'Aktív?', sortName: 'isActive' }];

export default function List(_: RouteComponentProps) {
  /* Context */
  const { projects, onGetProjects } = useContext(ProjectsContext);

  /* Refs */
  const _table = useRef<TableRef>(null);

  const onOpenFilter = () => _table.current?.onToogleFilter();

  const onOpenSort = () => _table.current?.onToogleSort();

  useEffect(() => {
    if (projects.items.length < 2 && projects.page === 1) {
      onGetProjects();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-container projects-list max-w-4xl">
      <div className="table-header pt-4 sm:py-8 flex-row items-center">
        <h1 className="main-title pt-0">Projektek</h1>

        <Button title="Új projekt" hiddenFor={['Reader', 'ProHumanManager', 'BRCManager']} to="/projektek/hozzaadas" className="hidden twsm:block" />

        <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} hiddenFor={['Reader', 'ProHumanManager', 'BRCManager']} to="/projektek/hozzaadas" theme="primary" className="twsm:hidden" />
      </div>

      <div className="flex gap-x-2 mt-4 justify-end twlg:hidden">
        <IconButton icon={<FilterIcon className="icon-xs" />} theme="secondary" onClick={onOpenFilter} disabled={!projects.items.length} />

        <IconButton icon={<SortAscendingIcon className="icon-xs" />} theme="secondary" onClick={onOpenSort} disabled={!projects.items.length} />
      </div>

      <Table
        ref={_table}
        header={header}
        className="projects-list-table"
        maxDataCount={projects.totalLength}
        loading={projects.isLoading}
        filter={projects.filter}
        error={projects.error}
        order={projects.order}
        data={projects.items}
        sort={projects.sort}
        page={projects.page}
        onChangePage={onGetProjects}
        onSearch={onGetProjects}
        onFilter={onGetProjects}
        onSort={onGetProjects}
        onFilterRender={Filter}
        onRowRender={TableRow}
      />
    </div>
  );
}

const MobileRow = (project: Project) => (
  <Accordion
    key={project.id}
    className="card"
    isCard
    isBorderBottom
    summaryClassName="h-auto"
    label={
      <div className="card-header">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col items-start">
            <Link title={project.client.text} to={`/partnerek/ugyfel/${project.client.value}`} className="card-header__primary-text hot-link w-full">
              {project.client.text}
            </Link>
            <span className="card-header__secondary-text">{project.workNumber}</span>
          </div>

          <Badge label={project.isActive === true ? 'Aktív' : 'Inaktív'} className={project.isActive === true ? 'badge--green' : 'badge--gray'} />
        </div>

        <div className="data border-t-0 mt-0">
          <div className="data-item">
            <span className="data-item__title">Ügyfél munkaszám</span>

            <span className="data-item__data">{project.clientWorkNumber}</span>
          </div>
        </div>
      </div>
    }>
    <div className="data border-t-0">
      <div className="data-item data-item--action">
        <div className="data-item__data ml-auto gap-x-4">
          <IconButton icon={<EyeIcon className="icon-sm" />} to={`/projektek/${project.id}`} />

          <IconButton icon={<PencilIcon className="icon-sm" />} to={`/projektek/szerkesztes/${project.id}`} hiddenFor={['Reader', 'ProHumanManager', 'BRCManager']} />
        </div>
      </div>
    </div>
  </Accordion>
);

const Row = (project: Project, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={project.id}>
    <div>
      <span title={project.workNumber}>{project.workNumber}</span>
    </div>

    <div>
      <Link to={`/partnerek/ugyfel/${project.client.value}`} className="hot-link w-full">
        {project.client.text}
      </Link>
    </div>

    <div>
      <span title={project.clientWorkNumber}>{project.clientWorkNumber}</span>
    </div>

    <div>
      <Badge label={project.isActive === true ? 'Aktív' : 'Inaktív'} className={project.isActive === true ? 'badge--green' : 'badge--gray'} />
    </div>

    <div className="action-2">
      <IconButton icon={<EyeIcon className="icon-sm" />} to={`/projektek/${project.id}`} />

      <IconButton icon={<PencilIcon className="icon-sm" />} to={`/projektek/szerkesztes/${project.id}`} hiddenFor={['Reader', 'ProHumanManager', 'BRCManager']} />
    </div>
  </div>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: LARGE_SCREEN };
