import ReactDOM from 'react-dom';
import React from 'react';

/* Component */
import App from './App';

/* Style */
import './index.css';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

//https://github.com/reach/router/issues/504
/* const element = document.getElementById('root');
const root = createRoot(element!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
