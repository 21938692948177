import React, { useCallback, useEffect, useState } from 'react';

// Navigation things
import { Link } from '@reach/router';

// Presentation things
import { Accordion } from '../../../components/Accordion';
import { Table } from '../../../components/Table';

// Data things
import { IExpiredProjectListDTO } from '../../../typings/DTOs';
import { ExpiredProjectListItem } from '../../../contexts/Projects/helper';
import { ApiErrorResult, api } from '../../../utils/api';
import { PaginatedList } from '../../../typings/common';
import { MEDIUM_SCREEN } from '../../../constants';
import { toDateString } from '../../../constants/functions';
import { useIsMounted } from '../../../hooks';

const header = ['Munkavállaló', 'Munkaszám', 'Szálláshely', 'Beköltözés', 'Kiköltözés', 'Szerződés lejárata', ''];

const ExpiredProjects = () => {
  /* States */
  const [projects, setProjects] = useState<PaginatedList<ExpiredProjectListItem>>({ items: [], error: '', isLoading: true, offset: 0, page: 1, totalLength: 0 });

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetAccommodations = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1): Promise<string | null> => {
      try {
        setProjects((prev) => ({ ...prev, error: '', isLoading: true, page }));

        const resp = await api<IExpiredProjectListDTO>('project/expired', { search, limit: 50, offset: (page - 1) * 50 }, { signal });

        if (!resp.message && _isMounted.current) {
          if (!resp.items.length) throw new Error('Nincsenek beköltözések projekt szerződés nélkül');
          setProjects((p) => ({ ...p, items: resp.items.map((x) => new ExpiredProjectListItem(x)), totalLength: resp.totalLength, offset: resp.offset, isLoading: false }));
          return Promise.resolve(null);
        } else throw new Error(resp.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        if (_isMounted.current) {
          setProjects((p) => ({ ...p, items: [], error: String(message || error), isLoading: false }));
        }
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  useEffect(() => void onGetAccommodations(), [onGetAccommodations]);

  return (
    <>
      <div className="table-header flex-row items-center">
        <h3>Beköltözések projekt szerződés nélkül</h3>
      </div>

      <Table
        className="expired-dashboard-table"
        maxDataCount={projects.totalLength}
        loading={projects.isLoading}
        error={projects.error}
        data={projects.items}
        page={projects.page}
        header={header}
        onChangePage={onGetAccommodations}
        onSearch={onGetAccommodations}
        onRowRender={TableRow}
      />
    </>
  );
};

const Row = (row: ExpiredProjectListItem, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={row.employee.id}>
    <div>
      <Link title={row.employee.name} to={`/munkavallalok/${row.employee.id}`} className="hot-link w-full">
        {row.employee.name}
      </Link>

      <span>{row.employee.idNumbers}</span>
    </div>

    <div>
      <Link title={row.project.workNumber} to={`/projektek/${row.project.id}`} className="hot-link w-full">
        {row.project.workNumber}
      </Link>
    </div>

    <div>
      <Link title={row.accommodation.address} to={`/szallasok/${row.accommodation.id}`} className="hot-link w-full">
        {row.accommodation.address}
      </Link>
    </div>

    <div>{toDateString(row.moveInDate)}</div>

    <div>{toDateString(row.moveOutDate)}</div>

    <div>{toDateString(row.lastContractEndDate)}</div>
  </div>
);

const MobileRow = (row: ExpiredProjectListItem) => (
  <Accordion
    key={row.employee.id}
    isCard
    isBorderBottom
    className="card"
    summaryClassName="h-auto"
    label={
      <div className="card-header twsm:flex-row twsm:justify-between twsm:items-center">
        <div className="flex flex-col items-start">
          <Link title={row.employee.name} to={`/munkavallalok/${row.employee.id}`} className="card-header__primary-text hot-link">
            {row.employee.name}
          </Link>
          <span className="card-header__secondary-text">{row.employee.idNumbers}</span>
        </div>

        <div className="data border-t-0 mt-0 twsm:min-w-[160px]">
          <div className="data-item gap-4">
            <span className="data-item__title">Szerződés lejárata</span>

            <span className="data-item__data">{toDateString(row.lastContractEndDate)}</span>
          </div>
        </div>
      </div>
    }>
    <div className="data">
      <div className="data-item">
        <span className="data-item__title">Munkaszám</span>

        <span className="data-item__data">
          <Link title={row.project.workNumber} to={`/projektek/${row.project.id}`} className="hot-link">
            {row.project.workNumber}
          </Link>
        </span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Szálláshely</span>

        <span className="data-item__data">
          <Link title={row.accommodation.address} to={`/szallasok/${row.accommodation.id}`} className="hot-link">
            {row.accommodation.address}
          </Link>
        </span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Beköltözés</span>

        <span className="data-item__data">{toDateString(row.moveInDate)}</span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Kiköltözés</span>

        <span className="data-item__data">{toDateString(row.moveOutDate)}</span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: MEDIUM_SCREEN };

export default ExpiredProjects;
