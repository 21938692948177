import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Navigation things
import { Link, RouteComponentProps } from '@reach/router';

// Presentation things
import { Select, SelectHandle } from '../../components/Select';
import { MissingEmployeesForm } from './MissingForm';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { Button, IconButton } from '../../components/Buttons';
import { UploadIcon } from '@heroicons/react/solid';
import { MoveInForm } from './Form';
import { Snackbar } from '../../components/Snackbar';
import { Checkbox } from '../../components/Inputs';
import { Filter } from './Filter';
import { Table } from '../../components/Table';
import { Identification } from '../../components/Identification';

// Data Things
import {
  transformSelectableEmployeeToIEmployeeMovingIn,
  transformIEmployeeMovingInToICreateBookingDTO,
  transformIEmployeeDTOToIEmployeeMovingIn,
  getVisibleEmployees,
  checkIsNotSaveable,
  SelectableEmployee,
  IEmployeeMovingIn,
  processCSVfile,
  Model,
  MoveInEmployeesFilter,
  getMissingEmployees
} from './helper';
import { ICreateEmployeeDTO, IEmployeeDTO, ISelectableEmployeeDTO } from '../../typings/DTOs';
import { toCurrencyString, toDateString } from '../../constants/functions';
import { ApiErrorResult, api } from '../../utils/api';
import { ConfigContext } from '../../contexts/Config';
import { useIsMounted } from '../../hooks';
import { Order, Sort } from '../../typings/common';

/* Template */ //@ts-ignore
import CSVtemplate from '../../assets/files/template.csv';

type RowProps = { employee: IEmployeeMovingIn; isSelected: boolean; gridTemplate?: React.CSSProperties; onToogleEmployee(id: number): void; onDeleteEmployee(id: number): void };

export default function MoveIn(props: RouteComponentProps) {
  /* States */
  const [isShowMissingEmployeesForm, setIsShowMissingEmployeesForm] = useState(false);
  const [selectableEmployees, setSelectableEmployees] = useState<SelectableEmployee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<IEmployeeMovingIn[]>([]);
  const [missingEmployees, setMissingEmployees] = useState<ICreateEmployeeDTO[]>([]);
  const [isShowMovingForm, setIsShowMovingForm] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState<{ [id: number]: boolean }>({});
  const [filter, setFilter] = useState<MoveInEmployeesFilter>({
    contribution: null,
    moveInFrom: null,
    moveInTo: null,
    moveOutFrom: null,
    moveOutTo: null
  });
  const [sort, setSort] = useState<Sort<IEmployeeMovingIn>>({ sort: 'name', order: 'ASC' });

  /* Variables */
  const editableEmployeesCount = useMemo(() => Object.keys(editable).length, [editable]);
  const visibleEmployees = useMemo(() => getVisibleEmployees(selectedEmployees, searchValue, filter, sort), [selectedEmployees, searchValue, filter, sort]);
  const isNotSaveable = useMemo(() => checkIsNotSaveable(selectedEmployees), [selectedEmployees]);

  /* Context */
  const { onFeedback } = useContext(ConfigContext);

  /* Refs */
  const _employeeSelect = useRef<SelectHandle>(null);
  const _isMounted = useIsMounted();

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await api('booking/movein', undefined, { method: 'POST', body: selectedEmployees.map((x) => transformIEmployeeMovingInToICreateBookingDTO(x)) });
      if (_isMounted.current && props.navigate) {
        props.navigate('/munkavallalok');
        onFeedback({ type: 'success', message: 'Sikeres beköltöztetés' });
      }
    } catch (error) {
      if (_isMounted.current) {
        const { message } = error as ApiErrorResult;
        if (typeof message === 'string') {
          onFeedback({ message, type: 'error' });
        } else {
          //FIXME: error handling
          const errors = message as {id: number, message: string}[];
          errors.forEach(error => {
            const employee = selectedEmployees.find(x => x.id === error.id);
            if(employee) {
              onFeedback({ message: `${employee.name}: ${error.message}`, type: 'error' });
            }
          })
        }
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onToogleEmployee = (id: number) => {
    setEditable((prev) => {
      if (prev[id]) {
        const tmp = { ...prev };
        delete tmp[id];
        return tmp;
      } else {
        return { ...prev, [id]: true };
      }
    });
  };

  const onDeleteEmployee = (id: number) => {
    setSelectedEmployees((prev) => prev.filter((x) => x.id !== id));
    setEditable((prev) => {
      const tmp = { ...prev };
      delete tmp[id];
      return tmp;
    });
  };

  const onToogleEmployees = () => {
    if (selectedEmployees.length === editableEmployeesCount) {
      setEditable({});
    } else {
      setEditable(() => visibleEmployees.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
    }
  };

  const onChangeEmployees = (model: Model) => {
    setSelectedEmployees((employees) => employees.map((emp) => (editable[emp.id] ? { ...emp, ...model } : emp)));
    setEditable({});
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];

      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        if (typeof event.target?.result === 'string') {
          const { processed } = processCSVfile(event.target?.result);

          if (processed.length > 0) {
            const { missingEmployees, existEmployees } = getMissingEmployees(selectableEmployees, processed);

            const transformedExistEmployes = existEmployees.map((x) => transformSelectableEmployeeToIEmployeeMovingIn(x));

            setSelectedEmployees((prev) => [...prev, ...transformedExistEmployes].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i));
            setEditable((prev) => transformedExistEmployes.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), prev));
            setMissingEmployees(missingEmployees);

            if (missingEmployees.length > 0) {
              setIsShowMissingEmployeesForm(true);
            }
          } else {
            console.log("Nincs feldolgozható adat")
          }
        }
      };

      fileReader.readAsText(file);

      e.target.value = '';
    }
  };

  const onAddMissingEmployees = (employees: IEmployeeDTO[]) => {
    setSelectableEmployees((prev) => [...prev, ...employees.map((x) => new SelectableEmployee(x))]);
    setSelectedEmployees((prev) => [...prev, ...employees.map((x) => transformIEmployeeDTOToIEmployeeMovingIn(x))]);
    setEditable((prev) => employees.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), prev));
    setMissingEmployees([]);
  };

  const _rowRenderer = (employee: IEmployeeMovingIn, gridTemplate: React.CSSProperties) => {
    return <Row key={employee.id} employee={employee} isSelected={!!editable[employee.id]} gridTemplate={gridTemplate} onToogleEmployee={onToogleEmployee} onDeleteEmployee={onDeleteEmployee} />;
  };

  const onSelectEmployees = (options: SelectableEmployee[]) => {
    setSelectedEmployees((prev) => {
      options = options.filter((x) => !prev.some((y) => x.value === y.id));
      return [...prev, ...options.map((x) => transformSelectableEmployeeToIEmployeeMovingIn(x))];
    });

    setEditable((prev) => options.reduce((acc, curr) => ({ ...acc, [curr.value]: true }), prev));
  };

  const onCloseMissingEmployeesForm = () => setIsShowMissingEmployeesForm(false);

  const onOpenEmployeesSelect = () => _employeeSelect?.current?.open();

  const onCloseMovingForm = () => setIsShowMovingForm(false);

  const onOpenMovingForm = () => setIsShowMovingForm(true);

  const onChangeFilter = useCallback((filter: MoveInEmployeesFilter) => setFilter(filter), []);

  const onSort = (_: any, __: any, ___: any, ____: any, sort: keyof IEmployeeMovingIn = 'name', order: Order = 'ASC') => setSort({ sort, order });

  useEffect(() => {
    (async () => {
      try {
        const resp = await api<ISelectableEmployeeDTO[]>('employee/moveIn/available');
        if (!resp.message) {
          setSelectableEmployees(resp.map((x) => new SelectableEmployee(x)));
        } else throw new Error(resp.message);
      } catch (error) {}
    })();
  }, []);

  return (
    <div className="page-container max-w-8xl">
      <MissingEmployeesForm onAddEmployees={onAddMissingEmployees} isOpen={isShowMissingEmployeesForm} employees={missingEmployees} onClose={onCloseMissingEmployeesForm} />

      <MoveInForm onClose={onCloseMovingForm} onSubmit={onChangeEmployees} isOpen={isShowMovingForm} edtiableEmployeesCount={editableEmployeesCount} hotelId={Number((props.location?.state as any)?.hotelId)} />

      <div className="flex flex-row pb-0 pt-4 sm:py-8 justify-between sm:items-center">
        <h1 className="main-title pt-0">Beköltöztetés</h1>

        <div className="flex space-x-4 items-center">
          <label htmlFor="upload">
            <span className="icon-button button--secondary">
              <UploadIcon className="icon-xs" />
            </span>

            <input type="file" id="upload" hidden onChange={onFileChange} />
          </label>

          <Select title="Munkavállalók" options={selectableEmployees} dialog="multi" selected={selectedEmployees} onSelect={onSelectEmployees} ref={_employeeSelect}>
            <Button title="Munkavállalók kiválasztása" className="rounded-md hidden twsm:block" onClick={onOpenEmployeesSelect} disabled={!selectableEmployees.length} />

            <IconButton icon={<PlusIcon className="icon-xs icon-without-hover" />} theme="primary" className="rounded-md twsm:hidden" onClick={onOpenEmployeesSelect} />
          </Select>
        </div>
      </div>

      <div className="table-header">
        <div className="flex flex-col space-y-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Kiválasztott munkavállalók</h3>

          <a className="text-sm underline text-gray-600" download="sablon.csv" href={CSVtemplate}>
            Sablon letöltése
          </a>
        </div>

        <Button title="Beköltöztetés" className="ml-auto mb-2" disabled={selectedEmployees.length === 0 || isNotSaveable || isLoading} onClick={onSubmit} loading={isLoading} />
      </div>

      <div className="overflow-x-auto">
        <div className="riports-table-container">
          <Table
            className="moving-in-table"
            data={visibleEmployees}
            filter={filter}
            order={sort.order}
            sort={sort.sort}
            header={[
              visibleEmployees.length > 0 ? <Checkbox checked={editableEmployeesCount === visibleEmployees.length} onChange={onToogleEmployees} /> : '',
              { text: 'Munkavállaló', sortName: 'name' },
              'Jelenleg szálláson',
              { text: 'Szálláshely', sortName: 'accommodation' },
              { text: 'Beköltözés', sortName: 'movingIn' },
              { text: 'Kiköltözés', sortName: 'movingOut' },
              'Munkaszám',
              { text: 'Hozzájárulás', sortName: 'contribution' }
            ]}
            onSearch={setSearchValue}
            onSort={onSort}
            onFilterRender={(open, onClose, onSubmit) => <Filter filter={filter} open={open} onClose={onClose} onSubmit={onSubmit} onChangeFilter={onChangeFilter} />}
            onRowRender={{ default: _rowRenderer }}
            error={searchValue && !visibleEmployees.length ? 'A keresett kifejezésre nincs találat' : !selectedEmployees.length ? 'Válassz ki munkavállalókat' : ''}
          />
        </div>
      </div>

      <Snackbar opened={editableEmployeesCount > 0} label={`${editableEmployeesCount} munkavállaló van kiválasztva`} type="info" actionButton={{ title: 'Szerkesztés', onClick: onOpenMovingForm }} />
    </div>
  );
}

const Row = ({ employee, isSelected, onToogleEmployee, onDeleteEmployee, gridTemplate }: RowProps) => {
  const handleDeleteEmployee = () => onDeleteEmployee(employee.id);

  const handleToogleEmployee = () => onToogleEmployee(employee.id);

  return (
    <div className="data-table__body-row" style={gridTemplate} key={employee.id}>
      <div className="checkbox-cell">
        <Checkbox className="p-0" id={String(employee.id)} checked={isSelected} onChange={handleToogleEmployee} />
      </div>

      <label htmlFor={String(employee.id)}>
        <span className="w-full truncate" title={employee.name}>
          {employee.name}
        </span>

        <span className="text-gray-500 w-full truncate">
          <Identification mobile={false} idCardNumber={employee.idCardNumber} probondId={employee.probondId} taxIdentificationNumber={employee.taxIdentificationNumber} passportNumber={employee.passportNumber} />
        </span>
      </label>

      <div className="flex flex-col leading-none items-start">
        {employee.movedIn && (
          <>
            <span>{toDateString(employee.movedIn)}</span>
            {employee.movedOut && <span>{toDateString(employee.movedOut)}</span>}
          </>
        )}
      </div>

      <div>
        {employee.accommodation ? (
          <>
            <Link to={`/szallasok/${employee.accommodation.value}`} title={employee.accommodation.text} className="hot-link w-full">
              {employee.accommodation.text}
            </Link>

            <span>{employee.accommodation.description}</span>
          </>
        ) : (
          '-'
        )}
      </div>

      <div>{toDateString(employee.movingIn)}</div>

      <div>{toDateString(employee.movingOut)}</div>

      <div className="flex flex-col">
        <span>{employee.project?.text ?? '-'}</span>
        {employee.secondaryProject && <span>({employee.secondaryProject.text})</span>}
      </div>

      <div>{employee.contribution ? toCurrencyString(employee.contribution) : '-'}</div>

      <div className="action-1">
        <TrashIcon className="icon-sm cursor-pointer" onClick={handleDeleteEmployee} aria-hidden="true" />
      </div>
    </div>
  );
};
