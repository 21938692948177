export const CircularProgress = ({ className, theme = 'colored' }: { className?: string; theme?: 'colored' | 'button' | 'full-height' }) => {
  return (
    <span className={`flex items-center justify-center ${className} ${theme === 'full-height' && 'fixed top-0 left-0 right-0 h-full z-20 bg-black/30'}`}>
      <svg className={`mdc-circular-progress w-6 h-6 ${theme === 'button' && 'w-3.5 h-3.5'}`} viewBox="25 25 50 50">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={theme === 'button' ? 'white' : '#4d9326'} />
            <stop offset="100%" stopColor={theme === 'button' ? 'white' : '#A5EC7A'} />
          </linearGradient>
        </defs>

        <circle className="mdc-circular-progress__path" cx="50" cy="50" r="20" fill="none" stroke="url(#gradient)" strokeWidth="6" strokeMiterlimit="10" />
      </svg>
    </span>
  );
};
