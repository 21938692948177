import { EmployeeAccommodationListItem } from "../../../contexts/Employees/helper";

export const getVisibleAccommodations = (searchValue: string, accommodations?: EmployeeAccommodationListItem[] | null): EmployeeAccommodationListItem[] => {
    if (Array.isArray(accommodations)) {
        const search = searchValue.toLowerCase();
        return accommodations.filter(x => {
            if (x.project.workNumber.toLowerCase().includes(search) || x.accommodation.address.toLowerCase().includes(search) || x.host.name.toLowerCase().includes(search)) {
                return true;
            }
            return false
        })
    }
    return []
}