import React, { useCallback, useReducer } from 'react';

/* Data Things */
import { ICreateUserDTO, IUserDTO, IUpdateUserDTO } from '../../typings/DTOs';
import { UsersState, usersReducer, initialState } from '../../reducers/Users';
import { ApiErrorResult, api } from '../../utils/api';
import { useIsMounted } from '../../hooks';

/* Presentation Things */
import { Snackbar } from '../../components/Snackbar';

interface IUsersContext extends UsersState {
  onGetUsers: () => Promise<string | null>;
  onGetUser: (id: number) => Promise<string | null>;
  onEditUser: (body: IUpdateUserDTO, id: number) => Promise<string | null>;
  onAddUser: (body: ICreateUserDTO) => Promise<string | null>;
}

const UsersContext = React.createContext<IUsersContext>({
  ...initialState,
  onGetUsers: () => Promise.reject(),
  onGetUser: () => Promise.reject(),
  onEditUser: () => Promise.reject(),
  onAddUser: () => Promise.reject()
});

function UsersProvider(props: React.PropsWithChildren<{}>) {
  /* States */
  const [state, dispatch] = useReducer(usersReducer, initialState);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetUsers = useCallback(async (): Promise<string | null> => {
    try {
      const users = await api<IUserDTO[]>('user');

      if (!users.message && users.length && _isMounted.current) {
        dispatch({ type: 'success_get_users', payload: { users } });

        return Promise.resolve(null);
      } else throw new Error(users.message || 'Nincsen rendelkezésre álló felhasználó');
    } catch (error) {
      const { message } = error as ApiErrorResult;
      _isMounted.current && dispatch({ type: 'error_get_users', payload: { error: message } });
      return Promise.reject(message);
    }
  }, [_isMounted]);

  const onGetUser = useCallback(
    async (id: number): Promise<string | null> => {
      try {
        const user = await api<IUserDTO>(`user/${id}`);

        if (!user.message && _isMounted.current) {
          dispatch({ type: 'success_get_user', payload: { user } });

          return Promise.resolve(null);
        } else throw new Error(user.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onEditUser = useCallback(
    async (body: IUpdateUserDTO, id: number): Promise<string | null> => {
      try {
        const user = await api<IUserDTO>(`user/${id}`, undefined, { method: 'PUT', body });

        if (!user.message && _isMounted.current) {
          dispatch({ type: 'success_put_user', payload: { user } });

          return Promise.resolve(null);
        } else throw new Error(user.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_put_user', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAddUser = useCallback(
    async (body: ICreateUserDTO): Promise<string | null> => {
      try {
        const user = await api<IUserDTO>('user', undefined, { method: 'POST', body });

        if (!user.message && _isMounted.current) {
          dispatch({ type: 'success_post_user', payload: { user } });

          return Promise.resolve(null);
        } else throw new Error(user.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && dispatch({ type: 'error_post_user', payload: { error: message } });
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  const onAutoCloseResponse = useCallback(() => dispatch({ type: 'set_partial_state', payload: { response: null } }), []);

  return (
    <UsersContext.Provider value={{ ...state, onAddUser, onEditUser, onGetUser, onGetUsers }}>
      <Snackbar opened={!!state.response} label={state.response!?.message} type={state.response!?.type} onAutoClose={onAutoCloseResponse} />
      {props.children}
    </UsersContext.Provider>
  );
}

export { UsersProvider, UsersContext };
