import React, { useCallback, useEffect, useState } from 'react';

// Navigation things
import { Link } from '@reach/router';

// Presentation things
import { Accordion } from '../../../components/Accordion';
import { Table } from '../../../components/Table';

// Data things
import { OverbookedAccommodationListItem } from '../../../contexts/Accommodations/helper';
import { IOverbookedAccommodationListDTO } from '../../../typings/DTOs';
import { ApiErrorResult, api } from '../../../utils/api';
import { toPrettifiedNumber } from '../../../constants/functions';
import { PaginatedList } from '../../../typings/common';
import { MEDIUM_SCREEN } from '../../../constants';
import { useIsMounted } from '../../../hooks';

// Variables
const header = ['Szállás', 'Maximum férőhely / Elszállásolt munkavállalók', 'Túlfoglalt', ''];

const OverbookedAccommodations = () => {
  /* States */
  const [accommodations, setAccommodations] = useState<PaginatedList<OverbookedAccommodationListItem>>({ items: [], error: '', isLoading: true, offset: 0, page: 1, totalLength: 0 });

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetAccommodations = useCallback(
    async (search: string = '', signal?: AbortSignal, page: number = 1): Promise<string | null> => {
      try {
        setAccommodations((prev) => ({ ...prev, error: '', isLoading: true, page }));

        const resp = await api<IOverbookedAccommodationListDTO>('accommodation/overbooks', { search, limit: 50, offset: (page - 1) * 50 }, { signal });

        if (!resp.message && _isMounted.current) {
          if (!resp.items.length) throw new Error('Nincs túlfoglalt szálláshely');
          setAccommodations((p) => ({ ...p, items: resp.items.map((x) => new OverbookedAccommodationListItem(x)), totalLength: resp.totalLength, offset: resp.offset, isLoading: false }));
          return Promise.resolve(null);
        } else throw new Error(resp.message);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        if (_isMounted.current) {
          setAccommodations((p) => ({ ...p, items: [], error: String(message || error), isLoading: false }));
        }
        return Promise.reject(message);
      }
    },
    [_isMounted]
  );

  useEffect(() => void onGetAccommodations(), [onGetAccommodations]);

  return (
    <>
      <div className="table-header flex-row items-center">
        <h3>Túlfoglalt szálláshelyek</h3>
      </div>

      <Table
        className="overbooked-dashboard-table"
        maxDataCount={accommodations.totalLength}
        loading={accommodations.isLoading}
        error={accommodations.error}
        data={accommodations.items}
        page={accommodations.page}
        header={header}
        onChangePage={onGetAccommodations}
        onSearch={onGetAccommodations}
        onRowRender={TableRow}
      />
    </>
  );
};

const Row = (row: OverbookedAccommodationListItem, gridTemplate: React.CSSProperties) => (
  <div className="data-table__body-row" style={gridTemplate} key={row.accommodation.id}>
    <div>
      <Link title={row.accommodation.address} to={`/szallasok/${row.accommodation.id}`} className="hot-link w-full">
        {row.accommodation.address}
      </Link>
    </div>

    <div>
      {toPrettifiedNumber(row.maximumCapacity)} / {toPrettifiedNumber(row.booked)}
    </div>

    <div>
      <span className="text-red-800">{row.booked - row.maximumCapacity} fő</span>
    </div>
  </div>
);

const MobileRow = (row: OverbookedAccommodationListItem) => (
  <Accordion
    key={row.accommodation.id}
    isCard
    isBorderBottom
    className="card"
    summaryClassName="h-auto"
    label={
      <div className="card-header twsm:flex-row twsm:justify-between twsm:items-center">
        <Link title={row.accommodation.address} to={`/szallasok/${row.accommodation.id}`} className="card-header__primary-text hot-link">
          {row.accommodation.address}
        </Link>
        <div className="data border-t-0 mt-0 twsm:min-w-[190px]">
          <div className="data-item gap-4">
            <span className="data-item__title">Túlfoglalt</span>

            <span className="data-item__data text-red-800">{row.booked - row.maximumCapacity} fő</span>
          </div>
        </div>
      </div>
    }>
    <div className="data">
      <div className="data-item">
        <span className="data-item__title">Maximum férőhely</span>

        <span className="data-item__data">{toPrettifiedNumber(row.maximumCapacity)}</span>
      </div>

      <div className="data-item">
        <span className="data-item__title">Elszállásolt munkavállalók</span>

        <span className="data-item__data">{toPrettifiedNumber(row.booked)}</span>
      </div>
    </div>
  </Accordion>
);

const TableRow = { default: Row, mobile: MobileRow, breakpoint: MEDIUM_SCREEN };

export default OverbookedAccommodations;
