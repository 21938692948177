import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

// Presentation things
import { Dialog, DialogHandle } from '../../../components/Dialog';
import { FilterSelect, SelectDialogBody } from '../../../components/Select';
import { TableChips } from '../../../components/Table';
import { Fieldset } from '../../../components/Fieldset';
import { Button } from '../../../components/Buttons';
import { Radio } from '../../../components/Inputs';
import { Chips } from '../../../components/Chips';

/* Data Things */
import { SelectableProject } from '../../../contexts/Config/helper';
import { EmployeesContext } from '../../../contexts/Employees';
import { EmployeeFilter } from '../../../contexts/Employees/helper';
import { isEmptyObject } from '../../../constants/functions';
import { ConfigContext } from '../../../contexts/Config';
import { GENDER_MAP } from '../../../constants';
import { Sex } from '../../../typings/enum';

type FilterBodyProps = {
  onNextScreen(): void;
  onSelectGender(ev: React.ChangeEvent<HTMLInputElement>): void;
  selectedProject?: SelectableProject;
  model: EmployeeFilter;
};

export const Filter = (open: boolean, onClose: () => void, onSubmit?: (filter: EmployeeFilter) => Promise<void>) => {
  /* Contexts */
  const { workNumbers } = useContext(ConfigContext);
  const {
    employees: { filter },
    onFilter
  } = useContext(EmployeesContext);

  /* States */
  const [tempModel, setTempModel] = useState(filter);

  /* Variables */
  const selectedProject = useMemo(() => workNumbers.find((p) => p.value === tempModel.project), [workNumbers, tempModel.project]);
  const hasActiveFilter = useMemo(() => !isEmptyObject(filter), [filter]);

  /* Refs */
  const _dialog = useRef<DialogHandle>(null);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(tempModel);
      onFilter(tempModel);
      onClose();
    }
  };

  const handleClose = () => {
    setTempModel(filter);
    onClose();
  };

  const onDeselectGender = () => {
    if (onSubmit) {
      onSubmit({ ...tempModel, sex: null });
      onFilter({ ...tempModel, sex: null });
    }
  };

  const onDeselectProject = () => {
    if (onSubmit) {
      onSubmit({ ...tempModel, project: null });
      onFilter({ ...tempModel, project: null });
    }
  };

  const onSelectProject = (option: SelectableProject | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (option instanceof SelectableProject) {
      setTempModel((prev) => ({ ...prev, project: option.value }));
    } else {
      _dialog.current?.previousScreen();
    }
  };

  const onSelectGender = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTempModel((p) => ({ ...p, sex: Number(ev.target.value) }));
  };

  const onNextScreen = () => _dialog.current?.nextScreen();

  useEffect(() => setTempModel(filter), [filter]);

  return (
    <>
      <Dialog
        open={open}
        ref={_dialog}
        onClose={handleClose}
        className="h-[100vh] sm:h-[80vh]"
        contents={[
          {
            title: 'Szűrő',
            body: <FilterBody onNextScreen={onNextScreen} onSelectGender={onSelectGender} model={tempModel} selectedProject={selectedProject} />,
            footer: <Button title="Kész" className="grow" onClick={handleSubmit} />
          },
          {
            title: 'Munkaszám kiválasztása',
            body: <SelectDialogBody options={workNumbers} dialog="single" onSelect={onSelectProject} selecteds={selectedProject} />,
            footer: <Button title="Kiválasztom" className="grow" onClick={onSelectProject} />
          }
        ]}
      />

      <TableChips hasActiveFilter={hasActiveFilter}>
        {filter.sex && <Chips key="gender" label={GENDER_MAP[filter.sex]} onDelete={onDeselectGender} />}

        {filter.project && selectedProject && <Chips key="project" label={selectedProject.text} onDelete={onDeselectProject} />}
      </TableChips>
    </>
  );
};

const FilterBody = ({ onNextScreen, onSelectGender, selectedProject, model }: FilterBodyProps) => {
  return (
    <div className="p-5 flex flex-col gap-y-8 text-sm pb-8">
      <FilterSelect label="Munkaszám kiválasztása" selecteds={selectedProject?.text} onNextScreen={onNextScreen} />
      <Fieldset label="Nem">
        <Radio title="Férfi" htmlFor="male" value={Sex.Male} theme="form" checked={model.sex === Sex.Male} onChange={onSelectGender} />
        <Radio title="Nő" htmlFor="female" value={Sex.Female} theme="form" checked={model.sex === Sex.Female} onChange={onSelectGender} />
      </Fieldset>
    </div>
  );
};
