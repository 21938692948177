import { IClientWorkNumberListItemDTO } from "../../../../typings/DTOs";
import { DateTime } from "luxon";

export interface IClientWorkNumberListItem {
  id: number;
  workNumber: string;
  createdAt: DateTime;
  isActive: boolean;
};

export const transformIClientWorkNumberListItemDTO = (p: IClientWorkNumberListItemDTO): IClientWorkNumberListItem => {
  return {
    createdAt: p.createdAt ? DateTime.fromISO(p.createdAt) : DateTime.now(),
    id: p.id,
    isActive: p.isActive,
    workNumber: p.workNumber || ""
  }
}