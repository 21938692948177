import { SelectableAccommodation, SelectableClient, SelectableProHumanManager } from '../../../contexts/Config/helper';
import { ICreateProjectDTO, IUpdateProjectDTO } from '../../../typings/DTOs';
import { ChargeType } from '../../../typings/enum';
import { DateTime } from 'luxon';
import { Project } from '../../../contexts/Projects/helper';
import { TODAY } from '../../../constants';
import { File } from '../../../typings/common';

export type Model = {
  clientWorkNumber: string;
  workNumber: string;
  client: SelectableClient | null;
  proHumanManagers: SelectableProHumanManager[];
};

export type SpecialCostModel = {
  id: number | null;
  tempId: number;
  accommodation: SelectableAccommodation;
  cost: {
    chargeType: ChargeType;
    cost: number;
    dailyMinimum: number | null;
  };
};

export type ContractModel = {
  id: number | null;
  tempId: number;
  endDate: DateTime | null;
  startDate: DateTime;
  noticeDays: number | null;
  specialCosts: SpecialCostModel[];
  attachments: File[];
  tempAttachments: File[];
};

export const DEFAULT_CONTRACT = (tempId: number, startDate?: DateTime): ContractModel => {
  return {
    tempId,
    id: null,
    endDate: null,
    startDate: startDate || TODAY,
    noticeDays: null,
    specialCosts: [],
    attachments: [],
    tempAttachments: []
  };
};

export const transformProjectToModels = (
  project: Project,
  clients: SelectableClient[],
  selectableProHumanManagers: SelectableProHumanManager[],
  accommodations: SelectableAccommodation[]
): { model: Model; contracts: ContractModel[] } => {
  const proHumanManagers = selectableProHumanManagers.filter((x) => project.proHumanManagers.some((y) => y.value === x.value));
  const selectedClient = clients.find((x) => x.value === project.client.value) || null;

  return {
    model: {
      client: selectedClient,
      proHumanManagers,
      clientWorkNumber: project.clientWorkNumber,
      workNumber: project.workNumber
    },
    contracts: (project.contracts || []).map((x) => ({
      id: x.id,
      tempId: x.id,
      endDate: x.endDate,
      startDate: x.startDate,
      noticeDays: x.noticeDays,
      specialCosts: x.specialCosts.reduce((acc, curr) => {
        const accommodation = accommodations.find((i) => i.value === curr.accommodation.id);
        if (accommodation) {
          return [
            ...acc,
            {
              id: curr.id,
              tempId: curr.accommodation.id,
              accommodation,
              cost: {
                dailyMinimum: curr.cost.chargeType === ChargeType.Daily ? curr.cost.dailyMinimum : null,
                chargeType: curr.cost.chargeType,
                cost: curr.cost.cost
              }
            }
          ];
        }
        return acc;
      }, [] as SpecialCostModel[]),
      attachments: x.attachments,
      tempAttachments: []
    }))
  };
};

export const transformModelToICreateProjectDTO = (model: Model, contracts: ContractModel[]): ICreateProjectDTO => {
  const proHumanManagerIds = model.proHumanManagers.map((x) => x.value);
  return {
    proHumanManagerIds: proHumanManagerIds.length > 0 ? proHumanManagerIds : null,
    clientId: model.client!.value,
    workNumber: model.workNumber,
    clientWorkNumber: model.clientWorkNumber,
    contracts: contracts.map((x) => {
      const specialCosts = x.specialCosts.map((y) => ({
        accommodationId: y.accommodation.value,
        cost: y.cost
      }));
      const attachments = x.attachments.map((y) => y.id);
      const tempAttachments = x.tempAttachments.map((y) => y.id);
      return {
        id: x.id,
        startDate: x.startDate.toISODate(),
        endDate: x.endDate ? x.endDate.toISODate() : null,
        noticeDays: x.noticeDays,
        specialCosts: specialCosts.length > 0 ? specialCosts : null,
        attachments: attachments.length > 0 ? attachments : null,
        tempAttachments: tempAttachments.length > 0 ? tempAttachments : null
      };
    })
  };
};

export const transformModelToIUpdateProjectDTO = (model: Model, contracts: ContractModel[]): IUpdateProjectDTO => {
  const proHumanManagerIds = model.proHumanManagers.map((x) => x.value);
  return {
    proHumanManagerIds: proHumanManagerIds.length > 0 ? proHumanManagerIds : null,
    clientId: model.client!.value,
    workNumber: model.workNumber,
    clientWorkNumber: model.clientWorkNumber,
    contracts: contracts.map((x) => {
      const specialCosts = x.specialCosts.map((y) => ({
        id: y.id,
        accommodationId: y.accommodation.value,
        cost: y.cost
      }));
      const attachments = x.attachments.map((y) => y.id);
      const tempAttachments = x.tempAttachments.map((y) => y.id);
      return {
        id: x.id,
        startDate: x.startDate.toISODate(),
        endDate: x.endDate ? x.endDate.toISODate() : null,
        noticeDays: x.noticeDays,
        specialCosts: specialCosts.length > 0 ? specialCosts : null,
        attachments: attachments.length > 0 ? attachments : null,
        tempAttachments: tempAttachments.length > 0 ? tempAttachments : null
      };
    })
  };
};
