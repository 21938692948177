import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentation things
import { Checkbox, Input, TextArea } from '../../../../components/Inputs';
import { CircularProgress } from '../../../../components/CircularProgress';
import { Button } from '../../../../components/Buttons';

// Data Things
import {
  EMPTY_COMPANY_HOST,
  EMPTY_INDIVIDUAL_HOST,
  getSelectedView,
  transformICreateHostIndividualDTO,
  transformIUpdateHostIndividualDTO,
  transformToICreateHostCompanyDTO,
  transformToIUpdateHostCompanyDTO
} from './helper';
import { useAuthorization, useNavigate, useIsMounted } from '../../../../hooks';
import { CompanyHost, IndividualHost } from '../../../../contexts/Partners/Hosts/helper';
import { taxNumberCheck } from '../../../Employees/CreateOrEdit/helper';
import { ApiErrorResult } from '../../../../utils/api';
import { onChangeInput } from '../../../../constants/functions';
import { HostsContext } from '../../../../contexts/Partners/Hosts';

export default function CreateOrEdit(props: RouteComponentProps<{ id: number }>) {
  // Context
  const { onGetHost, hosts } = useContext(HostsContext);

  //Hooks
  const { role } = useAuthorization();
  const { goTo404 } = useNavigate();
  const _isMounted = useIsMounted();

  // Variables
  const existHost = useMemo(() => hosts.items.find((x) => x.id === Number(props.id)), [hosts, props.id]);
  const isEditPage = props.id !== undefined;

  // States
  const [selectedView, setSelectedView] = useState(getSelectedView(existHost));
  const [isLoading, setIsLoading] = useState(isEditPage);
  const [error, setError] = useState('');

  const handleGetHost = useCallback(async () => {
    if (props.id) {
      try {
        setIsLoading(true);
        setError('');

        const resp = await onGetHost(Number(props.id));
        if (typeof resp === 'string') throw new Error(resp);
      } catch (error) {
        const { message } = error as ApiErrorResult;
        _isMounted.current && setError(String(message || error));
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    }
  }, [props.id, onGetHost, _isMounted]);

  const onSelectIndividualView = () => setSelectedView('individual');

  const onSelectCompanyView = () => setSelectedView('company');

  useEffect(() => void handleGetHost(), [handleGetHost]);

  useEffect(() => setSelectedView(getSelectedView(existHost)), [existHost]);

  useEffect(() => {
    if (role === 'ProHumanManager' || role === 'Reader') {
      goTo404();
    }
  }, [role, goTo404]);

  return (
    <div className="page-container max-w-4xl">
      {isLoading ? (
        <CircularProgress className="flex-1" />
      ) : error.length ? (
        <div className="full-page-error">
          <span className="error-text">{error}</span>
          <Button title="Újratöltés" onClick={handleGetHost} />
        </div>
      ) : (
        <>
          <div className="flex flex-col sm:flex-row py-8 gap-y-4 justify-between sm:items-center">
            <div className="flex flex-row items-center justify-between sm:pt-10 w-full">
              <h1 className="main-title pt-0">{isEditPage ? `${existHost?.name} szerkesztése` : 'Hozzáadás'}</h1>

              {!isEditPage && (
                <div className="flex space-x-3">
                  <Button title="Cég" theme={selectedView === 'company' ? 'primary' : 'secondary'} onClick={onSelectCompanyView} />
                  <Button title="Magánszemély" onClick={onSelectIndividualView} theme={selectedView === 'individual' ? 'primary' : 'secondary'} />
                </div>
              )}
            </div>
          </div>

          {selectedView === 'company' ? <CompanyForm host={existHost as CompanyHost} isEdit={isEditPage} /> : <IndividualForm host={existHost as IndividualHost} isEdit={isEditPage} />}
        </>
      )}
    </div>
  );
}

function CompanyForm({ host, isEdit }: { host?: CompanyHost; isEdit: boolean }) {
  /* Context */
  const { onAddCompanyHost, onEditCompanyHost } = useContext(HostsContext);

  /* States */
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(host || EMPTY_COMPANY_HOST);

  /* Hooks */
  const _isMounted = useIsMounted();
  const { goBack } = useNavigate();

  const onSubmit = async (ev: React.FormEvent) => {
    try {
      ev.preventDefault();
      setIsLoading(true);

      const resp = isEdit ? await onEditCompanyHost(transformToIUpdateHostCompanyDTO(model), Number(host?.id)) : await onAddCompanyHost(transformToICreateHostCompanyDTO(model));

      if (typeof resp !== 'string' && _isMounted.current) {
        goBack();
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onChangeContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        [e.target.name]: e.target.value
      }
    }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e, setModel);

  const onToogleIsActive = () => setModel((prev) => ({ ...prev, isActive: !prev.isActive }));

  useEffect(() => {
    if (host) {
      setModel(host);
    }
  }, [host]);

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit}>
      <div className="pt-4 sm:pt-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Alapadatok</h3>

        <div className="mt-6 grid gap-4 grid-cols-6">
          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Cégnév" name="name" value={model.name} disabled={isLoading} onChange={handleOnChange} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Adószám" name="taxNumber" value={model.taxNumber} disabled={isLoading} onChange={handleOnChange} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Bankszámlaszám" name="bankAccountNumber" value={model.bankAccountNumber} disabled={isLoading} onChange={handleOnChange} required />
          </div>

          {isEdit && (
            <div className="col-span-6 sm:col-span-3 md:col-span-2">
              <Checkbox title="Aktív?" htmlFor="active" checked={model.isActive} disabled={isLoading} onChange={onToogleIsActive} theme="form" />
            </div>
          )}

          <div className="col-span-6">
            <TextArea title="Megjegyzés" rows={4} name="description" id="comment" disabled={isLoading} value={model.description} onChange={handleOnChange} />
          </div>
        </div>
      </div>

      <div className="pt-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Elérhetőség</h3>

        <div className="mt-6 grid gap-4 grid-cols-6">
          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Kapcsolattartó neve" name="name" value={model.contact.name} onChange={onChangeContact} disabled={isLoading} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="tel" label="Telefonszám" name="phoneNumber" value={model.contact.phoneNumber} onChange={onChangeContact} disabled={isLoading} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="email" label="Email" name="emailAddress" value={model.contact.emailAddress} onChange={onChangeContact} disabled={isLoading} required />
          </div>
        </div>
      </div>

      <div className="flex space-x-3 justify-end pt-5">
        <Button type="button" title="Mégsem" theme="secondary" disabled={isLoading} onClick={goBack} />
        <Button type="submit" disabled={isLoading} loading={isLoading} title="Mentés" />
      </div>
    </form>
  );
}

function IndividualForm({ host, isEdit }: { host?: IndividualHost; isEdit: boolean }) {
  /* Context */
  const { onAddIndividualHost, onEditIndividualHost } = useContext(HostsContext);

  /* States */
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(host || EMPTY_INDIVIDUAL_HOST);

  /* Hooks */
  const _isMounted = useIsMounted();
  const { goBack } = useNavigate();

  /* Refs */
  const taxIdentificationNumberRef_ = useRef<HTMLInputElement>(null);

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    try {
      let validTaxNumber = false;
      if (taxIdentificationNumberRef_.current) {
        validTaxNumber = taxIdentificationNumberRef_.current.checkValidity();
      }

      if (validTaxNumber) {
        setIsLoading(true);
        const resp = isEdit ? await onEditIndividualHost(transformIUpdateHostIndividualDTO(model), Number(host?.id)) : await onAddIndividualHost(transformICreateHostIndividualDTO(model));

        if (typeof resp !== 'string' && _isMounted.current) {
          goBack();
        }
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onChangeContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        [e.target.name]: e.target.value
      }
    }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeInput(e, setModel);
  };

  const onToogleIsActive = () => setModel((prev) => ({ ...prev, isActive: !prev.isActive }));

  useEffect(() => {
    if (model.taxIdentificationNumber?.length && model.birthdate) {
      const result = taxNumberCheck(model.taxIdentificationNumber, model.birthdate.toISODate().substring(0, 10));
      if (!result.isValid) {
        taxIdentificationNumberRef_.current?.setCustomValidity(
          'Kérjük ellenőrizze az adóazonosító számot!' + (result.correctBirthdate ? ` A helyes születési dátum: ${result.correctBirthdate.toLocaleDateString()}` : '')
        );
      } else {
        taxIdentificationNumberRef_.current?.setCustomValidity('');
      }
    } else {
      taxIdentificationNumberRef_.current?.setCustomValidity('');
    }
  }, [model.birthdate, model.taxIdentificationNumber]);

  useEffect(() => {
    if (host) {
      setModel(host);
    }
  }, [host]);

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit}>
      <div className="pt-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Alapadatok</h3>

        <div className="mt-6 grid gap-4 grid-cols-6">
          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Név" name="name" value={model.name} disabled={isLoading} onChange={handleOnChange} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Születési név" name="birthName" value={model.birthName} disabled={isLoading} onChange={handleOnChange} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="date" label="Születési idő" name="birthdate" value={model.birthdate} disabled={isLoading} onChange={handleOnChange} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input
              ref={taxIdentificationNumberRef_}
              minLength={10}
              maxLength={10}
              label="Adóazonosító jele"
              name="taxIdentificationNumber"
              value={model.taxIdentificationNumber}
              disabled={isLoading}
              onChange={handleOnChange}
              required
            />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="TAJ szám" name="socialSecurityNumber" value={model.socialSecurityNumber} disabled={isLoading} onChange={handleOnChange} />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Szig. szám" name="idCardNumber" value={model.idCardNumber} disabled={isLoading} onChange={handleOnChange} />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Bankszámlaszám" name="bankAccountNumber" value={model.bankAccountNumber} disabled={isLoading} onChange={handleOnChange} required />
          </div>

          {isEdit && (
            <div className="col-span-6 sm:col-span-3 md:col-span-2">
              <Checkbox title="Aktív?" htmlFor="active" checked={model.isActive} disabled={isLoading} onChange={onToogleIsActive} theme="form" />
            </div>
          )}

          <div className="col-span-6">
            <TextArea title="Megjegyzés" rows={4} name="description" id="comment" value={model.description} disabled={isLoading} onChange={handleOnChange} />
          </div>
        </div>
      </div>

      <div className="pt-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Elérhetőség</h3>

        <div className="mt-6 grid gap-4 grid-cols-6">
          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Állandó lakcím" name="homeAddress" value={model.contact.homeAddress} disabled={isLoading} onChange={onChangeContact} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input label="Tartózkodási cím" name="residenceAddress" value={model.contact.residenceAddress} disabled={isLoading} onChange={onChangeContact} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="tel" label="Telefonszám" name="phoneNumber" value={model.contact.phoneNumber} disabled={isLoading} onChange={onChangeContact} required />
          </div>

          <div className="col-span-6 sm:col-span-3 md:col-span-2">
            <Input type="email" label="Email" name="emailAddress" value={model.contact.emailAddress} disabled={isLoading} onChange={onChangeContact} required />
          </div>
        </div>
      </div>

      <div className="flex space-x-3 justify-end pt-5">
        <Button type="button" title="Mégsem" theme="secondary" onClick={goBack} disabled={isLoading} />
        <Button type="submit" title="Mentés" disabled={isLoading} loading={isLoading} />
      </div>
    </form>
  );
}
