import { ICreateBookingDTO, ICreateEmployeeDTO, IEmployeeDTO, ISelectableEmployeeDTO } from '../../typings/DTOs';
import { SelectableAccommodation, SelectableProject } from '../../contexts/Config/helper';
import { basicSort } from '../../constants/functions';
import { DateTime } from 'luxon';
import { parse } from 'papaparse';
import { Sort } from '../../typings/common';
import { Sex } from '../../typings/enum';

export class SelectableEmployee {
  public value: number;
  public text: string;
  public idCardNumber: string;
  public probondId: string;
  public taxIdentificationNumber: string;
  public passportNumber: string;
  public isRelative: boolean;
  public disabled: boolean;
  public moveOutData: DateTime | null;
  public moveInDate: DateTime | null;

  constructor(p: ISelectableEmployeeDTO | IEmployeeDTO) {
    const moveOutData = 'moveOutDate' in p && typeof p.moveOutDate === 'string' ? DateTime.fromISO(p.moveOutDate) : null;
    const moveInDate = 'moveInDate' in p && typeof p.moveInDate === 'string' ? DateTime.fromISO(p.moveInDate) : null;

    this.value = p.id;
    this.text = p.name || '';
    this.moveInDate = moveInDate;
    this.moveOutData = moveOutData;
    this.isRelative = p.isRelative;
    this.disabled = Boolean(moveInDate && !moveOutData);
    this.idCardNumber = p.idCardNumber || '';
    this.probondId = p.probondId || '';
    this.taxIdentificationNumber = p.taxIdentificationNumber || '';
    this.passportNumber = p.passportNumber || '';
  }
}

export interface IEmployeeMovingIn {
  id: number;
  name: string;
  idCardNumber: string;
  probondId: string;
  taxIdentificationNumber: string;
  passportNumber: string;
  movedIn: DateTime | null;
  movedOut: DateTime | null;
  movingIn: DateTime | null;
  movingOut: DateTime | null;
  accommodation: SelectableAccommodation | null;
  project: SelectableProject | null;
  secondaryProject: SelectableProject | null;
  contribution: number | null;
}

export type Model = {
  accommodation: SelectableAccommodation | null;
  project: SelectableProject | null;
  secondaryProject: SelectableProject | null;
  movingOut: DateTime | null;
  movingIn: DateTime;
  contribution: number | null;
};

export const transformIEmployeeMovingInToICreateBookingDTO = (x: IEmployeeMovingIn): ICreateBookingDTO => {
  return {
    accommodationId: x.accommodation!.value,
    employeeId: x.id,
    moveInDate: x.movingIn!.toISODate(),
    moveOutDate: x.movingOut ? x.movingOut.toISODate() : null,
    monthlyContributions: x.contribution,
    projectId: x.project!.value,
    secondaryProjectId: x.secondaryProject?.value ?? null
  };
};

export const transformSelectableEmployeeToIEmployeeMovingIn = (employee: SelectableEmployee): IEmployeeMovingIn => {
  return {
    id: employee.value,
    name: employee.text,
    idCardNumber: employee.idCardNumber,
    probondId: employee.probondId,
    taxIdentificationNumber: employee.taxIdentificationNumber,
    passportNumber: employee.passportNumber,
    movedIn: employee.moveInDate,
    movedOut: employee.moveOutData,
    movingIn: null,
    movingOut: null,
    accommodation: null,
    project: null,
    secondaryProject: null,
    contribution: null
  };
};

export const transformIEmployeeDTOToIEmployeeMovingIn = (employee: IEmployeeDTO): IEmployeeMovingIn => {
  return {
    id: employee.id,
    accommodation: null,
    idCardNumber: employee.idCardNumber || '',
    probondId: employee.probondId || '',
    taxIdentificationNumber: employee.taxIdentificationNumber || '',
    passportNumber: employee.passportNumber || '',
    contribution: null,
    movedIn: null,
    movedOut: null,
    movingIn: null,
    movingOut: null,
    name: employee.name || '',
    project: null,
    secondaryProject: null
  };
};

export const checkIsNotSaveable = (employees: IEmployeeMovingIn[]) => employees.some((x) => !x.accommodation || !x.project || !x.movingIn);

export const getVisibleEmployees = (employees: IEmployeeMovingIn[], searchValue: string, filter: MoveInEmployeesFilter, sort: Sort<IEmployeeMovingIn>): IEmployeeMovingIn[] => {
  searchValue = searchValue.toLowerCase();

  const filteredEmployees = employees.filter((emp) => {
    if (filter.contribution && !emp.contribution) {
      return false;
    }

    if (filter.moveInFrom && ((emp.movingIn && emp.movingIn < filter.moveInFrom) || !emp.movingIn)) {
      return false;
    }

    if (filter.moveInTo && ((emp.movingIn && emp.movingIn > filter.moveInTo) || !emp.movingIn)) {
      return false;
    }

    if (filter.moveOutFrom && ((emp.movingOut && emp.movingOut < filter.moveOutFrom) || !emp.movingOut)) {
      return false;
    }

    if (filter.moveOutTo && ((emp.movingOut && emp.movingOut > filter.moveOutTo) || !emp.movingOut)) {
      return false;
    }

    return true;
  });

  return filteredEmployees.filter((x) => x.name.toLowerCase().includes(searchValue)).sort((x, y) => basicSort(x, y, sort));
};

export const processCSVfile = (raw: string): { error: string[]; processed: ICreateEmployeeDTO[] } => {
  const rows = raw
    .slice(raw.indexOf('\n') + 1)
    .split('\n')
    .filter(Boolean);
  return rows.reduce(
    (acc, currentRow) => {
      const columns = parse<string>(currentRow, { header: false, delimitersToGuess: [';', ',', '\t'] })?.data[0];

      if (columns) {
        const sex = columns[1]?.toLocaleLowerCase();

        const dob = columns[2] ? new Date(columns[2]) : undefined;
        return {
          error: acc.error,
          processed: [
            ...acc.processed,
            {
              name: columns[0],
              sex: sex?.startsWith('f') ? Sex.Male : sex?.startsWith('n') ? Sex.Female : undefined,
              birthdate: dob ? [dob.getFullYear(), dob.getMonth() + 1, dob.getDate()].map(x => (x + '').padStart(2, '0')).join('-') : undefined,
              idCardNumber: columns[3] || '',
              passportNumber: columns[4] || '',
              taxIdentificationNumber: columns[5] || '',
              probondId: columns[6] || '',
              isRelative: columns[7] && columns[7] === 'Igen' ? true : false,
              relativeIdentifier: columns[8] || '',
              relatives: []
            } as ICreateEmployeeDTO
          ]
        };
      }

      return { ...acc, error: [...acc.error, currentRow] };
    },
    { error: [], processed: [] } as { error: string[]; processed: ICreateEmployeeDTO[] }
  );
};

export const getMissingEmployees = (selectableEmployees: SelectableEmployee[], processed: ICreateEmployeeDTO[]): { missingEmployees: ICreateEmployeeDTO[]; existEmployees: SelectableEmployee[] } => {
  const missingEmployees: ICreateEmployeeDTO[] = [];
  const existEmployees: SelectableEmployee[] = [];

  for (let i = 0; i < processed.length; i++) {
    const processedIds = new Set([processed[i]?.idCardNumber, processed[i]?.passportNumber, processed[i]?.taxIdentificationNumber, processed[i]?.probondId].filter(Boolean));
    const exist = selectableEmployees.find(
      (y) => [y.idCardNumber, y.passportNumber, y.taxIdentificationNumber, y.probondId].filter(Boolean).some((x) => processedIds.has(x)) //any of the ids match with any of the processed ids
    );
    if (exist) {
      existEmployees.push(exist);
    } else {
      missingEmployees.push(processed[i] as ICreateEmployeeDTO);
    }
  }

  return { missingEmployees, existEmployees };
};

export type MoveInEmployeesFilter = {
  moveInFrom: DateTime | null;
  moveInTo: DateTime | null;
  moveOutFrom: DateTime | null;
  moveOutTo: DateTime | null;
  contribution: boolean | null;
};
