import { XIcon } from '@heroicons/react/solid';
import { motion } from 'framer-motion';

type Props = {
  label: string;
  from?: string | number | null;
  to?: string | number | null;
  unit?: string;
  className?: string;
  onDelete(): void;
};

export const Chips = ({ label, from, to, className, onDelete }: Props) => {
  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.1 }}
      exit={{ scale: 0.8, opacity: 0 }}
      className={`flex basis-[fit-content] gap-1 ring-1 ring-gray-200 shrink-0 grow-0 m-0 items-center px-2 py-1 text-primary-darker/75 bg-gray-50 text-xs rounded-md ${className}`}>
      <span className={from || to ? 'font-semibold' : ''}>{label}</span>

      {from ? <span>{`${!to ? '>= ' : ''} ${from}`}</span> : null}

      {to ? <span>{`${!from ? '<= ' : ' - '}${to}`}</span> : null}

      <XIcon className="icon h-3 ml-1 cursor-pointer" onClick={onDelete} />
    </motion.div>
  );
};
