import { IHostAccommodationListItemDTO, IHostCompanyDTO, IHostDTO, IHostIndividualDTO, IHostListDTO } from "../../../typings/DTOs";
import { APIResponseFeedback, FilterAndSorteablePaginatedList, Order } from "../../../typings/common";
import { CompanyHost, HostAccommodation, HostFilter, IndividualHost } from "../../../contexts/Partners/Hosts/helper";

export const initialState: HostsState = {
    hosts: {
        items: [],
        totalLength: 0,
        offset: 0,
        page: 1,
        isLoading: true,
        error: '',
        filter: {
            status: null
        },
        order: 'ASC',
        sort: 'name'
    },
    response: null,
};

export interface HostsState {
    hosts: FilterAndSorteablePaginatedList<HostFilter, IndividualHost | CompanyHost>
    response: APIResponseFeedback | null;
}

type HostsAction =
    | { type: 'success_get_hosts'; payload: { hosts: IHostListDTO, page: number, sort: keyof (CompanyHost | IndividualHost), order: Order } }
    | { type: 'error_get_hosts'; payload: { error: string } }
    | { type: 'success_get_host'; payload: { host: IHostDTO, accommodations: IHostAccommodationListItemDTO[] } }
    | { type: 'success_post_company'; payload: { company: IHostCompanyDTO } }
    | { type: 'error_post_company'; payload: { error: string } }
    | { type: 'success_post_individual'; payload: { individual: IHostIndividualDTO } }
    | { type: 'error_post_individual'; payload: { error: string } }
    | { type: 'success_put_individual'; payload: { host: IHostIndividualDTO } }
    | { type: 'error_put_individual'; payload: { error: string } }
    | { type: 'success_put_company'; payload: { host: IHostCompanyDTO } }
    | { type: 'error_put_company'; payload: { error: string } }
    | { type: 'set_filter'; payload: { filter: HostFilter } }
    | { type: 'set_partial_state'; payload: Partial<HostsState> }

export function hostsReducer(state: HostsState = initialState, action: HostsAction): HostsState {
    switch (action.type) {
        case 'success_get_hosts': {
            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    items: action.payload.hosts.items.reduce((acc, curr) => {
                        return [...acc, curr.contactName ? new CompanyHost(curr) : new IndividualHost(curr)]
                    }, [] as (CompanyHost | IndividualHost)[]),
                    totalLength: action.payload.hosts.totalLength,
                    offset: action.payload.hosts.offset,
                    order: action.payload.order,
                    sort: action.payload.sort,
                    page: action.payload.page,
                    isLoading: false,
                    error: ''
                },
            };
        }

        case 'error_get_hosts': {
            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }

        case "success_get_host": {
            let isExist = false;
            const newHost = action.payload.host.company ? new CompanyHost(action.payload.host.company) : new IndividualHost(action.payload.host.individual!)
            newHost.accommodations = action.payload.accommodations.map(accommodation => new HostAccommodation(accommodation));

            state.hosts.items.forEach((host, i) => {
                if (host.id === action.payload.host.company?.id || host.id === action.payload.host.individual?.id) {
                    isExist = true;
                    state.hosts.items[i] = newHost
                }
            })

            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    items: isExist ? [...state.hosts.items] : [newHost, ...state.hosts.items]
                }
            }
        }

        case "success_post_company": {
            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    items: [new CompanyHost(action.payload.company), ...state.hosts.items],
                    totalLength: state.hosts.totalLength + 1
                },
                response: { type: 'success', message: "Sikeres szállás partner létrehozás" }
            }
        }

        case "error_post_company": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_post_individual": {
            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    items: [new IndividualHost(action.payload.individual), ...state.hosts.items]
                },
                response: { type: 'success', message: "Sikeres szállás partner létrehozás" }
            }
        }

        case "error_post_individual": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_put_individual": {
            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    items: state.hosts.items.map(x => x.id === action.payload.host.id ? new IndividualHost(action.payload.host) : x)
                },
                response: { type: 'success', message: "Sikeres szállás partner szerkesztés" }
            }
        }

        case "error_put_individual": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case "success_put_company": {
            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    items: state.hosts.items.map(x => x.id === action.payload.host.id ? new CompanyHost(action.payload.host) : x)
                },
                response: { type: 'success', message: "Sikeres szállás partner szerkesztés" }
            }
        }

        case "error_put_company": {
            return {
                ...state,
                response: { type: 'error', message: action.payload.error }
            }
        }

        case 'set_filter': {
            return {
                ...state,
                hosts: {
                    ...state.hosts,
                    filter: action.payload.filter
                }
            }
        }

        case "set_partial_state": {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state;
    }
}
